import React from 'react';

import styles from './SettingsContent.module.css';
import NameAndPhone from './NameAndPhone/NameAndPhone';
import MainSettings from './MainSettings/MainSettings';
import MainButtons from './MainButtons/MainButtons';
function SettingsContent() {
    return (
        <div className={styles.content}>
            <NameAndPhone />
            <MainSettings />
            <MainButtons />
        </div>
    );
}

export default SettingsContent;
