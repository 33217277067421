import React, { useEffect, useRef, useState } from 'react';

import '../../../components/Pages/Requests/CreateRequest/ModalViews/ModalView.css';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
    selectContactInfo,
    selectContactName,
    setContactName,
} from '../../../store/slices/sliceSelectedInfo';
import { RootState } from '../../../store/store';
import ModalOk from '../../../content/svg/RequestsPage/ModalOk';
import AddInfoButton from '../../Utils/Requests/CreateRequest/AddInfoForm/AddInfoButton';
import { ContactInfoDataType } from '../Interfaces/Interfaces';
import { fixPageState } from '../../../store/slices/sliceUser';

interface ContactInfoProps {
    content: ContactInfoDataType[];
    backRedirect: string;
    request: any; //todo
    setContent: React.Dispatch<any[]>;
}

//! много ререндеров

function ContactInfoModal({ content, backRedirect, request, setContent }: ContactInfoProps) {
    const theme = localStorage.getItem('theme');

    const telephone = useSelector((state: RootState) => state.selectedInfo.selectedContactNumber);
    const [isChanged, setIsChanged] = useState(false);

    const selectedRef = useRef<HTMLDivElement>(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (selectedRef.current !== null) {
            selectedRef.current.scrollIntoView({
                behavior: 'smooth',
                inline: 'start',
                block: 'center',
            });
        }
    }, [content]);

    const setContactInformation = (selectedPhone: string, name: string) => {
        setData();

        navigate(`/${backRedirect}`, { state: { request: request, paramToFixPage: true } });

        function setData() {
            dispatch(selectContactInfo(selectedPhone));
            dispatch(setContactName(name));
            dispatch(fixPageState(true));
        }
    };

    return (
        <>
            <div className="contentContainer">
                <div className="contentFields">
                    {content.map((ticket, index) => (
                        <div
                            onClick={() => setContactInformation(ticket.title, ticket?.name)}
                            key={index}
                            ref={ticket.title === telephone ? selectedRef : null}
                            className="contentTicket"
                        >
                            {ticket.name ? (
                                <div className="boxContaienr">
                                    <span className="contentTxt">{ticket.name}</span>
                                    <span className="phoneTxt">{ticket.title}</span>
                                </div>
                            ) : (
                                <>
                                    <span className="contentTxt">{ticket.title}</span>
                                    {ticket.title === telephone ? <ModalOk theme={theme} /> : null}
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </div>

            <AddInfoButton
                isChanged={isChanged}
                setIsChanged={setIsChanged}
                content={content}
                setContent={setContent}
            />
        </>
    );
}

export default ContactInfoModal;

// ! Предыдущая версия кода в мапе

// if (ticket.name === '') {
//     return (
//         <div
//             onClick={() => setContactInformation(ticket.title)}
//             key={index}
//             className="contentTicket"
//         >
// <span className="contentTxt">{ticket.title}</span>
// {ticket.title === telephone ? <ModalOk theme={theme} /> : null}
//         </div>
//     );
// } else {
//     return (
//         <div
//             onClick={() => setContactInformation(ticket.title)}
//             key={index}
//             className="contentTicket"
//         >
// <div className="boxContaienr">
//     <span className="contentTxt">{ticket.name}</span>
//     <span className="phoneTxt">{ticket.title}</span>
// </div>
//             {ticket.title === telephone ? <ModalOk theme={theme} /> : null}
//         </div>
//     );
// }
