import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

import { RootState } from '../../../store/store';
import { togglePopup } from '../../../store/slices/sliceDocuments';
import { baseType, mobileProrabIntstance } from '../../Entities/Lib/axios';
import { getCookie } from '../../Entities/Lib/CookieWork';

import styles from './Popup.module.css';
import toast from 'react-hot-toast';

function Popup(): React.JSX.Element | null {
    const dispatch = useDispatch();

    const isPopupOpen = useSelector<RootState>((state) => state.documents.isPopupOpen);
    const deliveryUID = useSelector<RootState>((state) => state.documents.deliveryTicket);
    const email = localStorage.getItem('email');

    const handleSendLetter = async (type: string, uid: string) => {
        if (email !== 'undefined' && email !== 'Нету привязанной почты') {
            const response = mobileProrabIntstance.post('sendLetter', {
                type: type,
                docUid: uid,
            });

            toast.promise(response, {
                loading: 'Отправка...',
                success: 'Письмо отправлено',
                error: 'Произошла ошибка, повторите попытку позже',
            });

            dispatch(togglePopup(false));
        } else {
            toast.error('Нету привязанной почты, обратитесь к менеджеру');
        }
    };

    if (isPopupOpen) {
        return (
            <div className={styles.modal} onClick={() => dispatch(togglePopup(false))}>
                <div className={styles.modal__content} onClick={(event) => event.stopPropagation()}>
                    <div className={styles.modal__header}>
                        <div className={styles.modal__title}>
                            Выберите документ <br /> для скачивания
                        </div>
                    </div>
                    <div className={styles.modal__footer}>
                        {localStorage.getItem('isOpenDocumentInBrowser') === 'true' ? (
                            <>
                                <Link
                                    target="_blank"
                                    to={`https://api-erkon.tech/${baseType}/hs/mobileProrab/deliveryDoc/${deliveryUID}/${getCookie(
                                        'token',
                                    )}/Паспорт`}
                                    className={styles.modal__button}
                                >
                                    PDF
                                </Link>
                                <Link
                                    target="_blank"
                                    to={`https://api-erkon.tech/${baseType}/hs/mobileProrab/deliveryDoc/${deliveryUID}/${getCookie(
                                        'token',
                                    )}/ЭЦП`}
                                    className={styles.modal__button}
                                >
                                    Комплект ЭЦП
                                </Link>
                            </>
                        ) : (
                            <>
                                <button
                                    onClick={() => handleSendLetter('pass', deliveryUID as string)}
                                    className={styles.modal__button}
                                >
                                    PDF
                                </button>
                                <button
                                    onClick={() => handleSendLetter('ecp', deliveryUID as string)}
                                    className={styles.modal__button}
                                >
                                    Комплект ЭЦП
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
}

export default React.memo(Popup);
