import React from 'react';

import styles from './Department.module.css';

function Department({ request }: { request: any }) {
    //todo
    return (
        <div className={styles.content}>
            <div className={styles.upSide}>
                <div className={styles.requestlaceholder}>
                    <span className={styles.requestTxt}>Заявка подтверждена.</span>
                </div>
                <div className={styles.requestlaceholder}>
                    <span className={styles.requestTxt}>Идет распределение на завод.</span>
                </div>
            </div>
            <div className="capacityInfo">
                <div className="capacityBlock">
                    <span className="capacityTitle">Общий</span>
                    <span className="capacityValue">{`${request.capacity}м3`}</span>
                </div>

                <hr className="infoBlockLine" />

                <div className="capacityBlock">
                    <span className="capacityTitle">Размещено</span>
                    <span className="capacityValue">{`${request.shipped}м3`}</span>
                </div>
                <hr className="infoBlockLine" />

                <div className="capacityBlock">
                    <span className="capacityTitle">Выгружено</span>
                    <span className="capacityValue">{`${request.distributed}м3`}</span>
                </div>
            </div>
        </div>
    );
}

export default Department;
