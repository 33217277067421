import { addDays, addMinutes } from 'date-fns';

export const createCookie = (cookieName, cooikeValue, expiresDate) => {
    if (!expiresDate) {
        expiresDate = addDays(new Date(), 7);
    }

    document.cookie = `${cookieName}=${cooikeValue}; expires=${expiresDate.toUTCString()}`;
};

export const getCookie = (name) => {
    const matches = document.cookie.match(
        new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'),
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const setCookie = (name, value, expirydays) => {
    const d = new Date();
    d.setTime(d.getTime() + expirydays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    document.cookie = name + '=' + value + '; ' + expires;
};

export const deleteCookie = (name) => {
    setCookie(name, '', -1);
};

export const deleteAllCookies = () => {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
};
