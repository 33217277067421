import React, { useState } from 'react';

import styles from './ChangeName.module.css';

import Header from '../../Requests/CreateRequest/Header';
import Navigation from '../../Navigation/Navigation';
import { useNavigate } from 'react-router-dom';
import sendSettings from '../../../Entities/Lib/sendSettings';

function ChangeName() {
    const navigate = useNavigate();

    const [name, setName] = useState(localStorage.getItem('name') || '');

    function saveNewName() {
        localStorage.setItem('name', name);
        navigate('/settings');
    }

    return (
        <>
            <Header headerTitle={'Изменение имени'} backRedirect={'settings'} />

            <div className={styles.content}>
                <div className={styles.textField}>
                    <span className={styles.nameLabel}>Имя</span>
                    <input
                        minLength={2}
                        maxLength={20}
                        className={styles.nameValue}
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                    ></input>
                </div>
                <button onClick={() => sendSettings(saveNewName)} className={styles.submitBtn}>
                    Сохранить
                </button>
            </div>

            <Navigation currentSection={'Настройки'} />
        </>
    );
}

export default ChangeName;
