import { useMemo, useState } from 'react';
import React from 'react';
import Choicer from '../../Shared/Choicer/Choicer';
import styles from './StatisticUpd.module.css';
import { useGetUPDStatisticQuery } from '../../../servises/api';
import { Link, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { ChartUPD } from '../../Shared/Chart.js/ChartUPD/ChartUPD';
import { getCookie } from '../../Entities/Lib/CookieWork';

export const StatisticUpd = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
    const [statisticUpd, setStatisticUpd] = useState('Все');
    const navigate = useNavigate();
    const selectedValues = useSelector((state: RootState) => state.bonuses.selectedValues);

    const { data, isSuccess } = useGetUPDStatisticQuery({
        startDate,
        endDate,
        selectedValues: selectedValues,
        valueType: sessionStorage?.getItem('listType')
            ? JSON.parse(sessionStorage?.getItem('listType') as unknown as string)
            : 'Адреса',
    });

    const totalSum = useMemo(() => {
        return statisticUpd === 'Все'
            ? data?.updRating?.goods?.reduce(
                  (sum: number, { summ }: { summ: number }) => sum + summ,
                  0,
              ) +
                  data?.updRating?.services.reduce(
                      (sum: number, { summ }: { summ: number }) => sum + summ,
                      0,
                  )
            : statisticUpd === 'Товары'
              ? data?.updRating?.goods?.reduce(
                    (sum: number, { summ }: { summ: number }) => sum + summ,
                    0,
                )
              : statisticUpd === 'Услуги' &&
                data?.updRating?.services?.reduce(
                    (sum: number, { summ }: { summ: number }) => sum + summ,
                    0,
                );
    }, [isSuccess, statisticUpd]);

    const calculateData = () => {
        return statisticUpd === 'Все'
            ? /* eslint-disable-next-line no-unsafe-optional-chaining */
              [...data?.updRating?.goods, ...data?.updRating?.services]
            : statisticUpd === 'Товары'
              ? data?.updRating?.goods
              : statisticUpd === 'Услуги' && data?.updRating?.services;
    };

    const dataDiagram = useMemo(() => {
        const labelsAll = data?.salesRating?.all.map((el: { summ: number; name: string }) => {
            if (el.name.slice(0, 6) === 'Услуга') {
                return el.name.slice(7);
            }
            return el.name;
        });
        const labelsGoods = data?.salesRating?.goods.map((el: { summ: number; name: string }) => {
            return el.name;
        });
        const labelsServices = data?.salesRating?.services.map(
            (el: { summ: number; name: string }) => {
                if (el.name.slice(0, 6) === 'Услуга') {
                    return el.name.slice(7);
                }
                return el.name;
            },
        );

        const summaryAll = data?.salesRating?.all.map((el: { summ: number; name: string }) => {
            return el.summ;
        });
        const summaryGoods = data?.salesRating?.goods.map((el: { summ: number; name: string }) => {
            return el.summ;
        });
        const summaryServices = data?.salesRating?.services.map(
            (el: { summ: number; name: string }) => {
                return el.summ;
            },
        );

        return {
            labels:
                statisticUpd === 'Все'
                    ? labelsAll
                    : statisticUpd === 'Товары'
                      ? labelsGoods
                      : statisticUpd === 'Услуги' && labelsServices,
            datasets: [
                {
                    backgroundColor: '#F96760',
                    borderColor: '#F96760',
                    borderRadius: 4,
                    borderSkipped: false,
                    barThickness: 35,
                    data:
                        statisticUpd === 'Все'
                            ? summaryAll
                            : statisticUpd === 'Товары'
                              ? summaryGoods
                              : statisticUpd === 'Услуги' && summaryServices,
                },
            ],
        };
    }, [isSuccess, statisticUpd]);

    if (isSuccess) {
        return (
            <>
                <div className={styles.headerContainer}>
                    <span className={styles.headTxt}>СТАТИСТИКА ПО УПД</span>

                    <Choicer
                        choiceList={['Все', 'Товары', 'Услуги']}
                        chosen={statisticUpd}
                        setChoice={setStatisticUpd}
                    />

                    <div className={styles.pieChart}>
                        <ChartUPD chartData={dataDiagram} />
                        <div className={styles.txt}>
                            <span className={styles.totalSum}>Всего сумма</span>
                            <div className={styles.amount}>
                                <span className={styles.amountTxt}>
                                    {totalSum.toLocaleString('ru-RU', {
                                        style: 'currency',
                                        currency: 'RUB',
                                    })}
                                </span>
                            </div>
                        </div>
                        <Link
                            target="_blank"
                            to={`https://api-erkon.tech/mobile/hs/mobileProrab/updStatisticsExcel/${getCookie(
                                'token',
                            )}/${startDate.getTime()}/${endDate.getTime()}`}
                            className={styles.downloadButton}
                        >
                            Скачать реестр
                        </Link>
                    </div>
                </div>
                <div className={styles.list}>
                    {statisticUpd === 'Все' ? (
                        <>
                            {data?.updRating?.goods.slice(0, 3).map((item: any) => (
                                <div
                                    key={item.date + item.name + item.summ}
                                    className={styles.blockContainer}
                                >
                                    <div className={styles.firstBlockContainer}>
                                        <span
                                            style={{ flex: '1 0 0' }}
                                            className={styles.headerTxt}
                                        >
                                            {`${item.name} (${format(item.date, 'dd.MM/HH:mm')})`}
                                        </span>
                                        <span className={styles.headerTxt}>
                                            {item.summ.toLocaleString('ru-RU', {
                                                style: 'currency',
                                                currency: 'RUB',
                                            })}
                                        </span>
                                    </div>
                                    <div className={styles.firstBlockContainer}>
                                        <span style={{ flex: '1 0 0' }} className={styles.lowerTxt}>
                                            Объем
                                        </span>
                                        <span className={styles.lowerTxt}>{item.amount} м3</span>
                                    </div>
                                </div>
                            ))}
                        </>
                    ) : statisticUpd === 'Товары' ? (
                        <>
                            {data?.updRating?.goods.slice(0, 3).map((item: any) => (
                                <div
                                    key={item.date + item.name + item.summ}
                                    className={styles.blockContainer}
                                >
                                    <div className={styles.firstBlockContainer}>
                                        <span
                                            style={{ flex: '1 0 0' }}
                                            className={styles.headerTxt}
                                        >
                                            {`${item.name} (${format(item.date, 'dd.MM/HH:mm')})`}
                                        </span>
                                        <span className={styles.headerTxt}>
                                            {item.summ.toLocaleString('ru-RU', {
                                                style: 'currency',
                                                currency: 'RUB',
                                            })}{' '}
                                        </span>
                                    </div>
                                    <div className={styles.firstBlockContainer}>
                                        <span style={{ flex: '1 0 0' }} className={styles.lowerTxt}>
                                            Объем
                                        </span>
                                        <span className={styles.lowerTxt}>{item.amount} м3</span>
                                    </div>
                                </div>
                            ))}
                        </>
                    ) : statisticUpd === 'Услуги' ? (
                        <>
                            {data?.updRating?.services.slice(0, 3).map((item: any) => (
                                <div
                                    key={item.date + item.name + item.summ}
                                    className={styles.blockContainer}
                                >
                                    <div className={styles.firstBlockContainer}>
                                        <span
                                            style={{ flex: '1 0 0' }}
                                            className={styles.headerTxt}
                                        >
                                            {`${item.name} (${format(item.date, 'dd.MM/HH:mm')})`}
                                        </span>
                                        <span className={styles.headerTxt}>
                                            {item.summ.toLocaleString('ru-RU', {
                                                style: 'currency',
                                                currency: 'RUB',
                                            })}
                                        </span>
                                    </div>
                                    <div className={styles.firstBlockContainer}>
                                        <span style={{ flex: '1 0 0' }} className={styles.lowerTxt}>
                                            Объем
                                        </span>
                                        <span className={styles.lowerTxt}>{item.amount} м3</span>
                                    </div>
                                </div>
                            ))}
                        </>
                    ) : (
                        <div></div>
                    )}
                </div>

                <section className={styles.buttonContainer}>
                    <button
                        onClick={() =>
                            navigate('updList', {
                                state: { data: calculateData(), statisticUpd: statisticUpd },
                            })
                        }
                        className={styles.allButton}
                    >
                        Смотреть{' '}
                        {statisticUpd === 'Все'
                            ? 'все товары и услуги'
                            : statisticUpd.toLocaleLowerCase()}
                    </button>

                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7.21967 4.73285C6.92678 5.02575 6.92678 5.50062 7.21967 5.79351L11.6893 10.2632L7.21967 14.7329C6.92678 15.0257 6.92678 15.5006 7.21967 15.7935C7.51256 16.0864 7.98744 16.0864 8.28033 15.7935L13.2803 10.7935C13.5732 10.5006 13.5732 10.0257 13.2803 9.73285L8.28033 4.73285C7.98744 4.43996 7.51256 4.43996 7.21967 4.73285Z"
                            fill="#007AFF"
                        />
                    </svg>
                </section>
            </>
        );
    } else {
        return (
            <div className={styles.headerContainer}>
                <span className={styles.headTxt}>СТАТИСТИКА ПО УПД</span>

                <Choicer
                    choiceList={['Все', 'Товары', 'Услуги']}
                    chosen={statisticUpd}
                    setChoice={setStatisticUpd}
                />
            </div>
        );
    }
};
