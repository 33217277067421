import React, { useEffect } from 'react';

import { BrowserRouter } from 'react-router-dom';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { registerLocale } from 'react-datepicker';

import { setDefaultLocale } from 'react-datepicker';

import { ru } from 'date-fns/locale';
import { I18nextProvider } from 'react-i18next';

import { YMaps } from '@pbe/react-yandex-maps';
import './i18next';

import { getCookie } from './components/Entities/Lib/CookieWork.js';
import CodeInputModal from './components/Utils/Settings/ChangePhone/CodeInput/CodeInputModal'; // * do not using
import CodeInput from './components/Widgets/CodeInput/CodeInput'; // * do not using but css xD

import LoginByPhoneField from './components/Pages/Auth/Login.tsx';
import Logo from './components/Shared/Animations/Logo/Logo';
import SuccessAniamtion from './components/Shared/Animations/Success/Success';
import OnBording from './components/Pages/OnBording/OnBording';
import Settings from './components/Pages/Settings/Settings';
import PushesSettings from './components/Utils/Settings/Pushes/PushesSettings/PushesSettings';
import PrivacyPolicy from './components/Utils/Settings/PrivacyPolicy/PrivacyPolicy';
import Language from './components/Utils/Settings/Language/Language';
import ChangeName from './components/Utils/Settings/ChangeName/ChangeName';
import ChangePhone from './components/Utils/Settings/ChangePhone/ChangePhone';
import Documents from './components/Pages/Documents/Documents';
import AdditionalCapacity from './components/Widgets/AdditionalCapacity/AdditionalCapacity.jsx';
import ChangeRequestMenu from './components/Pages/Requests/ShowRequestInfo/ChangeRequestMenu';
import UpdateRequest from './components/Pages/Requests/ShowRequestInfo/Actions/ChangeRequest/UpdateRequest';
import CreateRequestMenu from './components/Pages/Requests/CreateRequest/CreateRequestMenu';
import ConfirmRequest from './components/Pages/Requests/ShowRequestInfo/Actions/ConfirmRequest/ConfirmRequest';
import Address from './components/Pages/Requests/CreateRequest/CreatingComponents/Address';
import ModalView from './components/Pages/Requests/CreateRequest/ModalViews/ModalView';
import Capacity from './components/Pages/Requests/CreateRequest/CreatingComponents/Capacity';
import ContactInfo from './components/Pages/Requests/CreateRequest/CreatingComponents/ContactInformation';
import Success from './components/Utils/Requests/Success/Success.jsx';
import Bonuses from './components/Pages/Bonuses/Bonuses';
import CardChoose from './components/Pages/Bonuses/CardChoose/CardChoose';
import CodeConfirm from './components/Widgets/CodeConfirm/CodeConfirm.jsx';
import GiftStory from './components/Pages/Bonuses/GiftStory/GiftStory';
import Nomenclature from './components/Pages/Requests/ShowRequestInfo/Actions/ChangeRequest/RequestsChangedValues/Nomenclature';
import Requests from './components/Pages/Requests/Requests';
import Error from './components/Utils/Requests/Error/Error';
import CodeInputUniversal from './components/Widgets/CodeInput/CodeInputUniversal';
import Listener from './servises/Listener.js';
import PeriodSelect from './components/Pages/Bonuses/PeriodSelect/PeriodSelect';
import Pushes from './components/Utils/Settings/Pushes/Pushes';
import ActiveRequest from './components/Pages/Requests/RequestsType/ActiveRequest/ActiveRequest';
import Filter from './components/Pages/Bonuses/Filter/Filter';
import SelectList from './components/Widgets/SelectList/SelectList';
import LoadingTiles from './components/Shared/Loadings/LoadingTiles/LoadingTiles.jsx';
import NasosSettings from './components/Utils/Requests/CreateRequest/NasosSettings/NasosSettings';
import PumpOrder from './components/Utils/Requests/CreateRequest/NasosSettings/PumpOrder/PumpOrder';
import i18next from './i18next';
import PrivacyPolicyAuth from './components/Pages/Auth/PrivacyPolicyAuth/PrivacyPolicyAuth';
import Manual from './components/Pages/Bonuses/Manual/Manual';
import CreateRequestMenuMarks from './components/Pages/Requests/CreateRequestMarks/CreateRequestMarks';
import Complaint from './components/Pages/Requests/ShowRequestInfo/Сomplaint/Complaint';
import NewMarkMenu from './components/Pages/Requests/NewMarkMenu/NewMarkMenu';
import { UpdList } from './components/Pages/Bonuses/UpdList/UpdList';
import DocumentsStatistic from './components/Pages/DocumentsStatistic/DocumentsStatistic';

function App() {
    const theme = useSelector((state) => state.user.theme);

    registerLocale('ru', ru);
    setDefaultLocale(ru);

    useEffect(() => {
        document.documentElement.dataset.theme = theme;
    }, [theme]);

    return (
        <I18nextProvider i18n={i18next}>
            <YMaps>
                <Listener />
                <BrowserRouter>
                    <Routes>
                        <Route path="login" element={<LoginByPhoneField />} />
                        <Route path="loginByPass" element={<LoginByPhoneField />} />
                        <Route path="privacy" element={<PrivacyPolicyAuth />} />
                        <Route path="code" element={<CodeInputUniversal codeType="login" />} />
                        <Route path="logo" element={<Logo />} />
                        <Route path="success" element={<SuccessAniamtion />} />
                        <Route path="onBording" element={<OnBording />} />

                        <Route path="loading" element={<LoadingTiles />} />

                        <Route path="settings" element={<Settings />} />
                        <Route path="settings/notifications" element={<Pushes />} />
                        <Route path="settings/pushes" element={<PushesSettings />} />
                        <Route path="settings/privacyPolicy" element={<PrivacyPolicy />} />
                        <Route path="settings/language" element={<Language />} />
                        <Route path="settings/changeName" element={<ChangeName />} />
                        <Route path="settings/changePhone" element={<ChangePhone />} />
                        <Route path="settings/changePhone/code" element={<CodeInputModal />} />

                        <Route
                            path="documents"
                            element={
                                localStorage.getItem('isAppStatistic') === 'true' ? (
                                    <DocumentsStatistic />
                                ) : (
                                    <Documents />
                                )
                            }
                        />

                        <Route path="requests" element={<Requests />} />
                        <Route
                            path="activeRequest/requestAdditionalCapacity"
                            element={<AdditionalCapacity />}
                        />
                        <Route path="requestMenu" element={<ChangeRequestMenu />} />
                        <Route path="activeRequest" element={<ActiveRequest />} />

                        <Route path="updateRequest" element={<UpdateRequest />} />
                        <Route path="createRequest" element={<CreateRequestMenu />} />
                        <Route path="createRequestMarks" element={<CreateRequestMenuMarks />} />
                        <Route path="createRequestMarks/newMark" element={<NewMarkMenu />} />
                        <Route path="confirmRequest" element={<ConfirmRequest />} />
                        <Route path="nasosSettings" element={<NasosSettings />} />
                        <Route
                            path="nasosSettingsMarks"
                            element={<NasosSettings backRedirect="createRequestMarks" />}
                        />
                        <Route path="nasosOrder" element={<PumpOrder />} />
                        <Route path="complaint" element={<Complaint />} />

                        <Route path="address" element={<Address />} />

                        <Route path="addClient" element={<ModalView ModalViewType="Клиенты" />} />
                        <Route path="addAddress" element={<ModalView ModalViewType="Адрес" />} />
                        <Route
                            path="addReceiver"
                            element={<ModalView ModalViewType="Грузополучатель" />}
                        />
                        <Route path="nomenclature" element={<Nomenclature />} />
                        <Route
                            path="addNomenclature"
                            element={<ModalView ModalViewType="Номенклатура" />}
                        />
                        <Route
                            path="updateNomenclature"
                            element={
                                <ModalView
                                    ModalViewType="Номенклатура"
                                    backRedirect="updateRequest"
                                />
                            }
                        />
                        <Route path="capacity" element={<Capacity />} />
                        <Route path="contactInfo" element={<ContactInfo />} />
                        <Route
                            path="addContactInformation"
                            element={<ModalView ModalViewType="Контактная информация" />}
                        />
                        <Route
                            path="updateContactInformation"
                            element={
                                <ModalView
                                    ModalViewType="Контактная информация"
                                    backRedirect="updateRequest"
                                />
                            }
                        />
                        <Route
                            path="confirmContactInformation"
                            element={
                                <ModalView
                                    ModalViewType="Контактная информация"
                                    backRedirect="confirmRequest"
                                />
                            }
                        />

                        <Route
                            path="successUpdate"
                            element={
                                <Success
                                    backRedirect={'/requests'}
                                    successType="Заявка успешно изменена"
                                />
                            }
                        />
                        <Route
                            path="successAccept"
                            element={
                                <Success
                                    backRedirect={'/requests'}
                                    successType="Заявка успешно подтверждена"
                                />
                            }
                        />
                        <Route
                            path="successCancel"
                            element={
                                <Success
                                    backRedirect={'/requests'}
                                    successType="Заявка успешно отменена"
                                />
                            }
                        />
                        <Route
                            path="successAdditionalCapacity"
                            element={
                                <Success
                                    backRedirect={'/requests'}
                                    successType="Довоз успешно офомлен"
                                />
                            }
                        />
                        <Route
                            path="successCer"
                            element={
                                <Success
                                    backRedirect={'/bonuses'}
                                    successType="Сертификат получен!"
                                    successAdditionalTxt={'Ozon'}
                                />
                            }
                        />

                        <Route path="bonuses" element={<Bonuses />} />
                        <Route path="bonuses/updList" element={<UpdList />} />
                        <Route path="bonuses/giftStory/cardManualOzon" element={<Manual />} />
                        <Route path="bonuses/cardSelection" element={<CardChoose />} />
                        <Route path="bonuses/cardSelection/code" element={<CodeConfirm />} />
                        <Route path="bonuses/giftStory" element={<GiftStory />} />
                        <Route path="bonuses/periodSelection" element={<PeriodSelect />} />
                        <Route path="bonuses/filter" element={<Filter />} />
                        <Route path="bonuses/filter/selectList" element={<SelectList />} />

                        <Route path="privacyPolicyAuth" element={<PrivacyPolicyAuth />} />

                        <Route path="err" element={<Error />} />
                        <Route
                            path="*"
                            element={
                                getCookie('token') ? (
                                    <Navigate to="requests" />
                                ) : (
                                    <Navigate to="login" />
                                )
                            }
                        />
                    </Routes>
                </BrowserRouter>
            </YMaps>
        </I18nextProvider>
    );
}

export default App;
