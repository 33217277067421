import React from 'react';
import { useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import axios from 'axios';

import toast from 'react-hot-toast';

import giftBlack from '../../../../content/jpgs/giftboxanimation6x8Black.jpg';

import presentIMG from '../../../../content/jpgs/present_image.jpg';

import ozonPng from '../../../../content/CardsImg/ozon.png';

import styles from './CardChoose.module.css';

import Header from '../../../Utils/Requests/CreateRequest/Header';
import { mobileProrabIntstance } from '../../../Entities/Lib/axios';

// import giftBlack from '..jpgs/giftboxanimation6x8Black.jpg';

function CardChoose() {
    const navigate = useNavigate();

    const theme = localStorage.getItem('theme');
    const location = useLocation();
    const erkoins = location?.state?.erkoins;

    const [activeCard, setActiveCard] = useState('');

    const handleCertificate = async () => {
        const response = await mobileProrabIntstance.post(`cardRequest`, {
            nominal: 5000,
        });

        if (response.status === 200) {
            const data = await response.data;
            navigate('code');
        }
    };

    return (
        <>
            <Header headerTitle={'Оформление подарка'} backRedirect={'bonuses'} />
            <div className={styles.content}>
                <div className={styles.erkon_sum}>
                    <span className={styles.erkoins}>Ваши эркоины:</span>

                    <div className={styles.amount}>
                        <span className={styles.erkoinsTxt}>{erkoins}</span>

                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="31"
                            height="32"
                            viewBox="0 0 31 32"
                            fill="none"
                        >
                            <circle cx="15.5" cy="16" r="15.5" fill="#D0D0D2" />
                            <circle cx="15.5" cy="15.5" r="12.5" fill="#E3E3E3" />
                            <circle
                                cx="15.5"
                                cy="15.5"
                                r="11.7329"
                                stroke="#A5A5A5"
                                strokeWidth="1.53424"
                            />
                            <path
                                d="M14.9778 16.6752V14.6602H18.4873V12.8803H13.0468V14.5259L11.0318 14.2069V13.3841C11.0318 12.5781 11.1885 12.0352 11.502 11.7553C11.793 11.4754 12.3527 11.3355 13.1811 11.3355H18.353C19.1813 11.3355 19.7411 11.4754 20.0321 11.7553C20.3456 12.0352 20.5023 12.5781 20.5023 13.3841V17.9514C20.5023 18.7574 20.3456 19.3003 20.0321 19.5802C19.7411 19.8601 19.1813 20 18.353 20H13.1811C12.3527 20 11.793 19.8601 11.502 19.5802C11.1885 19.3003 11.0318 18.7574 11.0318 17.9514V16.9271L13.0468 16.5073V18.3712H18.4873V16.6752H14.9778Z"
                                fill="#8A8A8E"
                            />
                        </svg>
                    </div>
                </div>
                <div className={styles.blocks}>
                    <div className={styles.add_info}>
                        <div className={styles.pic}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="31"
                                height="32"
                                viewBox="0 0 31 32"
                                fill="none"
                            >
                                <circle cx="15.5" cy="16" r="15.5" fill="#D0D0D2" />
                                <circle cx="15.5" cy="15.5" r="12.5" fill="#E3E3E3" />
                                <circle
                                    cx="15.5"
                                    cy="15.5"
                                    r="11.7329"
                                    stroke="#A5A5A5"
                                    strokeWidth="1.53424"
                                />
                                <path
                                    d="M14.9778 16.6752V14.6602H18.4873V12.8803H13.0468V14.5259L11.0318 14.2069V13.3841C11.0318 12.5781 11.1885 12.0352 11.502 11.7553C11.793 11.4754 12.3527 11.3355 13.1811 11.3355H18.353C19.1813 11.3355 19.7411 11.4754 20.0321 11.7553C20.3456 12.0352 20.5023 12.5781 20.5023 13.3841V17.9514C20.5023 18.7574 20.3456 19.3003 20.0321 19.5802C19.7411 19.8601 19.1813 20 18.353 20H13.1811C12.3527 20 11.793 19.8601 11.502 19.5802C11.1885 19.3003 11.0318 18.7574 11.0318 17.9514V16.9271L13.0468 16.5073V18.3712H18.4873V16.6752H14.9778Z"
                                    fill="#8A8A8E"
                                />
                            </svg>

                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="10"
                                viewBox="0 0 15 10"
                                fill="none"
                            >
                                <path
                                    d="M14.6234 5.45667C14.8756 5.20446 14.8756 4.79554 14.6234 4.54333L10.5133 0.43327C10.2611 0.181056 9.85217 0.181056 9.59996 0.43327C9.34774 0.685483 9.34774 1.0944 9.59996 1.34662L13.2533 5L9.59996 8.65339C9.34774 8.9056 9.34774 9.31452 9.59996 9.56673C9.85217 9.81895 10.2611 9.81895 10.5133 9.56673L14.6234 5.45667ZM0.166687 5.64583L14.1667 5.64583L14.1667 4.35417L0.166687 4.35417L0.166687 5.64583Z"
                                    fill="#8A8A8E"
                                />
                            </svg>

                            <img src={theme === 'dark' ? giftBlack : presentIMG} alt="pres" />
                        </div>

                        <span className={styles.tabTxt}>
                            Обменяйте 5000 эркоинов на подарочный сертификат с таким же номиналом.
                        </span>
                    </div>

                    <div className={styles.choose_card}>
                        <div className={styles.contentCard}>
                            <span className={styles.cardTxt}>Выберите сертификат:</span>

                            <div className={styles.cards}>
                                <div
                                    onClick={() => setActiveCard('ozon')}
                                    className={
                                        activeCard === 'ozon' ? styles.cardSelected : styles.card
                                    }
                                >
                                    <img width={70} height={35} src={ozonPng} alt="ozon" />
                                </div>
                            </div>
                        </div>
                        <button
                            onClick={activeCard ? handleCertificate : undefined}
                            className={activeCard ? styles.sendBtnActive : styles.sendBtn}
                        >
                            Получить сертификат
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CardChoose;
