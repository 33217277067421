import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import '../../../components/Pages/Requests/CreateRequest/ModalViews/ModalView.css';

import {
    selectNomenclature,
    selectNomenclatureUID,
    setNomenclatureMobility,
    setIsGraniteavailable,
} from '../../../store/slices/sliceSelectedInfo';

import { RootState } from '../../../store/store';
import ModalOk from '../../../content/svg/RequestsPage/ModalOk';
import { NomenclatureDataType, NomenclatureOptionsType } from '../Interfaces/Interfaces';
import { fixPageState } from '../../../store/slices/sliceUser';

interface NomenclatureModalProps {
    content: NomenclatureDataType[];
    backRedirect: string;
    request: any;
}

function NomenclatureModal({ content, backRedirect, request }: NomenclatureModalProps) {
    const theme = localStorage.getItem('theme');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const selectedRef = useRef<HTMLDivElement>(null);

    const nomenclatureUID = useSelector(
        (state: RootState) => state.selectedInfo.selectedNomenclatureUID,
    );

    useEffect(() => {
        if (selectedRef.current !== null) {
            selectedRef.current.scrollIntoView({
                behavior: 'smooth',
                inline: 'start',
                block: 'center',
            });
        }
    }, [content]);

    const setNomenclature = (
        selectedNomenclature: string,
        selectedNomenclatureUID: string,
        options: NomenclatureOptionsType[],
        nomenclatureMobility: string,
    ) => {
        setData();

        navigate(`/${backRedirect}`, { state: { request: request, paramToFixPage: true } });

        function setData() {
            if (backRedirect === 'updateRequest') {
                dispatch(selectNomenclature(selectedNomenclature));
                dispatch(selectNomenclatureUID(selectedNomenclatureUID));
                dispatch(setIsGraniteavailable(options));
                dispatch(setNomenclatureMobility(nomenclatureMobility));
                dispatch(fixPageState(true));
            } else {
                dispatch(selectNomenclature(selectedNomenclature));
                dispatch(selectNomenclatureUID(selectedNomenclatureUID));
                dispatch(setIsGraniteavailable(options));
                dispatch(setNomenclatureMobility(nomenclatureMobility));
                dispatch(fixPageState(true));
            }
        }
    };

    return (
        <div className="contentContainer">
            <div className="contentFields">
                {content.map((ticket, index) => {
                    return (
                        <div
                            onClick={() =>
                                setNomenclature(
                                    ticket.title,
                                    ticket.nomenclature_uid,
                                    ticket.options,
                                    ticket.options[0].mobility,
                                )
                            }
                            key={index}
                            ref={ticket.nomenclature_uid === nomenclatureUID ? selectedRef : null}
                            className="contentTicket"
                        >
                            <span className="contentTxt">{ticket.title}</span>
                            {ticket.nomenclature_uid === nomenclatureUID && (
                                <ModalOk theme={theme} />
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default NomenclatureModal;
