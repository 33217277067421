import React from 'react';

import styles from './CheckBox.module.css';

interface CheckBoxProps {
    checked: boolean;
    name: string;
    onChange: (value: boolean) => void;
}
export default function CheckBox({ checked, onChange, name }: CheckBoxProps) {
    return (
        <label className={styles.switch} htmlFor={name}>
            <input
                className={styles.checkbox}
                onChange={(event) => {
                    onChange(event.target.checked);
                }}
                checked={checked}
                type="checkbox"
                id={name}
                name={name}
            />
            <span className={styles.slider} />
        </label>
    );
}
