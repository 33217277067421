import React from 'react';

function Clock({ theme }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={22}
            height={20}
            viewBox="0 0 22 20"
            fill="none"
        >
            <path
                d="M11 1.75C6.44531 1.75 2.75 5.44531 2.75 10C2.75 14.5547 6.44531 18.25 11 18.25C15.5547 18.25 19.25 14.5547 19.25 10C19.25 5.44531 15.5547 1.75 11 1.75Z"
                stroke={theme === 'dark' ? '#B3B3B3' : '#8A8A8E'}
                strokeOpacity={0.6}
                strokeWidth={1.65}
                strokeMiterlimit={10}
            />
            <path
                d="M11 4.5V10.6875L14.3 12.2"
                stroke={theme === 'dark' ? '#B3B3B3' : '#8A8A8E'}
                strokeOpacity={0.6}
                strokeWidth={1.65}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default Clock;
