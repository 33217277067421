import React from 'react';

import styles from './MainButtons.module.css';

import { clearToken } from '../../../Entities/Lib/Settings';

import Exit from '../../../../content/svg/SettingsPage/Exit';

function MainButtons() {
    return (
        <div className={styles.btns}>
            <a
                style={{ textDecoration: 'none' }}
                className={styles.mainBtn}
                href="tel:+74996538186"
            >
                Связаться с поддержкой
            </a>
            <button
                style={{ marginBottom: '25px' }}
                onClick={() => clearToken()}
                className={styles.exitBtn}
            >
                <Exit />
                Выйти
            </button>
        </div>
    );
}

export default MainButtons;
