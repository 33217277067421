import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import logoSvg from '../../../../content/svg/logo.svg';
import logoTxt from '../../../../content/svg/logo_text.svg';
import logoTxtWhite from '../../../../content/svg/erkonWhite.svg';

import './Logo.css';
import sendSettings from '../../../Entities/Lib/sendSettings';

function Logo() {
    const navigate = useNavigate();

    const theme = localStorage.getItem('theme');

    localStorage.setItem('onBoarding', 'false');
    // sendSettings(null);

    useEffect(() => {
        const logo__animate__content = document.querySelector('.logo__animate__content');
        setTimeout(function () {
            logo__animate__content.classList.add('active__scale');

            setTimeout(() => {
                navigate('/requests');
            }, 1000);
        }, 2000);
    }, []);

    return (
        <div className="animate__logo">
            <div className="logo__animate__content">
                <img src={logoSvg} className="logo__ani" />
                <img src={theme === 'dark' ? logoTxtWhite : logoTxt} className="text__ani" />
            </div>
        </div>
    );
}

export default Logo;
