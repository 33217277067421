import React from 'react';

function Copy({ theme }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
        >
            <path
                d="M14.5998 1.07178H4.9998C4.84067 1.07178 4.68806 1.13499 4.57554 1.24751C4.46302 1.36004 4.3998 1.51265 4.3998 1.67178V4.67178H1.3998C1.24067 4.67178 1.08806 4.73499 0.975541 4.84751C0.863019 4.96004 0.799805 5.11265 0.799805 5.27178V14.8718C0.799805 15.0309 0.863019 15.1835 0.975541 15.296C1.08806 15.4086 1.24067 15.4718 1.3998 15.4718H10.9998C11.1589 15.4718 11.3115 15.4086 11.4241 15.296C11.5366 15.1835 11.5998 15.0309 11.5998 14.8718V11.8718H14.5998C14.7589 11.8718 14.9115 11.8086 15.0241 11.696C15.1366 11.5835 15.1998 11.4309 15.1998 11.2718V1.67178C15.1998 1.51265 15.1366 1.36004 15.0241 1.24751C14.9115 1.13499 14.7589 1.07178 14.5998 1.07178ZM10.3998 14.2718H1.9998V5.87178H10.3998V14.2718ZM13.9998 10.6718H11.5998V5.27178C11.5998 5.11265 11.5366 4.96004 11.4241 4.84751C11.3115 4.73499 11.1589 4.67178 10.9998 4.67178H5.5998V2.27178H13.9998V10.6718Z"
                fill={theme === 'dark' ? 'white' : '#5B5B5B'}
            />
        </svg>
    );
}

export default Copy;
