import React, { Dispatch, SetStateAction } from 'react';

import '../CreateRequestMenu.css';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addHours, addWeeks, setHours, setMinutes } from 'date-fns';

import { selectAddress, selectAddressUID } from '../../../../../store/slices/sliceSelectedInfo';

import ModalSelect from '../../../../Utils/Requests/CreateRequest/ModalSelect';
import FactoryTypeSelect from '../../../../Utils/Requests/CreateRequest/Selectors/FactoryTypeSelect';
import DeliveryTypeSelect from '../../../../Utils/Requests/CreateRequest/Selectors/DeliveryTypeSelect';
import DateAndTimeInput from '../../../../Utils/Requests/CreateRequest/Input/DateAndTimeInput/DateAndTimeInput';
import EntaranceSelector from '../../../../Utils/Requests/CreateRequest/Input/EntaranceSelector/EntaranceSelector';
import { getCookie } from '../../../../Entities/Lib/CookieWork';
import { RootState } from '../../../../../store/store';
import { AddressDataType } from '../../../../Entities/Interfaces/Interfaces';
import { ReceiversDataType } from '../../../../Entities/Interfaces/Interfaces';
import {
    useGetAddressesQuery,
    useGetConsigneePickupListQuery,
    useGetCounterpartsQuery,
} from '../../../../../servises/api';

interface AddressProps {
    date: Date;
    setDate: React.Dispatch<Date>;
    selectedAddress: string;
    requestCopy?: any; //todo
    factoryType: string;
    setFactoryType: Dispatch<SetStateAction<string>>;
    deliveryType: string;
    setDeliveryType: Dispatch<SetStateAction<string>>;
    refAddressSelector: React.RefObject<HTMLDivElement>;
    isDateAndTimeHidden?: boolean;
    route: string;
}

function Address({
    date,
    setDate,
    selectedAddress,
    requestCopy,
    factoryType,
    setFactoryType,
    deliveryType,
    setDeliveryType,
    refAddressSelector,
    isDateAndTimeHidden = false,
    route,
}: AddressProps) {
    const isSelfPickupAvailable = localStorage?.getItem('isSelfPickupAvailable');

    const dispatch = useDispatch();

    const receiver = useSelector((state: RootState) => state.selectedInfo.selectedReceiver);
    const client = useSelector((state: RootState) => state.selectedInfo.selectedClient);
    const clientUID = useSelector((state: RootState) => state.selectedInfo.selectedClientUID);

    const availebleEntarances = useSelector(
        (state: RootState) => state.requests.availebleEntarances,
    );

    const { data: dataAddresses, isSuccess: addressSuccess } = useGetAddressesQuery(
        clientUID ? clientUID : null,
    );
    const { data: dataConsignee, isSuccess: consigneeSuccess } = useGetConsigneePickupListQuery(
        clientUID ? clientUID : null,
    );
    const { data: dataCounterparts, isSuccess: isSuccessCounterparts } =
        useGetCounterpartsQuery('');

    const handleEditableValue = () => {
        if (!requestCopy && localStorage.getItem('severalCounterparts') === 'true' && !client) {
            return false;
        } else if (!requestCopy && localStorage.getItem('severalCounterparts')) {
            return true;
        } else if (!localStorage?.getItem('severalCounterparts')) {
            return true;
        } else {
            return false;
        }
    };

    const isEditable = handleEditableValue();

    useEffect(() => {
        if (addressSuccess && consigneeSuccess) {
            if (dataAddresses && Object.keys(dataAddresses).length === 1 && !selectedAddress) {
                if (Object.keys(dataAddresses).length === 1 && !selectedAddress) {
                    dispatch(selectAddress(dataAddresses[0].title));
                    dispatch(selectAddressUID(dataAddresses[0]?.address_uid));
                }
            }
        }
    }, [dataAddresses]);

    return (
        <>
            <div className="addressAndTime">
                <span className="titleRequestMenu">Куда</span>
                <div className="addressAndTimeContent">
                    {isSelfPickupAvailable === 'true' && !requestCopy && (
                        <DeliveryTypeSelect
                            deliveryType={deliveryType}
                            setDeliveryType={setDeliveryType}
                        />
                    )}
                    {localStorage?.getItem('severalCounterparts') === 'true' && !requestCopy && (
                        <ModalSelect
                            isLoaded={isSuccessCounterparts}
                            isEditable={true}
                            request={requestCopy}
                            selectTitle={'Клиент'}
                            route={route === 'mark' ? 'addClientMark' : 'addClient'}
                            selectedInformation={client}
                            content={dataCounterparts}
                            methodToGetContent={`clients`}
                        />
                    )}

                    {deliveryType === 'Самовывоз' ? (
                        <>
                            <ModalSelect
                                isLoaded={addressSuccess}
                                isEditable={isEditable}
                                refAddressSelector={refAddressSelector}
                                request={requestCopy}
                                selectTitle={'Грузополучатель'}
                                route={'addReceiver'}
                                selectedInformation={receiver}
                                content={dataConsignee}
                                methodToGetContent={`consignee`}
                            />

                            <FactoryTypeSelect
                                factoryType={factoryType}
                                setFactoryType={setFactoryType}
                            />
                        </>
                    ) : (
                        <ModalSelect
                            isLoaded={consigneeSuccess}
                            isEditable={isEditable}
                            refAddressSelector={refAddressSelector}
                            request={requestCopy}
                            selectTitle={'Адрес'}
                            route={'addAddress'}
                            selectedInformation={selectedAddress}
                            content={dataAddresses}
                            methodToGetContent={`address`}
                        />
                    )}
                </div>
            </div>
            {isDateAndTimeHidden ? null : (
                <>
                    <div className="nomenclatureAndCapacity">
                        <span className="titleRequestMenu">Когда</span>
                        <div className="addressAndTimeContent">
                            <DateAndTimeInput
                                date={date}
                                setDate={setDate}
                                isNewRequest={true}
                                upper_date={addWeeks(new Date(), 2)}
                                lower_date={addHours(new Date(), 2)}
                                upper_time={setHours(setMinutes(new Date(), 30), 23)}
                                lower_time={setHours(setMinutes(new Date(), 0), 0)}
                            />

                            {Object.keys(availebleEntarances).length !== 0 && (
                                <EntaranceSelector
                                    entaranses={availebleEntarances}
                                    request={requestCopy}
                                />
                            )}
                        </div>
                    </div>
                    <div style={{ marginBottom: '32px' }}></div>
                </>
            )}
        </>
    );
}

export default Address;
