import React from 'react';

import styles from './RadioButtonRound.module.css';

export default function RadioButtonRound({ checked }: { checked: boolean }) {
    const mainColor = localStorage.getItem('theme') === 'dark' ? '#2F3237' : 'white';
    const secondColor = localStorage.getItem('theme') === 'dark' ? 'white' : '#2F3237';
    return (
        <div className={styles.container}>
            {checked ? (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                >
                    <circle cx="10" cy="10" r="10" fill={secondColor} />
                    <path
                        d="M14.9998 5.8335L7.4165 13.7123L4.1665 10.5608"
                        stroke={mainColor}
                        strokeWidth="1.47727"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            ) : (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                >
                    <circle
                        cx="10"
                        cy="10"
                        r="9.56224"
                        stroke={secondColor}
                        strokeWidth="0.875514"
                    />
                </svg>
            )}
        </div>
    );
}
