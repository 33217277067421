import React, { useEffect, useRef } from 'react';

import '../../../components/Pages/Requests/CreateRequest/ModalViews/ModalView.css';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
    selectAddress,
    selectAddressUID,
    selectReceiver,
    selectReceiverUID,
    setSelectedReceiverAddressUID,
} from '../../../store/slices/sliceSelectedInfo';

import ModalOk from '../../../content/svg/RequestsPage/ModalOk';
import { RootState } from '../../../store/store';
import { ReceiversDataType } from '../Interfaces/Interfaces';
import { fixPageState } from '../../../store/slices/sliceUser';

interface ReceiverModalProps {
    content: ReceiversDataType[];
    backRedirect: string;
    request: any;
}
function ReceiverModal({ content, backRedirect, request }: ReceiverModalProps) {
    const theme = localStorage.getItem('theme');
    const receiverUID = useSelector((state: RootState) => state.selectedInfo.selectedReceiverUID);

    const selectedRef = useRef<HTMLDivElement>(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (selectedRef.current !== null) {
            selectedRef.current.scrollIntoView({
                behavior: 'smooth',
                inline: 'start',
                block: 'center',
            });
        }
    }, [content]);

    const setReceiver = (
        selectedReceiver: string,
        selectedReceiverUID: string,
        selectedAddressUID: string,
    ) => {
        setData();
        refreshData();

        navigate(`/${backRedirect}`, { state: { request: request, paramToFixPage: true } });

        function refreshData() {
            dispatch(selectAddress(null));
        }

        function setData() {
            dispatch(selectReceiver(selectedReceiver));
            dispatch(selectReceiverUID(selectedReceiverUID));
            dispatch(selectAddressUID(selectedAddressUID));
            dispatch(setSelectedReceiverAddressUID(selectedAddressUID));
            dispatch(fixPageState(true));
        }
    };
    return (
        <div className="contentContainer">
            <div className="contentFields">
                {content.map((ticket, index) => {
                    return (
                        <div
                            ref={ticket.title === receiverUID ? selectedRef : null}
                            onClick={() =>
                                setReceiver(ticket.title, ticket.consigneeUid, ticket.address_uid)
                            }
                            key={index}
                            className="contentTicket"
                        >
                            <span className="contentTxt">{ticket.title}</span>
                            {ticket.title === receiverUID ? <ModalOk theme={theme} /> : null}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default ReceiverModal;
