import React from 'react';
import ReactDOM from 'react-dom';

import styles from './Modal.module.css';

interface ModalProps {
    children: React.ReactNode;
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
}

export default function Modal({ children, isOpen, setIsOpen }: ModalProps) {
    if (!isOpen) return null;
    const root = document.getElementById('root');
    if (!root) return null;

    return ReactDOM.createPortal(
        <div className={styles.container}>
            <div className={styles.modal}>{children}</div>
        </div>,
        root,
    );
}
