import React from 'react';

function PrivacyPolicy({ theme }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="18"
            viewBox="0 0 16 18"
            fill="none"
        >
            <path
                d="M8.48101 13.0305C8.71055 13.0305 8.90309 12.9527 9.05863 12.7972C9.21418 12.6416 9.29168 12.4494 9.29114 12.2204V8.97987C9.29114 8.75033 9.21337 8.55779 9.05782 8.40225C8.90228 8.2467 8.71001 8.1692 8.48101 8.16974C8.25148 8.16974 8.05894 8.24752 7.90339 8.40306C7.74785 8.5586 7.67035 8.75087 7.67089 8.97987V12.2204C7.67089 12.4499 7.74866 12.6425 7.9042 12.798C8.05975 12.9535 8.25202 13.031 8.48101 13.0305ZM8.48101 6.54949C8.71055 6.54949 8.90309 6.47172 9.05863 6.31617C9.21418 6.16063 9.29168 5.96836 9.29114 5.73936C9.29114 5.50983 9.21337 5.31729 9.05782 5.16174C8.90228 5.0062 8.71001 4.9287 8.48101 4.92924C8.25148 4.92924 8.05894 5.00701 7.90339 5.16255C7.74785 5.3181 7.67035 5.51037 7.67089 5.73936C7.67089 5.9689 7.74866 6.16144 7.9042 6.31698C8.05975 6.47253 8.25202 6.55003 8.48101 6.54949ZM8.48101 17.0001C8.3865 17.0001 8.29873 16.9934 8.21772 16.9799C8.13671 16.9664 8.0557 16.9461 7.97468 16.9191C6.1519 16.3115 4.70042 15.1873 3.62025 13.5466C2.54008 11.9058 2 10.1405 2 8.25076V4.42291C2 4.08535 2.09803 3.78156 2.29408 3.51151C2.49013 3.24147 2.74316 3.04569 3.05316 2.92417L7.91392 1.10139C8.10295 1.03388 8.29198 1.00012 8.48101 1.00012C8.67004 1.00012 8.85907 1.03388 9.0481 1.10139L13.9089 2.92417C14.2194 3.04569 14.4727 3.24147 14.6688 3.51151C14.8648 3.78156 14.9626 4.08535 14.962 4.42291V8.25076C14.962 10.1411 14.4219 11.9066 13.3418 13.5474C12.2616 15.1881 10.8101 16.3121 8.98734 16.9191C8.90633 16.9461 8.82532 16.9664 8.7443 16.9799C8.66329 16.9934 8.57553 17.0001 8.48101 17.0001ZM8.48101 15.3799C9.88523 14.9343 11.0464 14.0432 11.9646 12.7065C12.8827 11.3697 13.3418 9.88451 13.3418 8.25076V4.42291L8.48101 2.60012L3.62025 4.42291V8.25076C3.62025 9.88451 4.07932 11.3697 4.99747 12.7065C5.91561 14.0432 7.07679 14.9343 8.48101 15.3799Z"
                fill={theme === 'dark' ? 'white' : '#202020'}
                stroke={theme === 'dark' ? '#323232' : 'white'}
                strokeWidth="0.3"
            />
        </svg>
    );
}

export default PrivacyPolicy;
