import React from 'react';

import loader from '../../../../content/png/loader.png';
import success_icon from '../../../../content/svg/success__icon.svg';
import success__modal from '../../../../content/svg/success__modal.svg';

import './Success.css';

import { useNavigate } from 'react-router-dom';

function SuccessAniamtion() {
    const navigate = useNavigate();

    setTimeout(() => {
        navigate('/requests');
    }, 4200);

    return ( 
    <div className="animate__success__modal">
        <div className="logo__animate__content">
            <div className="text__loader">
                <img src={loader} className="loading__image" />
                <img src={success_icon} className="success__ani" />
                <img src={success__modal} className="success__modal" />
            </div>
        </div>
    </div>
     );
}

export default SuccessAniamtion;