import React from 'react';

function About({ theme }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                d="M3.33341 2.33344H12.6667C13.1972 2.33344 13.7059 2.54415 14.081 2.91922C14.456 3.29429 14.6667 3.803 14.6667 4.33343V11.0001C14.6667 11.5305 14.456 12.0392 14.081 12.4143C13.7059 12.7894 13.1972 13.0001 12.6667 13.0001H4.94008L2.47341 15.4734C2.41112 15.5352 2.33724 15.5841 2.25602 15.6173C2.17479 15.6505 2.08782 15.6673 2.00008 15.6668C1.91263 15.669 1.82587 15.6508 1.74675 15.6134C1.625 15.5634 1.52078 15.4785 1.44723 15.3693C1.37367 15.2602 1.33407 15.1317 1.33341 15.0001V4.33343C1.33341 3.803 1.54413 3.29429 1.9192 2.91922C2.29427 2.54415 2.80298 2.33344 3.33341 2.33344ZM2.66675 13.3934L4.19341 11.8601C4.25571 11.7983 4.32958 11.7494 4.41081 11.7163C4.49203 11.6831 4.57901 11.6663 4.66675 11.6668H12.6667C12.8436 11.6668 13.0131 11.5965 13.1382 11.4715C13.2632 11.3465 13.3334 11.1769 13.3334 11.0001V4.33343C13.3334 4.15662 13.2632 3.98705 13.1382 3.86203C13.0131 3.73701 12.8436 3.66677 12.6667 3.66677H3.33341C3.1566 3.66677 2.98703 3.73701 2.86201 3.86203C2.73698 3.98705 2.66675 4.15662 2.66675 4.33343V13.3934Z"
                fill={theme === 'dark' ? 'white' : '#202020'}
            />
            <path
                d="M8.47347 6.69782L7.2144 6.85562L7.16931 7.06455L7.41673 7.11018C7.57837 7.14867 7.61026 7.20695 7.57508 7.36805L7.16931 9.2748C7.06265 9.76798 7.22704 10 7.61356 10C7.91321 10 8.26124 9.86145 8.41904 9.67121L8.46742 9.44249C8.35746 9.53926 8.19691 9.57774 8.09025 9.57774C7.93905 9.57774 7.88407 9.47163 7.92311 9.28469L8.47347 6.69782ZM8.51196 5.54981C8.51196 5.69563 8.45403 5.83548 8.35092 5.93859C8.24781 6.0417 8.10796 6.09963 7.96214 6.09963C7.81632 6.09963 7.67648 6.0417 7.57337 5.93859C7.47026 5.83548 7.41233 5.69563 7.41233 5.54981C7.41233 5.40399 7.47026 5.26415 7.57337 5.16104C7.67648 5.05793 7.81632 5 7.96214 5C8.10796 5 8.24781 5.05793 8.35092 5.16104C8.45403 5.26415 8.51196 5.40399 8.51196 5.54981Z"
                fill={theme === 'dark' ? 'white' : '#202020'}
            />
        </svg>
    );
}

export default About;
