import { deleteAllCookies } from './CookieWork';
import { mobileProrabIntstance } from './axios';

export async function clearToken() {
    try {
        const response = await mobileProrabIntstance.post(`exit`);

        const theme = localStorage.getItem('theme');
        localStorage.setItem('theme', theme);
        document.documentElement.dataset.theme = localStorage.getItem('theme');
        deleteAllCookies();
        window.location.href = '/login';
    } catch (e) {
        localStorage.removeItem('token');
        window.location.href = '/login';
    }
}
