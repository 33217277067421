import React from 'react';

export default function BoldPlus() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M4.2839 13.2868H10.7132V19.7161C10.7132 20.0571 10.8486 20.3842 11.0898 20.6253C11.3309 20.8665 11.658 21.002 11.999 21.002C12.3401 21.002 12.6671 20.8665 12.9083 20.6253C13.1494 20.3842 13.2849 20.0571 13.2849 19.7161V13.2868H19.7141C20.0552 13.2868 20.3822 13.1514 20.6234 12.9102C20.8645 12.6691 21 12.342 21 12.001C21 11.6599 20.8645 11.3329 20.6234 11.0917C20.3822 10.8506 20.0552 10.7151 19.7141 10.7151H13.2849V4.28585C13.2849 3.94482 13.1494 3.61776 12.9083 3.37662C12.6671 3.13547 12.3401 3 11.999 3C11.658 3 11.3309 3.13547 11.0898 3.37662C10.8486 3.61776 10.7132 3.94482 10.7132 4.28585V10.7151H4.2839C3.94287 10.7151 3.6158 10.8506 3.37466 11.0917C3.13352 11.3329 2.99804 11.6599 2.99804 12.001C2.99804 12.342 3.13352 12.6691 3.37466 12.9102C3.6158 13.1514 3.94287 13.2868 4.2839 13.2868Z"
                fill="#F54941"
            />
        </svg>
    );
}
