import React from 'react';

import styles from './Pushes.module.css';
import Header from '../../Requests/CreateRequest/Header';
import RequestDate from '../../Documents/RequestField/RequestDate/RequestDate';
import { useGetPushListQuery } from '../../../../servises/api';

import { PushListType } from '../../../Entities/Interfaces/Interfaces';
import { getCookie } from '../../../Entities/Lib/CookieWork';
import Loading from '../../../Shared/Loadings/Loading/Loading';

function Pushes() {
    const { data, isSuccess } = useGetPushListQuery(null, {
        skip: !getCookie('token'),
    }) as {
        data: PushListType[];
        isSuccess: boolean
    };
  
    if (isSuccess){
        return (
            <>
                <Header headerTitle="Уведомления" backRedirect={'settings'} />
    
                {data?.length ? (
                    <div className={styles.pushList}>
                        {data.map((item, index) => (
                            <div className={styles.day} key={index}>
                                <RequestDate isPushes={true} date={item.date} />
                                <div className={styles.content}>
                                    {item.data.map((push, index) => (
                                        <div className={styles.notification} key={index}>
                                            <span className={styles.notificationMainTxt}>
                                                {push.title}
                                            </span>
                                            <span className={styles.notificationBody}>
                                                <pre>{push.body?.replaceAll('¶', `\n`)}</pre>
                                            </span>
                                            <span className={styles.notificationDate}>{push.date}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                )
                :
                <>
                <div className={styles.pushList}>
                    <div className={styles.content}>
                        <div className={styles.notification}>
                            У вас не было уведомлений за последнюю неделю
                        </div>
                    </div>
                </div>
                </>
                }
    
                <br />
                <br />
            </>
        );
    }
    // <>
    // <div className={styles.pushList}>
    //     <div className={styles.content}>
    //         <div className={styles.notification}>
    //             У вас не было уведомлений за последнюю неделю
    //         </div>
    //     </div>
    // </div>
    // </>
    
    else {
        return (
            <Loading />
        );
    }
}

export default Pushes;
