import React from 'react';

import { Bar } from 'react-chartjs-2';
import { CategoryScale } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Chart from 'chart.js/auto';

Chart.register(CategoryScale);
Chart.register(ChartDataLabels);

export const ChartUPD = ({ chartData }) => {
    const theme = localStorage.getItem('theme');

    return (
        <Bar
            data={chartData}
            title=""
            plugins={ChartDataLabels}
            options={{
                plugins: {
                    tooltip: {
                        enabled: false,
                    },
                    title: {
                        display: true,
                    },
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        anchor: 'end',
                        align: 'top',
                        font: {
                            family: 'Inter, sans-serif',
                            size: 8,
                        },
                        rotation: -10,
                        formatter: (value) =>
                            `${value
                                .toLocaleString('ru-RU', {
                                    style: 'currency',
                                    currency: 'RUB',
                                })
                                .slice(0, -3)}`,
                        color: theme === 'dark' ? '#fff' : '#202020',
                    },
                },
                scales: {
                    x: {
                        ticks: {
                            display: true,
                            font: {
                                family: 'Inter, sans-serif',
                                size: 10,
                                wordWrap: 'break-word',
                            },
                            minRotation: 0,
                            maxRotation: 360,
                            color: theme === 'dark' ? '#b3b3b3' : '#8a8a8e',
                        },
                        grid: {
                            display: false,
                        },
                        border: {
                            display: false,
                        },
                    },
                    y: {
                        ticks: {
                            display: false,
                        },
                        grid: {
                            display: false,
                            lineWidth: 0,
                        },
                        border: {
                            display: false,
                        },
                    },
                },
            }}
        />
    );
};
