import React from 'react';

import styles from './PieChart.module.css';
import 'react-circular-progressbar/dist/styles.css';

import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';

import { useNavigate } from 'react-router-dom';
import { getCookie } from '../../../Entities/Lib/CookieWork';
import { useGetBonusesQuery } from '../../../../servises/api';

function PieChart({ erkoins }) {
    const navigate = useNavigate();
    const maxErkoins = 5000;

    const theme = localStorage.getItem('token');

    const { data: bonusesData, isSuccess: isSuccessBonuses } = useGetBonusesQuery(
        getCookie('token'),
        {
            skip: !getCookie('token'),
        },
    );

    return (
        <div className={styles.piechart_info}>
            <div className={styles.piechart_infoPlaceholder}>
                <div
                    style={{
                        maxWidth: '150px',
                        maxHeight: '150px',
                        minHeight: '100px',
                        minWidth: '100px',
                    }}
                >
                    <CircularProgressbarWithChildren
                        background={true}
                        strokeWidth={12}
                        backgroundPadding={1}
                        styles={buildStyles({
                            pathColor: '#F96760',
                            trailColor: theme === 'dark' ? 'rgba(227, 227, 235, 0.70)' : '#E3E3EB',
                            backgroundColor: 'none',
                            pathTransitionDuration: 0.15,
                        })}
                        value={Math.round((100 * erkoins) / maxErkoins)}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span className={styles.collectedTxt}>Накоплено:</span>

                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '3px',
                                    justifyContent: 'center',
                                }}
                            >
                                <span className={styles.ballsOverall}>{erkoins}</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="24"
                                    viewBox="0 0 31 32"
                                    fill="none"
                                >
                                    <circle cx="15.5" cy="16" r="15.5" fill="#D0D0D2" />
                                    <circle cx="15.5" cy="15.5" r="12.5" fill="#E3E3E3" />
                                    <circle
                                        cx="15.5"
                                        cy="15.5"
                                        r="11.7329"
                                        stroke="#A5A5A5"
                                        strokeWidth="1.53424"
                                    />
                                    <path
                                        d="M14.9778 16.6752V14.6602H18.4873V12.8803H13.0468V14.5259L11.0318 14.2069V13.3841C11.0318 12.5781 11.1885 12.0352 11.502 11.7553C11.793 11.4754 12.3527 11.3355 13.1811 11.3355H18.353C19.1813 11.3355 19.7411 11.4754 20.0321 11.7553C20.3456 12.0352 20.5023 12.5781 20.5023 13.3841V17.9514C20.5023 18.7574 20.3456 19.3003 20.0321 19.5802C19.7411 19.8601 19.1813 20 18.353 20H13.1811C12.3527 20 11.793 19.8601 11.502 19.5802C11.1885 19.3003 11.0318 18.7574 11.0318 17.9514V16.9271L13.0468 16.5073V18.3712H18.4873V16.6752H14.9778Z"
                                        fill="#8A8A8E"
                                    />
                                </svg>
                            </div>
                        </div>
                    </CircularProgressbarWithChildren>
                </div>

                <div className={styles.piechart_info}>
                    <div className={styles.pieChartTxt}>
                        <div className={styles.left_to_save}>
                            <div className={styles.txtPlaceholder}>
                                <span className={styles.txt}>Осталось накопить:</span>
                                <div className={styles.amount}>
                                    <span className={styles.amountValue}>
                                        {maxErkoins - erkoins < 0 ? 0 : maxErkoins - erkoins}
                                    </span>

                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="31"
                                        height="32"
                                        viewBox="0 0 31 32"
                                        fill="none"
                                    >
                                        <circle cx="15.5" cy="16" r="15.5" fill="#D0D0D2" />
                                        <circle cx="15.5" cy="15.5" r="12.5" fill="#E3E3E3" />
                                        <circle
                                            cx="15.5"
                                            cy="15.5"
                                            r="11.7329"
                                            stroke="#A5A5A5"
                                            strokeWidth="1.53424"
                                        />
                                        <path
                                            d="M14.9778 16.6752V14.6602H18.4873V12.8803H13.0468V14.5259L11.0318 14.2069V13.3841C11.0318 12.5781 11.1885 12.0352 11.502 11.7553C11.793 11.4754 12.3527 11.3355 13.1811 11.3355H18.353C19.1813 11.3355 19.7411 11.4754 20.0321 11.7553C20.3456 12.0352 20.5023 12.5781 20.5023 13.3841V17.9514C20.5023 18.7574 20.3456 19.3003 20.0321 19.5802C19.7411 19.8601 19.1813 20 18.353 20H13.1811C12.3527 20 11.793 19.8601 11.502 19.5802C11.1885 19.3003 11.0318 18.7574 11.0318 17.9514V16.9271L13.0468 16.5073V18.3712H18.4873V16.6752H14.9778Z"
                                            fill="#8A8A8E"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button
                onClick={
                    erkoins >= 5000 && bonusesData?.bonuses?.showSertificates
                        ? () => navigate('cardSelection', { state: { erkoins: erkoins } })
                        : null
                }
                className={
                    erkoins >= 5000 && bonusesData?.bonuses?.showSertificates
                        ? styles.buttonsActive
                        : styles.buttonsDisabled
                }
            >
                Выбрать сертификат
            </button>

            {!bonusesData?.bonuses?.showSertificates && (
                <div className={styles.alarmContainer}>
                    <svg
                        style={{ marginTop: '2px' }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8.94944 10.0503L9.11563 3.93948H7.21933L7.38978 10.0503H8.94944ZM7.44518 12.4792C7.64688 12.6781 7.88836 12.7775 8.16961 12.7775C8.35143 12.7775 8.51904 12.7321 8.67245 12.6412C8.82586 12.5474 8.94944 12.4239 9.04319 12.2704C9.13978 12.1142 9.1895 11.9423 9.19234 11.7548C9.1895 11.4764 9.0858 11.2378 8.88126 11.0389C8.67671 10.84 8.4395 10.7406 8.16961 10.7406C7.88836 10.7406 7.64688 10.84 7.44518 11.0389C7.24347 11.2378 7.14404 11.4764 7.14688 11.7548C7.14404 12.0361 7.24347 12.2775 7.44518 12.4792Z"
                            fill="var(--redA-rebB)"
                        />
                    </svg>
                    <div className={styles.alarm}>
                        <span className={styles.boldCertificateTxt}>
                            Сертификаты временно закончились!
                        </span>
                        <span className={styles.regularCertificateTxt}>
                            Мы знаем, как вы их любите, и уже работаем над тем, чтобы они вновь
                            появились в приложении.
                        </span>
                    </div>
                </div>
            )}
        </div>
    );
}

export default PieChart;
