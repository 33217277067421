import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

import './Login.css';

import { useNavigate } from 'react-router-dom';

import toast from 'react-hot-toast';

import PhoneValue from '../../Utils/Requests/Login/PhoneValue';
import Modal from '../../Utils/Requests/ModalWindow/Modal';
import blackLogo from '../../../content/png/erkon black.png';

import { useTryReLogin } from '../../CustomHooks/useTryReLogin';
import { mobileProrabIntstance } from '../../Entities/Lib/axios.js';
import { useGetPingQuery } from '../../../servises/api';

function LoginByPhoneField() {
    const navigate = useNavigate();
    const theme = localStorage.getItem('theme');

    const { handleSubmit } = useForm();

    const [phoneValue, setPhoneValue] = useState('');
    const [isError, setIsError] = useState(false);
    const [isButtonActive, setIsButtonActive] = useState(true);
    const [buttonTxt, setButtonTxt] = useState('Продолжить');

    const [isPolicyConfirmed, setIsPolicyConfirmed] = useState(false);

    const [errorText, setErrorText] = useTryReLogin(setIsError) as [
        string,
        React.Dispatch<React.SetStateAction<string>>,
    ];

    async function tryVerifyPhone(event: React.SyntheticEvent) {
        if (isPolicyConfirmed) {
            setIsButtonActive(false);
            setButtonTxt('Загрузка...');
            try {
                const response = await mobileProrabIntstance.post('auth', {
                    phoneValue: phoneValue.replace(/ /g, ''),
                });

                if (response.data.result === true) {
                    localStorage.setItem('phone', phoneValue);
                    navigate('/code', { state: { phoneValue: phoneValue }, replace: true });
                } else {
                    setIsError(true);
                    setErrorText(response.data.text.toString());
                    setIsButtonActive(true);
                    setButtonTxt('Продолжить');
                }
            } catch (error) {
                toast.error('Ошибка авторизации, попробуйте снова');
                setIsButtonActive(true);
                setButtonTxt('Продолжить');
            }
        } else {
            toast.error('Вы должны согласиться с политикой конфиденциальности');
        }
    }

    return (
        <div className="fullBody">
            <div className="logoPlaceholder">
                {theme === 'dark' ? (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="138"
                        height="22"
                        viewBox="0 0 138 22"
                        fill="none"
                    >
                        <path
                            d="M31.557 4.584H45.285V6.872H34.677V9.862H40.865V12.15H34.677V15.478H45.415V18H31.557V4.584ZM51.684 4.584H63.15C64.2073 4.584 64.9786 4.78333 65.464 5.182C65.9493 5.598 66.192 6.26533 66.192 7.184V10.33C66.192 11.2487 65.9493 11.916 65.464 12.332C64.9786 12.7307 64.2073 12.93 63.15 12.93H61.408L67.596 18H63.124L57.716 12.93H54.752V18H51.684V4.584ZM54.752 6.898V10.642H61.772C62.2746 10.642 62.63 10.5727 62.838 10.434C63.0633 10.278 63.176 9.992 63.176 9.576V7.964C63.176 7.548 63.0633 7.262 62.838 7.106C62.63 6.96733 62.2746 6.898 61.772 6.898H54.752ZM73.0297 4.584H76.1757V10.174L83.6117 4.584H88.1357L79.2437 10.902L89.2277 18H84.2097L76.1757 12.098V18H73.0297V4.584ZM107.302 15.478V6.976H97.0062V15.478H107.302ZM93.8862 7.756C93.8862 6.508 94.1289 5.66733 94.6142 5.234C95.0649 4.80067 95.9316 4.584 97.2142 4.584H107.094C108.377 4.584 109.244 4.80067 109.694 5.234C110.18 5.66733 110.422 6.508 110.422 7.756V14.828C110.422 16.076 110.18 16.9167 109.694 17.35C109.244 17.7833 108.377 18 107.094 18H97.2142C95.9316 18 95.0649 17.7833 94.6142 17.35C94.1289 16.9167 93.8862 16.076 93.8862 14.828V7.756ZM117.472 4.584H119.604L129.042 12.488C129.666 12.9907 130.178 13.476 130.576 13.944C130.49 12.696 130.446 11.9247 130.446 11.63V4.584H133.254V18H131.122L121.268 9.706C120.818 9.32467 120.445 8.96067 120.15 8.614C120.202 9.082 120.237 9.51533 120.254 9.914C120.272 10.2433 120.28 10.5207 120.28 10.746V18H117.472V4.584Z"
                            fill="white"
                        />
                        <path
                            d="M0.503281 6.87763C7.5993 8.04428 22.6643 5.438 24.7589 4.853C24.7957 4.84273 24.8232 4.87447 24.8203 4.91255C24.7554 5.74646 24.6837 6.65093 24.6428 7.01406C24.6375 7.06153 24.6147 7.10291 24.5766 7.13174C20.5048 10.214 7.49531 11.5986 0.250977 9.96296L0.503281 6.87763Z"
                            fill="#BC2329"
                        />
                        <path
                            d="M2.29383 14.7637C8.40658 15.6352 24.4804 12.1929 26.5933 11.5898C26.6301 11.5793 26.6569 11.6113 26.654 11.6493C26.5906 12.4815 26.5341 13.4799 26.4953 13.8665C26.4898 13.9217 26.4601 13.9685 26.4123 13.9967C21.2671 17.0244 7.90935 20.3538 1.95386 18.6041L2.29383 14.7637Z"
                            fill="#BC2329"
                        />
                        <path
                            d="M0.506419 6.87751C3.75131 12.0546 24.1327 12.9835 26.7743 9.59248C26.8083 9.54886 26.882 9.57546 26.8807 9.63074C26.8531 10.7628 26.6226 13.561 26.5148 13.936C26.5073 13.9624 26.4895 13.9787 26.4653 13.9916C22.1134 16.3043 6.00725 15.3616 0.158701 11.3485C0.131922 11.3301 0.118375 11.2995 0.121191 11.2671L0.50316 6.87845C0.503311 6.87671 0.505495 6.87603 0.506419 6.87751Z"
                            fill="#F54941"
                        />
                        <path
                            d="M2.29774 14.7621C5.67486 19.3913 21.2356 19.7645 23.4271 17.0551C23.4619 17.012 23.5378 17.0385 23.5366 17.0939C23.515 18.1644 23.3411 20.4151 23.2762 20.7065C23.2717 20.7264 23.2623 20.7395 23.2465 20.7526C21.5687 22.1487 8.16854 22.9095 1.97046 18.6429C1.94351 18.6243 1.92935 18.5929 1.93246 18.5604L2.29453 14.7631C2.29469 14.7614 2.29676 14.7607 2.29774 14.7621Z"
                            fill="#F54941"
                        />
                        <path
                            d="M3.63302 0.708447C11.0285 1.33216 15.8828 0.641506 17.7652 0.101402C17.8064 0.0895866 17.843 0.127441 17.8314 0.168681C17.1963 2.42608 16.1384 5.48666 3.36121 3.78898L3.63302 0.708447Z"
                            fill="#BC2329"
                        />
                        <path
                            d="M3.6141 0.786621C7.93343 6.09256 22.454 5.86849 24.7852 3.14184C24.8199 3.10125 24.8889 3.12718 24.8882 3.18058C24.8748 4.24567 24.7521 6.65259 24.6516 7.06445C24.6429 7.09995 24.6176 7.12363 24.5843 7.13869C20.177 9.13227 9.41434 8.91248 3.31668 5.01638C3.28851 4.99839 3.27303 4.96648 3.27575 4.93317L3.6141 0.786621Z"
                            fill="#F54941"
                        />
                    </svg>
                ) : (
                    <img src={blackLogo} className="logo" alt="logo" />
                )}
            </div>

            <form
                style={{ width: '100%' }}
                onSubmit={handleSubmit(
                    phoneValue && phoneValue.length === 17 && isButtonActive
                        ? (event) => tryVerifyPhone(event as React.SyntheticEvent)
                        : () => null,
                )}
            >
                <div className="numberInputPlaceholder">
                    <span className="numberInputTitle">Телефон для входа</span>

                    <div className="phoneNumberInput">
                        <PhoneValue
                            phoneValue={phoneValue}
                            setPhoneValue={setPhoneValue}
                            style={phone}
                        />
                    </div>
                </div>

                <div className="footerSendButton">
                    <div className="sendButtonPlaceholder">
                        {/* <span className="privacyPolicyRu">
                            Нажимая кнопку, вы соглашаетесь с{' '}
                            <a
                                className="privacyPolicyRuLink"
                                href="https://api-erkon.tech/mobile/hs/mobileProrab/personalPolitic"
                            >
                                политикой конфиденциальности
                            </a>
                        </span> */}
                        <div className="layoutPolicy">
                            <input
                                onClick={() => setIsPolicyConfirmed(!isPolicyConfirmed)}
                                className="checkbox"
                                type="checkbox"
                                name="PrivacyPolicy"
                                id="PrivacyPolicy"
                            />
                            <label htmlFor="PrivacyPolicy" className="privacyPolicyRu">
                                <span className="privacyPolicyRuNotLink">Я принимаю условия </span>
                                <a
                                    className="privacyPolicyRuLink"
                                    href="https://api-erkon.tech/Политика персональные данные.docx"
                                >
                                    {'политикой конфиденциальности, '}
                                </a>
                                <a
                                    className="privacyPolicyRuLink"
                                    href="https://api-erkon.tech/mobile/hs/mobileProrab/Руководство по эксплуатации.docx"
                                >
                                    пользовательского соглашения
                                </a>
                                <span className="privacyPolicyRu">и даю согласие на </span>
                                <a
                                    className="privacyPolicyRuLink"
                                    href="https://api-erkon.tech/mobile/hs/mobileProrab/Политика персональные данные.docx"
                                >
                                    обработку персональных данных
                                </a>
                            </label>
                        </div>
                        <button
                            className={
                                phoneValue &&
                                phoneValue.length === 17 &&
                                isButtonActive &&
                                isPolicyConfirmed
                                    ? 'sendButtonActive'
                                    : 'sendButton'
                            }
                        >
                            {buttonTxt}
                        </button>
                    </div>
                </div>
            </form>

            <Modal
                title="Что то пошло не так"
                isConfirming={false}
                active={isError}
                setIsError={setIsError}
            >
                <div>{errorText}</div>
            </Modal>
        </div>
    );
}

const phone = {
    display: 'flex',
    height: '3.75rem',
    padding: '0.625rem 1rem',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    borderRadius: '0.5rem',
    background: 'var(--fill-primary, rgba(116, 116, 128,))',
    color: '#8a8a8e',
    overflow: 'hidden',
    fontFamily: "'Inter', sans-serif",
    fontFeatureSettings: '"case" on',
    textOverflow: 'ellipsis',
    fontSize: '1.1rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '1.5rem',
};

export default LoginByPhoneField;
