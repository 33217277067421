import React from 'react';

function OverheadGift() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="22"
            viewBox="0 0 30 22"
            fill="none"
        >
            <rect x="0.00012207" width="30" height="19" rx="3" fill="#006BDB" />
            <path
                d="M30.0001 16C30.0001 16 -0.5 22 3 0C1.34323 0 0.000124425 1.34148 0.000114604 2.99825L2.35349e-05 16.0275C1.07765e-05 17.6844 1.34316 19.0266 3.00003 19.0266H27.0001C28.657 19.0266 30.0001 17.6569 30.0001 16Z"
                fill="#0059BE"
            />
            <rect x="8.00012" width="3" height="19" fill="#8ADEF9" />
            <rect
                x="30.0001"
                y="8"
                width="3"
                height="30"
                transform="rotate(90 30.0001 8)"
                fill="#8ADEF9"
            />
            <path
                d="M4.30952 3.6713C7.50952 0.871296 9.30952 7.50463 9.80952 11.1713L7.30957 12.1714L4.30952 9.17139C2.30952 7.17139 0.309523 7.1713 4.30952 3.6713Z"
                fill="#E73844"
            />
            <path
                d="M15.3096 3.6713C12.1096 0.871296 10.3096 7.50463 9.80962 11.1713L12.3096 12.1714L15.3096 9.17139C17.3096 7.17139 19.3096 7.1713 15.3096 3.6713Z"
                fill="#E73844"
            />
            <path
                d="M4.30957 20.1714L6.30954 21.1714C5.90954 17.5714 8.14288 13.3381 9.30954 11.6714L7.80957 10.6714C4.20957 13.0714 2.80957 19.0047 2.80957 21.1714L4.30957 20.1714Z"
                fill="#FF7A7E"
            />
            <path
                d="M15.8096 20.1714L13.8096 21.1714C14.2096 17.5714 11.9763 13.3381 10.8096 11.6714L12.3096 10.6714C15.9096 13.0714 17.3096 19.0047 17.3096 21.1714L15.8096 20.1714Z"
                fill="#FF7A7E"
            />
            <path
                d="M2.00012 9.50035C2.00012 7 6.83346 8.33368 9.00012 10.0003V11.5001C3.40012 13.9001 2.00012 12.8335 2.00012 12.0001V9.50035Z"
                fill="#E73844"
            />
            <path
                d="M18.0001 9.38414C18.0001 6.88379 13.1668 8.21747 11.0001 9.88414V11.3839C16.6001 13.7839 18.0001 12.7173 18.0001 11.8839V9.38414Z"
                fill="#E73844"
            />
            <path
                d="M7.51519 10.9178C7.11519 10.1178 7.68186 9.58445 8.01519 9.41779C3.01514 6.91797 1.84852 8.25112 2.01518 9.41779V11.9178C2.01518 10.7178 5.68185 10.7511 7.51519 10.9178Z"
                fill="#FF4D55"
            />
            <path
                d="M12.5151 10.9178C12.9151 10.1178 12.3484 9.58445 12.0151 9.41779C17.0151 6.91797 18.1818 8.25112 18.0151 9.41779V11.9178C18.0151 10.7178 14.3484 10.7511 12.5151 10.9178Z"
                fill="#FF4D55"
            />
            <rect x="8.01514" y="8.91797" width="4" height="3" rx="1.5" fill="#FF4D55" />
        </svg>
    );
}

export default OverheadGift;
