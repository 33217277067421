import React from 'react';

import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CodeInputReact } from 'code-input-react';

import toast from 'react-hot-toast';

import sendSettings from '../../../../Entities/Lib/sendSettings';
import { mobileProrabIntstance } from '../../../../Entities/Lib/axios';

import styles from './CodeInputModal.module.css';
import { useSendSettings } from '../../../../CustomHooks/useSendSettings';

function CodeInputModal() {
    const navigate = useNavigate();
    const [sendSettings] = useSendSettings();

    const location = useLocation();
    const phoneValue = location?.state?.phoneValue;

    const theme = localStorage.getItem('theme');

    const [codeValue, setCodeValue] = useState('');

    async function verifyCode(code: string) {
        try {
            const response = await mobileProrabIntstance.post('phoneNumberEdit', {
                phoneValue: phoneValue.replace(/ /g, ''),
                code: code,
            });

            if (response.status === 200) {
                const data = await response.data;

                if (data.result === true) {
                    localStorage.setItem('phone', phoneValue);
                    sendSettings();
                    navigate('/settings');
                }
            }
        } catch (e) {
            toast.error('Неверный код');
        }
    }

    return (
        <>
            <div className={styles.header}>
                <svg
                    onClick={() => navigate('/settings/changePhone')}
                    className={styles.exitBtn}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <path
                        d="M17.892 5.30202C17.7995 5.20932 17.6896 5.13577 17.5686 5.08559C17.4477 5.03541 17.318 5.00958 17.187 5.00958C17.0561 5.00958 16.9264 5.03541 16.8054 5.08559C16.6844 5.13577 16.5745 5.20932 16.482 5.30202L11.592 10.182L6.70202 5.29202C6.60944 5.19944 6.49953 5.126 6.37856 5.07589C6.2576 5.02579 6.12795 5 5.99702 5C5.86609 5 5.73644 5.02579 5.61548 5.07589C5.49451 5.126 5.3846 5.19944 5.29202 5.29202C5.19944 5.3846 5.126 5.49451 5.07589 5.61548C5.02579 5.73644 5 5.86609 5 5.99702C5 6.12795 5.02579 6.2576 5.07589 6.37856C5.126 6.49953 5.19944 6.60944 5.29202 6.70202L10.182 11.592L5.29202 16.482C5.19944 16.5746 5.126 16.6845 5.07589 16.8055C5.02579 16.9264 5 17.0561 5 17.187C5 17.318 5.02579 17.4476 5.07589 17.5686C5.126 17.6895 5.19944 17.7994 5.29202 17.892C5.3846 17.9846 5.49451 18.058 5.61548 18.1081C5.73644 18.1583 5.86609 18.184 5.99702 18.184C6.12795 18.184 6.2576 18.1583 6.37856 18.1081C6.49953 18.058 6.60944 17.9846 6.70202 17.892L11.592 13.002L16.482 17.892C16.5746 17.9846 16.6845 18.058 16.8055 18.1081C16.9264 18.1583 17.0561 18.184 17.187 18.184C17.318 18.184 17.4476 18.1583 17.5686 18.1081C17.6895 18.058 17.7994 17.9846 17.892 17.892C17.9846 17.7994 18.058 17.6895 18.1081 17.5686C18.1583 17.4476 18.184 17.318 18.184 17.187C18.184 17.0561 18.1583 16.9264 18.1081 16.8055C18.058 16.6845 17.9846 16.5746 17.892 16.482L13.002 11.592L17.892 6.70202C18.272 6.32202 18.272 5.68202 17.892 5.30202Z"
                        fill={theme === 'dark' ? '#fff' : '#1C1C1C'}
                    />
                </svg>
            </div>
            <div className="titleCodePlaceholder">
                <div className="titleCode">
                    <span className={styles.titleCodeTxt}>4-значный код</span>
                    <span className="titleCodeValue">
                        Введите код, отправленный на номер <br /> {phoneValue}
                    </span>
                </div>

                <div className="codeValuePlaceholder">
                    <div className="container">
                        <CodeInputReact
                            length={4}
                            value={codeValue}
                            onChange={setCodeValue}
                            onComplete={(code) => verifyCode(code)}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default CodeInputModal;
