import React from 'react';

import { useNavigate } from 'react-router-dom/dist';

import { RequestDataType } from '../../../Requests';

function RequestButtonCompleted({ request }: { request: RequestDataType }) {
    const navigate = useNavigate();

    return (
        <div className="footer123">
            <button
                onClick={() => navigate('/createRequest', { state: { request: request } })}
                className="changeButtonWhite"
            >
                Повторить заявку
            </button>
        </div>
    );
}

export default RequestButtonCompleted;
