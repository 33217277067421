import * as React from 'react';
import { mobileProrabIntstance } from '../Entities/Lib/axios';
import { getCookie } from '../Entities/Lib/CookieWork';
import toast from 'react-hot-toast';

export function useSendSettings(callback) {
    const [isSended, setIsSended] = React.useState(false);

    if (callback) {
        callback();
    }

    const sendSettings = (callback) => {
        if (callback) {
            callback();
        }

        if (!isSended) {
            setIsSended(true);
            mobileProrabIntstance
                .post('setSettings', {
                    nameuser: localStorage?.getItem('name'),
                    theme: localStorage?.getItem('theme'),
                    NotificationOfVehicleDeparture: JSON.parse(
                        localStorage?.getItem('isOnlyOneCar'),
                    ),
                    carArrival: JSON.parse(localStorage?.getItem('isCarArrive')),
                    token: getCookie('token'),
                    onboarding: JSON.parse(localStorage?.getItem('onBoarding')),
                    isPushEnabled: JSON.parse(localStorage?.getItem('isPushEnabled')),
                    isMarkCreation: JSON.parse(localStorage?.getItem('IsMarkCreation')),
                })
                .then((response) => {
                    if (response.status === 200 && callback) {
                        callback();
                    }
                    setIsSended(false);
                })
                .catch(() => {
                    setIsSended(false);
                    toast.error('Ошибка сохранения настроек');
                });
        }
    };

    return [sendSettings];
}
