import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { setSearchInput } from '../../../../store/slices/sliceRequests';
import { RootState } from '../../../../store/store';
import { clearValues } from '../../../../store/slices/sliceBonuses';
import Clear from '../../../../content/svg/DocumentsUtils/Clear';
import { useTranslation } from 'react-i18next';

interface HeaderProps {
    headerTitle: string;
    backRedirect?: string;
}

function Header({ headerTitle, backRedirect }: HeaderProps) {
    const location = useLocation();

    const { t } = useTranslation();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const theme = localStorage.getItem('theme');

    const token = location?.state?.token;
    const request = location?.state?.request;

    const search = useSelector((state: RootState) => state.requests.isSearchAvaileble);
    const searchValue = useSelector((state: RootState) => state.requests.searchInput);

    const reset = () => {
        dispatch(clearValues());
        navigate(-1);
        handleClearField();
    };

    const handleClearField = () => {
        dispatch(setSearchInput(''));

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    };

    return (
        <div className="headerRequestsSearch">
            <div className="headerRequestMenu">
                {backRedirect === 'bonuses/filter' && (
                    <button
                        onClick={() => reset()}
                        style={{
                            position: 'absolute',
                            top: '17px',
                            right: '10px',
                            zIndex: 999999,
                            border: 'none',
                            fontFamily: 'Inter',
                            fontSize: '1rem',
                            fontWeight: 400,
                            lineHeight: 'normal',
                            color:
                                localStorage?.getItem('theme') === 'dark' ? '#78B9FF' : '#007AFF',
                            background: 'none',
                        }}
                    >
                        {t('Сбросить')}
                    </button>
                )}
                {backRedirect ? (
                    <svg
                        className="backButton"
                        onClick={() =>
                            navigate(`/${backRedirect}`, {
                                state: { token: token, request: request },
                                replace: true,
                            })
                        }
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <path
                            d="M15 6L9 12L15 18"
                            stroke={theme === 'dark' ? '#FFFFFF' : '#1c1c1c'}
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                ) : null}
                <span className="headerTitle">{t(headerTitle)}</span>
            </div>
            {search && (
                <div className="searchFieldHeader">
                    <div className="searchFieldPlaceholderHeader">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="22"
                            viewBox="0 0 25 22"
                            fill="none"
                        >
                            <g clipPath="url(#clip0_2394_13882)">
                                <path
                                    d="M2 9.39148C2 12.9943 4.93143 15.9258 8.5343 15.9258C9.95905 15.9258 11.261 15.4672 12.3337 14.6975L16.3624 18.7344C16.5507 18.9227 16.7964 19.0128 17.0584 19.0128C17.6152 19.0128 18 18.5952 18 18.0466C18 17.7845 17.9018 17.547 17.7298 17.3751L13.7257 13.3464C14.5691 12.2492 15.0686 10.8818 15.0686 9.39148C15.0686 5.7886 12.1372 2.85718 8.5343 2.85718C4.93143 2.85718 2 5.7886 2 9.39148ZM3.40021 9.39148C3.40021 6.55831 5.70114 4.25739 8.5343 4.25739C11.3674 4.25739 13.6684 6.55831 13.6684 9.39148C13.6684 12.2246 11.3674 14.5256 8.5343 14.5256C5.70114 14.5256 3.40021 12.2246 3.40021 9.39148Z"
                                    fill={theme === 'dark' ? '#B3B3B3' : '#8A8A8E'}
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_2394_13882">
                                    <rect
                                        width="16"
                                        height="16.1556"
                                        fill="white"
                                        transform="translate(2 2.85718)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                        <input
                            type="text"
                            value={searchValue}
                            onChange={(event) => dispatch(setSearchInput(event.target.value))}
                            placeholder={t('Поиск адреса')}
                            className="searchInputHeader"
                        />
                        {searchValue && (
                            <Clear
                                theme={theme}
                                style={{ position: 'absolute', right: '20' }}
                                onClick={() => handleClearField()}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Header;
