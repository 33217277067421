import React from 'react';

function BonusesIcon({ theme, isCurrent }) {
    const fillColor = !isCurrent ? '#B3B3B3' : theme === 'dark' ? '#FFFFFF' : 'black';
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
        >
            <circle cx="12.125" cy="12" r="9" stroke={fillColor} strokeWidth="2" />
            <path d="M15 8L8 15" stroke={fillColor} strokeWidth="2" strokeLinecap="round" />
            <path d="M15 13L13 15" stroke={fillColor} strokeWidth="2" strokeLinecap="round" />
        </svg>
    );
}

export default BonusesIcon;
