import React, { useEffect, useState } from 'react';
import Header from '../../../Utils/Requests/CreateRequest/Header';
import MonthSelect from './MonthSelect/MonthSelect';
import ReactDatePicker from 'react-datepicker';
import { endOfMonth, format, isEqual, setMonth, startOfMonth, subMonths } from 'date-fns';

import styles from './PeriodSelect.module.css';
import { ru } from 'date-fns/locale';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { setBonusesDates, setBonusesPeriod } from '../../../../store/slices/sliceBonuses';
import X from '../../../../content/svg/BonusesPage/X';

function PeriodSelect() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const monthNamesForPicker = [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
    ];

    const startDate = useSelector((state: RootState) => state.bonuses.startDate);
    const endDate = useSelector((state: RootState) => state.bonuses.endDate);
    const period = useSelector((state: RootState) => state.bonuses.period);
    const prevStartDate = useSelector((state: RootState) => state.bonuses.prevStartDate);
    const prevEndDate = useSelector((state: RootState) => state.bonuses.prevEndDate);
    const prevPeriod = useSelector((state: RootState) => state.bonuses.prevPeriod);

    const [startMonth, setStartMonth] = useState(startOfMonth(subMonths(new Date(), 2)));

    const lastMonths = [new Date(), subMonths(new Date(), 1), subMonths(new Date(), 2)]
        .map((elem: Date) => {
            return monthNamesForPicker[elem.getMonth()];
        })
        .concat(['3 месяца']);

    function onChangeDate(dates: Date[]) {
        const [start, end] = dates;
        dispatch(setBonusesDates({ startDate: start, endDate: end }));
    }

    function setPeriodMonth(monthNumber: number) {
        let start = new Date();
        let end = new Date();
        if (monthNumber !== 3) {
            start = startOfMonth(subMonths(new Date(), monthNumber));
            if (monthNumber === 0) {
                end = new Date();
            } else {
                end = endOfMonth(subMonths(new Date(), monthNumber));
            }
        } else {
            start = startOfMonth(subMonths(new Date(), 2));
            end = new Date();
        }

        dispatch(setBonusesDates({ startDate: start, endDate: end }));
    }

    function validationDates() {
        if (endDate) {
            if (isEqual(startDate, endDate)) {
                dispatch(setBonusesPeriod(format(startDate, 'dd MMM', { locale: ru })));
            }
        } else {
            dispatch(setBonusesDates({ startDate: startDate, endDate: startDate }));
            dispatch(setBonusesPeriod(format(startDate, 'dd MMM', { locale: ru })));
        }
        navigate('/bonuses');
    }
    function exit() {
        dispatch(setBonusesDates({ startDate: prevStartDate, endDate: prevEndDate }));
        dispatch(setBonusesPeriod(prevPeriod));

        navigate('/bonuses');
    }

    useEffect(() => {
        if (isEqual(startDate, endDate)) {
            dispatch(setBonusesPeriod(format(startDate, 'dd MMM')));
        }
        dispatch(
            setBonusesPeriod(
                endDate
                    ? `${format(startDate, 'dd MMM', { locale: ru })} - ${format(
                          endDate,
                          'dd MMM',
                          {
                              locale: ru,
                          },
                      )}`
                    : `${format(startDate, 'dd MMM', { locale: ru })}`,
            ),
        );
    }, [startDate, endDate]);

    return (
        <div className={styles.periodSelect}>
            <Header headerTitle="Выбор периода" />
            <X theme={localStorage.getItem('theme')} style={styles.exitX} onClick={exit} />
            <div className={styles.top}>
                <div className={styles.periodSelectTabs}>
                    {lastMonths.map((month, index) => (
                        <MonthSelect
                            title={month}
                            key={index}
                            monthNumber={index}
                            setPeriodMonth={setPeriodMonth}
                        />
                    ))}
                </div>
                <div className={styles.period}>
                    Выбрано: <span className={styles.current}>{period}</span>
                </div>
                <div className={styles.daysOfWeek}>
                    <div className={styles.day}>пн</div>
                    <div className={styles.day}>вт</div>
                    <div className={styles.day}>ср</div>
                    <div className={styles.day}>чт</div>
                    <div className={styles.day}>пт</div>
                    <div className={styles.day}>сб</div>
                    <div className={styles.day}>вс</div>
                </div>
            </div>
            <ReactDatePicker
                calendarClassName="period-select-picker"
                focusSelectedMonth={false}
                selected={startMonth}
                onChange={(dates: any) => {
                    onChangeDate(dates);
                }}
                minDate={startOfMonth(subMonths(new Date(), 7))}
                maxDate={new Date()}
                startDate={startDate}
                endDate={endDate}
                monthsShown={3}
                inline
                selectsRange
                showDisabledMonthNavigation
                calendarStartDay={1}
                openToDate={startMonth}
            />
            <div className={styles.bottom}>
                <div className={styles.button} onClick={validationDates}>
                    Готово
                </div>
            </div>
        </div>
    );
}

export default PeriodSelect;
