import React from 'react';

function BlockPlan({ theme }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13 8C13 10.7614 10.7614 13 8 13C5.23858 13 3 10.7614 3 8C3 5.23858 5.23858 3 8 3C10.7614 3 13 5.23858 13 8ZM15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8ZM9 5C9 4.44772 8.55228 4 8 4C7.44772 4 7 4.44772 7 5V8C7 8.26522 7.10536 8.51957 7.29289 8.70711L8.79289 10.2071C9.18342 10.5976 9.81658 10.5976 10.2071 10.2071C10.5976 9.81658 10.5976 9.18342 10.2071 8.79289L9 7.58579V5Z"
                fill={theme === 'dark' ? '#ffffff' : '#1c1c1c'}
            />
        </svg>
    );
}

export default BlockPlan;
