import React from 'react';

function Email({ theme }: { theme: string }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                d="M13.7748 3.00006H2.22485C1.46546 3.00006 0.849854 3.61567 0.849854 4.37505V12.625C0.849854 13.3844 1.46546 14 2.22485 14H13.7748C14.5342 14 15.1498 13.3844 15.1498 12.625V4.37505C15.1498 3.61567 14.5342 3.00006 13.7748 3.00006Z"
                stroke={theme === 'dark' ? 'white' : '#202020'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.05005 5.20001L8.00002 9.04999L12.95 5.20001"
                stroke={theme === 'dark' ? 'white' : '#202020'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default Email;
