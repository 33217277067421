import * as React from 'react';

import { useSessionStorage } from 'usehooks-ts';
import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import Header from '../../../Utils/Requests/CreateRequest/Header';
import LeftArrow from '../../../../content/svg/RequestsPage/LeftArrow';

import { setIsSearchAvaileble } from '../../../../store/slices/sliceRequests';

import { RootState } from '../../../../store/store';
import { clearValues } from '../../../../store/slices/sliceBonuses';

import styles from './Filter.module.css';

function Filter() {
    const [showAllAddress, setShowAllAddress] = useSessionStorage('showAllAddress', true);
    const [selectedListType, setSelectedListType] = useSessionStorage('listType', 'Адреса');
    const selectedValues = useSelector<RootState>((state) => state.bonuses.selectedValues);

    const isEditable = true;

    const navigate = useNavigate();
    const dispatch = useDispatch();

    dispatch(setIsSearchAvaileble(false));

    const resetFilter = () => {
        setShowAllAddress(true);
        setSelectedListType('Адреса');
        dispatch(clearValues());
    };

    const makeRightReg = () => {
        if (selectedListType === 'Адреса') {
            if ((selectedValues as unknown as Array<any>).length === 1) {
                return `Выбран ${(selectedValues as unknown as Array<any>).length} адрес`;
            } else if ((selectedValues as unknown as Array<any>).length <= 4) {
                return `Выбрано ${(selectedValues as unknown as Array<any>).length} адреса`;
            } else if ((selectedValues as unknown as Array<any>).length >= 5) {
                return `Выбрано ${(selectedValues as unknown as Array<any>).length} адресов`;
            }
        } else {
            if ((selectedValues as unknown as Array<any>).length === 1) {
                return `Выбран ${(selectedValues as unknown as Array<any>).length} контрагент`;
            } else if ((selectedValues as unknown as Array<any>).length <= 4) {
                return `Выбрано ${(selectedValues as unknown as Array<any>).length} контрагента`;
            } else if ((selectedValues as unknown as Array<any>).length >= 5) {
                return `Выбрано ${(selectedValues as unknown as Array<any>).length} контрагентов`;
            }
        }
    };

    return (
        <>
            <Header headerTitle="Фильтр" backRedirect="bonuses" />
            <button
                disabled={showAllAddress}
                onClick={() => resetFilter()}
                style={{
                    position: 'absolute',
                    top: '17px',
                    right: '10px',
                    zIndex: 999999,
                    border: 'none',
                    fontFamily: 'Inter',
                    fontSize: '1rem',
                    fontWeight: 400,
                    lineHeight: 'normal',
                    color: localStorage?.getItem('theme') === 'dark' ? '#78B9FF' : '#007AFF',
                    background: 'none',
                    opacity: showAllAddress ? '0.5' : '1',
                }}
            >
                Сбросить
            </button>
            <div className={styles.content}>
                <div className={styles.general_push}>
                    <div className={styles.general_push_placeholder}>
                        <span className={styles.txt}>Показывать все</span>
                        <input
                            className={styles.switcher}
                            onChange={() => {
                                setSelectedListType('Адреса');
                                setShowAllAddress(!showAllAddress);
                                dispatch(clearValues());
                            }}
                            checked={showAllAddress ? true : false}
                            type="checkbox"
                            id="switch2"
                        />
                        <label className={styles.labelForSwitcher} htmlFor="switch2">
                            Toggle
                        </label>
                    </div>
                </div>

                <div
                    style={{ opacity: showAllAddress ? '0.5' : '1' }}
                    className={styles.generalPush}
                >
                    <div className="coneDraftButtons">
                        <button
                            disabled={showAllAddress}
                            onClick={() => {
                                setSelectedListType('Адреса');
                                dispatch(clearValues());
                            }}
                            className={
                                selectedListType === 'Адреса'
                                    ? 'coneDraftBtn clicked3'
                                    : 'coneDraftBtn'
                            }
                        >
                            Адреса
                        </button>
                        <button
                            disabled={showAllAddress}
                            onClick={() => {
                                setSelectedListType('Грузопол-ли');
                                dispatch(clearValues());
                            }}
                            className={
                                selectedListType === 'Грузопол-ли'
                                    ? 'coneDraftBtn clicked3'
                                    : 'coneDraftBtn'
                            }
                        >
                            Грузопол-ли
                        </button>
                    </div>
                </div>
                <button
                    style={{ border: 'none', opacity: showAllAddress ? '0.5' : '1' }}
                    disabled={showAllAddress}
                    onClick={() =>
                        navigate('selectList', { state: { listType: selectedListType } })
                    }
                    className={
                        (selectedValues as unknown as Array<any>).length !== 0
                            ? 'modalSelect Active'
                            : 'modalSelect'
                    }
                >
                    {(selectedValues as unknown as Array<any>).length !== 0 ? (
                        <div style={{ textAlign: 'left' }} className="modalInformationBlock">
                            <span className="title Active">
                                {selectedListType === 'Адреса' ? 'Адрес' : 'Грузополучатель'}
                            </span>
                            <span className="selectedInformation">{makeRightReg()}</span>
                        </div>
                    ) : (
                        <span
                            style={{ justifyContent: 'flex-start', flexDirection: 'row' }}
                            className={isEditable ? 'title' : 'title Active'}
                        >
                            {selectedListType === 'Адреса'
                                ? 'Выберите адреса'
                                : 'Выберите грузополучателя'}
                        </span>
                    )}

                    {isEditable && <LeftArrow theme={localStorage.getItem('theme')} />}
                </button>

                <div className="footerSendButton">
                    <div className="sendButtonPlaceholder">
                        <button onClick={() => navigate('/bonuses')} className={'sendButtonActive'}>
                            Показать
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Filter;
