import React from 'react';

function DownArrow({ theme }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="24"
            viewBox="0 0 16 24"
            fill="none"
        >
            <g clipPath="url(#clip0_37_4200)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.21967 9.21967C2.51256 8.92678 2.98744 8.92678 3.28033 9.21967L7.75 13.6893L12.2197 9.21967C12.5126 8.92678 12.9874 8.92678 13.2803 9.21967C13.5732 9.51256 13.5732 9.98744 13.2803 10.2803L8.28033 15.2803C7.98744 15.5732 7.51256 15.5732 7.21967 15.2803L2.21967 10.2803C1.92678 9.98744 1.92678 9.51256 2.21967 9.21967Z"
                    fill={theme === 'dark' ? '#fff' : '#3C3C43'}
                />
            </g>
            <defs>
                <clipPath id="clip0_37_4200">
                    <rect
                        width="24"
                        height="16"
                        fill="white"
                        transform="translate(16) rotate(90)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}

export default DownArrow;
