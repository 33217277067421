import React from 'react';
import { useState } from 'react';

import uuid from 'react-uuid';

import { Link } from 'react-router-dom';

import Accordion from '../Accordion/Accordion';
import { baseType, mobileProrabIntstance } from '../../../../Entities/Lib/axios';
import { RequestFieldDataType } from '../../../../Entities/Interfaces/Interfaces';
import Download from '../../../../../content/svg/RequestsPage/Download';
import BigUpArrow from '../../../../../content/svg/RequestsPage/BigUpArrow';
import BigDownArrow from '../../../../../content/svg/RequestsPage/BigDownArrow';

import { getCookie } from '../../../../Entities/Lib/CookieWork';

import FileChecked from '../../../../../content/svg/DocumentsUtils/FileChecked';

import styles from './Requests.module.css';
import Letter from '../../../../../content/svg/RequestsPage/Letter';
import toast from 'react-hot-toast';

interface RequestProps {
    order: RequestFieldDataType;
    isSearchView?: boolean;
}

function Requests({ order, isSearchView }: RequestProps) {
    const [isAccordeonOpened, setIsAccordeonOpened] = useState(isSearchView ? true : false);
    const theme = localStorage.getItem('theme');
    const email = localStorage.getItem('email');

    const handleSendLetter = async (type: string, uid: string) => {
        if (email !== 'undefined' && email !== 'Нету привязанной почты') {
            const response = mobileProrabIntstance.post('sendLetter', {
                type: type,
                docUid: uid,
            });

            toast.promise(response, {
                loading: 'Отправка...',
                success: 'Письмо отправлено',
                error: 'Произошла ошибка, повторите попытку позже',
            });
        } else {
            toast.error('Нету привязанной почты, обратитесь к менеджеру');
        }
    };

    return (
        <div key={uuid()} className={styles.accordeonPlaceholder}>
            <div className={styles.accordeonPosition}>
                <div className={styles.accordeon}>
                    <div className={styles.order}>
                        <div className={styles.namePlaceholder}>
                            <div className={styles.name}>
                                <span className={styles.nomenclature}>
                                    {order.orderNomenclature}
                                </span>
                                <span className={styles.address}>{order.orderAddress}</span>
                            </div>
                            {!isSearchView && <hr className={styles.separator} />}
                        </div>
                        <div className={styles.totalPlaceholder}>
                            {localStorage.getItem('isOpenDocumentInBrowser') === 'true' &&
                            order.deliveryData[0].declarationAvailable ? (
                                <>
                                    <Link
                                        target="_blank"
                                        to={`https://api-erkon.tech/${baseType}/hs/mobileProrab/deliveryDoc/${
                                            order.deliveryData[0].deliveryUID
                                        }/${getCookie('token')}/Декларация`}
                                        className={styles.base}
                                    >
                                        <span className={styles.baseTxt}>Декларация</span>
                                        <FileChecked theme={theme} />

                                        <div className={styles.downloadBtn}>
                                            <div className={styles.arrowImg}>
                                                <Letter theme={theme} />
                                            </div>
                                        </div>
                                    </Link>
                                    <hr className={styles.separator} />
                                </>
                            ) : (
                                <>
                                    {order.deliveryData[0].declarationAvailable && (
                                        <>
                                            <button
                                                style={{
                                                    opacity: order.deliveryData[0]
                                                        .declarationAvailable
                                                        ? 1
                                                        : 0.5,
                                                }}
                                                onClick={
                                                    order.deliveryData[0].declarationAvailable
                                                        ? () =>
                                                              handleSendLetter(
                                                                  'dec',
                                                                  order.deliveryData[0].deliveryUID,
                                                              )
                                                        : () => {
                                                              toast.error(
                                                                  'Декларация появиться позже',
                                                              );
                                                          }
                                                }
                                                className={styles.base}
                                            >
                                                <span className={styles.baseTxt}>Декларация</span>
                                                {order.deliveryData[0].declarationAvailable && (
                                                    <FileChecked theme={theme} />
                                                )}

                                                <div className={styles.downloadBtn}>
                                                    <div className={styles.arrowImg}>
                                                        <Letter theme={theme} />
                                                    </div>
                                                </div>
                                            </button>
                                            <hr className={styles.separator} />
                                        </>
                                    )}
                                </>
                            )}
                            {localStorage.getItem('advancedMode') === 'true' && !isSearchView && (
                                <div className={styles.twoPlashkas}>
                                    {localStorage.getItem('isOpenDocumentInBrowser') === 'true' ? (
                                        <>
                                            <Link
                                                target="_blank"
                                                to={`https://api-erkon.tech/${baseType}/hs/mobileProrab/deliveryDocAll/${
                                                    order.orderUID
                                                }/${getCookie('token')}/ВсеТН`}
                                                className={styles.baseTwo}
                                            >
                                                <span className={styles.baseTxt}>Все ТН</span>

                                                {order.deliveryData.every(
                                                    (deliveryTicket) =>
                                                        deliveryTicket.TNfromArchive === true,
                                                ) && <FileChecked theme={theme} />}
                                                <div className={styles.downloadBtn}>
                                                    <div className={styles.arrowImg}>
                                                        <Letter theme={theme} />
                                                    </div>
                                                </div>
                                            </Link>

                                            <Link
                                                target="_blank"
                                                to={`https://api-erkon.tech/${baseType}/hs/mobileProrab/deliveryDocAll/${
                                                    order.orderUID
                                                }/${getCookie('token')}/ВсеПК`}
                                                className={styles.baseTwo}
                                            >
                                                <span className={styles.baseTxt}>Все ПК</span>
                                                <FileChecked theme={theme} />
                                                <div className={styles.downloadBtn}>
                                                    <div className={styles.arrowImg}>
                                                        <Letter theme={theme} />
                                                    </div>
                                                </div>
                                            </Link>
                                        </>
                                    ) : (
                                        <>
                                            <button
                                                onClick={() =>
                                                    handleSendLetter('tnAll', order.orderUID)
                                                }
                                                className={styles.baseTwo}
                                            >
                                                <span className={styles.baseTxt}>Все ТН</span>

                                                {order.deliveryData.every(
                                                    (deliveryTicket) =>
                                                        deliveryTicket.TNfromArchive === true,
                                                ) && <FileChecked theme={theme} />}
                                                <div className={styles.downloadBtn}>
                                                    <div className={styles.arrowImg}>
                                                        <Letter theme={theme} />
                                                    </div>
                                                </div>
                                            </button>

                                            <button
                                                onClick={() =>
                                                    handleSendLetter('passAll', order.orderUID)
                                                }
                                                className={styles.baseTwo}
                                            >
                                                <span className={styles.baseTxt}>Все ПК</span>
                                                <FileChecked theme={theme} />
                                                <div className={styles.downloadBtn}>
                                                    <div className={styles.arrowImg}>
                                                        <Letter theme={theme} />
                                                    </div>
                                                </div>
                                            </button>
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                        {!isSearchView && (
                            <div
                                onClick={() => setIsAccordeonOpened(!isAccordeonOpened)}
                                className={styles.qty}
                            >
                                <div className={styles.txt}>
                                    <div className={styles.race}>
                                        <span className={styles.racesText}>Рейсы:</span>
                                        <span className={styles.racesValue}>
                                            {order.deliveryCount}
                                        </span>
                                    </div>

                                    <div className={styles.amount}>
                                        <span className={styles.amountText}>Объем:</span>
                                        <span className={styles.amountValue}>
                                            {order.weightALL}м<sup className="supMidTxt">3</sup>
                                        </span>
                                    </div>

                                    <div className={styles.arrow}>
                                        {isAccordeonOpened ? (
                                            <BigUpArrow theme={theme} />
                                        ) : (
                                            <BigDownArrow theme={theme} />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {isAccordeonOpened && (
                        <Accordion deliveryData={order.deliveryData} key={order.orderNumber} />
                    )}
                </div>
            </div>
        </div>
    );
}

export default React.memo(Requests);
