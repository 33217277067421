import React, { useEffect } from 'react';

import styles from './AcceptanceTypeSelect.module.css';
import SelfDrain from '../../../../../content/svg/RequestUtils/SelfDrain';
import VerticalCrane from '../../../../../content/svg/RequestUtils/VerticalCrane';
import Pump from '../../../../../content/svg/RequestUtils/Pump';
import HorizontalCrane from '../../../../../content/svg/RequestUtils/HorizontalCrane';

interface AcceptanceTypeSelectProps {
    setIntensity: React.Dispatch<React.SetStateAction<number>>;
    setIntervalValue: React.Dispatch<React.SetStateAction<string>>;
    setSelectedAccptanceType: React.Dispatch<React.SetStateAction<string>>;
    acceptanceType: string;
}

function AcceptanceTypeSelect({
    setIntensity,
    setIntervalValue,
    setSelectedAccptanceType,
    acceptanceType,
}: AcceptanceTypeSelectProps) {
    const theme = localStorage.getItem('theme');

    function setValues(interval: string, intensity: string, acceptanceType: string) {
        setIntensity(+intensity);
        setIntervalValue(interval);
        setSelectedAccptanceType(acceptanceType);
    }

    return (
        <>
            <div className="nasosAndKran">
                <button
                    id="1"
                    className={
                        acceptanceType === 'Насос'
                            ? 'selectionMethonBtn active'
                            : 'selectionMethonBtn'
                    }
                    onClick={() => setValues('00:15', '36', 'Насос')}
                >
                    <div className={styles.centeredDiv}>
                        <Pump />
                        <br />
                        <span
                            className={
                                acceptanceType === 'Насос' ? styles.chipTxtSelected : styles.chipTxt
                            }
                        >
                            Насос
                        </span>
                    </div>
                </button>

                <button
                    id="2"
                    className={
                        acceptanceType === 'Кран вертикал'
                            ? 'selectionMethonBtn active'
                            : 'selectionMethonBtn'
                    }
                    onClick={() => setValues('01:00', '9', 'Кран вертикал')}
                >
                    <div className={styles.centeredDiv}>
                        <VerticalCrane theme={theme} />
                        <br />
                        <span
                            className={
                                acceptanceType === 'Кран вертикал'
                                    ? styles.chipTxtSelected
                                    : styles.chipTxt
                            }
                        >
                            Кран вертикал
                        </span>
                    </div>
                </button>
            </div>

            <div className="svayaAndSamosliv">
                <button
                    id="3"
                    className={
                        acceptanceType === 'Свая/Самослив'
                            ? 'selectionMethonBtn active'
                            : 'selectionMethonBtn'
                    }
                    onClick={() => setValues('00:20', '27', 'Свая/Самослив')}
                >
                    <div className={styles.centeredDiv}>
                        <SelfDrain />
                        <br />
                        <span
                            className={
                                acceptanceType === 'Свая/Самослив'
                                    ? styles.chipTxtSelected
                                    : styles.chipTxt
                            }
                        >
                            Свая/Самослив
                        </span>
                    </div>
                </button>

                <button
                    id="4"
                    className={
                        acceptanceType === 'Кран горизонт'
                            ? 'selectionMethonBtn active'
                            : 'selectionMethonBtn'
                    }
                    onClick={() => setValues('00:30', '18', 'Кран горизонт')}
                >
                    <div className={styles.centeredDiv}>
                        <HorizontalCrane theme={theme} />
                        <br />
                        <span
                            className={
                                acceptanceType === 'Кран горизонт'
                                    ? styles.chipTxtSelected
                                    : styles.chipTxt
                            }
                        >
                            Кран горизонт
                        </span>
                    </div>
                </button>
            </div>
        </>
    );
}

export default AcceptanceTypeSelect;
