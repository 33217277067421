import React from 'react';

function HorizontalCrane({ theme }) {
    return (
        <svg
            width="135"
            height="66"
            color="#1C1C1C"
            viewBox="0 0 135 66"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M28.8711 26.8085L45.383 12.8472L63.1936 26.8085"
                stroke={theme === 'dark' ? '#B3B3B3' : '#474747'}
                strokeLinecap="round"
            />
            <rect
                x="28.5"
                y="29.5913"
                width="35.2501"
                height="12.9869"
                fill="url(#paint0_linear_375_7427)"
            />
            <path
                d="M28.5 42.5784H63.7501L54.1027 56.6784H38.8895L28.5 42.5784Z"
                fill="url(#paint1_linear_375_7427)"
            />
            <path
                d="M28.8711 64.4706C28.5637 64.4706 28.3145 64.7198 28.3145 65.0271C28.3145 65.3345 28.5637 65.5837 28.8711 65.5837V64.4706ZM64.1212 65.5837C64.4286 65.5837 64.6778 65.3345 64.6778 65.0271C64.6778 64.7198 64.4286 64.4706 64.1212 64.4706V65.5837ZM34.4369 42.5784V65.0271H35.55V42.5784H34.4369ZM57.2567 42.5784V65.0271H58.3699V42.5784H57.2567ZM28.8711 65.5837H64.1212V64.4706H28.8711V65.5837Z"
                fill={theme === 'dark' ? '#B3B3B3' : '#474747'}
            />
            <path
                d="M28.8711 42.5784L63.3791 42.5784"
                stroke={theme === 'dark' ? '#B3B3B3' : '#474747'}
                strokeWidth="1.11316"
                strokeLinecap="round"
            />
            <rect
                x="38.8895"
                y="56.6782"
                width="15.2132"
                height="4.45265"
                fill="url(#paint2_linear_375_7427)"
            />
            <path
                d="M28.8711 29.7768V26.6228M63.3791 29.7768V26.6228"
                stroke={theme === 'dark' ? '#B3B3B3' : '#474747'}
                strokeWidth="1.11316"
                strokeLinecap="round"
            />
            <path
                d="M28.8711 29.9624L63.3791 29.9624"
                stroke={theme === 'dark' ? '#B3B3B3' : '#474747'}
                strokeWidth="1.11316"
                strokeLinecap="round"
            />
            <path
                d="M46.9537 2.93294C46.9537 3.66235 46.4357 4.27078 45.7476 4.41048C45.6501 4.43026 45.5493 4.44064 45.446 4.44064C45.3428 4.44064 44.8776 4.43026 44.7801 4.41048C44.7801 4.41048 43.8757 3.66235 43.8757 2.93294C43.8757 2.10026 44.6134 1.42524 45.446 1.42524C46.2787 1.42524 46.9537 2.10026 46.9537 2.93294Z"
                fill={theme === 'dark' ? '#B3B3B3' : '#474747'}
            />
            <path
                d="M45.446 4.44064C45.3428 4.44064 44.8776 4.43026 44.7801 4.41048V6.79017C44.7801 7.32446 45.2133 7.75758 45.7476 7.75758L45.7476 4.41048C45.6501 4.43026 45.5493 4.44064 45.446 4.44064Z"
                fill={theme === 'dark' ? '#B3B3B3' : '#474747'}
            />
            <path
                d="M47.0418 2.78231C47.0418 3.78152 46.2318 4.59147 45.2326 4.59147C44.2334 4.59147 43.4234 3.78152 43.4234 2.78231C43.4234 1.78309 44.2334 0.973145 45.2326 0.973145C46.2318 0.973145 47.0418 1.78309 47.0418 2.78231Z"
                fill={theme === 'dark' ? '#B3B3B3' : '#474747'}
            />
            <path
                d="M45.2327 6.85299C45.7654 6.85299 46.6782 6.95777 47.1386 7.22569C47.5991 7.4936 47.9803 7.87873 48.2436 8.34187C48.5069 8.80501 48.6427 9.32963 48.6373 9.86233C48.632 10.395 48.4855 10.9168 48.213 11.3745C47.9404 11.8323 47.5515 12.2096 47.0857 12.4682C46.6199 12.7268 46.094 12.8573 45.5613 12.8466C45.0287 12.8358 44.5085 12.6841 44.0535 12.407C43.6794 12.1791 43.3605 11.873 43.1178 11.5111C43.0306 11.3811 43.0828 11.2083 43.2204 11.1337C43.3579 11.0591 43.5286 11.1114 43.6186 11.2394C43.8116 11.5142 44.0598 11.7473 44.3483 11.923C44.7178 12.1481 45.1403 12.2713 45.5728 12.28C46.0053 12.2888 46.4324 12.1827 46.8107 11.9728C47.1889 11.7628 47.5048 11.4564 47.7261 11.0846C47.9474 10.7129 48.0663 10.2892 48.0707 9.85661C48.0751 9.42402 47.9648 8.99799 47.751 8.62188C47.5372 8.24578 47.2276 7.93303 46.8537 7.71546C46.6119 7.57478 46.186 7.49235 45.7982 7.45165C45.4869 7.41899 45.2327 7.16594 45.2327 6.85299Z"
                fill={theme === 'dark' ? '#B3B3B3' : '#474747'}
                stroke={theme === 'dark' ? '#B3B3B3' : '#474747'}
                strokeWidth="0.633207"
            />
            <path
                d="M80.3339 41.0274C80.3756 41.1254 80.4351 41.2147 80.5095 41.2908L87.428 48.2094C87.5841 48.3548 87.7905 48.4339 88.0037 48.4301C88.217 48.4264 88.4204 48.34 88.5712 48.1892C88.7221 48.0384 88.8084 47.8349 88.8122 47.6217C88.816 47.4084 88.7368 47.202 88.5914 47.046L83.0777 41.5323L113.049 41.5318L107.381 47.2001C107.235 47.3561 107.156 47.5625 107.16 47.7757C107.164 47.989 107.25 48.1924 107.401 48.3432C107.552 48.4941 107.755 48.5804 107.968 48.5842C108.182 48.588 108.388 48.5088 108.544 48.3634L115.617 41.2904C115.691 41.2143 115.751 41.1249 115.793 41.027C115.836 40.9265 115.859 40.8182 115.859 40.7087C115.859 40.5992 115.836 40.4909 115.793 40.3904C115.751 40.2925 115.691 40.2031 115.617 40.127L108.544 33.0542C108.39 32.9001 108.18 32.8135 107.962 32.8135C107.744 32.8135 107.535 32.9001 107.381 33.0542C107.227 33.2085 107.14 33.4178 107.14 33.6359C107.14 33.854 107.227 34.0632 107.381 34.2176L113.049 39.8856L83.0777 39.886L88.5914 34.3725C88.7455 34.2182 88.8321 34.009 88.8321 33.7908C88.8321 33.5727 88.7455 33.3635 88.5914 33.2092C88.4371 33.055 88.2278 32.9684 88.0097 32.9684C87.7916 32.9684 87.5824 33.055 87.428 33.2092L80.5095 40.1275C80.4351 40.2036 80.3756 40.2929 80.3339 40.3909C80.2904 40.4914 80.268 40.5997 80.268 40.7092C80.268 40.8186 80.2904 40.927 80.3339 41.0274Z"
                fill={theme === 'dark' ? '#B3B3B3' : '#474747'}
            />
            <defs>
                <linearGradient
                    id="paint0_linear_375_7427"
                    x1="28.5"
                    y1="36.0848"
                    x2="63.7502"
                    y2="36.0848"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D5D5D5" />
                    <stop offset="0.258081" stopColor="#ECECEC" />
                    <stop offset="0.765625" stopColor="#ECECEC" />
                    <stop offset="1" stopColor="#D5D5D5" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_375_7427"
                    x1="28.5"
                    y1="49.6284"
                    x2="63.7502"
                    y2="49.6284"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D5D5D5" />
                    <stop offset="0.258081" stopColor="#ECECEC" />
                    <stop offset="0.765625" stopColor="#ECECEC" />
                    <stop offset="1" stopColor="#D5D5D5" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_375_7427"
                    x1="46.4961"
                    y1="56.6782"
                    x2="46.4961"
                    y2="61.1309"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#929292" stopOpacity="0.49" />
                    <stop offset="1" stopColor="#929292" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default HorizontalCrane;
