import React from 'react';

import { Link } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import FileChecked from '../../../../../content/svg/DocumentsUtils/FileChecked';
import Letter from '../../../../../content/svg/RequestsPage/Letter';
import { baseType, mobileProrabIntstance } from '../../../../Entities/Lib/axios';
import { setDeliveryTicket, togglePopup } from '../../../../../store/slices/sliceDocuments';
import { getCookie } from '../../../../Entities/Lib/CookieWork';

import styles from './AccordionStatistic.module.css';
import toast from 'react-hot-toast';
import { format } from 'date-fns';

function AccordionStatisticOrder({ updData }: { updData: any }) {
    const theme = localStorage.getItem('theme');

    const result = updData.map((updTicket: any, index: number) => {
        return (
            <>
                {index === 0 && <hr className={styles.separator} />}
                <div key={updTicket.UID} className={styles.raceBlock}>
                    <div className={styles.raceTicket}>
                        <div className={styles.raceNameUPD}>
                            <span className={styles.raceNameTxt}>{updTicket.name}</span>
                        </div>
                        <div className={styles.raceNameUPD}>
                            <span className={styles.raceAutoTxtUPD}>{updTicket.address}</span>
                        </div>
                        <div className={styles.docs}>
                            <Link
                                target="_blank"
                                to={`https://api-erkon.tech/${baseType}/hs/mobileProrab/updFile/${getCookie(
                                    'token',
                                )}/${updTicket.UID}`}
                                className={styles.base}
                            >
                                <span className={styles.baseTxt}>УПД</span>
                                {updTicket.signed && <FileChecked theme={theme} />}
                                <div className={styles.downloadBtn}>
                                    <div className={styles.arrowImg}>
                                        <Letter theme={theme} />
                                    </div>
                                </div>
                            </Link>
                            <Link
                                target="_blank"
                                to={`https://api-erkon.tech/${baseType}/hs/mobileProrab/updTNFile/${getCookie(
                                    'token',
                                )}/${updTicket.UID}`}
                                className={styles.base}
                            >
                                <span className={styles.baseTxt}>УПД+ТН</span>
                                {updTicket.signed && <FileChecked theme={theme} />}
                                <div className={styles.downloadBtn}>
                                    <div className={styles.arrowImg}>
                                        <Letter theme={theme} />
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </>
        );
    });

    return <>{result}</>;
}

export default React.memo(AccordionStatisticOrder);
