import { createSlice } from '@reduxjs/toolkit';

export const requestsSlice = createSlice({
    name: 'requests',
    initialState: {
        activeTabsCount: 0,
        availebleEntarances: [],
        searchInput: '',
        isSearchAvaileble: false,
        isDateShouldForceUpdated: false,
        nasosDate: new Date(),
    },
    reducers: {
        setActiveTabsCountDis: (state, action) => {
            state.activeTabsCount = action.payload;
        },
        setAvailebleEntarances: (state, action) => {
            state.availebleEntarances = action.payload;
        },
        setIsSearchAvaileble: (state, action) => {
            state.isSearchAvaileble = action.payload;
        },
        setSearchInput: (state, action) => {
            state.searchInput = action.payload;
        },
        setIsDateShouldForceUpdated: (state, action) => {
            state.isDateShouldForceUpdated = action.payload;
        },
        setNasosDate: (state, action) => {
            state.nasosDate = action.payload;
        },
    },
});

export const {
    setActiveTabsCountDis,
    setAvailebleEntarances,
    setIsSearchAvaileble,
    setSearchInput,
    setIsDateShouldForceUpdated,
    setNasosDate,
} = requestsSlice.actions;

export default requestsSlice.reducer;
