import React from 'react';
import { useState, useRef } from 'react';
import { useEffect } from 'react';

import { useSessionStorage } from 'usehooks-ts';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { format, parse, parseISO } from 'date-fns';

import SubmitButton from '../../../../../Features/SubmitButton/SubmitButton';
import Capacity from '../../../CreateRequest/CreatingComponents/Capacity';
import Header from '../../../../../Utils/Requests/ChangeRequest/Header';
import {
    selectAddressUID,
    selectNomenclature,
    selectReceiverUID,
    setCars,
    setFiller,
    setSelectedClient,
    setSelectedClientUID,
} from '../../../../../../store/slices/sliceSelectedInfo';
import { setIsSearchAvaileble } from '../../../../../../store/slices/sliceRequests';

import { RootState } from '../../../../../../store/store';

import ContactInfo from './RequestsChangedValues/ContactInformation';
import Nomenclature from './RequestsChangedValues/Nomenclature';
import Address from './RequestsChangedValues/Address';

function UpdateRequest() {
    const dispatch = useDispatch();
    const location = useLocation();

    const request = location?.state?.request;

    const interval = parse(request.interval, 'HH:mm', new Date());

    const deliveryType = request.deliveryType;
    const address_uid = request.address_uid;
    const order_uid = request.order_uid;

    const address = request.address;
    const nomenclature = request.nomenclature;
    const nomenclatureType = request.nomenclatureType;
    const receiver = request.receiver ? request.receiver : null;
    const receiverUID = request.receiverUID ? request.receiverUID : null;
    const client = request.counterpart ? request.counterpart : null;
    const clientUID = request.counterpartUid ? request.counterpartUid : null;
    dispatch(setSelectedClient(client));
    dispatch(setSelectedClientUID(clientUID));

    dispatch(selectNomenclature(nomenclature));
    dispatch(selectReceiverUID(receiverUID));
    dispatch(setFiller(nomenclatureType));

    let changedNomeclatureUID = useSelector(
        (state: RootState) => state.selectedInfo.selectedNomenclatureUID,
    );
    let changedNomeclature = useSelector(
        (state: RootState) => state.selectedInfo.selectedNomenclature,
    );

    if (!changedNomeclatureUID) {
        changedNomeclatureUID = request.nomenclature_uid;
    }

    if (!changedNomeclature) {
        changedNomeclature = request.nomenclature;
    }

    const upper_date = parse(request.upper_bound_date, 'dd.MM.yyyy', new Date());
    const lower_date = parse(request.lower_bound_date, 'dd.MM.yyyy', new Date());
    const lower_time = parse(request.lower_bound_time, 'HH:mm', new Date());
    const upper_time = parse(request.upper_bound_time, 'HH:mm', new Date());
    const date1C = format(parse(request.date, 'dd.MM.yy', new Date()), 'dd.MM.yyyy');
    const time1C = format(parse(request.time, 'HH:mm', new Date()), 'HH:mm');

    const refAddressSelector = useRef<HTMLDivElement>(null);
    const refNomenclatureSelector = useRef<HTMLDivElement>(null);
    const refPhoneSelector = useRef<HTMLDivElement>(null);
    const refPumpSelector = useRef<HTMLDivElement>(null);

    const contactInformation = useRef(request.phone_number);
    const selectedContactNumber = useSelector(
        (state: RootState) => state.selectedInfo.selectedContactNumber,
    );

    const [selectedAccptanceType, setSelectedAccptanceType] = useSessionStorage(
        'acceptanceType',
        request.acceptance_method,
    );
    const [commentaryValue, setCommentaryValue] = useSessionStorage('comment', request.commentary);
    const [intervalValue, setIntervalValue] = useSessionStorage('interval', request.interval);
    const [pumpCount, setPumpCount] = useSessionStorage('pumpCount', request.pumpCount);
    const [receivingMethod, setRecivingMethod] = useSessionStorage('receivingMethod', 'Интервал');
    const [intensity, setIntensity] = useSessionStorage('intensity', request.intensity);
    const [capacity, setCapacity] = useSessionStorage('capacity', request.capacity);
    const [coneDraft, setConeDraft] = useSessionStorage('coneDraft', request.cone_draft);
    const [crane, setCrane] = useSessionStorage('crane', request?.crane ? request?.crane : 1);
    const [date, setDate] = useSessionStorage(
        'date',
        parse(`${date1C} ${time1C}`, 'dd.MM.yyyy HH:mm', new Date()),
    );
    const [parsedDate, setParsedDate] = useSessionStorage('date1c', date);
    const [time, setTime] = useSessionStorage('time', parse(request.time, 'HH:mm', new Date()));
    const [laitance, setLaitance] = useSessionStorage('laitance', 0);
    const [isDateChanged, setisDateChanged] = useState(false);
    const [factoryType, setFactoryType] = useSessionStorage('factoryType', request.factoryType);

    useEffect(() => {
        setisDateChanged(true);
    }, [date]);

    useEffect(() => {
        dispatch(setIsSearchAvaileble(false));
    }, []);

    useEffect(() => {
        if (selectedContactNumber) {
            contactInformation.current = selectedContactNumber;
        }
    }, [contactInformation.current]);

    useEffect(() => {
        if (
            changedNomeclature === 'Цементное молоко' ||
            changedNomeclature === 'Цементное молоко густое'
        ) {
            setCapacity(1);
        }
    }, [changedNomeclature]);

    useEffect(() => {
        dispatch(selectAddressUID(request.address_uid));
        dispatch(setCars(request.numberOfCars));
    }, []);

    const isEditable = request.substatus === 'Ожидание' ? false : true;
    const isPlanned = request.substatus === 'Плановая' ? true : false;

    return (
        <div className="requestMenu">
            <Header headerTitle={changedNomeclature} backRedirect={'requests'} request={request} />
            {isDateChanged ? (
                <Address
                    deliveryType={deliveryType}
                    receiver={receiver}
                    factoryType={factoryType}
                    setFactoryType={setFactoryType}
                    refAddressSelector={refAddressSelector}
                    address={address}
                    request={request}
                    lower_date={lower_date}
                    lower_time={lower_time}
                    upper_date={upper_date}
                    upper_time={upper_time}
                    isPlanned={isPlanned}
                    setParsedDate={setParsedDate}
                    parsedDate={parsedDate}
                />
            ) : (
                <Address
                    deliveryType={deliveryType}
                    receiver={receiver}
                    factoryType={factoryType}
                    setFactoryType={setFactoryType}
                    address={address}
                    refAddressSelector={refAddressSelector}
                    lower_date={lower_date}
                    lower_time={lower_time}
                    upper_date={upper_date}
                    upper_time={upper_time}
                    isPlanned={isPlanned}
                    setParsedDate={setParsedDate}
                    parsedDate={parsedDate}
                />
            )}
            <Nomenclature
                capacity={capacity}
                request={request}
                refNomenclatureSelector={refNomenclatureSelector}
                nomenclature={nomenclature}
                coneDraft={coneDraft}
                isEditable={isEditable}
                addressUID={address_uid}
                setCapacity={setCapacity}
                setConeDraft={setConeDraft}
            />
            {changedNomeclature !== 'Цементное молоко' &&
            changedNomeclature !== 'Цементное молоко густое' ? (
                <Capacity
                    requestType={'Изменение'}
                    intervalValue={intervalValue}
                    intensity={intensity}
                    capacity={capacity}
                    acceptanceType={selectedAccptanceType}
                    setIntervalValue={setIntervalValue}
                    setIntensity={setIntensity}
                    setSelectedAccptanceType={setSelectedAccptanceType}
                    receivingMethod={receivingMethod}
                    setRecivingMethod={setRecivingMethod}
                    pumpCount={pumpCount}
                    setPumpCount={setPumpCount}
                    crane={crane}
                    setCrane={setCrane}
                    laitance={laitance}
                    setLaitance={setLaitance}
                    refPumpSelector={refPumpSelector}
                />
            ) : (
                <>{null}</>
            )}
            <ContactInfo
                selectedTelephone={contactInformation.current}
                request={request}
                commentaryValue={commentaryValue}
                setCommentaryValue={setCommentaryValue}
                refPhoneSelector={refPhoneSelector}
                routeToSelect={'updateContactInformation'}
            />
            <br />
            <br />
            <br />
            <br />
            <SubmitButton
                action={'update'}
                isRequestCopy={false}
                methodToExecute={'change_order'}
                initialTextValue={'Изменить заявку'}
                successType={'/successUpdate'}
                addressUID={address_uid}
                nomenclatureUID={changedNomeclatureUID}
                contactInformation={contactInformation.current}
                selectedAcceptanceType={selectedAccptanceType}
                intervalValue={intervalValue}
                intensity={intensity}
                capacity={capacity}
                coneDraft={coneDraft}
                date={typeof parsedDate === 'string' ? parseISO(parsedDate) : parsedDate}
                receivingMethod={receivingMethod}
                deliveryType={deliveryType}
                factoryType={factoryType}
                orderUID={order_uid}
                pumpCount={pumpCount}
                crane={crane}
                laitance={laitance}
                refAddressSelector={refAddressSelector}
                refNomenclatureSelector={refNomenclatureSelector}
                refPhoneSelector={refPhoneSelector}
                request={request}
                refPumpSelector={refPumpSelector}
            />
        </div>
    );
}

export default UpdateRequest;
