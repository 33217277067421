import React from 'react';

import { useNavigate } from 'react-router-dom';

import AvatarIcon from '../../../../content/svg/SettingsUtils/AvatarIcon';

import RedDot from '../../../../content/svg/SettingsUtils/RedDot';
import Notify from '../../../../content/svg/SettingsUtils/Notify';

import styles from './NameAndPhone.module.css';
import { useTranslation } from 'react-i18next';

function NameAndPhone() {
    const tel = localStorage.getItem('phone') || '';
    const name = localStorage.getItem('name') || '';

    const { t } = useTranslation();

    const isAnyMessages = false;

    const navigate = useNavigate();

    return (
        <div className={styles.name}>
            <div className={styles.avatar}>
                <AvatarIcon theme={localStorage.getItem('theme')} />
                <span className={styles.bigCharName}>{name ? name[0] : ''}</span>
            </div>

            <div className={styles.fullName}>
                <span className={styles.fullNameTxt}>
                    {name ? name : t('Ошибка получения имени')}
                </span>
                <span className={styles.phoneTxt}>
                    {tel ? tel : t('Ошибка получения телефона')}
                </span>
            </div>
            {localStorage.getItem('pushyToken') && (
                <div className={styles.notification_icon}>
                    <div
                        className={styles.iconPlaceholder}
                        onClick={() => navigate('notifications')}
                    >
                        <Notify theme={localStorage.getItem('theme')} />
                    </div>
                    <div className={styles.circleAttention}>{isAnyMessages && <RedDot />}</div>
                </div>
            )}
        </div>
    );
}

export default NameAndPhone;
