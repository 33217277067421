import React from 'react';

import { useEffect } from 'react';
import { useState } from 'react';

import { mobileProrabIntstance } from '../../Entities/Lib/axios';

import styles from './SentAgainBtn.module.css';

import toast from 'react-hot-toast';
import { format, subSeconds } from 'date-fns';

interface SentAgainBtnProps {
    phoneValue: string;
    isBonuse?: boolean;
}

function SentAgainBtn({ phoneValue, isBonuse }: SentAgainBtnProps) {
    const [isButtonPressed, setIsButtonPressed] = useState(false);
    const [targetTime, setTargetTime] = useState(Date.now() + 60000);
    const [timer, setTimer] = useState(format(60000, 'mm:ss'));
    const [isTimerRun, setIsTimerRun] = useState(true);

    async function sendCode() {
        const response = await mobileProrabIntstance.post(`auth`, {
            phoneValue: phoneValue.replace(/ /g, '')
        });

        if (response.status === 200) {
            setIsButtonPressed(true);
        }
    }

    async function sendCodeConfirm() {
        try {
            const response = await mobileProrabIntstance.post(
                `cardRequest`,
                {
                    nominal: 5000
                }
            );

            if (response.status === 200) {
                return;
            }
        } catch (error) {
            toast.error('Ошибка авторизации, попробуйте снова');
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            const currentTime = Date.now();
            const delta = (targetTime - currentTime) / 1000;
            if (delta <= 0) {
                clearInterval(interval);
                setIsTimerRun(false);
            } else {
                setTimer(format(delta * 1000, 'mm:ss'));
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <span
            onClick={isBonuse ? () => sendCode() : () => sendCodeConfirm()}
            className={isTimerRun ? styles.sendCodeOneMoreDisabled : styles.sendCodeOneMore}
        >
            Отправить код еще раз {isTimerRun && `через ${timer}`}
        </span>
    );
}

export default SentAgainBtn;
