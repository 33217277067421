import React from 'react';

import { useNavigate } from 'react-router-dom';

import uuid from 'react-uuid';

import Interval from '../Interval/Interval';

import { RequestDataType } from '../../../Pages/Requests/Requests';

import Calendar from '../../../../content/svg/RequestsPage/Calendar';
import Timer from '../../../../content/svg/RequestsPage/Timer';
import ReducedValue from '../../../../content/svg/RequestUtils/ReducedValue';

interface RequestsFieldProps {
    filteredRequests: RequestDataType[];
    status: string;
    substatus: string;
    statusToShow?: string;
}

function RequestsField({ filteredRequests, status, substatus, statusToShow }: RequestsFieldProps) {
    const navigate = useNavigate();
    const theme = localStorage.getItem('theme');

    const image = (status: string, substatus: string) => {
        if (status === 'Текущая') {
            return (
                <div className="card_statusActive">
                    <span className="card_statusActiveTxt">{statusToShow}</span>
                </div>
            );
        } else if (substatus === 'Пауза') {
            return (
                <div className="card_statusPause">
                    <span className="card_statusPauseTxt">{substatus}</span>
                </div>
            );
        } else if (status === 'Плановая' && substatus === 'Плановая') {
            return (
                <div className="card_statusPlanned">
                    <span className="card_statusPlannedTxt">{substatus}</span>
                </div>
            );
        } else if (status === 'Плановая' && substatus === 'Ожидание') {
            return (
                <div className="card_statusWaiting">
                    <span className="card_statusWaitingTxt">{substatus}</span>
                </div>
            );
        } else if (status === 'Завершена' && substatus === 'Завершена') {
            return (
                <div className="card_statusComplete">
                    <span className="card_statusCompleteTxt">{substatus}</span>
                </div>
            );
        } else if (status === 'Плановая' && substatus === 'На проверке') {
            return (
                <div className="card_statusComplete">
                    <span className="card_statusCompleteTxt">В работе</span>
                </div>
            );
        }
    };

    const requests = [...filteredRequests]
        .filter((request) => request.status === status && request.substatus === substatus)
        .map((request) => {
            return (
                <div key={uuid()} className="requestPosition">
                    <div className="requestTicket">
                        <div
                            onClick={() => {
                                if (
                                    request.status === 'Плановая' &&
                                    request.substatus === 'Ожидание'
                                ) {
                                    navigate('/confirmRequest', { state: { request: request } });
                                } else if (
                                    request.status === 'Плановая' &&
                                    request.substatus === 'Плановая'
                                ) {
                                    navigate('/updateRequest', { state: { request: request } });
                                } else if (request.status === 'Текущая') {
                                    navigate('/activeRequest', { state: { request: request } });
                                } else {
                                    navigate('/requestMenu', { state: { request: request } });
                                }
                            }}
                            className="requestHeader"
                        >
                            <div
                                className={
                                    request.substatus !== 'На проверке'
                                        ? 'nomenclatureAndStatusRequest'
                                        : 'uniqueHeaderXd'
                                }
                            >
                                {request.substatus === 'На проверке' ? (
                                    <div className="longStatusPlan">
                                        <span className="longStatusTxtPlan">
                                            На проверке у диспетчера
                                        </span>
                                    </div>
                                ) : (
                                    <span className="requestNomenclature">
                                        {request.nomenclature}
                                    </span>
                                )}
                                {request.substatus !== 'На проверке' ? (
                                    <div className="status">
                                        {image(request.status, request.substatus)}
                                    </div>
                                ) : null}
                            </div>

                            {request.substatus === 'На проверке' ? (
                                <span className="requestNomenclatureLong">
                                    {request.nomenclature}
                                </span>
                            ) : null}

                            <span
                                className={
                                    request.substatus === 'На проверке'
                                        ? 'requestAddressFullSize'
                                        : 'requestAddress'
                                }
                            >
                                {request.address}
                            </span>

                            <hr className="requestLine" />

                            <div className="timeAndCapacityInfo">
                                <div className="infoTimeAndCapacityBlock">
                                    <span className="capacityTitle">Время</span>

                                    {request.dateChanged ? (
                                        <div className={'changedValueFrame'}>
                                            <span className="capacityTitleRed">{request.time}</span>
                                            <Timer paddingTop="2px" theme={theme} />
                                        </div>
                                    ) : (
                                        <span className="capacityValue">{request.time}</span>
                                    )}
                                </div>
                                <div className="infoTimeAndCapacityBlock">
                                    <span className="capacityTitle">Объём</span>
                                    {request.capacityChanged ? (
                                        <div className={'changedValueFrame'}>
                                            <span className="capacityTitleRed">
                                                {`${request.capacity}м`}
                                                <sup className="supMidTxt">3</sup>
                                                <ReducedValue theme={theme} />
                                            </span>
                                        </div>
                                    ) : (
                                        <span className="capacityValue">
                                            {`${request.capacity}м`}
                                            <sup className="supMidTxt">3</sup>
                                        </span>
                                    )}
                                </div>
                                <div className="infoTimeAndCapacityBlock">
                                    {request.substatus === 'Завершена' ? (
                                        <>
                                            <span className="capacityTitle">Выгружено</span>
                                            <span className="capacityValue">
                                                {`${request.distributed}м`}
                                                <sup className="supMidTxt">3</sup>
                                            </span>
                                        </>
                                    ) : (
                                        <>
                                            <span className="capacityTitle">Приём</span>
                                            <span className="capacityValue">
                                                {request.acceptance_method === 'Свая/Самослив'
                                                    ? 'Самослив'
                                                    : request.acceptance_method}
                                            </span>
                                        </>
                                    )}
                                </div>
                            </div>
                            <hr className="requestLine" />
                            <Interval
                                interval={request.interval}
                                ticketIndex={request.number}
                                requestUID={request.order_uid}
                            />
                        </div>
                    </div>
                </div>
            );
        });

    return <>{requests}</>;
}

export default RequestsField;
