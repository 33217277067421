import React from 'react';

import DateObject from 'react-date-object';

import './DatePicker.css';

import { useDispatch, useSelector } from 'react-redux';
import { addDays, format, parse, subDays } from 'date-fns';
import { useEffect } from 'react';
import { selectDate } from '../../../../store/slices/sliceSelectedInfo.ts';
import { useRef } from 'react';
import { ru } from 'date-fns/locale';

function DatePicker({ requests, setSelectedDate, setFilterdRequests, headerRef }) {
    const dispactch = useDispatch();
    const selectedDate = useSelector((state) => state.selectedInfo.selectedDate);
    const tabsCount = useSelector((state) => state.requests.activeTabsCount);
    const datePickerRef = useRef();

    const dates = [];
    for (let i = -2; i < 5; i++) {
        if (i < 0) {
            dates.push(subDays(new Date(), -1 * i));
        } else {
            dates.push(addDays(new Date(), i));
        }
    }

    useEffect(() => {
        dispactch(selectDate(dates[2]));
    }, []);

    useEffect(() => {
        if (headerRef) {
            headerRef.current.style.setProperty('--heightValue', tabsCount < 2 ? '80px' : '132px');
        }
    }, [tabsCount, headerRef]);

    function filterRequests(date) {
        if (requests) {
            setSelectedDate(date);
            dispactch(selectDate(date));
            let newRequests = [...requests].filter(
                (request) => request.date === format(date, 'dd.MM.yy'),
            );
            setFilterdRequests(newRequests);

            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
    }

    return (
        <div ref={datePickerRef} className="datePicker">
            <>
                {dates.map((date, index) => (
                    <button
                        className={
                            format(selectedDate, 'dd.MM.yyyy') === format(date, 'dd.MM.yyyy')
                                ? 'datePicker button currentDate'
                                : 'datePicker button '
                        }
                        onClick={() => filterRequests(date)}
                        key={index}
                    >
                        {' '}
                        <span className="weekDay">{format(date, 'EEEEEE', { locale: ru })}</span>
                        <span className="weekNumberDay">{format(date, 'd')}</span>
                    </button>
                ))}
            </>
        </div>
    );
}

export default React.memo(DatePicker);
