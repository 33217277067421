import React from 'react';

interface FactoryTypeSelectProps {
    factoryType: string;
    setFactoryType: React.Dispatch<React.SetStateAction<string>>;
}

function FactoryTypeSelect({ factoryType, setFactoryType }: FactoryTypeSelectProps) {
    const handleSelect = (factoryType: string) => {
        setFactoryType(factoryType);
    };

    return (
        <div className="coneDraftButtons">
            <button
                onClick={() => handleSelect('Магистральная')}
                className={
                    factoryType === 'Магистральная' ? 'coneDraftBtn clicked2' : 'coneDraftBtn'
                }
            >
                Магистральная
            </button>
            <button
                onClick={() => handleSelect('Южнопортовая')}
                className={
                    factoryType === 'Южнопортовая' ? 'coneDraftBtn clicked2' : 'coneDraftBtn'
                }
            >
                Южнопортовая
            </button>
        </div>
    );
}

export default FactoryTypeSelect;
