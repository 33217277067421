import React from 'react';
import { useNavigate } from 'react-router-dom';

import './Header.css';
import InWork from '../../../../content/svg/RequestUtils/InWork';
import InDistribution from '../../../../content/svg/RequestUtils/InDistribution';
import Waiting from '../../../../content/svg/RequestUtils/Waiting';
import InPlan from '../../../../content/svg/RequestUtils/InPlan';
import OnCheck from '../../../../content/svg/RequestUtils/OnCheck';

interface HeaderProps {
    headerTitle: string;
    backRedirect?: string;
    request?: any; // todo
}

function Header({ headerTitle, backRedirect, request }: HeaderProps) {
    const navigate = useNavigate();

    const theme = localStorage.getItem('theme');

    const image = () => {
        switch (request.substatus) {
            case 'В работе':
                return <InWork />;
            case 'На распределении':
                return <InDistribution />;
            case 'Ожидание':
                return <Waiting />;
            case 'Плановая':
                return <InPlan theme={theme} />;
            case 'На проверке':
                return <OnCheck theme={theme} />;
            case 'Завершена':
                return null;
        }
    };

    return (
        <div className="headerRequestsSearch">
            <div className="headerRequestMenu">
                <svg
                    className="backButtonSecondHeader"
                    onClick={() => navigate(`/${backRedirect}`, { replace: true })}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <path
                        d="M15 6L9 12L15 18"
                        stroke={theme === 'dark' ? '#fff' : '#3C3C43'}
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>

                <div className="titleAndStatus">
                    <span className="headerTitle">{headerTitle}</span>

                    <div className="statusTitle">
                        {image()}

                        <span className="requestChangeStatus">
                            {request.substatus === 'На проверке'
                                ? 'На проверке у диспетчера'
                                : request.substatus === 'На распределении'
                                  ? 'В работе'
                                  : request.substatus}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;
