import React, { useEffect, useState } from 'react';

import './YMap.css';

import { YMaps, Map, GeoObject } from '@pbe/react-yandex-maps';

import axios from 'axios';
import uuid from 'react-uuid';

import { RequestDataType } from '../../../Pages/Requests/Requests';

import { mobileProrabIntstance } from '../../../Entities/Lib/axios';

import { CarDateType } from '../../../Entities/Interfaces/Interfaces';

import Cars from './Cars/Cars';
import LoadingCarsPlaceholder from './Cars/LoadingCarsPlaceholder';

function YMap({
    request,
    isWithMap = true,
    isWithActiveCars = true,
}: {
    request: RequestDataType;
    isWithMap?: boolean;
    isWithActiveCars?: boolean;
}) {
    const [activeCars, setActiveCars] = useState<CarDateType[]>();
    const [finishedCars, setFinishedCars] = useState<CarDateType[]>();
    const [isRendered, setIsRendered] = useState(false);
    const [mapState, setMapState] = useState({ center: [55.751574, 37.60856], zoom: 10 });
    const [personLatitude, setPersonLatitude] = useState(NaN);
    const [personLongtitude, setPersonLongtitude] = useState(NaN);

    function geoFindMe() {
        function success(position: GeolocationPosition) {
            setPersonLatitude(position.coords.latitude);
            setPersonLongtitude(position.coords.longitude);
        }

        function error() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(success, error);
            } else {
                return;
            }
        }
    }

    useEffect(() => {
        async function tryLoadCars() {
            const response = await mobileProrabIntstance.post(`abslist_upd`, {
                orderUID: request.order_uid,
            });

            if (response.status === 200) {
                const data = (await response.data) as CarDateType[];

                const activeCars = [...data].filter(
                    (car) => car.status === 'На разгрузке' || car.status === 'В пути',
                );
                const onWayCars = [...data].filter((car) => car.status === 'Завершен');

                setActiveCars(activeCars);
                setFinishedCars(onWayCars);
                setIsRendered(true);
            }
        }

        tryLoadCars();
    }, [request.order_uid]);

    setInterval(() => {
        if (document.querySelectorAll('[class$="gotoymaps"]')[0]) {
            (document.querySelectorAll('[class$="gotoymaps"]')[0] as HTMLElement).style.display =
                'none';
            (
                document.getElementsByClassName('ymaps-2-1-79-copyrights-pane')[0] as HTMLElement
            ).style.display = 'none';
        }
    }, 1);

    return (
        <>
            <div className="mapAndObjectsPlaceholder">
                <div className="capacityInfo">
                    <div className="capacityBlock">
                        <span className="capacityTitle">Объём</span>
                        <span className="capacityValue">
                            {request.capacity}м<sup className="supMidTxt">3</sup>
                        </span>
                    </div>

                    <hr className="infoBlockLine" />

                    <div className="capacityBlock">
                        <span className="capacityTitle">Заявлено</span>
                        <span className="capacityValue">
                            {request.distributed}м<sup className="supMidTxt">3</sup>
                        </span>
                    </div>

                    <hr className="infoBlockLine" />

                    <div className="capacityBlock">
                        <span className="capacityTitle">Выгружено</span>
                        <span className="capacityValue">
                            {request.unloaded}м<sup className="supMidTxt">3</sup>
                        </span>
                    </div>
                </div>
                {isWithMap && (
                    <YMaps query={{ apikey: '440be56a-1e71-49b8-8ee3-c84002b6095c' }}>
                        <Map width={'100%'} height={425} state={mapState}>
                            {activeCars
                                ? activeCars.map((car) => {
                                      return (
                                          <GeoObject
                                              key={uuid()}
                                              geometry={{
                                                  type: 'Point',
                                                  coordinates: [+car.latitude, +car.longitude],
                                              }}
                                              properties={{
                                                  iconContent: car.abs,
                                              }}
                                              options={{
                                                  preset: 'islands#blackStretchyIcon',
                                              }}
                                          />
                                      );
                                  })
                                : null}
                            <GeoObject
                                key={uuid()}
                                geometry={{
                                    type: 'Point',
                                    coordinates: [
                                        +request.address_latitude,
                                        +request.address_longitude,
                                    ],
                                }}
                                properties={{
                                    iconContent: 'О',
                                }}
                                options={{
                                    preset: 'islands#grayIcon',
                                }}
                            />
                            <GeoObject
                                key={uuid()}
                                geometry={{
                                    type: 'Point',
                                    coordinates: [personLatitude, personLongtitude],
                                }}
                                properties={{
                                    iconContent: 'Я',
                                }}
                                options={{
                                    preset: 'islands#redIcon',
                                }}
                            />
                        </Map>
                    </YMaps>
                )}
            </div>
            {isRendered ? (
                activeCars !== undefined || finishedCars !== undefined ? (
                    <div className="carsPlaceholder">
                        {isWithActiveCars && (
                            <Cars
                                setMapState={setMapState}
                                cars={activeCars}
                                carStatusToShow={'текущие рейсы'}
                            />
                        )}
                        <Cars
                            setMapState={setMapState}
                            cars={finishedCars}
                            carStatusToShow={'Завершенные рейсы'}
                        />
                    </div>
                ) : null
            ) : (
                <LoadingCarsPlaceholder />
            )}
        </>
    );
}

export default YMap;
