import React from 'react';

import { useSessionStorage } from 'usehooks-ts';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { parse, parseISO } from 'date-fns';
import { format } from 'date-fns';
import { useRef } from 'react';
import { useEffect } from 'react';

import ContactInfo from '../ChangeRequest/RequestsChangedValues/ContactInformation';
import SubmitButton from '../../../../../Features/SubmitButton/SubmitButton';
import Capacity from '../../../CreateRequest/CreatingComponents/Capacity';
import Header from '../../../../../Utils/Requests/ChangeRequest/Header';
import {
    selectAddressUID,
    selectContactInfo,
    selectNomenclature,
    selectReceiverUID,
    setCars,
    setFiller,
    setSelectedClient,
    setSelectedClientUID,
} from '../../../../../../store/slices/sliceSelectedInfo';
import { setIsSearchAvaileble } from '../../../../../../store/slices/sliceRequests';
import { RootState } from '../../../../../../store/store';

import Nomenclature from './ConfirmRequestsActions/Nomenclature';
import Address from './ConfirmRequestsActions/Address';

function ConfirmRequest() {
    const dispatch = useDispatch();
    const location = useLocation();
    const request = location?.state?.request;

    const contactInformation = useSelector(
        (state: RootState) => state.selectedInfo.selectedContactNumber,
    );
    const changedContactInformation = useSelector(
        (state: RootState) => state.selectedInfo.selectedContactNumber,
    );

    const address_uid = request.address_uid;
    const deliveryType = request.deliveryType;
    const nomenclature = request.nomenclature;
    const nomenclatureType = request.nomenclatureType;
    const nomenclature_uid = request.nomenclature_uid;
    const order_uid = request.order_uid;
    const address = request.address;
    const receiver = request.receiver;
    const receiverUID = request.receiverUID;
    const client = request.counterpart ? request.counterpart : null;
    const clientUID = request.counterpartUid ? request.counterpartUid : null;

    dispatch(setSelectedClient(client));
    dispatch(setSelectedClientUID(clientUID));
    dispatch(selectNomenclature(nomenclature));
    dispatch(selectReceiverUID(receiverUID));
    dispatch(setFiller(nomenclatureType));

    const refAddressSelector = useRef<HTMLDivElement>(null);
    const refNomenclatureSelector = useRef<HTMLDivElement>(null);
    const refPhoneSelector = useRef<HTMLDivElement>(null);

    const lower_time = parse(request.lower_bound_time, 'HH:mm', new Date());
    const upper_time = parse(request.upper_bound_time, 'HH:mm', new Date());
    const upper_date = parse(request.upper_bound_date, 'dd.MM.yyyy', new Date());
    const lower_date = parse(request.lower_bound_date, 'dd.MM.yyyy', new Date());
    const date1C = format(parse(request.date, 'dd.MM.yy', new Date()), 'dd.MM.yyyy');
    const time1C = format(parse(request.time, 'HH:mm', new Date()), 'HH:mm');

    const [selectedAccptanceType, setSelectedAccptanceType] = useSessionStorage(
        'acceptanceType',
        request.acceptance_method,
    );
    const [intervalValue, setIntervalValue] = useSessionStorage('interval', request.interval);
    const [commentaryValue, setCommentaryValue] = useSessionStorage('comment', request.commentary);
    const [pumpCount, setPumpCount] = useSessionStorage('pumpCount', request.pumpCount);
    const [receivingMethod, setRecivingMethod] = useSessionStorage('receivingMethod', 'Интервал');
    const [intensity, setIntensity] = useSessionStorage('intensity', request.intensity);
    const [capacity, setCapacity] = useSessionStorage('capacity', request.capacity);
    const [coneDraft, setConeDraft] = useSessionStorage('coneDraft', request.cone_draft);
    const [laitance, setLaitance] = useSessionStorage('laitance', 0);
    const [factoryType, setFactoryType] = useSessionStorage('factoryType', request.factoryType);
    const [crane, setCrane] = useSessionStorage('crane', request?.crane ? request?.crane : 1);
    const [date, setDate] = useSessionStorage(
        'date',
        parse(`${date1C} ${time1C}`, 'dd.MM.yyyy HH:mm', new Date()),
    );
    const [parsedDate, setParsedDate] = useSessionStorage('dateSaved', date);
    const [time, setTime] = useSessionStorage('time', parse(request.time, 'HH:mm', new Date()));

    const isPlanned = request.substatus === 'Плановая' ? true : false;

    useEffect(() => {
        dispatch(selectAddressUID(request.address_uid));
        dispatch(setCars(request.numberOfCars));
    }, [dispatch]);

    useEffect(() => {
        dispatch(setIsSearchAvaileble(false));
        dispatch(selectContactInfo(request.phone_number));
    }, []);

    return (
        <div className="requestMenu">
            <Header headerTitle={nomenclature} backRedirect={'requests'} request={request} />
            <Address
                deliveryType={deliveryType}
                factoryType={factoryType}
                setFactoryType={setFactoryType}
                refAddressSelector={refAddressSelector}
                request={request}
                receiver={receiver}
                address={address}
                lower_date={lower_date}
                lower_time={lower_time}
                upper_date={upper_date}
                upper_time={upper_time}
                // isPlanned={isPlanned}
                setParsedDate={setParsedDate}
                parsedDate={parsedDate}
            />
            <Nomenclature
                refNomenclatureSelector={refNomenclatureSelector}
                capacity={capacity}
                request={request}
                nomenclature={nomenclature}
                coneDraft={coneDraft}
                addressUID={address_uid}
                setCapacity={setCapacity}
                setConeDraft={setConeDraft}
            />
            {nomenclature !== 'Цементное молоко' && nomenclature !== 'Цементное молоко густое' ? (
                <Capacity
                    requestType={'Подтверждение'}
                    intervalValue={intervalValue}
                    intensity={intensity}
                    receivingMethod={receivingMethod}
                    setRecivingMethod={setRecivingMethod}
                    capacity={capacity}
                    acceptanceType={selectedAccptanceType}
                    setIntervalValue={setIntervalValue}
                    setIntensity={setIntensity}
                    setSelectedAccptanceType={setSelectedAccptanceType}
                    pumpCount={pumpCount}
                    setPumpCount={setPumpCount}
                    crane={crane}
                    setCrane={setCrane}
                    laitance={laitance}
                    setLaitance={setLaitance}
                />
            ) : (
                <>{null}</>
            )}
            <ContactInfo
                selectedTelephone={
                    changedContactInformation !== null
                        ? changedContactInformation
                        : contactInformation
                }
                refPhoneSelector={refPhoneSelector}
                request={request}
                commentaryValue={commentaryValue}
                setCommentaryValue={setCommentaryValue}
                routeToSelect={'confirmContactInformation'}
            />
            <br />
            <br />
            <br />
            <br />
            <div className="footerBtns">
                <SubmitButton
                    action={'confirm'}
                    isRequestCopy={false}
                    methodToExecute={'order_confirm'}
                    initialTextValue={'Подтвердить заявку'}
                    successType={'/successAccept'}
                    addressUID={address_uid}
                    nomenclatureUID={nomenclature_uid}
                    contactInformation={
                        changedContactInformation !== null
                            ? changedContactInformation
                            : contactInformation
                    }
                    selectedAcceptanceType={selectedAccptanceType}
                    intervalValue={intervalValue}
                    intensity={intensity}
                    capacity={capacity}
                    coneDraft={coneDraft}
                    crane={crane}
                    date={typeof parsedDate === 'string' ? parseISO(parsedDate) : parsedDate}
                    receivingMethod={receivingMethod}
                    deliveryType={deliveryType}
                    factoryType={factoryType}
                    orderUID={order_uid}
                    pumpCount={pumpCount}
                    laitance={laitance}
                    refAddressSelector={refAddressSelector}
                    refNomenclatureSelector={refNomenclatureSelector}
                    refPhoneSelector={refPhoneSelector}
                    request={request}
                />
            </div>
        </div>
    );
}

export default ConfirmRequest;
