import React from 'react';
import './Success.css';
import { useNavigate } from 'react-router-dom';

function Success({ successType, successAdditionalTxt, backRedirect }) {
    const navigate = useNavigate();
    const timeToExit = 2500;

    setTimeout(() => {
        navigate(backRedirect);
    }, timeToExit);

    return (
        <div className="successBody">
            <div className="successMark">
                <div className="successPictures">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="161"
                        height="160"
                        viewBox="0 0 161 160"
                        fill="none"
                    >
                        <circle opacity="0.3" cx="80.5" cy="80" r="79.5" stroke="white" />
                    </svg>

                    <svg
                        className="checked"
                        xmlns="http://www.w3.org/2000/svg"
                        width="158"
                        height="110"
                        viewBox="0 0 158 110"
                        fill="none"
                    >
                        <path
                            d="M1.5 58L50.5 107L156 1.5"
                            stroke="white"
                            strokeWidth="3"
                            strokeLinecap="round"
                        />
                    </svg>
                </div>
                <span className="successText">{successType}</span>
                {successAdditionalTxt === 'Ozon' && (
                    <span className="successAdditionalText">
                        Активируйте его на сайте или в приложении{' '}
                        <span className="certificateName">Озон</span>
                    </span>
                )}
            </div>
        </div>
    );
}

export default Success;
