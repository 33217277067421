import React, { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import toast from 'react-hot-toast';

import ModalSelect from '../../../../../../Utils/Requests/CreateRequest/ModalSelect';

import { selectCommentary } from '../../../../../../../store/slices/sliceSelectedInfo';
import { useGetPhoneNumbersQuery } from '../../../../../../../servises/api';

interface ContactInfoProps {
    selectedTelephone: string;
    request: any;
    routeToSelect: string;
    refPhoneSelector: React.RefObject<HTMLDivElement>;
    commentaryValue: string;
    setCommentaryValue: (value: string) => void;
}

function ContactInfo({
    selectedTelephone,
    request,
    routeToSelect,
    refPhoneSelector,
    commentaryValue,
    setCommentaryValue,
}: ContactInfoProps) {
    const dispatch = useDispatch();

    const {data: dataPhones, isSuccess: isSuccessPhones } = useGetPhoneNumbersQuery(request.address_uid, {
        skip: !request.address_uid
    });

    useEffect(() => {
        dispatch(selectCommentary(commentaryValue));
    }, [commentaryValue]);

    return (
        <>
            <div className="methodAndInterval">
                <span className="titleRequestMenu">Кому</span>
                <ModalSelect
                    isLoaded={isSuccessPhones}
                    request={request}
                    refPhoneSelector={refPhoneSelector}
                    selectTitle={'Телефон'}
                    route={routeToSelect}
                    selectedInformation={selectedTelephone}
                    isEditable={true}
                    content={dataPhones}
                />
            </div>
            <div className="nomenclatureAndCapacity">
                <span className="addInfoButtonTitle">Комментарий</span>
                <input
                    type="text"
                    value={commentaryValue === null ? '' : commentaryValue}
                    onChange={(event) => setCommentaryValue(event.target.value)}
                    placeholder="Комментарий"
                    style={converted}
                />
            </div>
        </>
    );
}

const converted = {
    display: 'flex',
    height: '60px',
    padding: '0px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
    alignSelf: 'stretch',
    borderRadius: '8px',
    background: 'var(--white-gray)',

    overflow: 'hidden',
    color: 'var(--txt-color)',
    fontFeatureSettings: "'case' on",
    textOverflow: 'ellipsis',
    fontFamily: "'Inter', sans-serif",
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
};

export default ContactInfo;
