import React from 'react';

function Moon({ theme }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <g clipPath="url(#clip0_807_21845)">
                <path
                    d="M4.71798 3.89746C4.71798 2.85063 4.87216 1.79114 5.26498 0.888916C2.65199 2.02635 0.888916 4.69473 0.888916 7.72652C0.888916 11.8048 4.19524 15.1111 8.27353 15.1111C11.3053 15.1111 13.9737 13.3481 15.1111 10.7351C14.2089 11.1279 13.1494 11.2821 12.1026 11.2821C8.0243 11.2821 4.71798 7.97575 4.71798 3.89746Z"
                    stroke={theme === 'dark' ? 'white' : 'black'}
                    strokeWidth="1.09722"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_807_21845">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default Moon;
