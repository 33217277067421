import React from 'react';

function ReducedValue({ theme }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={15}
            height={12}
            viewBox="0 0 14 13"
            fill="none"
        >
            <g clipPath="url(#clip0_2966_23005)">
                <path
                    d="M6.90002 1.79999H12.9"
                    stroke={theme === 'dark' ? '#FF8B86' : '#F54941'}
                    strokeWidth={1.2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M1 9L3.5 11.5"
                    stroke={theme === 'dark' ? '#FF8B86' : '#F54941'}
                    strokeWidth={1.2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M3.5 1.5L3.5 11.5"
                    stroke={theme === 'dark' ? '#FF8B86' : '#F54941'}
                    strokeWidth={1.2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.90002 4.79999H11.7"
                    stroke={theme === 'dark' ? '#FF8B86' : '#F54941'}
                    strokeWidth={1.2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.90002 7.79999H10.5"
                    stroke={theme === 'dark' ? '#FF8B86' : '#F54941'}
                    strokeWidth={1.2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.90002 10.8H9.30002"
                    stroke={theme === 'dark' ? '#FF8B86' : '#F54941'}
                    strokeWidth={1.2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_2966_23005">
                    <rect width={14} height={12} fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default ReducedValue;
