import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useController, useForm } from 'react-hook-form';

/*eslint no-extra-boolean-cast: "off"*/

import LeftArrow from '../../../../../content/svg/RequestsPage/LeftArrow';

import { ResizableTextarea } from '../../../../Shared/ResizableTextarea/ResizableTextarea';
import PaperClip from '../../../../../content/svg/RequestUtils/PaperClip';
import Trash from '../../../../../content/svg/RequestsPage/Trash';
import Img from '../../../../../content/svg/RequestUtils/Img';
import RadioButtonRound from '../../../../Shared/RadioButtonRound/RadioButtonRound';
import Modal from '../../../../Utils/Modal/Modal';

import { ComplaintForm } from './model';
import styles from './Complaint.module.css';

export default function Complaint() {
    const theme = localStorage.getItem('theme');
    const navigate = useNavigate();

    const nomenclature = localStorage.getItem('nomenclature') || 'номенклатура';
    const form = useForm<ComplaintForm>({
        defaultValues: {
            nomenclature: nomenclature,
        },
    });

    const [isOpenRouteSelector, setIsOpenRouteSelector] = useState(false);

    const { field: themeField } = useController({
        control: form.control,
        name: 'theme',
        rules: {
            required: true,
        },
    });
    const { field: descriptionField } = useController({
        control: form.control,
        name: 'description',
        rules: {
            required: themeField.value === 'Качество бетона',
        },
    });
    const { field: routeField } = useController({
        control: form.control,
        name: 'route',
        rules: {
            required: true,
        },
    });
    const { field: notesField } = useController({
        control: form.control,
        name: 'notes',
    });
    const { field: filesField } = useController({
        control: form.control,
        name: 'files',
    });

    const routes = [
        { id: 0, number: 'С 404 СА', time: '12:00' },
        { id: 1, number: 'В 394 СР', time: '12:00' },
        { id: 2, number: 'В 384 СР', time: '12:00' },
        { id: 3, number: 'В 395 СР', time: '12:00' },
        { id: 4, number: 'В 194 СР', time: '12:00' },
        { id: 5, number: 'В 894 СР', time: '12:00' },
        { id: 6, number: 'В 544 СФ', time: '12:00' },
    ];

    return (
        <div className={styles.page}>
            <div className={styles.header}>
                <div className={styles.icon} onClick={() => navigate(-1)}>
                    <LeftArrow theme={theme} />
                </div>
                <span>Претензия</span>
                <div className={styles.icon}></div>
            </div>
            <div className={styles.content}>
                <div className={styles.nomenclature}>{nomenclature}</div>
                <div className={styles.chips}>
                    <span className={styles.heading}>Выберите тему претензии</span>
                    <div className={styles.chipList}>
                        <div
                            className={[
                                styles.baseChip,
                                themeField.value === 'Качество бетона' && styles.chipActive,
                            ].join(' ')}
                            onClick={() => themeField.onChange('Качество бетона')}
                        >
                            Качество бетона
                        </div>
                        <div
                            className={[
                                styles.baseChip,
                                themeField.value === 'Количество' && styles.chipActive,
                            ].join(' ')}
                            onClick={() => themeField.onChange('Количество')}
                        >
                            Количество
                        </div>
                        <div
                            className={[
                                styles.baseChip,
                                themeField.value === 'Доставка' && styles.chipActive,
                            ].join(' ')}
                            onClick={() => themeField.onChange('Доставка')}
                        >
                            Доставка
                        </div>
                        <div
                            className={[
                                styles.baseChip,
                                themeField.value === 'Другое' && styles.chipActive,
                            ].join(' ')}
                            onClick={() => themeField.onChange('Другое')}
                        >
                            Другое
                        </div>
                    </div>
                </div>
                {themeField.value === 'Качество бетона' && (
                    <div className={styles.chips}>
                        <span className={styles.heading}>Вид</span>
                        <div className={styles.chipList}>
                            <div
                                className={[
                                    styles.baseChip,
                                    descriptionField.value === 'Подвижность' && styles.chipActive,
                                ].join(' ')}
                                onClick={() => descriptionField.onChange('Подвижность')}
                            >
                                Подвижность
                            </div>
                            <div
                                className={[
                                    styles.baseChip,
                                    descriptionField.value === 'Поверхность' && styles.chipActive,
                                ].join(' ')}
                                onClick={() => descriptionField.onChange('Поверхность')}
                            >
                                Поверхность
                            </div>
                            <div
                                className={[
                                    styles.baseChip,
                                    descriptionField.value === 'Заполнитель' && styles.chipActive,
                                ].join(' ')}
                                onClick={() => descriptionField.onChange('Заполнитель')}
                            >
                                Заполнитель
                            </div>
                            <div
                                className={[
                                    styles.baseChip,
                                    descriptionField.value === 'Смывание смеси' &&
                                        styles.chipActive,
                                ].join(' ')}
                                onClick={() => descriptionField.onChange('Смывание смеси')}
                            >
                                Смывание смеси
                            </div>
                            <div
                                className={[
                                    styles.baseChip,
                                    descriptionField.value === 'Документы' && styles.chipActive,
                                ].join(' ')}
                                onClick={() => descriptionField.onChange('Документы')}
                            >
                                Документы
                            </div>
                            <div
                                className={[
                                    styles.baseChip,
                                    descriptionField.value === 'Мусор в бетоне' &&
                                        styles.chipActive,
                                ].join(' ')}
                                onClick={() => descriptionField.onChange('Мусор в бетоне')}
                            >
                                Мусор в бетоне
                            </div>
                            <div
                                className={[
                                    styles.baseChip,
                                    descriptionField.value === 'Другое' && styles.chipActive,
                                ].join(' ')}
                                onClick={() => descriptionField.onChange('Другое')}
                            >
                                Другое
                            </div>
                        </div>
                    </div>
                )}
                <div className={styles.routes}>
                    <span className={styles.heading}>Рейсы</span>
                    <div className={styles.routeList}>
                        {routeField.value?.map((route, index) => (
                            <div className={styles.routeChip} key={index}>
                                <span className={styles.number}>{route.number}</span>
                                <span className={styles.title}>{route.time}</span>
                            </div>
                        ))}
                        <div
                            className={styles.routeChip}
                            onClick={() => setIsOpenRouteSelector(true)}
                        >
                            <span className={styles.plus}>+</span>
                            <span className={styles.title}>Добавить</span>
                        </div>
                    </div>
                </div>
                <div className={styles.chips}>
                    <span className={styles.heading}>Опиcание вашу проблему</span>
                    <div className={styles.textareaContainer}>
                        <ResizableTextarea
                            value={notesField.value}
                            onChange={notesField.onChange}
                            className={styles.textarea}
                            placeholder={'Описание проблемы'}
                        />
                    </div>
                </div>
                <div className={styles.files}>
                    <label htmlFor="file" className={styles.fileHeader}>
                        <div className={styles.headingWithIcon}>
                            <div className={styles.icon}>
                                <PaperClip />
                            </div>
                            <span className={styles.heading}>Прикрепить фото</span>
                        </div>
                        <div className={styles.input}>
                            <span>Выбрать</span>
                            <input
                                type="file"
                                id="file"
                                name="file"
                                accept="image/png, image/jpeg"
                                className={styles.fileInput}
                                onChange={(e) =>
                                    e.target.files?.[0] &&
                                    filesField.onChange([
                                        ...(filesField.value || []),
                                        e.target.files?.[0],
                                    ])
                                }
                            />
                        </div>
                    </label>
                    <div className={styles.fileList}>
                        {filesField.value?.map((file, index) => (
                            <div className={styles.fileChip} key={index}>
                                <div className={styles.titleWithIcon}>
                                    <div className={styles.icon}>
                                        <Img />
                                    </div>
                                    <span className={styles.fileName}>{file.name}</span>
                                </div>
                                <div
                                    className={styles.icon}
                                    onClick={() =>
                                        filesField.onChange(
                                            filesField.value?.filter((_, i) => i !== index),
                                        )
                                    }
                                >
                                    <Trash theme={theme} />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <button
                    className={[
                        styles.submitButton,
                        form.formState.isValid && styles.submitButtonActive,
                    ].join(' ')}
                >
                    Отправить претензию
                </button>
            </div>
            {isOpenRouteSelector && (
                <Modal isOpen={isOpenRouteSelector} setIsOpen={setIsOpenRouteSelector}>
                    <div className={styles.modal}>
                        <div className={styles.heading}>Выберите рейсы</div>
                        <div>
                            {routes.map((route, index) => (
                                <div
                                    className={styles.route}
                                    key={index}
                                    style={index !== 0 ? { borderTop: '1px solid #CDCED2' } : {}}
                                    onClick={() => {
                                        routeField.onChange(
                                            !!routeField.value?.find((r) => r.id === route.id)
                                                ? routeField.value?.filter((r) => r.id !== route.id)
                                                : [...(routeField.value || []), route],
                                        );
                                    }}
                                >
                                    <span className={styles.number}>{route.number}</span>
                                    <div className={styles.checkboxWithTime}>
                                        <span className={styles.number}>{route.time}</span>
                                        <RadioButtonRound
                                            checked={
                                                !!routeField.value?.find((r) => r.id === route.id)
                                            }
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className={styles.footer}>
                            <button
                                className={styles.whiteButton}
                                onClick={() => routeField.onChange([])}
                            >
                                Сбросить
                            </button>
                            <button
                                className={styles.blackButton}
                                onClick={() => setIsOpenRouteSelector(false)}
                            >
                                Готово
                            </button>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
}
