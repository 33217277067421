import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { getCookie } from '../Entities/Lib/CookieWork';
import { obmenHSInstance } from '../Entities/Lib/axios';

export const useTryReLogin = (setIsError: React.Dispatch<React.SetStateAction<boolean>>) => {
    const navigate = useNavigate();

    const [errorText, setErrorText] = useState('');

    const token = getCookie('token');

    useEffect(() => {
        if (token) {
            tryToLogin();
        }

        async function tryToLogin() {
            const response = await obmenHSInstance.get(`PNA/${token}}`);
            const data = await response.data;

            if (response.status === 200 && data === true) {
                navigate('requests');
            } else if (token === null) {
                return;
            } else {
                setIsError(true);
                setErrorText('Необходима повторная авторизация');
            }
        }
    }, []);

    return [errorText, setErrorText];
};
