import React from 'react';
import { useEffect } from 'react';
import Plus from '../../../../../../content/svg/RequestsPage/Plus';
import Minus from '../../../../../../content/svg/RequestsPage/Minus';
import { max } from 'date-fns';

interface InputWithButtonsProps {
    inputTitle: string;
    inputValue: number;
    setInputValue: (value: number) => void;
    nomenclatureModility?: string;
    valueToChange: number;
    maxValue?: number;
    minValue?: number;
}

function InputWithButtons({
    inputTitle,
    inputValue,
    setInputValue,
    nomenclatureModility,
    valueToChange,
    maxValue,
    minValue,
}: InputWithButtonsProps) {
    const theme = localStorage.getItem('theme');

    const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(+e.target?.value);
    };

    function validationInputValue(value: number) {
        if (minValue !== undefined && +value < minValue) {
            value = minValue;
        }
        if (maxValue !== undefined && +value > maxValue) {
            value = maxValue;
        }
        return value;
    }

    function controleValue(step: number, currentValue: number, action: '+' | '-') {
        if (isNaN(currentValue)) {
            currentValue = 0;
        }
        if (action === '+') {
            currentValue += step;
        } else {
            currentValue -= step;
        }
        const value = validationInputValue(currentValue);

        setInputValue(value);
    }

    useEffect(() => {
        if (nomenclatureModility) {
            switch (nomenclatureModility) {
                case '1':
                    setInputValue(3);
                    break;
                case '2':
                    setInputValue(7);
                    break;
                case '3':
                    setInputValue(12);
                    break;
                case '4':
                    setInputValue(18);
                    break;
                case '5':
                    setInputValue(22);
                    break;
                case '6':
                    setInputValue(30);
                    break;
                default:
                    return;
            }
        }
    }, []);

    return (
        <div className="inputRequestMenu">
            <Minus theme={theme} onClick={() => controleValue(valueToChange, inputValue, '-')} />

            <div className="inputAndTitle">
                <span className="labelForInput">
                    {inputValue < 0 ? null : (
                        <span className="labelForInput">
                            {inputTitle}
                            {inputTitle === 'Цементное молоко, м' || inputTitle === 'Объём, м' ? (
                                <sup className="supLowTxt">3</sup>
                            ) : null}
                        </span>
                    )}
                </span>
                <input
                    placeholder={
                        inputValue === 0
                            ? inputTitle === 'Цементное молоко, м'
                                ? 'Цементное молоко, м³'
                                : inputTitle
                            : undefined
                    }
                    maxLength={4}
                    value={
                        inputValue === null || inputValue < 0 || isNaN(inputValue) ? '' : inputValue
                    }
                    onBlur={(event) =>
                        setInputValue(validationInputValue(parseInt(event.target.value, 10)))
                    }
                    onChange={inputChangeHandler}
                    type="tel"
                    className="inputedInformation"
                />
            </div>
            <Plus theme={theme} onClick={() => controleValue(valueToChange, inputValue, '+')} />
        </div>
    );
}

export default InputWithButtons;
