import React, { useEffect, useState } from 'react';

import styles from './CarSelector.module.css';

import { useDispatch, useSelector } from 'react-redux';
import { setCars } from '../../../../../../store/slices/sliceSelectedInfo';

import InputWithButtons from '../InputWithButtons/InputWithButtons';
import { RootState } from '../../../../../../store/store';

function CarSelector() {
    const cars = useSelector((state: RootState) => state.selectedInfo.selectedCars);

    const [carsValue, setCarsValue] = useState(
        useSelector((state: RootState) => state.selectedInfo.selectedCars),
    );
    const [isAutoComplete, setisAutoComplete] = useState(cars === 0 ? true : false);

    const dispatch = useDispatch();

    useEffect(() => {
        setisAutoComplete(cars === 0 ? true : false);
    }, [cars]);

    useEffect(() => {
        dispatch(setCars(carsValue));
        setCarsValue(carsValue);
    }, [carsValue]);

    return (
        <div className={styles.CarSelectorPlaceholder}>
            <div className={styles.togglePlaceholder}>
                <span className={styles.toggleName}>Авто-расчет количества машин</span>
                <input
                    className={styles.switcher}
                    onChange={() => {
                        setisAutoComplete(!isAutoComplete);

                        if (isAutoComplete === false) {
                            setCarsValue(0);
                        }
                    }}
                    checked={isAutoComplete}
                    type="checkbox"
                    id="switch"
                />
                <label className={styles.labelForSwitcher} htmlFor="switch">
                    Toggle
                </label>
            </div>
            {isAutoComplete || (
                <InputWithButtons
                    inputTitle={'Количество машин'}
                    inputValue={cars}
                    setInputValue={setCarsValue}
                    valueToChange={1}
                    minValue={1}
                    maxValue={100}
                />
            )}
        </div>
    );
}

export default CarSelector;
