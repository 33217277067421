import React from 'react';

function Pig() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="39"
            height="41"
            fill="none"
            viewBox="0 0 39 41"
        >
            <rect
                width="3"
                height="6"
                x="7"
                y="11.983"
                fill="#F87373"
                rx="1.5"
                transform="rotate(-41.37 7 11.983)"
            ></rect>
            <rect width="5" height="9" x="8" y="27" fill="#F87373" rx="2.5"></rect>
            <rect width="5" height="9" x="18" y="32" fill="#F87373" rx="2.5"></rect>
            <rect width="5" height="9" x="30" y="29" fill="#F87373" rx="2.5"></rect>
            <path
                fill="#F7A1A1"
                d="M37.463 26.177c0 7.056-6.025 13.738-15.228 12.513-9.203-1.225-15.89-8.342-15.89-15.757 0-7.581 6.494-13.898 15.611-12.842 9.118 1.057 15.507 9.03 15.507 16.086z"
            ></path>
            <path
                fill="#97453A"
                d="M26.018 16.802c.555-.39.692-1.163.247-1.675a12.29 12.29 0 00-6.73-3.969c-.664-.14-1.273.353-1.347 1.027-.074.675.416 1.274 1.076 1.432a9.832 9.832 0 014.98 2.937c.458.501 1.22.64 1.774.248z"
            ></path>
            <path
                fill="#C2A600"
                d="M27.974 6.616c.523 1.543.76 5.144-.24 6.644-.507.658-1.776 1.254-2.5 1.5-2.51.85-5.157-1.56-6.345-5.063-.957-2.823-.444-5.482 1.344-6.937.43-.35 1.684-1 2.5-1 2.5 0 4.053 1.354 5.24 4.856z"
            ></path>
            <ellipse
                cx="22.591"
                cy="8.72"
                fill="gold"
                rx="3.82"
                ry="6.429"
                transform="rotate(-18.735 22.591 8.72)"
            ></ellipse>
            <ellipse
                cx="22.425"
                cy="8.651"
                fill="#C2A600"
                rx="2.529"
                ry="4.623"
                transform="rotate(-18.735 22.425 8.651)"
            ></ellipse>
            <ellipse
                cx="22.412"
                cy="8.691"
                fill="gold"
                rx="1.967"
                ry="3.797"
                transform="rotate(-18.735 22.412 8.69)"
            ></ellipse>
            <circle cx="27.5" cy="25.5" r="1.5" fill="#97453A"></circle>
            <circle cx="34.443" cy="22.195" r="1.319" fill="#97453A"></circle>
            <path
                fill="#FAC7C5"
                d="M20.5 19c-1.5 1.5-.5 2.5 0 3l2.5-2.5c-.5-.5-1-1.5-2.5-.5z"
            ></path>
            <path
                fill="#FAB3B3"
                d="M20.5 22c-.5-.5-1.5-1.5 0-3 1.5-1 2 0 2.5.5l.5-.5s-1-2.5-3.5-.5 0 4 0 4l.5-.5z"
            ></path>
            <path fill="#97453A" d="M31.5 33v-3.5c0-1 1-1 1-.5v3.5c0 1-1 1-1 .5z"></path>
            <ellipse
                cx="33.419"
                cy="30.013"
                fill="#F87373"
                rx="3"
                ry="5.231"
                transform="rotate(34.152 33.42 30.013)"
            ></ellipse>
            <path
                fill="#97453A"
                d="M32 32.39v-3.007c0-.859 1-.859 1-.43v3.007c0 .859-1 .859-1 .43zM34.762 30.39v-3.007c0-.859 1-.859 1-.43v3.007c0 .859-1 .859-1 .43z"
            ></path>
            <path
                fill="#FAC7C5"
                d="M32.712 15.14c-1.87-.401-2.31.797-2.529 1.396l3.118.67c.22-.6.86-1.43-.589-2.065z"
            ></path>
            <path
                fill="#FAB3B3"
                d="M30.183 16.536c.22-.6.658-1.797 2.529-1.395 1.45.634.809 1.466.59 2.065l.623.134s1.703-1.895-.994-2.798c-2.696-.903-3.371 1.86-3.371 1.86l.623.134z"
            ></path>
        </svg>
    );
}

export default Pig;
