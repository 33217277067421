import React from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import LeftArrow from '../../../../content/svg/RequestsPage/LeftArrow';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';

interface ModalSelectProps {
    selectTitle: string;
    selectedInformation?: string;
    route?: string;
    addressUID?: string;
    content?: any; // TODO
    isLoaded?: boolean;
    isEditable?: boolean;
    request?: any;
    methodToGetContent?: string;
    refAddressSelector?: React.RefObject<HTMLDivElement>;
    refNomenclatureSelector?: React.RefObject<HTMLDivElement>;
    refPhoneSelector?: React.RefObject<HTMLDivElement>;
    refPumpSelector?: React.RefObject<HTMLDivElement>;
}

function ModalSelect({
    selectTitle,
    selectedInformation,
    route,
    content,
    isLoaded,
    isEditable,
    request,
    methodToGetContent,
    refAddressSelector,
    refNomenclatureSelector,
    refPhoneSelector,
    refPumpSelector,
    addressUID,
}: ModalSelectProps) {
    const navigate = useNavigate();
    const location = useLocation();
    const contactName = useSelector((state: RootState) => state.selectedInfo.contactName);
    const backRedirect = location?.pathname.replace('/', '');

    const theme = localStorage.getItem('theme');

    const opacity = isEditable ? null : 'Opacity';

    const router = () => {
        if (isEditable) {
            const currentScrollProperty = document.documentElement.scrollTop;
            sessionStorage.removeItem('savedScrollProperty');
            sessionStorage.setItem('savedScrollProperty', JSON.stringify(currentScrollProperty));
            navigate(`/${route}`, {
                state: {
                    data: content,
                    request: request,
                    methodToGetContent: methodToGetContent,
                    isLoaded: isLoaded,
                    addressUID: addressUID,
                    backRedirect: backRedirect,
                },
                replace: false,
            });
        } else {
            return null;
        }
    };

    const setTitleRef = () => {
        if (selectTitle === 'Адрес' || selectTitle === 'Грузополучатель') {
            return refAddressSelector;
        } else if (selectTitle === 'Номенклатура') {
            return refNomenclatureSelector;
        } else if (selectTitle === 'Телефон') {
            return refPhoneSelector;
        } else if (selectTitle === 'Параметры насоса') {
            return refPumpSelector;
        }

        return null;
    };

    if (selectedInformation) {
        return (
            <div
                ref={setTitleRef()}
                onClick={() => router()}
                className={`modalSelect Active ${opacity}`}
            >
                <div className="modalInformationBlock">
                    <span className="title Active">{selectTitle}</span>
                    <span className="selectedInformation">{`${selectedInformation}${
                        contactName ? `/(${contactName})` : ''
                    }`}</span>
                </div>

                {isEditable && <LeftArrow theme={theme} />}
            </div>
        );
    } else {
        return (
            <div ref={setTitleRef()} onClick={() => router()} className="modalSelect">
                <span className={isEditable ? 'title' : 'title Active'}>{selectTitle}</span>

                {isEditable && <LeftArrow theme={theme} />}
            </div>
        );
    }
}

export default ModalSelect;
