import React from 'react';

export default function PaperClip() {
    const color = localStorage?.getItem('theme') === 'dark' ? '#FFFFFF' : 'black';
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
        >
            <path
                d="M9.74229 1.17186L2.31779 8.59686C1.47402 9.44075 1 10.5852 1 11.7786C1 12.972 1.47402 14.1165 2.31779 14.9604C2.73565 15.3782 3.23173 15.7097 3.7777 15.9359C4.32367 16.162 4.90884 16.2784 5.49979 16.2784C6.09074 16.2784 6.67591 16.162 7.22188 15.9359C7.76785 15.7097 8.26393 15.3782 8.68179 14.9604L17.5208 6.12186C17.7994 5.84328 18.0204 5.51254 18.1712 5.14853C18.322 4.78452 18.3996 4.39437 18.3996 4.00036C18.3996 3.60635 18.322 3.2162 18.1712 2.85219C18.0204 2.48818 17.7994 2.15744 17.5208 1.87886C17.2422 1.60023 16.9115 1.37921 16.5475 1.22841C16.1834 1.07761 15.7933 1 15.3993 1C15.0053 1 14.6151 1.07761 14.2511 1.22841C13.8871 1.37921 13.5564 1.60023 13.2778 1.87886L4.43929 10.7179C4.29997 10.8572 4.18946 11.0225 4.11406 11.2045C4.03867 11.3865 3.99986 11.5816 3.99986 11.7786C3.99986 11.9756 4.03867 12.1707 4.11406 12.3527C4.18946 12.5347 4.29997 12.7001 4.43929 12.8394C4.72058 13.1206 5.10204 13.2785 5.49979 13.2785C5.89754 13.2785 6.279 13.1206 6.56029 12.8394L13.9853 5.41436"
                stroke={color}
                strokeWidth="1.56266"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
