import React, { useState } from 'react';

import reactManifest from 'react-manifest';

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useEffect } from 'react';

import toast, { Toaster } from 'react-hot-toast';

import { changeUserTheme, togglePushes } from '../../../../store/slices/sliceUser';

import Avatar from '../../../../content/svg/SettingsUtils/Avatar';
import Phone from '../../../../content/svg/SettingsUtils/Phone';
import Moon from '../../../../content/svg/SettingsUtils/Moon';
import Notify from '../../../../content/svg/SettingsUtils/Notify';
import Language from '../../../../content/svg/SettingsUtils/Language';
import BlueRightArrow from '../../../../content/svg/SettingsUtils/BlueRightArrow';
import About from '../../../../content/svg/SettingsUtils/About';
import PrivacyPolicy from '../../../../content/svg/SettingsUtils/PrivacyPolicy';
import NotifyAlarm from '../../../../content/svg/SettingsUtils/NotifyAlarm';

import styles from './MainSettings.module.css';
import { useSendSettings } from '../../../CustomHooks/useSendSettings';
import Email from '../../../../content/svg/SettingsUtils/Email';
import Mark from '../../../../content/svg/SettingsUtils/Mark';

function MainSettings() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const tel = localStorage?.getItem('phone');
    const name = localStorage?.getItem('name');
    const theme = localStorage?.getItem('theme');
    const email = localStorage?.getItem('email');

    const currentTheme = useSelector((state) => state.user.theme);
    const [isMarkCreation, setIsMarkCreation] = useState(
        localStorage?.getItem('IsMarkCreation') === 'true',
    );

    const [sendSettings] = useSendSettings();

    useEffect(() => {
        const theme = localStorage?.getItem('theme');
        document.documentElement.dataset.theme = theme;
        localStorage.setItem('theme', theme);
        const newTheme = localStorage.getItem('theme');

        const manifestSettings = {
            theme_color: `${newTheme === 'dark' ? '#1c1c1c' : '#fff'}`,
            background_color: `${newTheme === 'dark' ? '#1c1c1c' : '#fff'}`,
        };

        reactManifest.update(manifestSettings, '#manifest-placeholder');
    }, [theme, currentTheme]);

    return (
        <div className={styles.settings}>
            <div className={styles.personal}>
                <div className={styles.nameFieldPlaceholder}>
                    <div onClick={() => navigate('changeName')} className={styles.nameField}>
                        <Avatar theme={theme} />
                        <div className={styles.nameFieldTitle}>
                            <span className={styles.nameFieldTitleTxt}>Имя</span>
                            <div className={styles.nameFieldValue}>
                                <span className={styles.nameFieldValueTxt}>{name}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <hr className={styles.separator} />

                <div
                    onClick={() => navigate('changePhone')}
                    className={styles.nameFieldPlaceholder}
                >
                    <div className={styles.nameField}>
                        <Phone theme={theme} />

                        <div className={styles.nameFieldTitle}>
                            <span className={styles.nameFieldTitleTxt}>Телефон</span>
                            <div className={styles.nameFieldValue}>
                                <span className={styles.nameFieldValueTxt}>{tel}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <hr className={styles.separator} />

                <div className={styles.nameFieldPlaceholder}>
                    <div className={styles.nameField}>
                        <Email theme={theme} />

                        <div className={styles.nameFieldTitle}>
                            <span className={styles.nameFieldTitleTxt}>Почта</span>
                            <div className={styles.nameFieldValue}>
                                <span className={styles.nameFieldValueDisabled}>
                                    {email
                                        ? email !== 'undefined'
                                            ? email
                                            : 'Нету привязанной почты'
                                        : 'Нету привязанной почты'}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.others}>
                <div className={styles.nameFieldPlaceholder}>
                    <div className={styles.nameField}>
                        <Moon theme={theme} />
                        <div className={styles.nameFieldTitle}>
                            <span className={styles.nameFieldTitleTxt}>Темная тема</span>

                            <input
                                className={styles.switcher}
                                onChange={() => {
                                    if (theme === 'dark') {
                                        dispatch(changeUserTheme('light'));
                                        sendSettings();
                                        localStorage.setItem('theme', 'light');
                                    } else {
                                        dispatch(changeUserTheme('dark'));
                                        sendSettings();
                                        localStorage.setItem('theme', 'dark');
                                    }
                                }}
                                checked={theme === 'dark' ? true : false}
                                type="checkbox"
                                id="switch2"
                            />
                            <label className={styles.labelForSwitcher} htmlFor="switch2">
                                Toggle
                            </label>
                        </div>
                    </div>
                </div>

                <hr className={styles.separator} />
                {localStorage.getItem('isOnlyView') === 'false' ? (
                    <>
                        <div className={styles.nameFieldPlaceholder}>
                            <div className={styles.nameField}>
                                <Mark theme={theme} />

                                <div className={styles.nameFieldTitle}>
                                    <span className={styles.nameFieldTitleTxt}>
                                        Несколько бетонных марок
                                    </span>
                                    <input
                                        className={styles.switcher}
                                        onChange={() => {
                                            setIsMarkCreation(!isMarkCreation);
                                            localStorage.setItem(
                                                'IsMarkCreation',
                                                isMarkCreation ? 'false' : 'true',
                                            );
                                            sendSettings();
                                        }}
                                        checked={isMarkCreation}
                                        type="checkbox"
                                        id="switch"
                                    />
                                    <label className={styles.labelForSwitcher} htmlFor="switch">
                                        Toggle
                                    </label>
                                </div>
                            </div>
                        </div>

                        <hr className={styles.separator} />
                    </>
                ) : null}

                <div className={styles.nameFieldPlaceholder}>
                    <div className={styles.nameField}>
                        <NotifyAlarm theme={theme} />

                        <div className={styles.nameFieldTitle}>
                            <span className={styles.nameFieldTitleTxt}>Push-уведомления</span>
                            <span
                                onClick={() => navigate('pushes')}
                                className={styles.nameFieldValueTxt}
                            >
                                Настроить
                            </span>
                        </div>
                    </div>
                </div>

                <hr className={styles.separator} />

                <div onClick={() => navigate('language')} className={styles.nameFieldPlaceholder}>
                    <div className={styles.nameField}>
                        <Language theme={theme} />
                        <div className={styles.nameFieldTitle}>
                            <span className={styles.nameFieldTitleTxt}>Язык</span>
                            <span className={styles.nameFieldValueTxt}>Русский</span>
                            <BlueRightArrow />
                        </div>
                    </div>
                </div>

                <hr className={styles.separator} />

                <div onClick={() => navigate('/onBording')} className={styles.nameFieldPlaceholder}>
                    <div className={styles.nameField}>
                        <About theme={theme} />
                        <div className={styles.nameFieldTitle}>
                            <span className={styles.nameFieldTitleTxt}>О приложении</span>
                        </div>
                    </div>
                </div>

                <hr className={styles.separator} />

                <div
                    onClick={() => navigate('privacyPolicy')}
                    className={styles.nameFieldPlaceholder}
                >
                    <div className={styles.nameField}>
                        <PrivacyPolicy theme={theme} />
                        <div className={styles.nameFieldTitle}>
                            <span className={styles.nameFieldTitleTxt}>
                                Политика конфиденциальности
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainSettings;
