import React from 'react';

import './CreateRequestMenu.css';

import { useDispatch, useSelector } from 'react-redux';
import { useSessionStorage } from 'usehooks-ts';
import { useLocation } from 'react-router-dom/dist';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';

import SubmitButton from '../../../Features/SubmitButton/SubmitButton';
import Header from '../../../Utils/Requests/CreateRequest/Header';
import {
    selectDate,
    selectNomenclatureUID,
    setLaitance,
    setPumpCount,
    setSelectedClient,
    setSelectedClientUID,
} from '../../../../store/slices/sliceSelectedInfo';
import { setIsSearchAvaileble } from '../../../../store/slices/sliceRequests';

import { RootState } from '../../../../store/store';

import { fixPageState } from '../../../../store/slices/sliceUser';

import ContactInfo from './CreatingComponents/ContactInformation';
import Capacity from './CreatingComponents/Capacity';
import Nomenclature from './CreatingComponents/Nomenclature';
import Address from './CreatingComponents/Address';

function CreateRequestMenu() {
    const dispatch = useDispatch();
    const location = useLocation();
    const isFixedPage = useSelector((state: RootState) => state.user.isFixedPage);
    const requestCopy = location?.state?.request;
    const callbackFixPage = location?.state?.paramToFixPage;

    const savedScrollProperty = sessionStorage?.getItem('savedScrollProperty');

    if (callbackFixPage && isFixedPage) {
        dispatch(fixPageState(false));
        fixPage();
    }

    function fixPage() {
        if (savedScrollProperty) {
            const scrollValue = parseInt(savedScrollProperty, 10);

            window.scrollTo({
                top: scrollValue,
                left: 0,
            });
        }
    }

    const refAddressSelector = useRef<HTMLDivElement>(null);
    const refNomenclatureSelector = useRef<HTMLDivElement>(null);
    const refPhoneSelector = useRef<HTMLDivElement>(null);
    const refPumpSelector = useRef<HTMLDivElement>(null);

    let startValueAddress = useSelector((state: RootState) => state.selectedInfo.selectedAddress);
    let startValueAddressUID = useSelector(
        (state: RootState) => state.selectedInfo.selectedAddressUID,
    );
    let startValueNomenclature = useSelector(
        (state: RootState) => state.selectedInfo.selectedNomenclature,
    );
    let startValueNomenclatureUID = useSelector(
        (state: RootState) => state.selectedInfo.selectedNomenclatureUID,
    );
    let startValueContactInformation = useSelector(
        (state: RootState) => state.selectedInfo.selectedContactNumber,
    );

    const startValueDate = useRef(
        useSelector((state: RootState) => state.selectedInfo.selectedDate),
    );

    fixDate();

    let startValueSelectedAccptanceType = '';
    let startValueIntervalValue = '01:00';
    let startValueIntensity = 10;
    let startValueCapacity = 20;
    let startValueConeDraft = 18;

    isRequestCopy();

    const address = startValueAddress;
    const addressUID = startValueAddressUID;
    const nomenclature = startValueNomenclature;
    const nomenclatureUID = startValueNomenclatureUID;
    const contactInformation = startValueContactInformation;

    const [selectedAccptanceType, setSelectedAccptanceType] = useSessionStorage(
        'acceptanceType',
        startValueSelectedAccptanceType,
    );
    const [factoryType, setFactoryType] = useSessionStorage('factoryType', 'Магистральная');
    const [deliveryType, setDeliveryType] = useSessionStorage('deliveryType', 'Доставка');
    const [receivingMethod, setRecivingMethod] = useSessionStorage('receivingMethod', 'Интервал');
    const [crane, setCrane] = useSessionStorage('crane', 1);
    const [intervalValue, setIntervalValue] = useSessionStorage(
        'interval',
        startValueIntervalValue,
    );
    const [intensity, setIntensity] = useSessionStorage('intensity', startValueIntensity);
    const [capacity, setCapacity] = useSessionStorage('capacity', startValueCapacity);
    const [coneDraft, setConeDraft] = useSessionStorage('coneDraft', startValueConeDraft);

    const [parsedDate, setParsedDate] = useState(startValueDate.current);

    const selectedNomenclature = useSelector(
        (state: RootState) => state.selectedInfo.selectedNomenclature,
    );

    const laitance = useSelector((state: RootState) => state.selectedInfo.laitance);
    const setStoreLaitance = (value: number) => {
        dispatch(setLaitance(value));
    };
    const pumpCount = useSelector((state: RootState) => state.selectedInfo.pumpCount);
    const setStorePumpCount = (value: number) => {
        dispatch(setPumpCount(value));
    };

    useEffect(() => {
        if (parsedDate) {
            dispatch(selectDate(parsedDate));
        }
    }, [parsedDate]);

    useEffect(() => {
        dispatch(setIsSearchAvaileble(false));
    }, []);

    useEffect(() => {
        if (
            selectedNomenclature === 'Цементное молоко' ||
            selectedNomenclature === 'Цементное молоко густое'
        ) {
            setCapacity(1);
        }
    }, [selectedNomenclature]);

    function isRequestCopy() {
        if (requestCopy) {
            startValueAddress = requestCopy.address;
            startValueAddressUID = requestCopy.address_uid;
            startValueNomenclature = requestCopy.nomenclature;
            startValueNomenclatureUID = requestCopy.nomenclature_uid;
            startValueContactInformation = requestCopy.phone_number;
            startValueIntervalValue = requestCopy.interval;
            startValueSelectedAccptanceType = requestCopy.acceptance_method;
            startValueIntensity = requestCopy.intensity;
            startValueCapacity = requestCopy.capacity;
            startValueConeDraft = requestCopy.cone_draft;
            dispatch(setSelectedClient(requestCopy.counterpart));
            dispatch(selectNomenclatureUID(requestCopy.nomenclature_uid));
            dispatch(setSelectedClientUID(requestCopy.counterpartUid));
            startValueDate.current = new Date();
        }
    }

    function fixDate() {
        if (startValueDate.current) {
            if (startValueDate.current.getTime() <= new Date().getTime()) {
                startValueDate.current = new Date();
            }
        }
    }

    return (
        <div className="requestMenu">
            <Header headerTitle={'Новая заявка'} backRedirect={'requests'} />
            <Address
                route="create"
                factoryType={factoryType}
                requestCopy={requestCopy}
                setFactoryType={setFactoryType}
                deliveryType={deliveryType}
                setDeliveryType={setDeliveryType}
                date={parsedDate}
                setDate={setParsedDate}
                selectedAddress={address}
                refAddressSelector={refAddressSelector}
            />
            <Nomenclature
                requestCopy={requestCopy}
                capacity={capacity}
                setCapacity={setCapacity}
                coneDraft={coneDraft}
                setConeDraft={setConeDraft}
                addressUID={addressUID}
                selectedNomenclature={nomenclature}
                refNomenclatureSelector={refNomenclatureSelector}
            />
            {selectedNomenclature !== 'Цементное молоко' &&
                selectedNomenclature !== 'Цементное молоко густое' && (
                    <Capacity
                        capacity={capacity}
                        requestType={'Создание'}
                        intervalValue={intervalValue}
                        receivingMethod={receivingMethod}
                        setRecivingMethod={setRecivingMethod}
                        intensity={intensity}
                        setIntervalValue={setIntervalValue}
                        setIntensity={setIntensity}
                        acceptanceType={selectedAccptanceType}
                        setSelectedAccptanceType={setSelectedAccptanceType}
                        pumpCount={pumpCount}
                        crane={crane}
                        setCrane={setCrane}
                        setPumpCount={setStorePumpCount}
                        laitance={laitance}
                        setLaitance={setStoreLaitance}
                        refPumpSelector={refPumpSelector}
                    />
                )}
            <ContactInfo
                addressUID={addressUID}
                requestCopy={requestCopy}
                selectedTelephone={contactInformation}
                refPhoneSelector={refPhoneSelector}
            />
            <br />
            <br />
            <br />

            <SubmitButton
                methodToExecute={'neworder'}
                action={'create'}
                requestType={'Новая'}
                initialTextValue={'Отправить заявку'}
                successType={'/success'}
                addressUID={addressUID}
                crane={crane}
                isRequestCopy={!!requestCopy}
                nomenclatureUID={nomenclatureUID}
                contactInformation={contactInformation}
                selectedAcceptanceType={selectedAccptanceType}
                intervalValue={intervalValue}
                intensity={intensity}
                capacity={capacity}
                receivingMethod={receivingMethod}
                coneDraft={coneDraft}
                date={parsedDate}
                deliveryType={deliveryType}
                factoryType={factoryType}
                pumpCount={pumpCount}
                laitance={laitance}
                refAddressSelector={refAddressSelector}
                refNomenclatureSelector={refNomenclatureSelector}
                refPhoneSelector={refPhoneSelector}
                refPumpSelector={refPumpSelector}
            />
        </div>
    );
}

export default CreateRequestMenu;
