import React, { useLayoutEffect } from 'react';

import './CreateRequestMarks.css';

import { useDispatch, useSelector } from 'react-redux';
import { useSessionStorage } from 'usehooks-ts';
import { useLocation, useNavigate } from 'react-router-dom/dist';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';

import SubmitButton from '../../../Features/SubmitButton/SubmitButton';
import Header from '../../../Utils/Requests/CreateRequest/Header';
import {
    selectAddress,
    selectAddressUID,
    selectCommentary,
    selectContactInfo,
    selectContactName,
    selectDate,
    selectNomenclature,
    selectNomenclatureUID,
    selectReceiver,
    selectReceiverUID,
    setCars,
    setEntarance,
    setFiller,
    setIsGraniteavailable,
    setIzsLaitanceRequired,
    setLaitance,
    setOwnPumps,
    setPMD,
    setPumpCount,
    setPumps,
    setSelectedClient,
    setSelectedClientUID,
} from '../../../../store/slices/sliceSelectedInfo';
import { setIsSearchAvaileble, setSearchInput } from '../../../../store/slices/sliceRequests';

import { RootState } from '../../../../store/store';

import { fixPageState } from '../../../../store/slices/sliceUser';

import ContactInfo from '../CreateRequest/CreatingComponents/ContactInformation';
import Address from '../CreateRequest/CreatingComponents/Address';
import BoldPlus from '../../../../content/svg/RequestsPage/BoldPlus';
import MarkList from '../MarksList/MarksList';
import toast from 'react-hot-toast';

function CreateRequestMenuMarks() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const isFixedPage = useSelector((state: RootState) => state.user.isFixedPage);
    const callbackFixPage = location?.state?.paramToFixPage;

    const savedScrollProperty = sessionStorage?.getItem('savedScrollProperty');

    if (callbackFixPage && isFixedPage) {
        dispatch(fixPageState(false));
        fixPage();
    }

    function fixPage() {
        if (savedScrollProperty) {
            const scrollValue = parseInt(savedScrollProperty, 10);

            window.scrollTo({
                top: scrollValue,
                left: 0,
            });
        }
    }

    const refAddressSelector = useRef<HTMLDivElement>(null);
    const refNomenclatureSelector = useRef<HTMLDivElement>(null);
    const refPhoneSelector = useRef<HTMLDivElement>(null);
    const refPumpSelector = useRef<HTMLDivElement>(null);

    let startValueAddress = useSelector((state: RootState) => state.selectedInfo.selectedAddress);
    let startValueAddressUID = useSelector(
        (state: RootState) => state.selectedInfo.selectedAddressUID,
    );
    let startValueContactInformation = useSelector(
        (state: RootState) => state.selectedInfo.selectedContactNumber,
    );

    const startValueDate = useRef(
        useSelector((state: RootState) => state.selectedInfo.selectedDate),
    );

    fixDate();

    const address = startValueAddress;
    const addressUID = startValueAddressUID;
    const contactInformation = startValueContactInformation;

    const [parsedDate, setParsedDate] = useState(startValueDate.current);
    const [factoryType, setFactoryType] = useSessionStorage('factoryType', 'Магистральная');
    const [deliveryType, setDeliveryType] = useSessionStorage('deliveryType', 'Доставка');
    const laitance = useSelector((state: RootState) => state.selectedInfo.laitance);
    const pumpCount = useSelector((state: RootState) => state.selectedInfo.pumpCount);
    const dataMarks = useSelector((state: RootState) => state.marks.dataMarks);

    useEffect(() => {
        if (parsedDate) {
            dispatch(selectDate(parsedDate));
        }
    }, [parsedDate]);

    useLayoutEffect(() => {
        dispatch(setIsSearchAvaileble(false));
        dispatch(selectNomenclature(null));
        dispatch(selectNomenclatureUID(null));
        // dispatch(selectContactName(null));
        dispatch(setEntarance(null));
        dispatch(setPMD('Без ПМД'));
        dispatch(setSearchInput(''));
        dispatch(setCars(0));
        dispatch(setFiller(null));
        dispatch(setIsGraniteavailable(false));

        dispatch(setPumpCount(0));
        dispatch(setOwnPumps(0));
        dispatch(setLaitance(0));
        dispatch(setIzsLaitanceRequired(false));
        dispatch(setPumps([]));
        // sessionStorage.clear();
        sessionStorage.removeItem('interval');
        sessionStorage.removeItem('intensity');
        sessionStorage.removeItem('acceptanceType');
    }, []);

    function fixDate() {
        if (startValueDate.current) {
            if (startValueDate.current.getTime() <= new Date().getTime()) {
                startValueDate.current = new Date();
            }
        }
    }

    const receiver = useSelector((state: RootState) => state.selectedInfo.selectedReceiver);

    return (
        <div className="requestMenu">
            <Header headerTitle={'Новая заявка'} backRedirect={'requests'} />
            <Address
                route={'/createRequestMarks'}
                factoryType={factoryType}
                setFactoryType={setFactoryType}
                deliveryType={deliveryType}
                setDeliveryType={setDeliveryType}
                date={parsedDate}
                setDate={setParsedDate}
                selectedAddress={address}
                refAddressSelector={refAddressSelector}
                isDateAndTimeHidden={true}
            />

            <MarkList />

            <div className="buttonAddNomenclaturePlaceholder">
                <button
                    className="addNasos"
                    style={{ opacity: address || receiver ? 1 : 0.5 }}
                    onClick={() => {
                        address || receiver
                            ? navigate('newMark')
                            : toast.error('Сначала выберите адрес или\n грузополучателя');
                    }}
                >
                    <BoldPlus />
                    Добавить марку
                </button>
            </div>
            <ContactInfo
                addressUID={addressUID}
                requestCopy={false}
                selectedTelephone={contactInformation}
                refPhoneSelector={refPhoneSelector}
            />
            <br />
            <br />
            <br />

            <SubmitButton
                crane={dataMarks[0]?.crane}
                methodToExecute={'neworder'}
                action={'create'}
                requestType={'govno'}
                initialTextValue={'Отправить заявки'}
                successType={'/success'}
                addressUID={addressUID}
                isRequestCopy={false}
                contactInformation={contactInformation}
                selectedAcceptanceType={
                    dataMarks[0]?.nomenclatureUID === 'Цементное молоко' ||
                    dataMarks[0]?.nomenclatureUID === 'Цементное молоко густое'
                        ? 'Насос'
                        : dataMarks[0]?.selectedAcceptanceType
                }
                nomenclatureUID={dataMarks[0]?.nomenclatureUID}
                intervalValue={dataMarks[0]?.intervalValue}
                intensity={dataMarks[0]?.intensity}
                capacity={dataMarks[0]?.capacity}
                receivingMethod={dataMarks[0]?.receivingMethod}
                coneDraft={dataMarks[0]?.coneDraft}
                date={parsedDate}
                deliveryType={deliveryType}
                factoryType={factoryType}
                pumpCount={pumpCount}
                laitance={laitance}
                refAddressSelector={refAddressSelector}
                refNomenclatureSelector={refNomenclatureSelector}
                refPhoneSelector={refPhoneSelector}
                refPumpSelector={refPumpSelector}
            />
        </div>
    );
}

export default CreateRequestMenuMarks;
