import React from 'react';

function Clear({ theme, style, onClick }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            style={style}
            onClick={onClick}
        >
            <path
                d="M12 0C5.37262 0 0 5.37262 0 12C0 18.6278 5.37262 24 12 24C18.6278 24 24 18.6278 24 12C24 5.37262 18.6278 0 12 0ZM12 22.5236C6.21037 22.5236 1.5 17.7896 1.5 12C1.5 6.21033 6.21037 1.49995 12 1.49995C17.7896 1.49995 22.5 6.21035 22.5 12C22.5 17.7896 17.7896 22.5236 12 22.5236ZM16.2424 7.75763C15.9495 7.46475 15.4748 7.46475 15.1819 7.75763L12 10.9395L8.81812 7.75763C8.52525 7.46475 8.0505 7.46475 7.75725 7.75763C7.46438 8.0505 7.46438 8.52525 7.75725 8.81812L10.9391 12L7.75725 15.1819C7.46438 15.4744 7.46438 15.9499 7.75725 16.2424C8.05013 16.5353 8.52487 16.5353 8.81812 16.2424L12 13.0605L15.1819 16.2424C15.4748 16.5353 15.9495 16.5353 16.2424 16.2424C16.5352 15.9499 16.5352 15.4744 16.2424 15.1819L13.0605 12L16.2424 8.81812C16.5356 8.52487 16.5356 8.05013 16.2424 7.75763Z"
                fill={theme === 'dark' ? '#78B9FF' : '#007AFF'}
            />
        </svg>
    );
}

export default Clear;
