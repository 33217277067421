import React from 'react';

function Minus({ theme, onClick }) {
    return (
        <svg
            onClick={onClick}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 11.25C12.4142 11.25 12.5 11.25 12.75 11.25H18C18.4142 11.25 18.75 11.5858 18.75 12C18.75 12.4142 18.4142 12.75 18 12.75H12.75C12.4187 12.75 12.4142 12.75 12 12.75C11.5858 12.75 11.5 12.75 11.25 12.75H6C5.58579 12.75 5.25 12.4142 5.25 12C5.25 11.5858 5.58579 11.25 6 11.25H11.25C11.5 11.25 11.5858 11.25 12 11.25Z"
                fill={theme === 'dark' ? '#fff' : '#3C3C43'}
            />
        </svg>
    );
}

export default Minus;
