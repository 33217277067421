import React from 'react';

function Erkoin() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="32"
            fill="none"
            viewBox="0 0 31 32"
        >
            <circle cx="15.5" cy="16" r="15.5" fill="#D0D0D2"></circle>
            <circle cx="15.5" cy="15.5" r="12.5" fill="#E3E3E3"></circle>
            <circle cx="15.5" cy="15.5" r="11.733" stroke="#A5A5A5"></circle>
            <path
                fill="#8A8A8E"
                d="M14.978 16.675V14.66h3.51v-1.78h-5.441v1.646l-2.015-.32v-.822c0-.806.156-1.349.47-1.629.291-.28.85-.42 1.68-.42h5.171c.828 0 1.388.14 1.68.42.313.28.47.823.47 1.63v4.566c0 .806-.157 1.35-.47 1.63-.292.28-.852.419-1.68.419h-5.172c-.828 0-1.388-.14-1.679-.42-.314-.28-.47-.823-.47-1.629v-1.024l2.015-.42v1.864h5.44v-1.696h-3.51z"
            ></path>
        </svg>
    );
}

export default Erkoin;
