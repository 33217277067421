import { createSlice } from '@reduxjs/toolkit';

export const marksSlice = createSlice({
    name: 'marks',
    initialState: {
        date: new Date(),
        deliveryType: 'Доставка',
        factoryType: 'Магистральная',
        dataMarks: [] as any[],
    },
    reducers: {
        setDateMarks: (state, action) => {
            state.date = action.payload;
        },
        setDeliveryType: (state, action) => {
            state.deliveryType = action.payload;
        },
        setFactoryType: (state, action) => {
            state.factoryType = action.payload;
        },
        addMark: (state, action) => {
            return {
                ...state,
                dataMarks: state.dataMarks.concat(action.payload),
            };
        },
        clearMark: (state, action) => {
            const index = action.payload;

            if (index < 0) return;

            state.dataMarks.splice(index, 1);
        },
        clearAllMarks: (state) => {
            state.dataMarks = [];
        },
    },
});

export const { setDateMarks, setDeliveryType, setFactoryType, addMark, clearMark, clearAllMarks } =
    marksSlice.actions;

export default marksSlice.reducer;
