import React from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import { parseISO } from 'date-fns';

import { useSelector } from 'react-redux';

import ModalSelect from '../../../../../../Utils/Requests/CreateRequest/ModalSelect';
import EntaranceSelector from '../../../../../../Utils/Requests/CreateRequest/Input/EntaranceSelector/EntaranceSelector';
import DateAndTimeInput from '../../../../../../Utils/Requests/CreateRequest/Input/DateAndTimeInput/DateAndTimeInput';

import { RootState } from '../../../../../../../store/store';
import FactoryTypeSelect from '../../../../../../Utils/Requests/CreateRequest/Selectors/FactoryTypeSelect';

interface AddressProps {
    address: string;
    lower_date: Date;
    lower_time: Date;
    upper_date: Date;
    upper_time: Date;
    isPlanned: boolean;
    parsedDate: Date;
    setParsedDate: React.Dispatch<Date>;
    request?: any; // todo
    refAddressSelector: any; //todo,
    receiver: any;
    deliveryType: string;
    factoryType: string;
    setFactoryType: any;
}

function Address({
    address,
    lower_date,
    lower_time,
    upper_date,
    upper_time,
    isPlanned,
    parsedDate,
    setParsedDate,
    request,
    refAddressSelector,
    receiver,
    deliveryType,
    factoryType,
    setFactoryType,
}: AddressProps) {
    const availebleEntarances = useSelector(
        (state: RootState) => state.requests.availebleEntarances,
    );

    const client = useSelector((state: RootState) => state.selectedInfo.selectedClient);

    return (
        <div className="addressAndTime">
            <span className="titleRequestMenu">Куда</span>
            <div className="addressAndTimeContent">
                {localStorage?.getItem('severalCounterparts') === 'true' && (
                    <ModalSelect
                        isEditable={false}
                        selectTitle={'Клиент'}
                        selectedInformation={client}
                    />
                )}
                {deliveryType === 'Самовывоз' ? (
                    <>
                        <ModalSelect
                            isEditable={false}
                            refAddressSelector={refAddressSelector}
                            selectTitle={'Грузополучатель'}
                            route={'addReceiver'}
                            selectedInformation={receiver}
                        />

                        <FactoryTypeSelect
                            factoryType={factoryType}
                            setFactoryType={setFactoryType}
                        />
                    </>
                ) : (
                    <ModalSelect
                        selectTitle={'Адрес'}
                        refAddressSelector={refAddressSelector}
                        selectedInformation={address}
                    />
                )}

                {/* <ModalSelect
                    refAddressSelector={refAddressSelector}
                    selectTitle={'Адрес'}
                    selectedInformation={address}
                    isEditable={false}
                /> */}
            </div>
            <span className="titleRequestMenu">Когда</span>
            <div className="addressAndTimeContent">
                <DateAndTimeInput
                    isNewRequest={false}
                    date={typeof parsedDate === 'string' ? parseISO(parsedDate) : parsedDate}
                    setDate={setParsedDate}
                    lower_date={lower_date}
                    lower_time={lower_time}
                    upper_date={upper_date}
                    upper_time={upper_time}
                    isPlanned={isPlanned}
                />

                {Object.keys(availebleEntarances).length !== 0 ? (
                    <EntaranceSelector entaranses={availebleEntarances} request={request} />
                ) : null}
            </div>
        </div>
    );
}

export default Address;
