import React from 'react';

export default function UserCard() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="61"
            height="60"
            viewBox="0 0 61 60"
            fill="none"
        >
            <path
                d="M6.375 11.25V48.75H55.125V11.25H6.375ZM10.125 15H51.375V45H44.9883C44.8418 44.7437 44.9077 44.3921 44.6953 44.1797C43.9849 43.4692 42.9814 43.125 42 43.125C41.0186 43.125 40.0151 43.4692 39.3047 44.1797C39.0923 44.3921 39.1582 44.7437 39.0117 45H22.4883C22.3418 44.7437 22.4077 44.3921 22.1953 44.1797C21.4849 43.4692 20.4814 43.125 19.5 43.125C18.5186 43.125 17.5151 43.4692 16.8047 44.1797C16.5923 44.3921 16.6582 44.7437 16.5117 45H10.125V15ZM23.25 18.75C19.1265 18.75 15.75 22.1265 15.75 26.25C15.75 28.3374 16.6436 30.2197 18.0352 31.582C15.5376 33.2739 13.875 36.1597 13.875 39.375H17.625C17.625 36.2695 20.1445 33.75 23.25 33.75C26.3555 33.75 28.875 36.2695 28.875 39.375H32.625C32.625 36.1597 30.9624 33.2739 28.4648 31.582C29.8564 30.2197 30.75 28.3374 30.75 26.25C30.75 22.1265 27.3735 18.75 23.25 18.75ZM23.25 22.5C25.3447 22.5 27 24.1553 27 26.25C27 28.3447 25.3447 30 23.25 30C21.1553 30 19.5 28.3447 19.5 26.25C19.5 24.1553 21.1553 22.5 23.25 22.5ZM36.375 24.375V28.125H47.625V24.375H36.375ZM36.375 31.875V35.625H47.625V31.875H36.375Z"
                fill="var(--redA-rebB)"
            />
        </svg>
    );
}
