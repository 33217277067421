import React from 'react';

import './Loading.scss';

function Loading() {
    return ( 
    <>
    <div className='tetrominos'>
        <div className='tetromino box1'></div>
        <div className='tetromino box2'></div>
        <div className='tetromino box3'></div>
        <div className='tetromino box4'></div>
    </div>
    <h1 className='titleLoading'>Загрузка данных</h1>
    </>
     );
}

export default Loading;