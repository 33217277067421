import React from 'react';

export default function ModalOk({ theme }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M18.4617 5.19392C18.3316 5.10788 18.1861 5.0488 18.0336 5.02006C17.8811 4.99132 17.7245 4.99348 17.5729 5.02641C17.4212 5.05934 17.2773 5.12241 17.1496 5.212C17.0218 5.30159 16.9126 5.41595 16.8282 5.54855L10.2102 15.9432L6.98273 12.9023C6.86886 12.795 6.73537 12.7117 6.58987 12.6569C6.44438 12.6022 6.28973 12.5773 6.13476 12.5834C5.82179 12.5959 5.5265 12.7346 5.31386 12.9689C5.10122 13.2033 4.98865 13.5141 5.00091 13.833C5.01316 14.1519 5.14924 14.4528 5.37922 14.6695L9.61694 18.6619C9.61694 18.6619 9.73859 18.7686 9.79489 18.8059C9.925 18.892 10.0705 18.9511 10.223 18.9799C10.3756 19.0087 10.5322 19.0065 10.6839 18.9736C10.8357 18.9407 10.9795 18.8776 11.1073 18.788C11.2351 18.6983 11.3443 18.5839 11.4287 18.4513L18.8097 6.85836C18.8941 6.72579 18.9521 6.57758 18.9803 6.42218C19.0085 6.26678 19.0064 6.10724 18.9741 5.95267C18.9418 5.7981 18.8799 5.65154 18.792 5.52135C18.704 5.39116 18.5918 5.2799 18.4617 5.19392Z"
                fill={theme === 'dark' ? '#FF8B86' : '#F54941'}
            />
        </svg>
    );
}
