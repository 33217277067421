import React from 'react';

function FileChecked({ theme }) {
    return (
        // <svg
        //     xmlns="http://www.w3.org/2000/svg"
        //     width="16"
        //     height="16"
        //     viewBox="0 0 16 16"
        //     fill="none"
        // >
        //     <path
        //         d="M1.59961 11.2V12.8C1.59961 13.2243 1.76818 13.6313 2.06824 13.9313C2.3683 14.2314 2.77526 14.4 3.19961 14.4H12.7996C13.224 14.4 13.6309 14.2314 13.931 13.9313C14.231 13.6313 14.3996 13.2243 14.3996 12.8V11.2"
        //         stroke={theme === 'dark' ? '#78B9FF' : '#007AFF'}
        //         strokeWidth="1.6"
        //         strokeLinecap="round"
        //         strokeLinejoin="round"
        //     />
        //     <path
        //         d="M12 6.40015L8 10.4001M8 10.4001L4 6.40015M8 10.4001L8 0.800146"
        //         stroke={theme === 'dark' ? '#78B9FF' : '#007AFF'}
        //         strokeWidth="1.6"
        //         strokeLinecap="round"
        //         strokeLinejoin="round"
        //     />
        // </svg>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <g clipPath="url(#clip0_2291_8666)">
                <path
                    d="M10.6667 13.2869L8.94 11.5602L8 12.5002L10.6667 15.1669L16 9.83355L15.06 8.89355L10.6667 13.2869Z"
                    fill={theme === 'dark' ? '#78B9FF' : '#007AFF'}
                />
                <path
                    d="M6.875 14.0835H3.08333V1.08352H7.41667V4.33352C7.41752 4.62057 7.53194 4.89562 7.73491 5.0986C7.93789 5.30158 8.21295 5.41599 8.5 5.41685H11.75V8.66685H12.8333V4.33352C12.8352 4.26233 12.8217 4.19157 12.7937 4.12612C12.7656 4.06066 12.7237 4.00206 12.6708 3.95435L8.87917 0.162684C8.83148 0.109787 8.77288 0.0678657 8.70742 0.0398109C8.64196 0.0117562 8.57119 -0.00176357 8.5 0.000184188H3.08333C2.79628 0.0010415 2.52123 0.115453 2.31825 0.318432C2.11527 0.521411 2.00086 0.796463 2 1.08352V14.0835C2.00086 14.3706 2.11527 14.6456 2.31825 14.8486C2.52123 15.0516 2.79628 15.166 3.08333 15.1668H6.875V14.0835ZM8.5 1.30018L11.5333 4.33352H8.5V1.30018Z"
                    fill={theme === 'dark' ? '#78B9FF' : '#007AFF'}
                    stroke={theme === 'dark' ? '#78B9FF' : '#007AFF'}
                    strokeWidth="0.2"
                />
            </g>
            <defs>
                <clipPath id="clip0_2291_8666">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default FileChecked;
