import React, { useState } from 'react';

import styles from './Accordeon.module.css';
import BigUpArrow from '../../../../../content/svg/RequestsPage/BigUpArrow';
import BigDownArrow from '../../../../../content/svg/RequestsPage/BigDownArrow';

interface AccordeonProps {
    policyHeader: string;
    policyContent: string;
}

function Accordeon({ policyHeader, policyContent }: AccordeonProps) {
    const [isOpened, setisOpened] = useState(false);

    const theme = localStorage.getItem('theme');

    return (
        <div onClick={() => setisOpened(!isOpened)} className={styles.accordeon}>
            {isOpened ? (
                <>
                    <div className={styles.accordeonHeaderPlaceholder}>
                        <span className={styles.policyHeader}>{policyHeader}</span>

                        <BigUpArrow theme={theme} />
                    </div>
                    <span className={styles.accordeon_txt}>{policyContent}</span>
                </>
            ) : (
                <>
                    <div className={styles.accordeonHeaderPlaceholder}>
                        <span className={styles.accordeonHeader}>{policyHeader}</span>

                        <BigDownArrow theme={theme} />
                    </div>
                </>
            )}
        </div>
    );
}

export default Accordeon;
