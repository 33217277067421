import React from 'react';

function BigUpArrow({ theme }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="11"
            viewBox="0 0 19 11"
            fill="none"
        >
            <path
                d="M9.16067 0.079546C9.41704 0.079546 9.67333 0.182096 9.84772 0.376959L17.7842 8.50828C17.9585 8.6826 18.0611 8.90819 18.0611 9.16453C18.0611 9.69774 17.6611 10.1079 17.128 10.1079C16.8716 10.1079 16.6358 10.0054 16.4614 9.84129L8.60695 1.81248H9.70413L1.84962 9.84129C1.68556 10.0054 1.44972 10.1079 1.18312 10.1079C0.649917 10.1079 0.250015 9.69774 0.250015 9.16453C0.250015 8.90819 0.352555 8.6826 0.526871 8.49803L8.4634 0.376959C8.65822 0.182096 8.89406 0.079546 9.16067 0.079546Z"
                fill={theme === 'dark' ? '#fff' : '#1c1c1c'}
                fillOpacity="0.85"
            />
        </svg>
    );
}

export default BigUpArrow;
