import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

import toast from 'react-hot-toast';

import Input from '../../../../../../Utils/Requests/CreateRequest/Input/Input';
import ModalSelect from '../../../../../../Utils/Requests/CreateRequest/ModalSelect';
import InputWithButtons from '../../../../../../Utils/Requests/CreateRequest/Input/InputWithButtons/InputWithButtons';
import PMD from '../../../../../../Utils/Requests/CreateRequest/Input/PMD/PMD';
import { useGetNomenclaturesQuery } from '../../../../../../../servises/api';

interface NomenclatureProps {
    nomenclature: string;
    capacity: number;
    coneDraft: number;
    setCapacity: React.Dispatch<React.SetStateAction<number>>;
    setConeDraft: React.Dispatch<React.SetStateAction<number>>;
    request: any;
    addressUID: string;
    refNomenclatureSelector: React.RefObject<HTMLDivElement>;
}

function Nomenclature({
    nomenclature,
    capacity,
    coneDraft,
    setCapacity,
    setConeDraft,
    request,
    addressUID,
    refNomenclatureSelector,
}: NomenclatureProps) {
    const { data: dataNomenclatures, isSuccess: isSuccessNomenclatures } = useGetNomenclaturesQuery(
        addressUID,
        {
            skip: !addressUID as unknown as boolean,
        },
    );

    useEffect(() => {
        if (capacity <= 0) {
            capacity = 0;
        }

        if (capacity === null) {
            capacity = 0;
        } else if (capacity >= 10000) {
            capacity = 10000;
        }
    }, [capacity]);

    useEffect(() => {
        if (coneDraft <= 10) {
            coneDraft = 10;
        }

        if (coneDraft === null) {
            coneDraft = 0;
        } else if (coneDraft >= 30) {
            coneDraft = 30;
        }
    }, [coneDraft]);

    return (
        <>
            <div className="nomenclatureAndCapacity">
                <span className="titleRequestMenu">Что и сколько</span>

                <div className="nomenclatureAndCapacityContent">
                    <ModalSelect
                        refNomenclatureSelector={refNomenclatureSelector}
                        request={request}
                        isLoaded={isSuccessNomenclatures}
                        selectTitle={'Номенклатура'}
                        route={'updateNomenclature'}
                        selectedInformation={nomenclature}
                        isEditable={false}
                        content={dataNomenclatures}
                    />

                    {nomenclature === 'Цементное молоко' ||
                    nomenclature === 'Цементное молоко густое' ? (
                        <InputWithButtons
                            inputTitle={'Объём, м3'}
                            inputValue={capacity}
                            setInputValue={setCapacity}
                            valueToChange={0.5}
                        />
                    ) : (
                        <>
                            <Input
                                capacity={capacity}
                                setCapacity={setCapacity}
                                coneDraft={coneDraft}
                                setConeDraft={setConeDraft}
                                request={request}
                            />
                        </>
                    )}
                </div>
            </div>
            {/* <div className="nomenclatureAndCapacity">
                <span className="titleRequestMenu">Дополнительно</span>
                <div className="additionalMenuContent">
                    <PMD request={request} />
                </div>
            </div> */}
        </>
    );
}

export default Nomenclature;
