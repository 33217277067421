import { createSlice } from '@reduxjs/toolkit';
import { PumpInfo } from '../../components/Entities/Interfaces/Interfaces';

export const selectedInfo = createSlice({
    name: 'selectedInfo',
    initialState: {
        selectedAddress: '',
        selectedAddressUID: '',
        selectedNomenclature: '',
        selectedNomenclatureUID: '',
        selectedContactNumber: '',
        selectedReceiver: '',
        selectedReceiverUID: '',
        selectedReceiverAddressUID: '',
        selectedDate: new Date(),
        selectedCommentary: '',
        selectedContactName: '',
        nomenclatureMobility: '',
        isGraniteAvailable: [{ filler: '', mobility: '' }],
        selectedCounterpart: '',
        PMD: 'Без ПМД',
        selectedEntareance: '',
        selectedFiller: '',
        selectedCars: 0,
        selectedClient: '',
        selectedClientUID: '',
        pumps: [] as PumpInfo[],
        pumpCount: 0,
        ownPumps: 0,
        laitance: 0,
        isPipeRequired: false,
        isTubeRequired: false,
        isLaitanceRequired: false,
        contactName: null,
    },

    reducers: {
        selectAddress: (state, action) => {
            state.selectedAddress = action.payload;
        },
        selectCounterpart: (state, action) => {
            state.selectedCounterpart = action.payload;
        },
        selectAddressUID: (state, action) => {
            state.selectedAddressUID = action.payload;
        },
        selectNomenclature: (state, action) => {
            state.selectedNomenclature = action.payload;
        },
        selectNomenclatureUID: (state, action) => {
            state.selectedNomenclatureUID = action.payload;
        },
        selectContactInfo: (state, action) => {
            state.selectedContactNumber = action.payload;
        },
        selectContactName: (state, action) => {
            state.selectedContactName = action.payload;
        },
        selectDate: (state, action) => {
            state.selectedDate = action.payload;
        },
        selectCommentary: (state, action) => {
            state.selectedCommentary = action.payload;
        },
        setIsGraniteavailable: (state, action) => {
            state.isGraniteAvailable = action.payload;
        },
        selectReceiver: (state, action) => {
            state.selectedReceiver = action.payload;
        },
        selectReceiverUID: (state, action) => {
            state.selectedReceiverUID = action.payload;
        },
        setNomenclatureMobility: (state, action) => {
            state.nomenclatureMobility = action.payload;
        },
        setPMD: (state, action) => {
            state.PMD = action.payload;
        },
        setEntarance: (state, action) => {
            state.selectedEntareance = action.payload;
        },
        setFiller: (state, action) => {
            state.selectedFiller = action.payload;
        },
        setCars: (state, action) => {
            state.selectedCars = action.payload;
        },
        setSelectedReceiverAddressUID: (state, action) => {
            state.selectedReceiverAddressUID = action.payload;
        },
        setSelectedClient: (state, action) => {
            state.selectedClient = action.payload;
        },
        setSelectedClientUID: (state, action) => {
            state.selectedClientUID = action.payload;
        },
        setPumpCount: (state, action) => {
            state.pumpCount = action.payload;
        },
        setLaitance: (state, action) => {
            state.laitance = action.payload;
        },
        setIsTubeRequired: (state, action) => {
            state.isTubeRequired = action.payload;
        },
        setIzsLaitanceRequired: (state, action) => {
            state.isLaitanceRequired = action.payload;
        },
        setIsPipeRequired: (state, action) => {
            state.isPipeRequired = action.payload;
        },
        setPumps: (state, action) => {
            state.pumps = action.payload;
        },
        setOwnPumps: (state, action) => {
            state.ownPumps = action.payload;
        },
        setContactName: (state, action) => {
            state.contactName = action.payload;
        },
    },
});

export default selectedInfo.reducer;

export const {
    selectAddress,
    selectAddressUID,
    selectNomenclature,
    selectNomenclatureUID,
    selectContactInfo,
    selectDate,
    selectCommentary,
    selectReceiver,
    selectReceiverUID,
    setIsGraniteavailable,
    setNomenclatureMobility,
    selectContactName,
    setPMD,
    selectCounterpart,
    setEntarance,
    setFiller,
    setCars,
    setSelectedReceiverAddressUID,
    setSelectedClient,
    setSelectedClientUID,
    setPumpCount,
    setLaitance,
    setIsTubeRequired,
    setIzsLaitanceRequired,
    setIsPipeRequired,
    setPumps,
    setOwnPumps,
    setContactName,
} = selectedInfo.actions;
