import React, { useEffect } from 'react';
import { useState } from 'react';

import './AddInfoButton.css';

import PhoneValue from '../../Login/PhoneValue';

import { useDispatch, useSelector } from 'react-redux';
import { selectContactName } from '../../../../../store/slices/sliceSelectedInfo';
import { mobileProrabIntstance } from '../../../../Entities/Lib/axios';

import { ContactInfoDataType } from '../../../../Entities/Interfaces/Interfaces';
import { RootState } from '../../../../../store/store';

interface AddInfoButtonProps {
    content: ContactInfoDataType[];
    setContent: React.Dispatch<any[]>;
    isChanged: boolean;
    setIsChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

function AddInfoButton({ content, setContent, isChanged, setIsChanged }: AddInfoButtonProps) {
    const address = useSelector((state: RootState) => state.selectedInfo.selectedAddressUID);

    const [phone, setPhone] = useState('');
    const [contactName, setContactName] = useState(
        useSelector((state: RootState) => state.selectedInfo.selectedContactName),
    );

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(selectContactName(contactName));
    }, [contactName]);

    async function savePhone() {
        if (address !== null) {
            const response = await mobileProrabIntstance.post(
                `newPhoneNumber`,
                {
                    addressUID: address,
                    phoneValue: phone,
                    contactName: contactName
                }
            );

            if (response.status === 200) {
                return;
            }
        } else {
            sessionStorage.setItem('selectedPhone', phone);
            sessionStorage.setItem('selectedName', contactName);
        }
    }

    const setContactInformation = (selectedPhone: string) => {
        const pair = { title: selectedPhone, name: contactName };
        content.push(pair);
        setContent(content);
        setIsChanged(!isChanged);
        savePhone();
    };

    return (
        <>
            <div className="addInfoButton">
                <span className="addInfoButtonTitle">Новое контактное лицо</span>
                <input
                    type="text"
                    value={contactName === null ? '' : contactName}
                    onChange={(event) => setContactName(event.target.value)}
                    placeholder="Имя контакта"
                    style={converted}
                />
                <PhoneValue phoneValue={phone} setPhoneValue={setPhone} style={converted} />
                <button
                    disabled={!(phone[Object.keys(phone).length - 1] !== '_' && phone !== '')}
                    onClick={() => setContactInformation(phone)}
                    className={
                        !(phone[Object.keys(phone).length - 1] !== '_' && phone !== '')
                            ? 'addTelefon disabled'
                            : 'addTelefon'
                    }
                >
                    Добавить
                </button>
            </div>
            <br />
            <br />
            <br />
        </>
    );
}

const converted = {
    display: 'flex',
    height: '60px',
    padding: '0px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
    alignSelf: 'stretch',
    borderRadius: '8px',
    background: 'var(--pizdec)',

    overflow: 'hidden',
    color: 'var(--txt-color)',
    fontFeatureSettings: "'case' on",
    textOverflow: 'ellipsis',
    fontFamily: "'Inter', sans-serif",
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
};

const convertedFilled = {
    display: '-webkit-box',
    width: '19.4375rem',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '1',
    overflow: 'hidden',
    color: 'var(--txt-color)',
    fontFeatureSettings: "'case' on",
    textOverflow: 'ellipsis',
    fontFamily: 'Inter',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '1.5rem',
};

export default AddInfoButton;
