import React, { useState, useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import toast from 'react-hot-toast';

import ModalSelect from '../../../../Utils/Requests/CreateRequest/ModalSelect';
import { selectCommentary } from '../../../../../store/slices/sliceSelectedInfo';

import { RootState } from '../../../../../store/store';
import { useGetPhoneNumbersQuery } from '../../../../../servises/api';

interface ContactInfoProps {
    addressUID: string;
    selectedTelephone: string;
    requestCopy?: any;
    refPhoneSelector: React.RefObject<HTMLDivElement>;
}

function ContactInfo({
    addressUID,
    selectedTelephone,
    requestCopy,
    refPhoneSelector,
}: ContactInfoProps) {
    const [isEditable, setIsEditable] = useState(false);

    const [commentaryValue, setCommentaryValue] = useState(
        useSelector((state: RootState) => state.selectedInfo.selectedCommentary),
    );

    const { data: dataPhones, isSuccess: isSuccessPhones } = useGetPhoneNumbersQuery(addressUID, {
        skip: !addressUID as unknown as boolean,
    });

    const dispatch = useDispatch();

    useEffect(() => {
        if (requestCopy) {
            setIsEditable(false);
        }

        if (!addressUID) {
            setIsEditable(false);
        } else {
            setIsEditable(true);
        }
    }, [requestCopy]);

    useEffect(() => {
        dispatch(selectCommentary(commentaryValue));
    }, [commentaryValue]);

    return (
        <>
            <div className="methodAndInterval">
                <span className="titleRequestMenu">Кому</span>

                <ModalSelect
                    isLoaded={isSuccessPhones}
                    refPhoneSelector={refPhoneSelector}
                    isEditable={isEditable}
                    request={requestCopy}
                    addressUID={addressUID}
                    selectTitle={'Телефон'}
                    selectedInformation={selectedTelephone}
                    route={'addContactInformation'}
                    content={dataPhones}
                    methodToGetContent={`phone_numbers`}
                />
            </div>

            <div className="nomenclatureAndCapacity">
                <span className="addInfoButtonTitle">Комментарий</span>
                <input
                    type="text"
                    value={commentaryValue === null ? '' : commentaryValue}
                    onChange={(event) => setCommentaryValue(event.target.value)}
                    placeholder="Комментарий"
                    style={converted}
                />
            </div>
        </>
    );
}

const converted = {
    display: 'flex',
    height: '60px',
    padding: '0px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
    alignSelf: 'stretch',
    borderRadius: '8px',
    background: 'var(--white-gray)',

    overflow: 'hidden',
    color: 'var(--txt-color)',
    fontFeatureSettings: "'case' on",
    textOverflow: 'ellipsis',
    fontFamily: "'Inter', sans-serif",
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
};

export default ContactInfo;
