import React from 'react';

function BigDownArrow({ theme }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="11"
            viewBox="0 0 19 11"
            fill="none"
        >
            <path
                d="M9.16065 10.9205C9.41703 10.9205 9.67331 10.8179 9.8477 10.623L17.7842 2.49172C17.9585 2.3174 18.0611 2.09181 18.0611 1.83547C18.0611 1.30226 17.6611 0.892105 17.128 0.892105C16.8716 0.892105 16.6358 0.994646 16.4614 1.15871L8.60693 9.18752H9.70411L1.8496 1.15871C1.68554 0.994646 1.4497 0.892105 1.18311 0.892105C0.649902 0.892105 0.25 1.30226 0.25 1.83547C0.25 2.09181 0.35254 2.3174 0.526855 2.50197L8.46338 10.623C8.65821 10.8179 8.89405 10.9205 9.16065 10.9205Z"
                fill={theme === 'dark' ? '#fff' : '#1c1c1c'}
                fillOpacity="0.85"
            />
        </svg>
    );
}

export default BigDownArrow;
