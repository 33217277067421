import React from 'react';

function UpArrow({ theme }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="24"
            viewBox="0 0 16 24"
            fill="none"
        >
            <g clipPath="url(#clip0_37_4189)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.21967 14.7803C2.51256 15.0732 2.98744 15.0732 3.28033 14.7803L7.75 10.3107L12.2197 14.7803C12.5126 15.0732 12.9874 15.0732 13.2803 14.7803C13.5732 14.4874 13.5732 14.0126 13.2803 13.7197L8.28033 8.71967C7.98744 8.42678 7.51256 8.42678 7.21967 8.71967L2.21967 13.7197C1.92678 14.0126 1.92678 14.4874 2.21967 14.7803Z"
                    fill={theme === 'dark' ? '#fff' : '#3C3C43'}
                />
            </g>
            <defs>
                <clipPath id="clip0_37_4189">
                    <rect
                        width="24"
                        height="16"
                        fill="white"
                        transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 16 24)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}

export default UpArrow;
