import React, { forwardRef, useEffect, useState } from 'react';

import './DateAndTimeInput.css';

import { isEqual, parseISO, setHours, setMinutes, startOfDay } from 'date-fns';

import ReactDatePicker from 'react-datepicker';

import { useDispatch } from 'react-redux';

import Calendar from '../../../../../../content/svg/RequestsPage/Calendar';
import UpArrow from '../../../../../../content/svg/RequestsPage/UpArrow';
import DownArrow from '../../../../../../content/svg/RequestsPage/DownArrow';
import Clock from '../../../../../../content/svg/RequestsPage/Clock';
import Timer from '../../../../../../content/svg/RequestsPage/Timer';
import { setNasosDate } from '../../../../../../store/slices/sliceRequests';
import { setDateMarks } from '../../../../../../store/slices/sliceMarks';

interface DateInputFunctionInterface {
    value?: string;
    onClick?: () => void;
}

interface DateAndTimeInputProps {
    date: Date;
    setDate: React.Dispatch<Date>;
    upper_time?: Date;
    lower_time?: Date;
    upper_date: Date;
    lower_date: Date;
    isPlanned?: boolean;
    isNewRequest?: boolean;
    request?: any; //todo
}

function DateAndTimeInput({
    date,
    setDate,
    upper_time,
    lower_time,
    upper_date,
    lower_date,
    isPlanned,
    isNewRequest,
    request,
}: DateAndTimeInputProps) {
    const [isVisibleDate, setIsVisibleDate] = useState(false);
    const [isVisibleTime, setIsVisibleTime] = useState(false);

    const theme = localStorage.getItem('theme');

    const dispatch = useDispatch();

    // const isDateShouldForceUpdated = useSelector(
    //     (state: RootState) => state.requests.isDateShouldForceUpdated,
    // );
    useEffect(() => {
        if (date < lower_date) {
            setDate(lower_date);
        }
    }, []);

    function roundMinutes(date: Date) {
        if (date.getMinutes() <= 30 && date.getMinutes() !== 0) {
            date.setMinutes(30, 0, 0);
        } else if (date.getHours() === 0 && date.getMinutes() === 0) {
            date.setHours(0);
            date.setMinutes(1);
        } else {
            date.setHours(date.getHours() + Math.round(date.getMinutes() / 60));
            date.setMinutes(0, 0, 0);
        }
        return date;
    }

    const DateInput = forwardRef(
        (
            { value, onClick }: DateInputFunctionInterface,
            ref: React.Ref<any>, // todo
        ) => (
            <div onClick={onClick} ref={ref} className="chooseFieldDate">
                <div className="textField">
                    <div className="icon">
                        <Calendar theme={theme} />
                    </div>

                    <div className="fieldValue">
                        <span className="choosenFieldText">Дата</span>
                        {value}
                    </div>

                    {isVisibleDate ? <UpArrow theme={theme} /> : <DownArrow theme={theme} />}
                </div>
            </div>
        ),
    );

    const TimeInput = forwardRef(
        (
            { value, onClick }: DateInputFunctionInterface,
            ref: React.Ref<any>, //todo
        ) => (
            <div onClick={onClick} ref={ref} className="chooseFieldTime">
                <div className="textField">
                    <div className="icon">
                        {request ? (
                            request.dateChanged !== true ? (
                                <Clock theme={theme} />
                            ) : (
                                <Timer theme={theme} />
                            )
                        ) : (
                            <Clock theme={theme} />
                        )}
                    </div>

                    <div className="fieldValue">
                        <span className="choosenFieldText">Время</span>
                        {value}
                    </div>

                    {isVisibleTime ? <UpArrow theme={theme} /> : <DownArrow theme={theme} />}
                </div>
            </div>
        ),
    );

    const [minTime, setMinTime] = useState(upper_time);
    const [maxTime, setMaxTime] = useState(lower_time);
    const [isDateShouldForceUpdated, setIsDateShouldForceUpdated] = useState(false);

    const currentDate = new Date();

    useEffect(() => {
        if (isDateShouldForceUpdated) {
            setDate(setHours(setMinutes(new Date(upper_date), 0), 0));
        }
    }, [isDateShouldForceUpdated]);

    useEffect(() => {
        if (isNewRequest === false) {
            if (isEqual(startOfDay(date), startOfDay(currentDate))) {
                setMinTime(lower_date);
                setMaxTime(setHours(setMinutes(new Date(), 30), 23));
            } else if (isPlanned && isEqual(startOfDay(date), upper_date)) {
                setMinTime(lower_date);
                setMaxTime(setHours(setMinutes(new Date(), 30), 7));
            } else if (isPlanned) {
                setMinTime(setHours(setMinutes(new Date(), 0), 0));
                setMaxTime(setHours(setMinutes(new Date(), 30), 23));
            } else {
                if (date.getTime() !== setHours(setMinutes(new Date(upper_date), 0), 0).getTime()) {
                    setIsDateShouldForceUpdated(true);
                }
                setMinTime(setHours(setMinutes(new Date(), 0), 0));
                setMaxTime(setHours(setMinutes(new Date(), 30), 7));
            }
        } else {
            if (isEqual(startOfDay(date), startOfDay(currentDate))) {
                setMinTime(lower_date);
                setMaxTime(setHours(setMinutes(new Date(), 30), 23));
            } else {
                setMinTime(setHours(setMinutes(new Date(), 0), 0));
                setMaxTime(setHours(setMinutes(new Date(), 30), 23));
            }
        }
        setIsVisibleDate(false);
        setIsVisibleTime(false);
    }, [date]);

    return (
        <div className="inputRequestMenuDateAndTime">
            <ReactDatePicker
                open={isVisibleDate}
                dateFormat="dd.MM.yyyy"
                inline={false}
                onClickOutside={() => setIsVisibleDate(!isVisibleDate)}
                onInputClick={() => {
                    setIsVisibleDate(!isVisibleDate);
                    setIsVisibleTime(false);
                }}
                shouldCloseOnSelect={true}
                onFocus={(event: Event) => (event.target as HTMLElement)?.blur()}
                maxTime={maxTime}
                minTime={minTime}
                closeOnScroll={true}
                selected={date}
                onChange={(date: Date) => {
                    dispatch(setDateMarks(date));
                    dispatch(setNasosDate(typeof date === 'string' ? parseISO(date) : date));
                    setDate(typeof date === 'string' ? parseISO(date) : date);
                }}
                includeDateIntervals={[
                    { start: startOfDay(lower_date), end: startOfDay(upper_date) },
                ]}
                className="inputedInformation"
                customInput={<DateInput />}
            />

            <ReactDatePicker
                open={isVisibleTime}
                onInputClick={() => {
                    setIsVisibleTime(!isVisibleTime);
                    setIsVisibleDate(false);
                }}
                dateFormat="HH:mm"
                closeOnScroll={true}
                onClickOutside={() => setIsVisibleTime(!isVisibleTime)}
                onFocus={(event: Event) => (event.target as HTMLElement)?.blur()}
                selected={roundMinutes(date)}
                timeCaption="Время:"
                maxTime={maxTime}
                minTime={minTime}
                showTimeSelect
                showTimeSelectOnly
                onChange={(date: Date) => {
                    dispatch(setNasosDate(date));
                    dispatch(setDateMarks(date));
                    setDate(typeof date === 'string' ? parseISO(date) : date);
                }}
                includeDateIntervals={[
                    { start: startOfDay(lower_date), end: startOfDay(upper_date) },
                ]}
                className="inputedInformation"
                customInput={<TimeInput />}
            />
        </div>
    );
}

export default DateAndTimeInput;
