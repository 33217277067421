import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { setEntarance } from '../../../../../../store/slices/sliceSelectedInfo.ts';

import styles from './EntaranceSelector.module.css';

//todo

function EntaranceSelector({ entaranses, request }) {
    const dispactch = useDispatch();
    const entarance = useSelector((state) => state.selectedInfo.selectedEntareance);

    const handleChange = (event) => {
        dispactch(setEntarance(event.target.value));
    };

    useEffect(() => {
        if (request) {
            if (request.pmd) {
                dispactch(setEntarance(request.entarance));
            }
        }
    }, [request]);

    return (
        <div className={styles.placeholder}>
            <div className={styles.position}>
                <div className={styles.txtPlaceholder}>
                    <div className={styles.txts}>
                        <span className={styles.label}>Подъезд</span>
                        <select value={entarance} onChange={handleChange} className={styles.value}>
                            {entaranses.map((entarance, index) => {
                                return (
                                    <option key={index} className={styles.option} value={entarance}>
                                        {entarance}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" viewBox="0 0 16 24" fill="none">
                <g clip-path="url(#clip0_1684_5782)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.21967 9.21967C2.51256 8.92678 2.98744 8.92678 3.28033 9.21967L7.75 13.6893L12.2197 9.21967C12.5126 8.92678 12.9874 8.92678 13.2803 9.21967C13.5732 9.51256 13.5732 9.98744 13.2803 10.2803L8.28033 15.2803C7.98744 15.5732 7.51256 15.5732 7.21967 15.2803L2.21967 10.2803C1.92678 9.98744 1.92678 9.51256 2.21967 9.21967Z" fill="#202020"/>
                </g>
                <defs>
                    <clipPath id="clip0_1684_5782">
                    <rect width="24" height="16" fill="white" transform="translate(16) rotate(90)"/>
                    </clipPath>
                </defs>
                </svg> */}
            </div>
        </div>
    );
}

export default EntaranceSelector;
