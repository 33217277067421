import { createSlice } from '@reduxjs/toolkit';
import { startOfMonth } from 'date-fns';

const monthNamesForPicker = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
];

export function removeItemOnce(arr, value) {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }
  
export  function removeItemAll(arr, value) {
    var i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

export const bonusesSlice = createSlice({
    name: 'bonusesSlice',
    initialState: {
        startDate: startOfMonth(new Date()),
        endDate: new Date(),
        period: monthNamesForPicker[new Date().getMonth()],
        prevStartDate: startOfMonth(new Date()),
        prevEndDate: new Date(),
        prevPeriod: monthNamesForPicker[new Date().getMonth()],
        selectedValues: [],
    },
    reducers: {
        setBonusesDates: (state, action) => {
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
        },
        setBonusesPeriod: (state, action) => {
            state.period = action.payload;
        },

        setPrevValues: (state, action) => {
            state.prevStartDate = action.payload.startDate;
            state.prevEndDate = action.payload.endDate;
            state.prevPeriod = action.payload.period;
        },

        addValue: (state, action) => {
            state.selectedValues.unshift(action.payload);
        },
        deleteValue: (state, action) => {
            removeItemOnce(state.selectedValues, action.payload);
        },
        clearValues: (state) => {
          state.selectedValues = [];
      },
    },
});

export const { setBonusesDates, setBonusesPeriod, setPrevValues, addValue, deleteValue, clearValues } = bonusesSlice.actions;

export default bonusesSlice.reducer;
