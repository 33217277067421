import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name: 'userSlice',
    initialState: {
        theme: localStorage?.getItem('theme') ? localStorage.getItem('theme') : 'light',
        isPushEnabled: localStorage?.getItem('isPushEnabled')
            ? localStorage?.getItem('isPushEnabled')
            : 'false',
        isFixedPage: false,
        isError: false
    },
    reducers: {
        changeUserTheme: (state, action) => {
            state.theme = action.payload;
            document.documentElement.dataset.theme = action.payload;
            localStorage.setItem('theme', action.payload);
        },
        togglePushes: (state, action) => {
            state.isPushEnabled = action.payload;
            localStorage.setItem('isPushEnabled', action.payload);
        },
        fixPageState: (state, action) => {
            state.isFixedPage = action.payload;
        },
        setIsError: (state, action) => {
            state.isError = action.payload;
        },
    },
});

export const { togglePushes, changeUserTheme, fixPageState, setIsError } = userSlice.actions;

export default userSlice.reducer;
