import React, { useState } from 'react';

import { CodeInputReact } from 'code-input-react';
import { useNavigate } from 'react-router-dom';

import toast from 'react-hot-toast';

import SentAgainBtn from '../SentAgainBtn/SentAgainBtn';
import Header from '../../Utils/Requests/CreateRequest/Header';
import { mobileProrabIntstance } from '../../Entities/Lib/axios';
import { getCookie } from '../../Entities/Lib/CookieWork';

function CodeConfirm() {
    const phoneValue = localStorage?.getItem('phone');
    const navigate = useNavigate();

    const [codeValue, setCodeValue] = useState('');

    async function tryToGetCard(code) {
        try {
            const response = await mobileProrabIntstance.post(
                `/cardReceiving`,
                {
                    nominal: 5000,
                    code: code
                }
            );

            if (response.status === 200) {
                navigate('/bonuses');
            }
        } catch (error) {
            toast.error('Ошибка авторизации, попробуйте снова');
        }
    }

    return (
        <>
            <Header headerTitle={'4- значный код '} backRedirect={'cardSelection'} />

            <div className="titleCodePlaceholder">
                <div className="titleCode">
                    <span className="titleCodeValue">
                        Введите код, отправленный на номер <br /> {phoneValue}
                    </span>
                </div>

                <div className="codeValuePlaceholder">
                    <div className="container">
                        <CodeInputReact
                            length={4}
                            value={codeValue}
                            onChange={setCodeValue}
                            onComplete={(code) => tryToGetCard(code)}
                        />
                        <SentAgainBtn isBonuse={true} phoneValue={phoneValue} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default CodeConfirm;
