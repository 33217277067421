import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { useNavigate } from 'react-router-dom';
import { clearMark } from '../../../../store/slices/sliceMarks';
import { format, parse } from 'date-fns';

function MarkList() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const theme = localStorage.getItem('theme');

    const marks = useSelector((state: RootState) => state.marks.dataMarks);

    return (
        <div className="requestPosition">
            <span className="titleRequestMenu">Марки</span>

            {marks.map((request, index) => {
                return (
                    <div key={index} className="requestTicket">
                        <div className="requestHeader">
                            <div
                                style={{ alignItems: 'stretch' }}
                                className={'nomenclatureAndStatusRequest'}
                            >
                                <span className="requestNomenclature">
                                    {request.nomenclatureUID}
                                </span>
                                <div>
                                    <svg
                                        onClick={() => {
                                            dispatch(clearMark(index));
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="30"
                                        height="30"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            opacity="0.2"
                                            d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                            fill={
                                                theme === 'dark' ? `#fff` : `rgba(60, 60, 67, 0.80)`
                                            }
                                        />
                                        <path
                                            d="M8.96967 8.96967C9.26256 8.67678 9.73744 8.67678 10.0303 8.96967L12 10.9394L13.9697 8.96969C14.2626 8.6768 14.7374 8.6768 15.0303 8.96969C15.3232 9.26258 15.3232 9.73746 15.0303 10.0304L13.0607 12L15.0303 13.9696C15.3232 14.2625 15.3232 14.7374 15.0303 15.0303C14.7374 15.3232 14.2625 15.3232 13.9696 15.0303L12 13.0607L10.0304 15.0303C9.73746 15.3232 9.26258 15.3232 8.96969 15.0303C8.6768 14.7374 8.6768 14.2626 8.96969 13.9697L10.9394 12L8.96967 10.0303C8.67678 9.73744 8.67678 9.26256 8.96967 8.96967Z"
                                            fill={
                                                theme === 'dark'
                                                    ? `#474747`
                                                    : `rgba(60, 60, 67, 0.40)`
                                            }
                                        />
                                    </svg>
                                </div>
                            </div>
                            <hr className="requestLine" />
                            <div className="timeAndCapacityInfo">
                                <div className="infoTimeAndCapacityBlock">
                                    <span className="capacityTitle">Дата</span>
                                    <span className="capacityValue">
                                        {format(
                                            parse(request.date, 'yyyy-MM-dd', new Date()),
                                            'dd.MM',
                                        )}
                                    </span>
                                </div>
                                <div className="infoTimeAndCapacityBlock">
                                    <span className="capacityTitle">Время</span>
                                    <span className="capacityValue">{request.time}</span>
                                </div>
                                <div className="infoTimeAndCapacityBlock">
                                    <span className="capacityTitle">Объём</span>
                                    <span className="capacityValue">
                                        {`${request.capacity}м`}
                                        <sup className="supMidTxt">3</sup>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default MarkList;
