import React from 'react';
import loadingStyles from './LoadingCarsPlaceholder.module.css';
import styles from './Cars.module.css';
import CurrentCars from '../../../../../content/svg/RequestsPage/CurrentCars';
import DoneCars from '../../../../../content/svg/RequestsPage/DoneCars';

function LoadingCarsPlaceholder() {
    const theme = localStorage.getItem('theme');
    return (
        <div className="carsPlaceholder">
            <div className={loadingStyles.activeCars}>
                <div className={styles.truckStatusGroup}>
                    <div className={loadingStyles.header}>
                        <div className={loadingStyles.sideHeader}>
                            <CurrentCars theme={theme} />
                            <span className={styles.nameTxt}>Текущие рейсы</span>
                        </div>
                        <div className={loadingStyles.sideHeader}>
                            <div className={loadingStyles.miniLoader}></div>
                            <div className={loadingStyles.miniLoader}></div>
                        </div>
                    </div>
                </div>
                <div className={loadingStyles.carLoading}>
                    <div className={loadingStyles.graphicProgress}></div>
                    <div className={loadingStyles.secondRow}>
                        <div className={loadingStyles.carInfo}></div>
                        <div className={loadingStyles.carButtons}>
                            <div className={loadingStyles.carButton}></div>
                            <div className={loadingStyles.carButton}></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={loadingStyles.activeCars}>
                <div className={styles.truckStatusGroup}>
                    <div className={loadingStyles.header}>
                        <div className={loadingStyles.sideHeader}>
                            <CurrentCars theme={theme} />
                            <span className={styles.nameTxt}>Завершённые рейсы</span>
                        </div>
                        <div className={loadingStyles.sideHeader}>
                            <div className={loadingStyles.miniLoader}></div>
                            <div className={loadingStyles.miniLoader}></div>
                        </div>
                    </div>
                </div>
                <div className={loadingStyles.carLoading}>
                    <div className={loadingStyles.graphicProgress}></div>
                    <div className={loadingStyles.secondRow}>
                        <div className={loadingStyles.carInfo}></div>
                        <div className={loadingStyles.carButtons}>
                            <div className={loadingStyles.carButton}></div>
                            <div className={loadingStyles.carButton}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoadingCarsPlaceholder;
