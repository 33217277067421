import React, { useEffect, useRef, useState } from 'react';

import { useLocation } from 'react-router-dom';

import toast from 'react-hot-toast';

import Header from '../../../../Utils/Requests/CreateRequest/Header';
import LoadingTiles from '../../../../Shared/Loadings/LoadingTiles/LoadingTiles';

import { mobileProrabIntstance } from '../../../../Entities/Lib/axios';

import AddressModal from '../../../../Entities/modals/AddressModal';
import ContactInfoModal from '../../../../Entities/modals/ContactInfoModal';
import ReceiverModal from '../../../../Entities/modals/ReceiverModal';
import NomenclatureModal from '../../../../Entities/modals/NomenclatureModal';
import ClientModal from '../../../../Entities/modals/ClientModal';

interface ModalViewProps {
    ModalViewType: 'Адрес' | 'Номенклатура' | 'Контактная информация' | 'Грузополучатель';
    children: JSX.Element[] | JSX.Element;
}

function ModalView({ ModalViewType, children }: ModalViewProps) {
    const location = useLocation();

    const [content, setContent] = useState(location?.state?.data ? location.state.data : []);
    const isLoaded = useRef(location?.state?.isLoaded);

    const request = location?.state?.request;
    const methodToGetData = location?.state?.methodToGetContent;
    const addressUID = location?.state?.addressUID;
    const backRedirect = location?.state?.backRedirect;

    window.history.replaceState({}, 'foo', '/requests');

    useEffect(() => {
        if (Object.keys(content).length === 0 && isLoaded.current === false) {
            if (ModalViewType === 'Адрес') {
                getDataAddress();
            } else {
                getData();
            }
        } else {
            setContent(content);
        }

        async function getData() {
            const response = await mobileProrabIntstance.post(methodToGetData, {
                addressUID: addressUID ? addressUID : null,
            });

            if (response.status === 200) {
                const content = await response.data;
                setContent(content);
                isLoaded.current = true;
            } else {
                toast.error('Ошибка получения данных, попробуйте позже');
            }
        }

        async function getDataAddress() {
            const response = await mobileProrabIntstance.post(methodToGetData, {
                addressUID: addressUID ? addressUID : null,
            });

            if (response.status === 200) {
                const content = await response.data;
                setContent(content);
                isLoaded.current = true;
            } else {
                toast.error('Ошибка получения данных, попробуйте позже');
            }
        }
    }, [content]);

    if (Object.keys(content).length === 0 && (isLoaded.current as unknown as boolean) === false) {
        return <LoadingTiles tilesHeader={ModalViewType} backRedirect={backRedirect} />;
    } else {
        return (
            <div className="modalBody">
                <Header headerTitle={ModalViewType} backRedirect={backRedirect} />
                {ModalViewType == 'Адрес' ? (
                    <AddressModal content={content} backRedirect={backRedirect} request={request} />
                ) : ModalViewType == 'Номенклатура' ? (
                    <NomenclatureModal
                        content={content}
                        backRedirect={backRedirect}
                        request={request}
                    />
                ) : ModalViewType == 'Контактная информация' ? (
                    <ContactInfoModal
                        content={content}
                        backRedirect={backRedirect}
                        request={request}
                        setContent={setContent}
                    />
                ) : ModalViewType == 'Грузополучатель' ? (
                    <ReceiverModal
                        content={content}
                        backRedirect={backRedirect}
                        request={request}
                    />
                ) : (
                    <ClientModal content={content} backRedirect={backRedirect} request={request} />
                )}
                {children}
            </div>
        );
    }
}

export default ModalView;

{
    /* <ModalMainContent
    content={content}
    setContent={setContent}
    request={request}
    modalViewType={ModalViewType}
    backRedirect={backRedirect}
/> */
}
