import React from 'react';
import { useState } from "react";

import styles from './AgainButton.module.css';

import { useNow } from "../../CustomHooks/useNow";

export function AgainButton({cb}) {
    const resendTimeout = 60000; //ms
    const [sentAt, setSentAt] = useState(false);

    const now = useNow(1000, sentAt, (now) => {
        if (sentAt && resendTimeout - (now - sentAt) < 0) {
        setSentAt();
        }
    });

    const msToResend = sentAt ? resendTimeout - (now - sentAt) : 0;
    const isDisabled = msToResend > 0;

    const handleSend = () => {
        cb();
        setSentAt(Date.now());
    };

  return (
    <span
    onClick={() => handleSend()}
    className={sentAt ? styles.sendCodeOneMoreDisabled : styles.sendCodeOneMore}
    >
    {isDisabled ? `Отправить код еще раз через: ${Math.floor(msToResend / 1000)}` : "Отправить код еще раз"}
    </span>
  );
}