import Pushy from 'pushy-sdk-web';
import toast from 'react-hot-toast';

import { mobileProrabIntstance } from '../components/Entities/Lib/axios';
import { clearToken } from '../components/Entities/Lib/Settings';

export const handleRegisterPush = () => {
    Pushy.register({ appId: '658e88a8d13f88ac44aa9c34' })
        .then(function (deviceToken) {
            mobileProrabIntstance
                .post('deviceToken', {
                    deviceToken: deviceToken,
                })
                .catch((e) => {
                    console.log(e);
                    clearToken();
                });
        })
        .catch(function (err) {
            console.log(err);
        });
};

Pushy.setNotificationListener(function (data) {
    const message = data.message || 'Test notification';

    toast.success(message);
});
