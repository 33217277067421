import React from 'react';

function Gift() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="37"
            height="37"
            viewBox="0 0 37 37"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M32 16.5625L31 16.875L26 18.4375L32 16.5625ZM32 9.84131V16.5625L36 15.3125L37 15V8.63281L17 1L13.4233 2.05198C12.906 2.20411 7.54759 3.78012 5.66581 4.33358L13.4233 2.05198C14.1568 1.91474 14.7325 1.87465 15 2.00029L18.0191 4.57978L32 9.82091V9.84131Z"
                fill="#DDE3E7"
            />
            <path d="M25.0073 18.7477L31 16.875L26 18.4375L25.0073 18.7477Z" fill="#196DD4" />
            <path
                d="M26 18.4375L31 16.875L32 16.5625V9.84131V9.82091L18.0191 4.57978L15 2.00029C14.7325 1.87465 14.1568 1.91474 13.4233 2.05198L5.66581 4.33358L26 11.2915V18.4375Z"
                fill="#1D84FE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31 32.1645L25.0073 34.3106V20.6642V18.7477L31 16.875V18.6667L31 32.1645Z"
                fill="#1D84FE"
            />
            <path d="M21 20V22L25.0073 20.6642V18.7477L21 20Z" fill="#B8C0C7" />
            <path d="M36 17V15.3125L32 16.5625L31 16.875V18.6667L36 17Z" fill="#B8C0C7" />
            <path
                d="M21.1721 35.71L36 30.3679V17L31 18.6667L31 32.1645L25.0073 34.3106V20.6642L21 22L21.1721 35.71Z"
                fill="#DDE3E7"
            />
            <path d="M31 16.875L25.0073 18.7477V20.6642L31 18.6681V16.875Z" fill="#196DD4" />
            <path d="M21 20L2 13.2143V29.5L21.1721 35.71L21 22V20Z" fill="#C7D1D6" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21 22V20L2 13.2143V16L21 22Z"
                fill="#AAAFB6"
            />
            <path
                d="M0 6V12.5L2 13.2143L21 20L25.0073 18.7477L26 18.4375V11.2915L5.66581 4.33358L0 6Z"
                fill="#DBE1E5"
            />
            <path d="M0 6V12.5L2 13.2143L21 20V12.5L0 6Z" fill="#C7D1D6" />
            <path
                d="M22 2C24.4938 1.21711 27.2398 4.64715 27.754 5.41141C27.3531 4.98597 24.7666 4.91801 24 6C23.5137 3.68995 22 4.5 22 2Z"
                fill="#1D84FE"
            />
            <path
                d="M23.8145 7.17435C21.6625 7.17435 20.3043 5.77554 19.5494 6.20775C19.6604 6.02604 20.4117 6.06208 22.8832 6.56352C23.7111 6.73149 23.8116 6.225 24 6C24.7666 4.91801 27.3531 4.98597 27.754 5.41141C29.1283 7.45407 24.9176 7.17435 23.8145 7.17435Z"
                fill="#1058AC"
            />
            <path
                d="M19.5494 6.20775L19.097 4.7184C20.5754 2.6076 21.0315 2.30406 22 2C22 4.5 23.5137 3.68995 24 6C23.8116 6.225 23.7111 6.73149 22.8832 6.56352C20.4117 6.06208 19.6604 6.02604 19.5494 6.20775Z"
                fill="#186BD6"
            />
            <path
                d="M11.4163 2.95202C10.6428 3.54827 11.0006 5.81639 13.535 6.68749C13.6618 6.05946 14.3376 5.24803 14.8726 4.84807C15.627 1.83063 13.9062 1.82742 12.7227 2.14916C12.3074 2.33694 11.8729 2.60015 11.4163 2.95202Z"
                fill="#186BD6"
            />
            <path
                d="M16.2605 7.02063C17.9444 6.94385 19.3424 6.0574 19.7346 6.25354C17.9757 3.46149 15.3387 4.49966 14.8726 4.84807C14.3376 5.24803 13.6618 6.05946 13.535 6.68749C14.2582 6.93606 15.1586 7.07087 16.2605 7.02063Z"
                fill="#1058AC"
            />
            <path
                d="M19.7346 6.25354L19.4936 4.71331C17.5583 3.17388 15.6039 0.846394 12.7227 2.14916C13.9062 1.82742 15.627 1.83063 14.8726 4.84807C15.3387 4.49966 17.9757 3.46149 19.7346 6.25354Z"
                fill="#1D84FE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.7913 6.24737C20.3604 6.24737 20.8217 5.78603 20.8217 5.21694C20.8217 4.64786 20.3604 4.18652 19.7913 4.18652C19.6337 4.18652 19.4844 4.22189 19.3509 4.28512C19.3509 4.28512 19.8508 4.49776 19.5992 5.28401C19.385 5.95342 19.7913 6.24737 19.7913 6.24737Z"
                fill="#1D84FE"
            />
            <path
                d="M18.7609 5.21694C18.7609 5.78603 19.2222 6.24737 19.7913 6.24737C19.7913 6.24737 19.385 5.95342 19.5992 5.28401C19.8508 4.49776 19.3509 4.28512 19.3509 4.28512C19.0021 4.45025 18.7609 4.80543 18.7609 5.21694Z"
                fill="#105AB5"
            />
        </svg>
    );
}

export default Gift;
