import React from 'react';
import { IMask } from 'react-imask';

interface PhoneValueProps {
    phoneValue: string;
    setPhoneValue: React.Dispatch<React.SetStateAction<string>>;
    style?: any;
}

function PhoneValue({ phoneValue, setPhoneValue, style }: PhoneValueProps) {
    const formatPhone = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value;
        const mask = IMask.createMask({
            mask: '+7 (000)000-00-00',
            prepare: (appended, masked) => {
                if (appended === '8' && masked.value === '') {
                    return '+7';
                }
                return appended;
            },
        });
        mask.resolve(value);
        setPhoneValue(() => mask.value);
    };

    return (
        <input
            onChange={(event) => formatPhone(event)}
            type="tel"
            value={phoneValue}
            style={style}
            maxLength={17}
            placeholder="Номер телефона"
        />
    );
}

export default PhoneValue;
