import React from 'react';

function Timer({ theme, paddingTop = '0px' }) {
    return (
        <svg
            style={{ paddingTop: paddingTop }}
            xmlns="http://www.w3.org/2000/svg"
            width={14}
            height={14}
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                d="M10.5207 4.99768H9.48256C9.3876 4.99768 9.3099 5.07303 9.3099 5.16512V10.9272C9.3099 10.9816 9.3358 11.0319 9.38112 11.0633L12.9488 13.5895C13.0265 13.644 13.1344 13.6293 13.1905 13.554L13.8078 12.7377C13.866 12.6602 13.8488 12.5556 13.7711 12.5033L10.6934 10.3454V5.16512C10.6934 5.07303 10.6157 4.99768 10.5207 4.99768ZM15.2646 6.57163L18.6488 7.37326C18.7567 7.39837 18.8625 7.31884 18.8625 7.21209L18.8798 3.83186C18.8798 3.69163 18.7136 3.6121 18.6013 3.7L15.1999 6.27651C15.1742 6.2958 15.1546 6.32173 15.1434 6.35132C15.1322 6.38092 15.1299 6.41297 15.1366 6.4438C15.1434 6.47463 15.159 6.50298 15.1816 6.52559C15.2043 6.54821 15.233 6.56416 15.2646 6.57163ZM18.8841 12.8737L17.6603 12.4656C17.6177 12.4514 17.571 12.454 17.5302 12.4727C17.4895 12.4915 17.4579 12.525 17.4423 12.566C17.4013 12.6728 17.3582 12.7774 17.3128 12.8821C16.9287 13.7633 16.3783 14.5565 15.6747 15.2367C14.9789 15.9136 14.1543 16.4531 13.2466 16.8253C12.3063 17.2109 11.2955 17.4088 10.2747 17.4072C9.24299 17.4072 8.2437 17.2126 7.30269 16.8253C6.39499 16.4531 5.5704 15.9136 4.87461 15.2367C4.17317 14.5565 3.6228 13.7633 3.23647 12.8821C2.84111 11.9697 2.63854 10.9897 2.64078 10C2.64078 8.99954 2.8415 8.02837 3.24078 7.11582C3.62496 6.23465 4.17532 5.4414 4.87893 4.76117C5.57472 4.08432 6.39931 3.54482 7.307 3.17256C8.2437 2.78535 9.24515 2.5907 10.2768 2.5907C11.3085 2.5907 12.3078 2.78535 13.2488 3.17256C14.1565 3.54482 14.9811 4.08432 15.6769 4.76117C15.897 4.97675 16.1042 5.2007 16.2941 5.43721L17.5848 4.45768C15.8862 2.3521 13.2423 0.997909 10.2725 1C5.10123 1.0021 0.94868 5.07512 1.00048 10.0921C1.05228 15.0212 5.1854 19 10.2768 19C14.2804 19 17.6905 16.5386 18.9898 13.0914C19.0222 13.0035 18.9747 12.9051 18.8841 12.8737Z"
                fill={theme === 'dark' ? '#FF8B86' : '#F54941'}
                stroke={theme === 'dark' ? '#FF8B86' : '#F54941'}
                strokeWidth={0.3}
            />
        </svg>
    );
}

export default Timer;
