import React, { useState, useEffect } from 'react';

import './Requests.css';
import '../../Utils/Requests/DatePicker/DatePicker.css';

import DateObject from 'react-date-object';

import SwipeableViews from 'react-swipeable-views';

import { useDispatch, useSelector } from 'react-redux';

import { useRef } from 'react';

import { addDays, format } from 'date-fns';

import { ScrollRestoration, useNavigate } from 'react-router-dom';

import { RootState } from '../../../store/store';

import RequestsField from '../../Utils/Requests/RequestsField/RequestsField';
import RequestFilter from '../../Utils/Requests/RequestFilter/RequestFilter';
import LoadingRequests from '../../Shared/Loadings/LoadingRequests/LoadingRequests';

import {
    selectAddress,
    selectAddressUID,
    selectCommentary,
    selectContactInfo,
    selectContactName,
    selectDate,
    selectNomenclature,
    selectNomenclatureUID,
    selectReceiver,
    selectReceiverUID,
    setCars,
    setEntarance,
    setFiller,
    setIsGraniteavailable,
    setIsPipeRequired,
    setIzsLaitanceRequired,
    setLaitance,
    setOwnPumps,
    setPMD,
    setPumpCount,
    setPumps,
    setSelectedClient,
    setSelectedClientUID,
    // setSelectedInfo,
} from '../../../store/slices/sliceSelectedInfo';

import { setSearchInput } from '../../../store/slices/sliceRequests';
import { useGetRequestsQuery } from '../../../servises/api';
import { getCookie } from '../../Entities/Lib/CookieWork';

import CurrentInfoBlock from '../../Utils/Requests/CurrentInfoBlock/CurrentInfoBlock';

import Navigation from '../../Utils/Navigation/Navigation';
import RequestIcon from '../../../content/svg/RequestsPage/RequestIcon';
import sendSettings from '../../Entities/Lib/sendSettings';
import { clearAllMarks, clearMark } from '../../../store/slices/sliceMarks';

export interface RequestDataType {
    acceptance_method: string;
    address: string;
    address_latitude: string;
    address_longitude: string;
    address_uid: string;
    cancelAvailable: boolean;
    capacity: number;
    capacityChanged: boolean;
    commentary: string;
    cone_draft: string;
    date: string;
    dateChanged: boolean;
    deliveryType: string;
    distributed: number;
    entrances: [];
    factoryType: string;
    granite_available: boolean;
    intensity: number;
    interval: string;
    lastCapacity: number;
    lastDate: string;
    lower_bound_date: Date;
    lower_bound_time: Date;
    nomenclature: string;
    nomenclatureType: string;
    nomenclature_uid: string;
    number: string;
    numberOfCars: number;
    order_confirmed: boolean;
    order_uid: string;
    phone_number: string;
    pmd: string;
    pumpCount: number;
    receiver: string;
    shipment_end_date: Date;
    shipped: number;
    status: string;
    substatus: string;
    time: string;
    upper_bound_date: Date;
    upper_bound_time: Date;
    unloaded: number;
    counterpart: string;
}

function Requests() {
    sessionStorage.clear();
    const date = new Date();
    const dispatch = useDispatch();

    localStorage.setItem('err', 'false');

    const parsedDate = useSelector((state: RootState) => state.selectedInfo.selectedDate);

    const monthNames = [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря',
    ];

    const { data, isSuccess, isFetching } = useGetRequestsQuery(getCookie('token'), {
        refetchOnFocus: true,
        skip: !getCookie('token'),
    }) as {
        data: RequestDataType[];
        isSuccess: boolean;
        isFetching: boolean;
        isError: boolean;
    };

    const [filteredRequests, setFilteredRequests] = useState(() => {
        if (data) {
            return data;
        } else {
            return [];
        }
    });

    const [selectedDate, setSelectedDate] = useState(new Date());

    const [isCurrentInfoBlockVisible, setisCurrentInfoBlockVisible] = useState(false);
    const [isPlannedInfoBlockVisible, setIsPlannedInfoBlockVisible] = useState(false);
    const [isWaitingInfoBlockVisible, setIsWaitingInfoBlockVisible] = useState(false);
    const [isCompletedInfoBlockVisible, setIsCompletedInfoBlockVisible] = useState(false);
    const [isVerificatedInfoBlockVisible, setIsVerificatedInfoBlockVisible] = useState(false);
    const [index, setIndex] = useState(NaN);

    const activeTabs = useSelector((state: RootState) => state.requests.activeTabsCount);

    const handleChangeIndex = (index: number) => {
        setIndex(index);
    };

    useEffect(() => {
        controleInfoBlocks();

        if (isSuccess) {
            controleTabsContent();
        }

        function controleInfoBlocks() {
            if (filteredRequests) {
                if (
                    Object.keys(
                        filteredRequests.filter(
                            (request) =>
                                (request.status === 'Текущая' &&
                                    request.substatus === 'В работе') ||
                                request.substatus === 'На распределении',
                        ),
                    ).length !== 0
                ) {
                    setisCurrentInfoBlockVisible(true);
                } else {
                    setisCurrentInfoBlockVisible(false);
                }

                if (
                    Object.keys(
                        filteredRequests.filter(
                            (request) =>
                                request.status === 'Плановая' && request.substatus === 'Плановая',
                        ),
                    ).length !== 0
                ) {
                    setIsPlannedInfoBlockVisible(true);
                } else {
                    setIsPlannedInfoBlockVisible(false);
                }

                if (
                    Object.keys(
                        filteredRequests.filter(
                            (request) =>
                                request.status === 'Плановая' && request.substatus === 'Ожидание',
                        ),
                    ).length !== 0
                ) {
                    setIsWaitingInfoBlockVisible(true);
                } else {
                    setIsWaitingInfoBlockVisible(false);
                }

                if (
                    Object.keys(
                        filteredRequests.filter((request) => request.status === 'Завершена'),
                    ).length !== 0
                ) {
                    setIsCompletedInfoBlockVisible(true);
                } else {
                    setIsCompletedInfoBlockVisible(false);
                }

                if (
                    Object.keys(
                        filteredRequests.filter((request) => request.substatus === 'На проверке'),
                    ).length !== 0
                ) {
                    setIsVerificatedInfoBlockVisible(true);
                } else {
                    setIsVerificatedInfoBlockVisible(false);
                }
            }
        }

        function controleTabsContent() {
            if (
                Object.keys(
                    [...filteredRequests].filter(
                        (request) =>
                            (request.date === format(selectedDate, 'dd.MM.yy') &&
                                request.status === 'Текущая' &&
                                request.substatus === 'В работе') ||
                            request.substatus === 'На распределении',
                    ),
                ).length !== 0
            ) {
                setIndex(0);
            } else if (
                Object.keys(
                    [...filteredRequests].filter(
                        (request) =>
                            request.date === format(selectedDate, 'dd.MM.yy') &&
                            request.status === 'Плановая' &&
                            request.substatus === 'Ожидание',
                    ),
                ).length !== 0
            ) {
                setIndex(1);
            } else if (
                Object.keys(
                    [...filteredRequests].filter(
                        (request) =>
                            request.date === format(selectedDate, 'dd.MM.yy') &&
                            request.status === 'Плановая' &&
                            request.substatus === 'Плановая',
                    ),
                ).length !== 0
            ) {
                setIndex(2);
            } else if (
                Object.keys(
                    [...filteredRequests].filter(
                        (request) =>
                            request.date === format(selectedDate, 'dd.MM.yy') &&
                            request.status === 'Плановая' &&
                            request.substatus === 'На проверке',
                    ),
                ).length !== 0
            ) {
                setIndex(2);
            } else if (
                Object.keys(
                    [...filteredRequests].filter(
                        (request) =>
                            request.date === format(selectedDate, 'dd.MM.yy') &&
                            request.status === 'Завершена' &&
                            request.substatus === 'Завершена',
                    ),
                ).length !== 0
            ) {
                setIndex(3);
            } else if (parsedDate > date) {
                setIndex(2);
            } else if (parsedDate < date) {
                setIndex(3);
            }
        }
    }, [filteredRequests]);

    useEffect(() => {
        dispatch(selectAddress(null));
        dispatch(selectAddressUID(null));
        dispatch(selectNomenclature(null));
        dispatch(selectNomenclatureUID(null));
        dispatch(selectContactInfo(null));
        dispatch(selectCommentary(null));
        dispatch(selectReceiver(null));
        dispatch(selectReceiverUID(null));
        dispatch(selectContactName(null));
        dispatch(setEntarance(null));
        dispatch(setPMD('Без ПМД'));
        dispatch(setSearchInput(''));
        dispatch(setCars(0));
        dispatch(setSelectedClient(null));
        dispatch(setSelectedClientUID(null));
        dispatch(setFiller(null));
        dispatch(setIsGraniteavailable(false));

        dispatch(setPumpCount(0));
        dispatch(setOwnPumps(0));
        dispatch(setLaitance(0));
        dispatch(setIzsLaitanceRequired(false));
        dispatch(setPumps([]));
        filterRequestsFirst();

        function filterRequestsFirst() {
            if (isSuccess) {
                const newRequests = [...data].filter(
                    (request) => request.date === format(selectedDate, 'dd.MM.yy'),
                );

                setFilteredRequests(newRequests);
            }
        }
    }, [isSuccess]);

    useEffect(() => {
        window.history.pushState(null, '/requests', '/requests');

        dispatch(clearAllMarks());
    }, []);

    if (isSuccess === true) {
        return (
            <>
                <div className="wrapper">
                    <RequestFilter
                        index={index}
                        setIndex={setIndex}
                        filteredRequests={filteredRequests}
                        setFilterdRequests={setFilteredRequests}
                        setSelectedDate={setSelectedDate}
                        requests={data}
                    />
                    {activeTabs >= 2 ? (
                        <>
                            <div style={{ paddingTop: '125px' }}></div>
                        </>
                    ) : (
                        <>
                            <div style={{ paddingTop: '76px' }}></div>
                        </>
                    )}

                    <main className="main">
                        <SwipeableViews
                            disabled={true}
                            index={index}
                            onChangeIndex={handleChangeIndex}
                        >
                            {[...filteredRequests].filter(
                                (request) =>
                                    (request.date === format(selectedDate, 'dd.MM.yy') &&
                                        request.status === 'Текущая' &&
                                        request.substatus === 'В работе') ||
                                    request.substatus === 'На распределении',
                            ).length !== 0 ? (
                                <div>
                                    {isCurrentInfoBlockVisible && (
                                        <CurrentInfoBlock
                                            status={'Текущая'}
                                            substatuses={['В работе', 'На распределении']}
                                            statusToShow={'Активные'}
                                            filteredRequests={filteredRequests}
                                        />
                                    )}
                                    <div className="requestsPlaceholder">
                                        <RequestsField
                                            filteredRequests={filteredRequests}
                                            status={'Текущая'}
                                            substatus={'В работе'}
                                            statusToShow={'В работе'}
                                        />
                                        <RequestsField
                                            filteredRequests={filteredRequests}
                                            status={'Текущая'}
                                            substatus={'На распределении'}
                                            statusToShow={'В работе'}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="iconPlaceholderXddd">
                                    <RequestIcon />

                                    <span className="iconTxtxDDD">
                                        У вас пока нет активных заявок на эту дату
                                    </span>
                                </div>
                            )}

                            {Object.keys(
                                [...filteredRequests].filter(
                                    (request) =>
                                        request.date === format(selectedDate, 'dd.MM.yy') &&
                                        request.status === 'Плановая' &&
                                        request.substatus === 'Ожидание',
                                ),
                            ).length !== 0 ? (
                                <div>
                                    {isWaitingInfoBlockVisible && (
                                        <CurrentInfoBlock
                                            status={'Плановая'}
                                            substatuses={['Ожидание']}
                                            statusToShow={'Ожидают подтверждения'}
                                            filteredRequests={filteredRequests}
                                        />
                                    )}
                                    <div className="requestsPlaceholder">
                                        <RequestsField
                                            filteredRequests={filteredRequests}
                                            status={'Плановая'}
                                            substatus={'Ожидание'}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="iconPlaceholderXddd">
                                    <RequestIcon />

                                    <span className="iconTxtxDDD">
                                        {Object.keys(
                                            [...filteredRequests].filter(
                                                (request) =>
                                                    request.date ===
                                                        format(selectedDate, 'dd.MM.yy') &&
                                                    request.substatus === 'Ожидание',
                                            ),
                                        ).length !== 0
                                            ? 'У вас нет заявок, ожидающих подтверждения'
                                            : format(date, 'dd.MM.yy') ===
                                                format(addDays(new Date(), 1), 'dd.MM.yy')
                                              ? 'У вас пока нет заявок, запланированных на завтра'
                                              : `У вас пока нет заявок, запланированных на ${format(
                                                    selectedDate,
                                                    'd',
                                                )} ${monthNames[selectedDate.getMonth()]}`}
                                    </span>
                                </div>
                            )}

                            {Object.keys(
                                [...filteredRequests].filter(
                                    (request) =>
                                        request.date === format(selectedDate, 'dd.MM.yy') &&
                                        request.status === 'Плановая',
                                ),
                            ).length !== 0 ? (
                                <div>
                                    {isPlannedInfoBlockVisible && (
                                        <CurrentInfoBlock
                                            status={'Плановая'}
                                            substatuses={['Плановая']}
                                            statusToShow={'Плановые'}
                                            filteredRequests={filteredRequests}
                                        />
                                    )}
                                    <div className="requestsPlaceholder">
                                        <RequestsField
                                            filteredRequests={filteredRequests}
                                            status={'Плановая'}
                                            substatus={'Плановая'}
                                        />
                                    </div>
                                    {isVerificatedInfoBlockVisible && (
                                        <CurrentInfoBlock
                                            status={'Плановая'}
                                            substatuses={['На проверке']}
                                            statusToShow={'На проверке у диспетчера'}
                                            filteredRequests={filteredRequests}
                                        />
                                    )}
                                    <div className="requestsPlaceholder">
                                        <RequestsField
                                            filteredRequests={filteredRequests}
                                            status={'Плановая'}
                                            substatus={'На проверке'}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="iconPlaceholderXddd">
                                    <RequestIcon />

                                    <span className="iconTxtxDDD">
                                        {Object.keys(
                                            [...filteredRequests].filter(
                                                (request) =>
                                                    request.date ===
                                                        format(selectedDate, 'dd.MM.yy') &&
                                                    request.substatus === 'Ожидание',
                                            ),
                                        ).length !== 0
                                            ? 'У вас нет заявок, ожидающих подтверждения'
                                            : format(date, 'dd.MM.yy') ===
                                                format(addDays(new Date(), 1), 'dd.MM.yy')
                                              ? 'У вас пока нет заявок, запланированных на завтра'
                                              : `У вас пока нет заявок, запланированных на ${format(
                                                    selectedDate,
                                                    'd',
                                                )} ${monthNames[selectedDate.getMonth()]}`}
                                    </span>
                                </div>
                            )}

                            {Object.keys(
                                [...filteredRequests].filter(
                                    (request) =>
                                        request.date === format(selectedDate, 'dd.MM.yy') &&
                                        request.status === 'Завершена',
                                ),
                            ).length !== 0 ? (
                                <div>
                                    {isCompletedInfoBlockVisible ? (
                                        <CurrentInfoBlock
                                            status={'Завершена'}
                                            substatuses={['Завершена']}
                                            statusToShow={'Завершённые'}
                                            filteredRequests={filteredRequests}
                                        />
                                    ) : null}
                                    <div className="requestsPlaceholder">
                                        <RequestsField
                                            filteredRequests={filteredRequests}
                                            status={'Завершена'}
                                            substatus={'Завершена'}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="iconPlaceholderXddd">
                                    <RequestIcon />

                                    <span className="iconTxtxDDD">
                                        У вас пока нет завершенных заявок
                                    </span>
                                </div>
                            )}
                        </SwipeableViews>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </main>
                </div>
                <Navigation currentSection={'Заявки'} />
            </>
        );
    } else if (isFetching) {
        return <LoadingRequests />;
    }
}

export default Requests;
