import React from 'react';

import { format } from 'date-fns';

import styles from './RequestDate.module.css';
import { ru } from 'date-fns/locale';

function RequestDateStatistic({ date }: { date: number | Date }) {
    return (
        <div className={styles.date}>
            <div className={styles.datePlaceholder}>
                <div className={styles.dateTextPlaceholder}>
                    <div className={styles.dateText}>
                        <span className={styles.text}>
                            {format(date, 'dd MMMM', { locale: ru })}
                        </span>
                    </div>
                </div>
            </div>
            <hr className={styles.dateLine} />
        </div>
    );
}

export default RequestDateStatistic;
