import React from 'react';

import styles from './ChangePhone.module.css';

import Header from '../../Requests/CreateRequest/Header';
import PhoneValue from '../../Requests/Login/PhoneValue';
import Navigation from '../../Navigation/Navigation';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getCookie } from '../../../Entities/Lib/CookieWork';
import toast from 'react-hot-toast';
import { mobileProrabIntstance } from '../../../Entities/Lib/axios';

function ChangePhone() {
    const [phone, setPhone] = useState(localStorage.getItem('phone') || '');
    const navigate = useNavigate();

    async function tryGetCode() {
        try {
            const response = await mobileProrabIntstance.post(
                `phoneNumberCheck`, 
                {
                    phoneValue: phone.replace(/ /g, '')
                }
            );

            if (response.status === 200) {
                const data = await response.data;

                if (data.result === true) {
                    navigate('code', { state: { phoneValue: phone } });
                }
            }
        } catch (e) {
            toast.error('Недопустимый номер телефона');
        }
    }

    return (
        <>
            <Header headerTitle={'Изменение номера'} backRedirect={'settings'} />

            <div className={styles.content}>
                <div className={styles.textField}>
                    {phone ? <span className={styles.nameLabel}>Новый номер телефона</span> : null}
                    <PhoneValue phoneValue={phone} setPhoneValue={setPhone} style={style} />
                </div>

                {phone && phone.length === 17 && (
                    <button onClick={() => tryGetCode()} className={styles.submitBtn}>
                        Получить код по смс
                    </button>
                )}
            </div>

            <Navigation currentSection={'Настройки'} />
        </>
    );
}
const style = {
    display: 'flex',
    height: '1.75rem',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    borderRadius: '0.5rem',
    background: 'var(--fill-primary, rgba(116, 116, 128,))',
    color: 'var(--txt-color)',
    overflow: 'hidden',
    fontFamily: "'Inter', sans-serif",
    fontFeatureSettings: '"case" on',
    textOverflow: 'ellipsis',
    fontSize: '1.1rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '1.5rem',
};

export default ChangePhone;
