import toast from 'react-hot-toast';

import { getCookie } from '../components/Entities/Lib/CookieWork';

import { useGetSettingsQuery } from './api';
import { useEffect } from 'react';

function Listener() {
    const token = getCookie('token');

    useEffect(() => {
        if (!getCookie('token') || getCookie('token') === 'undefined') {
            if (window.location.pathname !== '/login' && window.location.pathname !== '/code') {
                window.location.href = 'login';
            }
        }
    }, []);

    if (token || getCookie('token') !== 'undefined') {
        const { data, isSuccess } = useGetSettingsQuery('', {
            skip: !getCookie('token'),
        });

        if (isSuccess) {
            localStorage.setItem('err', 'false');
            localStorage?.setItem('advancedMode', data.advancedMode);
            localStorage?.setItem('name', data.nameuser);
            localStorage?.setItem('isPushEnabled', data.isPushEnabled);
            localStorage?.setItem('isOnlyOneCar', data.NotificationOfVehicleDeparture);
            localStorage?.setItem('isCarArrive', data?.carArrival);
            localStorage?.setItem('onBoarding', data?.onboarding);
            localStorage?.setItem('isSelfPickupAvailable', data?.pickup);
            localStorage?.setItem('severalCounterparts', data?.severalCounterparts);
            localStorage?.setItem('isOpenDocumentInBrowser', data?.isOpenDocumentInBrowser);
            localStorage?.setItem('IsMarkCreation', data?.IsMarkCreation);
            localStorage?.setItem('isOnlyView', data?.isOnlyView);
            localStorage?.setItem('isAppStatistic', data?.isAppStatistic);
            if (data?.emailValue) {
                localStorage?.setItem('email', data?.emailValue);
            } else {
                localStorage?.setItem('email', 'Нету привязанной почты');
            }
        }
    }

    // if (isSuccessPing) {
    //     if (isError === false && localStorage?.getItem('err') === 'false' && token) {
    //         if (dataPing.result === false) {
    //             dispatch(setIsError(true));
    //             localStorage.setItem('err', 'true');
    //             window.location.href = 'err';
    //         }
    //     }
    // }

    window.addEventListener('online', () => toast.success('Вы снова подключились к интернету'));
    window.addEventListener('offline', () => toast.error('Потеряно соединение с интернетом...'));
}

export default Listener;
