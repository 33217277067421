import React from 'react';

import styles from './LoadingBonuses.module.css';

import Header from '../../../Utils/Requests/CreateRequest/Header';
import Navigation from '../../../Utils/Navigation/Navigation';


function LoadingBonuses() {
    const theme = localStorage.getItem('theme');

    return ( 
        <>
        <Header headerTitle={'Бонусы'} />
        <div className={styles.content}>
            <div className={styles.block}>
                <hr className={styles.smallLineHeader} />

                <div className={styles.Docs_card_not_loadedFirst}>
                    <div className={styles.name}>
                        <div className={styles.code}>
                            <hr className={styles.codeLine} />
                        </div>
                        <div className={styles.namePlaceholder}>
                            <hr className={styles.namePlaceholderLine} />
                        </div>
                    </div>
                </div>
                <div className={styles.Docs_card_not_loadedSecond}>
                    <div className={styles.chartContainer}>
                        <div className={styles.chartArea}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="119" height="120" viewBox="0 0 119 120" fill="none">
                            <g className={styles.chartPie} clipPath="url(#clip0_2051_28429)">
                                <path d="M119 60C119 92.8609 92.3609 119.5 59.5 119.5C26.6391 119.5 0 92.8609 0 60C0 27.1391 26.6391 0.5 59.5 0.5C92.3609 0.5 119 27.1391 119 60ZM26.4166 60C26.4166 78.2714 41.2286 93.0834 59.5 93.0834C77.7714 93.0834 92.5834 78.2714 92.5834 60C92.5834 41.7286 77.7714 26.9166 59.5 26.9166C41.2286 26.9166 26.4166 41.7286 26.4166 60Z" fill={theme !== 'dark' ? "rgba(203, 203, 209, 0.50)" : "#474747" }/>
                            </g>
                            <defs className={styles.chartPie}>
                                <clipPath id="clip0_2051_28429">
                                <rect width="119" height="119" fill="white" transform="translate(0 0.5)"/>
                                </clipPath>
                            </defs>
                        </svg>
                        </div>
                        <div className={styles.chart_info}>
                            <div className={styles.chart_infoPlaceholder}>
                                <hr className={styles.chartInfoLine} />
                                <hr className={styles.chartInfoLine} />
                                <hr className={styles.chartInfoLine} />
                                <hr className={styles.chartInfoLine} />
                                <hr className={styles.chartInfoLine} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.Docs_card_not_loadedThird}>
                    <div className={styles.nameSecond}>
                        <div className={styles.nameSecondPlaceholder}>
                            <hr className={styles.nameSecondPlaceholderLine} />
                        </div>
                        <div className={styles.codeSecond}>
                            <hr className={styles.codeSecondLine} />
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.block}>
                <hr className={styles.secondBlockLine} />

                <div className={styles.Docs_card_not_loadedFourth}>
                    <div className={styles.nameSecondBlock}>
                        <div className={styles.nameSecondBlockPlaceholder}>
                            <hr className={styles.nameSecondBlockPlaceholderBigLine} />
                        </div>
                        <div className={styles.codeSecondBlock}>
                            <hr className={styles.codeSecondBlockLine} />
                        </div>
                    </div>
                </div>
                <div className={styles.pieChartInfoSecond}>
                    <div className={styles.pieChartInfoSecondPlaceholder}>
                        <div className={styles.pieChartSvg}>
                            <div className={styles.chartPieSecond}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="142" height="143" viewBox="0 0 142 143" fill="none">
                                    <path d="M142 71.5C142 110.712 110.212 142.5 71 142.5C31.7878 142.5 0 110.712 0 71.5C0 32.2878 31.7878 0.5 71 0.5C110.212 0.5 142 32.2878 142 71.5ZM17.75 71.5C17.75 100.909 41.5908 124.75 71 124.75C100.409 124.75 124.25 100.909 124.25 71.5C124.25 42.0908 100.409 18.25 71 18.25C41.5908 18.25 17.75 42.0908 17.75 71.5Z" fill={theme !== 'dark' ? "rgba(203, 203, 209, 0.50)" : "#474747" }/>
                                </svg>
                                <div className={styles.txtSecondBlock}>
                                    <hr className={styles.ballsChart} />
                                    <hr className={styles.ballsChartSecond} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.chartInfoSecond}>
                            <div className={styles.left_to_save}>
                                <div className={styles.txtPlaceholder}>
                                    <div className={styles.txtArea}>
                                        <hr className={styles.chartInfoSecondLine} />
                                        <hr className={styles.chartInfoSecondLineSecond} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className={styles.pieChartButton} />
                </div>
            </div>

            <div className={styles.block}>
                <div className={styles.thirdBlock}>
                    <hr className={styles.thirdBlockLine} />
                </div>

                <div className={styles.twoBlocksThird}>
                    <div className={styles.thirdBlockFirstSection}>
                        <hr className={styles.thirdBlockLine} />
                    </div>
                    <div className={styles.thirdBlockSecondSection}>
                        <hr className={styles.thirdBlockLine} />
                    </div>
                </div>
            </div>

            <div className={styles.block}>
                <div className={styles.fourthBlockLine}>
                    <hr className={styles.thirdBlockLine} />
                </div>
                <div className={styles.plashkas}>
                    <div className={styles.saved}>
                        <div className={styles.contentFirst}>
                            <div className={styles.txtFourth}>
                                <hr className={styles.upperLine} />
                                <hr className={styles.lowerLine} />
                            </div>
                        </div>
                    </div>
                    <div className={styles.transfered}>
                        <div className={styles.contentSecond}>
                            <div className={styles.txtFourth}>
                                <hr className={styles.upperLine} />
                                <hr className={styles.lowerLine} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <Navigation
            currentSection={'Бонусы'}
            />

        </>
     );
}

export default LoadingBonuses;