import React from 'react';

function Cube() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="90"
            height="89"
            fill="none"
            viewBox="0 0 90 89"
        >
            <path
                fill="#FF8B86"
                d="M75.438 26.753L45.027 44.342 14.616 26.753a7.316 7.316 0 012.587-2.624L41.332 10.2a7.523 7.523 0 017.39 0l24.13 13.93a7.333 7.333 0 012.586 2.624z"
            ></path>
            <path
                fill="#F96760"
                d="M45.027 44.342v35.103a7.723 7.723 0 01-3.363-.776l-24.35-14.078a7.402 7.402 0 01-3.696-6.393V30.485c.006-1.31.35-2.595.998-3.732l30.41 17.589z"
            ></path>
            <path
                fill="#F54941"
                d="M76.436 30.485v27.713a7.401 7.401 0 01-3.695 6.393L48.279 78.706a7.733 7.733 0 01-3.252.74V44.341l30.411-17.59a7.645 7.645 0 01.998 3.733z"
            ></path>
        </svg>
    );
}

export default Cube;
