import React from 'react';

function Notify({ theme }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M17.85 14.2857V14.4357H18H19.85V16.0405H0.15V14.4357H2H2.15V14.2857V7.61905C2.15 5.64056 2.97518 3.74153 4.44659 2.34019C5.91826 0.938606 7.91575 0.15 10 0.15C12.0842 0.15 14.0817 0.938606 15.5534 2.34019C17.0248 3.74153 17.85 5.64056 17.85 7.61905V14.2857ZM16 14.4357H16.15V14.2857V7.61905C16.15 6.06132 15.5002 4.56896 14.3461 3.46982C13.1922 2.37091 11.6288 1.75476 10 1.75476C8.37122 1.75476 6.80776 2.37091 5.65391 3.46982C4.49981 4.56896 3.85 6.06132 3.85 7.61905V14.2857V14.4357H4H16ZM12.85 18.2452V19.85H7.15V18.2452H12.85Z" fill={theme === 'dark' ? '#fff' : '#202020'} stroke="white" strokeWidth="0.3"/>
        </svg>
        // <svg
        //     xmlns="http://www.w3.org/2000/svg"
        //     width="18"
        //     height="18"
        //     viewBox="0 0 18 18"
        //     fill="none"
        // >
        //     <path
        //         d="M9 17C9.88265 17 10.6048 16.2615 10.6048 15.359H7.39519C7.39519 16.2615 8.11735 17 9 17ZM13.8144 12.0769V7.97436C13.8144 5.45538 12.5065 3.34667 10.2036 2.78872V2.23077C10.2036 1.54974 9.666 1 9 1C8.334 1 7.79639 1.54974 7.79639 2.23077V2.78872C5.5015 3.34667 4.18556 5.44718 4.18556 7.97436V12.0769L2.58074 13.7179V14.5385H15.4193V13.7179L13.8144 12.0769ZM12.2096 12.8974H5.79037V7.97436C5.79037 5.93949 7.00201 4.28205 9 4.28205C10.998 4.28205 12.2096 5.93949 12.2096 7.97436V12.8974ZM5.45336 2.29641L4.30592 1.12308C2.38014 2.62462 1.11234 4.93846 1 7.5641H2.60481C2.66165 6.52354 2.9473 5.50948 3.4405 4.59745C3.93369 3.68541 4.62172 2.89887 5.45336 2.29641ZM15.3952 7.5641H17C16.8796 4.93846 15.6118 2.62462 13.6941 1.12308L12.5547 2.29641C13.3827 2.90184 14.0677 3.68924 14.5592 4.60079C15.0508 5.51234 15.3364 6.5249 15.3952 7.5641Z"
        //         fill={theme === 'dark' ? '#fff' : '#1c1c1c'}
        //         stroke="white"
        //         strokeWidth="0.3"
        //     />
        // </svg>
    );
}

export default Notify;
