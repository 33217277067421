import React from 'react';

import './Input.css';

import InputWithButtons from './InputWithButtons/InputWithButtons';
import TypeSelection from './InputWithButtons/TypeSelection';

import { useSessionStorage } from 'usehooks-ts';
import { useSelector } from 'react-redux';
import ReducedValue from '../../../../../content/svg/RequestUtils/ReducedValue';

import { RootState } from '../../../../../store/store';

interface InputProps {
    capacity: number;
    setCapacity: React.Dispatch<React.SetStateAction<number>>;
    coneDraft: number;
    setConeDraft: React.Dispatch<React.SetStateAction<number>>;
    request?: any;
}

function Input({ capacity, setCapacity, coneDraft, setConeDraft, request }: InputProps) {
    const capacityChangeValue = 1;
    const coneDraftChangeValue = 1;
    const theme = localStorage.getItem('theme');

    const [coneDraftText, setconeDraftText] = useSessionStorage(
        'coneDraftText',
        'Осадка конуса, см',
    );

    const nomenclatureModility = useSelector(
        (state: RootState) => state.selectedInfo.nomenclatureMobility,
    );

    return (
        <>
            <InputWithButtons
                inputTitle={'Объём, м'}
                inputValue={capacity}
                setInputValue={setCapacity}
                valueToChange={capacityChangeValue}
                minValue={1}
                maxValue={9999}
            />

            <div style={{ marginBottom: '3px' }}></div>

            {request ? (
                request.capacityChanged === true ? (
                    <div className="alarmDiv">
                        <ReducedValue theme={theme} />
                        <span className="alarmTxt">{`Объем был изменен с ${request.lastCapacity} на ${request.capacity}`}</span>
                    </div>
                ) : null
            ) : null}

            <TypeSelection
                coneDraftText={coneDraftText}
                setConeDraft={setConeDraft}
                setconeDraftText={setconeDraftText}
            />

            <InputWithButtons
                inputTitle={coneDraftText}
                inputValue={coneDraft}
                setInputValue={setConeDraft}
                nomenclatureModility={nomenclatureModility}
                valueToChange={coneDraftChangeValue}
                minValue={10}
                maxValue={80}
            />
        </>
    );
}

export default Input;
