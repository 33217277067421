import React from 'react';

function AvatarIcon({ theme }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="51"
            viewBox="0 0 50 51"
            fill="none"
        >
            <path
                d="M50 25.5C50 39.3071 38.8071 50.5 25 50.5C11.1929 50.5 0 39.3071 0 25.5C0 11.6929 11.1929 0.5 25 0.5C38.8071 0.5 50 11.6929 50 25.5Z"
                fill={theme === 'dark' ? '#474747' : '#fff'}
            />
        </svg>
    );
}

export default AvatarIcon;
