import React from 'react';
import { useEffect } from 'react';


function TypeSelection({coneDraftText, setConeDraft, setconeDraftText}) {

    useEffect(() => {
        let current = document.getElementsByClassName("pressed");

        switch(coneDraftText){
            case "Осадка конуса, см":
     
                if (Object.keys(current).length !== 0){
                    current[0].className = current[0].className.replace("pressed", "");
                    document.getElementById(5).classList.add('pressed');
                }
        
                else{
                    document.getElementById(5).classList.add('pressed');
                }
            break;
            case "Расплыв, см":     
                if (Object.keys(current).length !== 0){
                    current[0].className = current[0].className.replace("pressed", "");
                    document.getElementById(6).classList.add('pressed');
                }
        
                else{
                    document.getElementById(6).classList.add('pressed');
                }
            break;
        }
    }, [coneDraftText]);

    function setConeDraftAndRasplyv(id){
        if (id === '5'){
            setConeDraft(18);
            setconeDraftText('Осадка конуса, см')
        }

        else{
            setConeDraft(70);
            setconeDraftText('Расплыв, см')
        }

        let current = document.getElementsByClassName("pressed");
     
        if (Object.keys(current).length !== 0){
            current[0].className = current[0].className.replace("pressed", "");
            document.getElementById(`${id}`).classList.add('pressed');
        }

        else{
            document.getElementById(`${id}`).classList.add('pressed');
        }
    }

    return ( 
    <div className='coneDraftButtons'>
        <button id='5' onClick={(event) => setConeDraftAndRasplyv(event.target.id)} className='coneDraftBtn pressed'>Осадка конуса</button>
        <button id='6' onClick={(event) => setConeDraftAndRasplyv(event.target.id)} className='coneDraftBtn'>Расплыв</button>
    </div>
     );
}

export default TypeSelection;