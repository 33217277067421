import React from 'react';

function Mark({ theme }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <g clipPath="url(#clip0_4749_25609)">
                <path
                    d="M2 2.00012C2 1.46969 2.21071 0.960981 2.58579 0.585909C2.96086 0.210836 3.46957 0.00012207 4 0.00012207L12 0.00012207C12.5304 0.00012207 13.0391 0.210836 13.4142 0.585909C13.7893 0.960981 14 1.46969 14 2.00012V15.5001C14 15.5906 13.9754 15.6793 13.9289 15.7569C13.8824 15.8345 13.8157 15.898 13.736 15.9407C13.6563 15.9834 13.5664 16.0036 13.4761 15.9993C13.3858 15.995 13.2983 15.9662 13.223 15.9161L8 13.1011L2.777 15.9161C2.70171 15.9662 2.61423 15.995 2.52389 15.9993C2.43355 16.0036 2.34373 15.9834 2.264 15.9407C2.18427 15.898 2.1176 15.8345 2.07111 15.7569C2.02462 15.6793 2.00005 15.5906 2 15.5001V2.00012ZM4 1.00012C3.73478 1.00012 3.48043 1.10548 3.29289 1.29302C3.10536 1.48055 3 1.73491 3 2.00012V14.5661L7.723 12.0841C7.80506 12.0295 7.90143 12.0004 8 12.0004C8.09857 12.0004 8.19494 12.0295 8.277 12.0841L13 14.5661V2.00012C13 1.73491 12.8946 1.48055 12.7071 1.29302C12.5196 1.10548 12.2652 1.00012 12 1.00012H4Z"
                    fill={theme === 'dark' ? 'white' : 'black'}
                />
            </g>
            <defs>
                <clipPath id="clip0_4749_25609">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default Mark;
