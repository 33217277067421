import React from 'react';

function OnCheck({ theme }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
            <circle cx="4" cy="4" r="4" fill={theme === 'dark' ? '#78B9FF' : '#007AFF'} />
        </svg>
    );
}

export default OnCheck;
