import React, { useState } from 'react';

import 'swiper/css';

import styles from './TabsPlashkas.module.css';

import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper/modules';
import { format } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import { useRef } from 'react';
import { useEffect } from 'react';
import { setActiveTabsCountDis } from '../../../../../store/slices/sliceRequests';
import { RequestDataType } from '../../../../Pages/Requests/Requests';

import { RootState } from '../../../../../store/store';

interface TabsPlashkasProps {
    index: number;
    setIndex: React.Dispatch<React.SetStateAction<number>>;
    filteredRequests: RequestDataType[];
}

function TabsPlashkas({ index, setIndex, filteredRequests }: TabsPlashkasProps) {
    const selectedDate = useSelector((state: RootState) => state.selectedInfo.selectedDate);
    const tabsCount = useSelector((state: RootState) => state.requests.activeTabsCount);

    //эти переменные не используются, но и в рефе их нет смысла инициализировать
    const dispatch = useDispatch();

    const handleChange = (value: number) => {
        setIndex(value);
        const el = document.querySelector('.swiper-wrapper') as HTMLDivElement;

        if (el) {
            if (value > 2 && tabsCount === 3) {
                el.style.transform = 'translate3d(-40.333px, 0px, 0px)';
            } else if (value === 2 && tabsCount === 4) {
                el.style.transform = 'translate3d(-105.333px, 0px, 0px)';
            } else if (value === 3 && tabsCount === 4) {
                el.style.transform = 'translate3d(-184.333px, 0px, 0px)';
            } else if (value < 2) {
                el.style.transform = 'translate3d(0px, 0px, 0px)';
            }
        }

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        let count = 0;

        if (
            Object.keys(
                [...filteredRequests].filter(
                    (request) =>
                        request.date === format(selectedDate, 'dd.MM.yy') &&
                        request.status === 'Текущая',
                ),
            ).length !== 0
        ) {
            count++;
        }

        if (
            Object.keys(
                [...filteredRequests].filter(
                    (request) =>
                        (request.date === format(selectedDate, 'dd.MM.yy') &&
                            request.status === 'Плановая' &&
                            request.substatus === 'Плановая') ||
                        (request.status === 'Плановая' && request.substatus === 'На проверке'),
                ),
            ).length !== 0
        ) {
            count++;
        }

        if (
            Object.keys(
                [...filteredRequests].filter(
                    (request) =>
                        request.date === format(selectedDate, 'dd.MM.yy') &&
                        request.status === 'Плановая' &&
                        request.substatus === 'Ожидание',
                ),
            ).length !== 0
        ) {
            count++;
        }

        if (
            Object.keys(
                [...filteredRequests].filter(
                    (request) =>
                        request.date === format(selectedDate, 'dd.MM.yy') &&
                        request.status === 'Завершена',
                ),
            ).length !== 0
        ) {
            count++;
        }

        dispatch(setActiveTabsCountDis(count));
    }, [filteredRequests, selectedDate, index]);

    if (tabsCount >= 2) {
        return (
            <div className={styles.tabs}>
                <Swiper
                    slidesPerView={3}
                    spaceBetween={8}
                    freeMode={true}
                    className={styles.tabs}
                    modules={[FreeMode]}
                >
                    {Object.keys(
                        [...filteredRequests].filter(
                            (request) =>
                                (request.date === format(selectedDate, 'dd.MM.yy') &&
                                    request.status === 'Текущая' &&
                                    request.substatus === 'В работе') ||
                                request.substatus === 'На распределении',
                        ),
                    ).length !== 0 && (
                        <SwiperSlide
                            onClick={() => handleChange(0)}
                            className={index === 0 ? styles.card_statusActive : styles.card_status}
                        >
                            <span className={styles.card_statusTxt}>Активные</span>
                            <div className={styles.card_statusCount}>
                                <span className={styles.card_statusCountTxt}>
                                    {
                                        Object.keys(
                                            [...filteredRequests].filter(
                                                (request) =>
                                                    (request.date ===
                                                        format(selectedDate, 'dd.MM.yy') &&
                                                        request.status === 'Текущая' &&
                                                        request.substatus === 'В работе') ||
                                                    request.substatus === 'На распределении',
                                            ),
                                        ).length
                                    }
                                </span>
                            </div>
                        </SwiperSlide>
                    )}
                    {Object.keys(
                        [...filteredRequests].filter(
                            (request) =>
                                request.date === format(selectedDate, 'dd.MM.yy') &&
                                request.status === 'Плановая' &&
                                request.substatus === 'Ожидание',
                        ),
                    ).length !== 0 && (
                        <SwiperSlide
                            onClick={() => handleChange(1)}
                            className={index === 1 ? styles.card_statusActive : styles.card_status}
                        >
                            <span className={styles.card_statusTxt}>В ожидании</span>
                            <div className={styles.card_statusCount}>
                                <span className={styles.card_statusCountTxt}>
                                    {
                                        Object.keys(
                                            [...filteredRequests].filter(
                                                (request) =>
                                                    request.date ===
                                                        format(selectedDate, 'dd.MM.yy') &&
                                                    request.status === 'Плановая' &&
                                                    request.substatus === 'Ожидание',
                                            ),
                                        ).length
                                    }
                                </span>
                            </div>
                        </SwiperSlide>
                    )}
                    {Object.keys(
                        [...filteredRequests].filter(
                            (request) =>
                                (request.date === format(selectedDate, 'dd.MM.yy') &&
                                    request.status === 'Плановая' &&
                                    request.substatus === 'Плановая') ||
                                (request.status === 'Плановая' &&
                                    request.substatus === 'На проверке'),
                        ),
                    ).length !== 0 && (
                        <SwiperSlide
                            onClick={() => handleChange(2)}
                            className={index === 2 ? styles.card_statusActive : styles.card_status}
                        >
                            <span className={styles.card_statusTxt}>Плановые</span>
                            <div className={styles.card_statusCount}>
                                <span className={styles.card_statusCountTxt}>
                                    {
                                        Object.keys(
                                            [...filteredRequests].filter(
                                                (request) =>
                                                    (request.date ===
                                                        format(selectedDate, 'dd.MM.yy') &&
                                                        request.status === 'Плановая' &&
                                                        request.substatus === 'Плановая') ||
                                                    (request.status === 'Плановая' &&
                                                        request.substatus === 'На проверке'),
                                            ),
                                        ).length
                                    }
                                </span>
                            </div>
                        </SwiperSlide>
                    )}
                    {Object.keys(
                        [...filteredRequests].filter(
                            (request) =>
                                request.date === format(selectedDate, 'dd.MM.yy') &&
                                request.status === 'Завершена',
                        ),
                    ).length !== 0 && (
                        <SwiperSlide
                            onClick={() => handleChange(3)}
                            className={index === 3 ? styles.card_statusActive : styles.card_status}
                        >
                            <span className={styles.card_statusTxt}>Завершены</span>
                            <div className={styles.card_statusCount}>
                                <span className={styles.card_statusCountTxt}>
                                    {
                                        Object.keys(
                                            [...filteredRequests].filter(
                                                (request) =>
                                                    request.date ===
                                                        format(selectedDate, 'dd.MM.yy') &&
                                                    request.status === 'Завершена',
                                            ),
                                        ).length
                                    }
                                </span>
                            </div>
                        </SwiperSlide>
                    )}
                    <SwiperSlide className={styles.card_statusHidden}></SwiperSlide>
                </Swiper>
            </div>
        );
    } else {
        return null;
    }
}

export default React.memo(TabsPlashkas);
