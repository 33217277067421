import React, { useRef } from 'react';

import ReactDatePicker from 'react-datepicker';

import { format, subMonths } from 'date-fns';

import styles from './DatePickerAndSearch.module.css';
import Calendar from '../../../../content/svg/RequestsPage/Calendar';
import Loupe from '../../../../content/svg/DocumentsUtils/Loupe';
import Clear from '../../../../content/svg/DocumentsUtils/Clear';

interface DatePickerAndSearchProps {
    setPickerDate: React.Dispatch<React.SetStateAction<Date>>;
    pickerDate: Date;
    searchInput: string;
    setSearchInput: React.Dispatch<React.SetStateAction<string>>;
    dateWithDocuments?: string[];
}

function DatePickerAndSearch({
    setPickerDate,
    pickerDate,
    searchInput,
    setSearchInput,
    dateWithDocuments,
}: DatePickerAndSearchProps) {
    const dateRef = useRef<typeof ReactDatePicker>(null);

    const theme = localStorage.getItem('theme');

    const handleClearField = () => {
        setSearchInput('');

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    };

    return (
        <div className="header">
            <div className={styles.datePicker}>
                <div
                    onClick={() => dateRef.current?.setOpen(true)}
                    className={styles.buttonCalendar}
                >
                    <Calendar theme={theme} style={styles.icon} />

                    <ReactDatePicker
                        onFocus={(event: Event) => (event.target as HTMLInputElement).blur()}
                        ref={dateRef}
                        closeOnScroll={true}
                        popperPlacement="bottom-end"
                        selected={pickerDate}
                        onClickOutside={dateRef.current?.setOpen(false)}
                        onChange={(date: Date) => {
                            setPickerDate(date);
                            dateRef.current?.setOpen(false);
                        }}
                        includeDateIntervals={[
                            { start: subMonths(new Date(), 3), end: new Date() },
                        ]}
                        className={styles.hiddenDate}
                        dayClassName={(date: Date) => {
                            if (dateWithDocuments?.includes(format(date, 'dd.MM.yy'))) {
                                return styles.dateWithDocuments;
                            }
                        }}
                    />
                </div>

                <div className={styles.searchFieldPlaceholderHeader}>
                    <div className="searchFieldPlaceholderHeader">
                        <Loupe theme={theme} />
                        <input
                            type="text"
                            value={searchInput}
                            onChange={(event) => setSearchInput(event.target.value)}
                            placeholder="Поиск по номеру заявки/рейса"
                            className="searchInputHeader"
                        />
                        {searchInput && (
                            <Clear
                                theme={theme}
                                style={{ position: 'absolute', right: '20' }}
                                onClick={handleClearField}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(DatePickerAndSearch);
