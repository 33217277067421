import React, { PropsWithChildren } from 'react';

import './Modal.css';

interface IModalProps {
    active: boolean;
    isConfirming: boolean;
    title: string;
    onExecute?: () => void;
    onClose?: () => void;
    setIsError?: (condiiton: boolean) => void;
}

const Modal = ({
    active,
    title,
    onClose,
    isConfirming,
    children,
    onExecute,
    setIsError,
}: PropsWithChildren<IModalProps>) => {
    if (!active) {
        return null;
    }

    const closeModal = () => {
        if (setIsError) {
            // в компоненте /components/Pages/Requests/ShowRequestInfo/RequestButtons/RequestButtonWaiting/RequestButtonWaiting.tsx не передаётся setIsError
            setIsError(false);
        }
    };

    return (
        <div className="modal" onClick={onClose}>
            <div className="modal__content" onClick={(event) => event.stopPropagation()}>
                <div className="modal__header">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="65"
                        height="65"
                        viewBox="0 0 65 65"
                        fill="none"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M28.6994 6.57969C29.8533 5.90448 31.1626 5.5459 32.5 5.5459C33.8374 5.5459 35.1467 5.90448 36.3006 6.57969C37.4538 7.25446 38.4087 8.21992 39.0821 9.37373L39.0891 9.38572L60.8059 47.0667L60.8256 47.1015C61.4845 48.2874 61.8297 49.6249 61.8333 50.9818C61.837 52.3388 61.499 53.6781 60.8467 54.8676C60.194 56.0576 59.2463 57.0613 58.0884 57.7694C56.9297 58.4781 55.6046 58.8634 54.2459 58.8789L54.2169 58.8792L10.7541 58.8791C9.3954 58.8635 8.07038 58.4781 6.91164 57.7694C5.75369 57.0613 4.80599 56.0576 4.15336 54.8676C3.50102 53.6781 3.16306 52.3388 3.16672 50.9818C3.17037 49.6249 3.51554 48.2874 4.1744 47.1015L4.19411 47.0667L25.9179 9.37371C26.5913 8.2199 27.5463 7.25446 28.6994 6.57969ZM32.5 10.638C32.0748 10.638 31.6512 10.7516 31.2691 10.9752C30.8878 11.1983 30.5568 11.5261 30.3162 11.9363C30.3153 11.938 30.3143 11.9396 30.3133 11.9413L8.61502 49.5902C8.38356 50.0127 8.25784 50.4971 8.2565 50.9956C8.25514 51.4998 8.38116 51.9907 8.61562 52.4182C8.8498 52.8452 9.18082 53.189 9.56629 53.4248C9.94738 53.6578 10.3723 53.7799 10.8001 53.7869H54.1999C54.6278 53.7799 55.0527 53.6578 55.4338 53.4248C55.8192 53.189 56.1502 52.8452 56.3844 52.4182C56.6189 51.9907 56.7449 51.4998 56.7435 50.9956C56.7422 50.4972 56.6165 50.0127 56.3851 49.5903L34.6867 11.9413C34.6857 11.9396 34.6848 11.938 34.6838 11.9363C34.4433 11.5261 34.1122 11.1983 33.7309 10.9752C33.3489 10.7516 32.9253 10.638 32.5 10.638ZM32.5 21.8088C33.9055 21.8088 35.0449 22.9487 35.0449 24.3548V35.0142C35.0449 36.4204 33.9055 37.5603 32.5 37.5603C31.0945 37.5603 29.9551 36.4204 29.9551 35.0142V24.3548C29.9551 22.9487 31.0945 21.8088 32.5 21.8088ZM29.9551 45.6736C29.9551 44.2675 31.0945 43.1276 32.5 43.1276H32.5257C33.9312 43.1276 35.0706 44.2675 35.0706 45.6736C35.0706 47.0798 33.9312 48.2197 32.5257 48.2197H32.5C31.0945 48.2197 29.9551 47.0798 29.9551 45.6736Z"
                            fill="#1C1C1C"
                        />
                    </svg>

                    <div className="modal__title">{title}</div>
                </div>
                <div className="modal__body">{children}</div>
                <div className="modal__footer">
                    {isConfirming ? (
                        <>
                            <button className="modal__button" onClick={onExecute}>
                                Подтвердить
                            </button>
                            <button className="modal__button" onClick={onClose}>
                                Отменить
                            </button>
                        </>
                    ) : (
                        <button className="modal__button" onClick={closeModal}>
                            Закрыть
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default React.memo(Modal);
