import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { sslApi } from '../servises/api';
import { weatherApi } from '../servises/weatherApi';

import sliceSelectedInfo from './slices/sliceSelectedInfo';
import userSlice from './slices/sliceUser';
import sliceDocuments from './slices/sliceDocuments';
import sliceRequests from './slices/sliceRequests';
import bonusesSlice from './slices/sliceBonuses';
import sliceMarks from './slices/sliceMarks';

export const store = configureStore({
    reducer: {
        bonuses: bonusesSlice,
        selectedInfo: sliceSelectedInfo,
        user: userSlice,
        documents: sliceDocuments,
        requests: sliceRequests,
        marks: sliceMarks,
        [sslApi.reducerPath]: sslApi.reducer,
        [weatherApi.reducerPath]: weatherApi.reducer,
    },

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        })
            .concat(sslApi.middleware)
            .concat(weatherApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
