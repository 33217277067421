import React from 'react';

export default function ApplePayCard() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="61"
            height="60"
            viewBox="0 0 61 60"
            fill="none"
        >
            <path
                d="M28.25 22.5H55.75"
                stroke="var(--redA-rebB)"
                strokeWidth="3.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.75 27.5L12.7666 19.6064C14.6643 17.4715 17.3843 16.25 20.2407 16.25H20.75"
                stroke="var(--redA-rebB)"
                strokeWidth="3.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.75 48.7509L19.5 48.7501L29.5 41.2509C29.5 41.2509 31.5228 39.8821 34.5 37.5004C40.75 32.5006 34.5 24.5839 28.25 28.7494C23.1602 32.1414 18.25 35.0009 18.25 35.0009"
                stroke="var(--redA-rebB)"
                strokeWidth="3.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20.75 33.75V17.5C20.75 14.7386 22.9886 12.5 25.75 12.5H50.75C53.5115 12.5 55.75 14.7386 55.75 17.5V32.5C55.75 35.2615 53.5115 37.5 50.75 37.5H34.5"
                stroke="var(--redA-rebB)"
                strokeWidth="3.5"
            />
        </svg>
    );
}
