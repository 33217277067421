import { VerticalSeparator } from '../VerticalSeparator/VerticalSeparator';
import styles from './Choicer.module.sass';
import React from 'react';

interface ChoicerProps {
    choiceList: readonly string[];
    chosen: string;
    setChoice: (index: string) => void;
    disabled?: boolean;
}

export default function Choicer({ choiceList, chosen, setChoice, disabled }: ChoicerProps) {
    return (
        <div className={styles.container}>
            {choiceList.map((value, index) => (
                <div key={index} className={styles.choice}>
                    <div
                        className={
                            value === chosen
                                ? styles.chosen
                                : disabled
                                  ? styles.disabled
                                  : styles.notChosen
                        }
                        onClick={disabled ? undefined : () => setChoice(value)}
                    >
                        {value}
                    </div>
                    {index != choiceList.length - 1 && (
                        <div className={styles.separator}>
                            <VerticalSeparator />
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}
