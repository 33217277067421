import React from 'react';

import styles from './FieldToChoose.module.css';
import ModalOk from '../../../../../content/svg/RequestsPage/ModalOk';
import { useTranslation } from 'react-i18next';

interface FieldToChooseProps {
    fieldValue: string;
    image: JSX.Element;
    currentChoose: boolean;
    onClick: () => void;
}

function FieldToChoose({ fieldValue, image, currentChoose, onClick }: FieldToChooseProps) {
    const { t } = useTranslation();
    return (
        <div onClick={onClick} className={styles.contentFields}>
            <div className={styles.contentTicket}>
                {image}
                <span className={styles.contentTxt}>{t(fieldValue)}</span>
                {currentChoose && <ModalOk theme={localStorage.getItem('theme')} />}
            </div>
        </div>
    );
}

export default FieldToChoose;
