import React from 'react';

import './Settings.module.css';

import Navigation from '../../Utils/Navigation/Navigation';
import Header from '../../Utils/Requests/CreateRequest/Header';
import SettingsContent from '../../Utils/Settings/SettingsContent';

import styles from './Settings.module.css';
import { useTranslation } from 'react-i18next';
import { getCookie } from '../../Entities/Lib/CookieWork';
import { useGetRequestsQuery } from '../../../servises/api';

function Settings() {
    useGetRequestsQuery(getCookie('token'), {
        refetchOnFocus: true,
        skip: !getCookie('token'),
    });
    return (
        <div className={styles.page}>
            <Header headerTitle={'Настройки'} />
            <SettingsContent />
            <Navigation currentSection={'Настройки'} />
        </div>
    );
}

export default Settings;
