import React from 'react';

function Pump() {
    return (
        <svg
            width="135"
            height="66"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 135 66"
            fill="none"
        >
            <circle
                cx="46.806"
                cy="53.9496"
                r="6.18528"
                fill="#ECECEC"
                stroke="#474747"
                strokeWidth="0.8"
            />
            <circle
                cx="63.3629"
                cy="53.9496"
                r="6.18528"
                fill="#ECECEC"
                stroke="#474747"
                strokeWidth="0.8"
            />
            <circle
                cx="89.7911"
                cy="53.9496"
                r="6.18528"
                fill="#ECECEC"
                stroke="#474747"
                strokeWidth="0.8"
            />
            <path
                d="M94.1969 27.0239C93.9991 26.7234 93.6634 26.5425 93.3036 26.5425H85.9492C85.3585 26.5425 84.8797 27.0213 84.8797 27.6119V36.9393V47.3362H91.1903C92.6502 47.3362 94.4299 48.4808 95.6486 50.1811C96.2486 51.0181 96.7486 51.9898 96.9715 53.026H100.888C101.473 53.026 101.95 52.5561 101.958 51.9712L102.053 45.0602V38.9566H90.8114C90.2207 38.9566 89.7419 38.4778 89.7419 37.8872V32.4741C89.7419 31.8835 90.2207 31.4047 90.8114 31.4047H97.0809L94.1969 27.0239Z"
                fill="url(#paint0_linear_375_7372)"
            />
            <path
                d="M84.8797 47.3362C85.9487 47.3362 88.7074 47.3362 91.1903 47.3362M84.8797 47.3362V36.9393V27.6119C84.8797 27.0213 85.3585 26.5425 85.9492 26.5425H93.3036C93.6634 26.5425 93.9991 26.7234 94.1969 27.0239L97.0809 31.4047M84.8797 47.3362H91.1903M102.053 38.9566H90.8114C90.2207 38.9566 89.7419 38.4778 89.7419 37.8872V32.4741C89.7419 31.8835 90.2207 31.4047 90.8114 31.4047H97.0809M102.053 38.9566L97.0809 31.4047M102.053 38.9566V45.0602M96.9715 53.026H100.888C101.473 53.026 101.95 52.5561 101.958 51.9712L102.053 45.0602M96.9715 53.026C96.7486 51.9898 96.2486 51.0181 95.6486 50.1811C94.4299 48.4808 92.6502 47.3362 91.1903 47.3362M96.9715 53.026H33.6246C33.0339 53.026 32.5551 52.5472 32.5551 51.9565V47.254M102.053 45.0602H99.5698"
                stroke="#474747"
                strokeWidth="0.8"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M78.4617 27.4338L78.4643 32.3544L78.467 37.275L81.9412 37.1131V29.43L78.4617 27.4338Z"
                fill="#A7A7A7"
            />
            <path
                d="M37.2533 21.6419C36.7844 21.6647 36.3853 21.9907 36.2692 22.4455L35.3364 26.099C35.2641 26.3823 35.3109 26.6828 35.4659 26.9307L38.7378 32.1618C38.9332 32.4743 39.2758 32.6641 39.6445 32.6641H68.9856C69.6485 32.6641 70.1517 33.261 70.0396 33.9144L69.9595 34.3808C69.8714 34.8942 69.4263 35.2694 68.9055 35.2694H39.8971C39.4017 35.2694 38.9711 35.6096 38.8566 36.0916L38.3808 38.0945C38.2153 38.7911 38.7678 39.4504 39.4826 39.4093L58.1893 38.336L74.0268 37.5073L74.6641 28.969C74.7104 28.3478 74.2181 27.8186 73.5952 27.82L44.4219 27.8856C43.9872 27.8865 43.5952 27.6244 43.4302 27.2223L42.6975 25.4377H75.0473C75.2526 25.4377 75.4536 25.4968 75.6263 25.608L78.4618 27.4338L81.8297 29.6237C81.8909 29.6636 81.9728 29.6264 81.9832 29.5541L83.2592 20.6704C83.3548 20.005 82.82 19.4174 82.1485 19.4502L37.2533 21.6419Z"
                fill="url(#paint1_linear_375_7372)"
            />
            <path
                d="M84.4915 47.3364V36.5425L35.862 39.1396L34.7012 39.2017C33.6397 39.2585 32.8081 40.1358 32.8081 41.1988V47.3364H38.2773H40.3037H84.4915Z"
                fill="url(#paint2_linear_375_7372)"
            />
            <path
                d="M32.5552 51.4174C32.5552 52.5219 33.4506 53.4174 34.5552 53.4174H97.5888C97.3645 52.3812 96.5612 50.7409 95.9576 49.9039C94.7207 48.1887 93.2448 46.95 91.1549 46.95H84.8057H61.6781H59.5131H39.737H37.6881H32.5552V51.4174Z"
                fill="url(#paint3_linear_375_7372)"
            />
            <path
                d="M84.4915 36.5425L35.6217 39.1396L34.4464 39.2022C33.3846 39.2587 32.5527 40.1361 32.5527 41.1993V47.3364"
                stroke="#474747"
                strokeWidth="0.8"
            />
            <path
                d="M98.0535 53.0261H33.6246C33.034 53.0261 32.5552 52.5473 32.5552 51.9567V47.2542"
                stroke="#474747"
                strokeWidth="0.8"
            />
            <circle cx="46.806" cy="53.9493" r="5.78528" fill="#ECECEC" />
            <circle cx="63.3629" cy="53.9493" r="5.78528" fill="#ECECEC" />
            <circle cx="89.7911" cy="53.9493" r="5.78528" fill="#ECECEC" />
            <circle cx="46.806" cy="53.9493" r="3.07092" fill="#9C9C9C" />
            <circle cx="63.3629" cy="53.9493" r="3.07092" fill="#9C9C9C" />
            <circle cx="89.7911" cy="53.9493" r="3.07092" fill="#9C9C9C" />
            <circle cx="39.4354" cy="26.0668" r="2.2782" fill="#828282" />
            <circle cx="79.0775" cy="22.9247" r="2.2782" fill="#828282" />
            <circle cx="71.8177" cy="30.8717" r="1.62073" fill="#828282" />
            <circle cx="41.0207" cy="37.4151" r="1.20856" fill="#828282" />
            <defs>
                <linearGradient
                    id="paint0_linear_375_7372"
                    x1="100.532"
                    y1="26.5425"
                    x2="100.304"
                    y2="53.0323"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A7A7A7" />
                    <stop offset="0.505208" stopColor="#EBEBEB" />
                    <stop offset="0.890625" stopColor="#A7A7A7" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_375_7372"
                    x1="59.3334"
                    y1="14.2437"
                    x2="59.3334"
                    y2="39.4916"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#474747" />
                    <stop offset="0.473944" stopColor="#ECECEC" />
                    <stop offset="0.994792" stopColor="#474747" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_375_7372"
                    x1="60.3257"
                    y1="46.7664"
                    x2="60.3517"
                    y2="38.0487"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.298442" stopColor="#D5D5D5" />
                    <stop offset="1" stopColor="#ECECEC" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_375_7372"
                    x1="65.072"
                    y1="46.95"
                    x2="65.072"
                    y2="58.7705"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#969696" />
                    <stop offset="0.333333" stopColor="#BDBDBD" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default Pump;
