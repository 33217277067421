import React from 'react';

import styles from './GiftStory.module.css';

import { useGetCertificatesQuery } from '../../../../servises/api';
import { format, parseISO } from 'date-fns';

import Header from '../../../Utils/Requests/CreateRequest/Header';

import ozonBig from '../../../../content/CardsImg/ozonBig.png';

import Loading from '../../../Shared/Loadings/Loading/Loading';
import toast from 'react-hot-toast';

import { getCookie } from '../../../Entities/Lib/CookieWork';
import Copy from '../../../../content/svg/BonusesPage/Copy';
import { useNavigate } from 'react-router-dom';

interface CertificateType {
    number: string;
    date: string;
    receivingDate: string;
    code: string;
    nominal: string;
}

function GiftStory() {
    const theme = localStorage.getItem('theme');
    const navigate = useNavigate();

    const { data, isSuccess } = useGetCertificatesQuery(getCookie('token'), {
        skip: !getCookie('token'),
    }) as {
        data: CertificateType[];
        isSuccess: boolean;
    };

    const handleCopy = (certificate: CertificateType) => {
        navigator.clipboard.writeText(certificate.code.toString());

        toast('Вы скопировали код');
    };

    if (isSuccess) {
        if (Object.keys(data).length !== 0) {
            return (
                <>
                    <Header headerTitle={'Мои подарки'} backRedirect={'bonuses'} />

                    <div className={styles.content}>
                        <div className={styles.certificates}>
                            {data.map((certificate) => {
                                return (
                                    <div key={+certificate.number} className={styles.certificate}>
                                        <div className={styles.certificateContent}>
                                            <div className={styles.info}>
                                                <span className={styles.infoTxt}>
                                                    Электронный подарочный сертификат Озон{' '}
                                                </span>
                                                <svg
                                                    onClick={() => navigate('cardManualOzon')}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M1.25 10C1.25 5.16738 5.16738 1.25 10 1.25C14.8326 1.25 18.75 5.16738 18.75 10C18.75 14.8326 14.8326 18.75 10 18.75C5.16738 18.75 1.25 14.8326 1.25 10ZM10.625 11.3131V11.375C10.625 11.5573 10.5526 11.7322 10.4236 11.8611C10.2947 11.9901 10.1198 12.0625 9.9375 12.0625C9.75516 12.0625 9.5803 11.9901 9.45136 11.8611C9.32243 11.7322 9.25 11.5573 9.25 11.375V10.6875C9.25 10.5052 9.32243 10.3303 9.45136 10.2014C9.5803 10.0724 9.75516 10 9.9375 10C11.0745 10 12 9.22656 12 8.28125C12 7.33594 11.0745 6.5625 9.9375 6.5625C8.80055 6.5625 7.875 7.33594 7.875 8.28125V8.625C7.875 8.80734 7.80257 8.9822 7.67364 9.11114C7.5447 9.24007 7.36984 9.3125 7.1875 9.3125C7.00516 9.3125 6.8303 9.24007 6.70136 9.11114C6.57243 8.9822 6.5 8.80734 6.5 8.625V8.28125C6.5 6.57539 8.04172 5.1875 9.9375 5.1875C11.8333 5.1875 13.375 6.57539 13.375 8.28125C13.375 9.77484 12.1925 11.0252 10.625 11.3131ZM9.36457 15.3262C9.53416 15.4395 9.73354 15.5 9.9375 15.5C10.211 15.5 10.4733 15.3913 10.6667 15.198C10.8601 15.0046 10.9688 14.7423 10.9688 14.4688C10.9688 14.2648 10.9083 14.0654 10.795 13.8958C10.6816 13.7262 10.5206 13.5941 10.3321 13.516C10.1437 13.4379 9.93636 13.4175 9.73631 13.4573C9.53627 13.4971 9.35252 13.5953 9.2083 13.7395C9.06407 13.8838 8.96586 14.0675 8.92607 14.2676C8.88627 14.4676 8.9067 14.675 8.98475 14.8634C9.0628 15.0518 9.19498 15.2129 9.36457 15.3262Z"
                                                        fill={
                                                            localStorage.getItem('theme') === 'dark'
                                                                ? '#78B9FF'
                                                                : '#007AFF'
                                                        }
                                                    />
                                                </svg>
                                                <div className={styles.infoPlaceholder}>
                                                    <img src={ozonBig} alt="ozon" />

                                                    <div className={styles.certificateInfo}>
                                                        <div className={styles.sum}>
                                                            <span className={styles.sumTxt}>
                                                                Номинал:
                                                            </span>
                                                            <span className={styles.sumValue}>
                                                                5000 ₽
                                                            </span>
                                                        </div>

                                                        <div className={styles.sum}>
                                                            <span className={styles.sumTxt}>
                                                                Активировать до:
                                                            </span>
                                                            <span className={styles.sumValue}>
                                                                {format(
                                                                    parseISO(certificate.date),
                                                                    'dd.MM.yyyy',
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={styles.activate}>
                                                <span className={styles.activateTxt}>
                                                    Код активации:
                                                </span>

                                                <div className={styles.code}>
                                                    <span
                                                        onClick={() => handleCopy(certificate)}
                                                        className={styles.codeTxt}
                                                    >
                                                        {certificate.code.toString()}
                                                    </span>
                                                    <Copy theme={theme} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                        {/* <div className={styles.certificateSecondGrp}>
                            <div className={styles.certificateContent}>
                                <div className={styles.info}>
                                    <span className={styles.usedCerticficateTxt}>Электронный подарочный сертификат Озон </span>
        
                                    <div className={styles.infoPlaceholder}>
                                        <img style={{opacity: '0.6'}} src={ozonBig} alt="ozon" />
        
                                        <div className={styles.certificateInfo}>
                                            <div className={styles.sum}>
                                                <span className={styles.usedSumTxt}>Номинал:</span>
                                                <span className={styles.usedSumValue}>5000 ₽</span>
                                            </div>
        
                                            <div className={styles.lateActivation}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                                    <g clip-path="url(#clip0_2213_51666)">
                                                        <path d="M8.92477 15.3465C9.80042 15.3477 10.6677 15.1757 11.4766 14.8406C12.2856 14.5055 13.0204 14.0138 13.6388 13.3939C14.2588 12.7755 14.7504 12.0407 15.0855 11.2317C15.4206 10.4227 15.5926 9.5555 15.5914 8.67986C15.5926 7.80421 15.4206 6.93698 15.0855 6.128C14.7504 5.31901 14.2587 4.58422 13.6388 3.96586C13.0204 3.34586 12.2856 2.85419 11.4766 2.51908C10.6677 2.18398 9.80042 2.01205 8.92477 2.01319C8.04913 2.01207 7.1819 2.18401 6.37292 2.51911C5.56393 2.85421 4.82914 3.34588 4.21077 3.96586C3.5908 4.58422 3.09913 5.31901 2.76403 6.128C2.42893 6.93698 2.25699 7.80421 2.25811 8.67986C2.25697 9.5555 2.4289 10.4227 2.764 11.2317C3.09911 12.0407 3.59078 12.7755 4.21077 13.3939C4.82914 14.0138 5.56393 14.5055 6.37292 14.8406C7.1819 15.1757 8.04913 15.3476 8.92477 15.3465Z" stroke={theme === 'dark' ? "#FF8B86" : "#F54941"} stroke-width="1.33333" stroke-linejoin="round"/>
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.92477 13.0131C9.14579 13.0131 9.35775 12.9253 9.51403 12.769C9.67031 12.6127 9.7581 12.4008 9.7581 12.1798C9.7581 11.9588 9.67031 11.7468 9.51403 11.5905C9.35775 11.4342 9.14579 11.3464 8.92477 11.3464C8.70376 11.3464 8.4918 11.4342 8.33552 11.5905C8.17924 11.7468 8.09144 11.9588 8.09144 12.1798C8.09144 12.4008 8.17924 12.6127 8.33552 12.769C8.4918 12.9253 8.70376 13.0131 8.92477 13.0131Z" fill="#F54941"/>
                                                        <path d="M8.92477 4.67993V10.0133" stroke="#F54941" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_2213_51666">
                                                        <rect width="16" height="16" fill="white" transform="translate(0.924774 0.679443)"/>
                                                        </clipPath>
                                                    </defs>
                                                </svg>
        
                                                <div className={styles.lateActivationTxtPlaceholder}>
                                                    <span className={styles.lateActivationTxt}>Истек срок для активации</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <Header headerTitle={'Мои подарки'} backRedirect={'bonuses'} />

                    <div className={styles.contentImg}>
                        <img src={`https://api-erkon.tech/imageNoCards.png`} alt="noCer" />
                        <span className={styles.noCerTxt}>У вас пока нет подарков</span>
                    </div>
                </>
            );
        }
    } else {
        <Loading />;
    }
}

export default GiftStory;
