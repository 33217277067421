import React, { useEffect, useState } from 'react';

import { format } from 'date-fns';

import styles from './PrivacyPolicyAuth.module.css';

export default function PrivacyPolicyAuth() {
    const [scrollPosition, setScrollPosition] = useState(0);
    const height = document.body.scrollHeight - window.innerHeight;

    const seconds = 15;
    const targetTime = Date.now() + seconds * 1000;
    const [timer, setTimer] = useState(format(seconds * 1000, 'mm:ss'));
    const [isTimerRun, setIsTimerRun] = useState(true);

    const getScrollPosition = () => {
        setScrollPosition(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', getScrollPosition);
        return () => {
            window.removeEventListener('scroll', getScrollPosition);
        };
    });

    useEffect(() => {
        const interval = setInterval(() => {
            const currentTime = Date.now();
            const delta = (targetTime - currentTime) / 1000;

            if (delta <= 0) {
                clearInterval(interval);
                setIsTimerRun(false);
            } else {
                setTimer(format(delta * 1000, 'mm:ss'));
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div className={styles.page}>
            <span className={styles.lorem}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Imperdiet dui accumsan sit amet nulla
                facilisi. Viverra vitae congue eu consequat ac. Semper auctor neque vitae tempus
                quam pellentesque nec. Vivamus at augue eget arcu dictum varius. Amet mattis
                vulputate enim nulla. Enim ut sem viverra aliquet eget. Consectetur libero id
                faucibus nisl tincidunt eget nullam non. Nulla facilisi morbi tempus iaculis urna id
                volutpat lacus. Id semper risus in hendrerit gravida rutrum quisque non tellus.
                Dictum varius duis at consectetur lorem donec massa sapien. Vitae tortor condimentum
                lacinia quis vel eros donec ac odio. Tortor pretium viverra suspendisse potenti
                nullam ac tortor vitae. Purus non enim praesent elementum facilisis leo vel. Eget
                sit amet tellus cras adipiscing. Auctor elit sed vulputate mi sit amet mauris.
                Habitant morbi tristique senectus et netus. Viverra nibh cras pulvinar mattis nunc
                sed. In nibh mauris cursus mattis molestie a iaculis. Sit amet massa vitae tortor.
                Quam lacus suspendisse faucibus interdum posuere lorem ipsum dolor. Eget est lorem
                ipsum dolor sit amet consectetur. A arcu cursus vitae congue mauris rhoncus aenean
                vel elit. Dignissim enim sit amet venenatis urna cursus eget nunc scelerisque. At
                auctor urna nunc id. Proin libero nunc consequat interdum varius sit amet mattis
                vulputate. Egestas pretium aenean pharetra magna ac placerat vestibulum. Arcu odio
                ut sem nulla. Enim sit amet venenatis urna cursus eget nunc. Adipiscing enim eu
                turpis egestas pretium aenean pharetra magna. Vitae tortor condimentum lacinia quis
                vel eros donec. Nulla aliquet porttitor lacus luctus accumsan. Velit euismod in
                pellentesque massa placerat duis ultricies. Ultrices eros in cursus turpis massa
                tincidunt. Malesuada nunc vel risus commodo. Egestas fringilla phasellus faucibus
                scelerisque eleifend donec pretium vulputate. Tristique senectus et netus et
                malesuada. Nunc sed velit dignissim sodales ut eu. Odio facilisis mauris sit amet.
                Egestas diam in arcu cursus euismod quis. Mauris pharetra et ultrices neque ornare
                aenean euismod elementum. Pharetra diam sit amet nisl suscipit adipiscing bibendum.
                Ipsum consequat nisl vel pretium lectus quam id. Diam quis enim lobortis scelerisque
                fermentum dui. Dignissim sodales ut eu sem. Lobortis elementum nibh tellus molestie
                nunc non. Interdum varius sit amet mattis vulputate enim nulla. Mattis vulputate
                enim nulla aliquet porttitor. Purus faucibus ornare suspendisse sed nisi. Eu turpis
                egestas pretium aenean pharetra. Turpis nunc eget lorem dolor. Est ante in nibh
                mauris cursus mattis molestie a. Urna id volutpat lacus laoreet non curabitur
                gravida arcu. Arcu cursus vitae congue mauris rhoncus aenean vel. Tempus egestas sed
                sed risus pretium quam vulputate dignissim. Quis imperdiet massa tincidunt nunc
                pulvinar. Velit egestas dui id ornare arcu. Ac felis donec et odio pellentesque.
                Commodo nulla facilisi nullam vehicula ipsum a arcu. Sollicitudin nibh sit amet
                commodo nulla facilisi nullam vehicula ipsum. Nibh mauris cursus mattis molestie a.
                Id ornare arcu odio ut. In dictum non consectetur a erat. Id ornare arcu odio ut
                sem. Nibh ipsum consequat nisl vel pretium lectus quam id. Magna fermentum iaculis
                eu non diam phasellus vestibulum. Lorem mollis aliquam ut porttitor leo a diam
                sollicitudin. Ac turpis egestas sed tempus urna et. Hendrerit dolor magna eget est
                lorem ipsum dolor. Pellentesque habitant morbi tristique senectus et netus et
                malesuada fames. Blandit turpis cursus in hac habitasse platea dictumst quisque.
                Libero enim sed faucibus turpis in eu mi bibendum. Nisl nunc mi ipsum faucibus vitae
                aliquet. Neque ornare aenean euismod elementum nisi quis eleifend quam adipiscing.
                Neque sodales ut etiam sit amet nisl purus. Amet nisl suscipit adipiscing bibendum
                est ultricies integer quis. Nibh tortor id aliquet lectus proin nibh. Faucibus et
                molestie ac feugiat sed lectus. Proin nibh nisl condimentum id venenatis. Interdum
                varius sit amet mattis vulputate enim. Eget velit aliquet sagittis id consectetur.
                Sit amet consectetur adipiscing elit. Luctus accumsan tortor posuere ac ut consequat
                semper. A scelerisque purus semper eget duis at tellus at urna. Ipsum suspendisse
                ultrices gravida dictum. Viverra tellus in hac habitasse platea dictumst. Bibendum
                neque egestas congue quisque egestas diam. Feugiat scelerisque varius morbi enim
                nunc faucibus a pellentesque sit. Consectetur a erat nam at lectus. Ut tellus
                elementum sagittis vitae et leo duis ut diam. Suspendisse interdum consectetur
                libero id faucibus nisl tincidunt eget. Consequat interdum varius sit amet mattis.
                Non blandit massa enim nec dui nunc mattis enim ut. Sem viverra aliquet eget sit
                amet tellus cras. Duis ultricies lacus sed turpis. Eget arcu dictum varius duis at.
                Sed viverra tellus in hac habitasse platea dictumst. Nec ullamcorper sit amet risus.
                Feugiat nisl pretium fusce id. Magna fermentum iaculis eu non diam. Blandit aliquam
                etiam erat velit scelerisque in dictum non consectetur. Fringilla phasellus faucibus
                scelerisque eleifend. Ac tortor dignissim convallis aenean et tortor at risus.
                Pellentesque massa placerat duis ultricies lacus sed turpis. Accumsan sit amet nulla
                facilisi morbi tempus. Metus dictum at tempor commodo ullamcorper a. Cras ornare
                arcu dui vivamus arcu felis bibendum ut. Gravida rutrum quisque non tellus orci ac
                auctor augue. Pharetra convallis posuere morbi leo urna molestie. Iaculis nunc sed
                augue lacus viverra. Est velit egestas dui id ornare arcu. Sed sed risus pretium
                quam vulputate dignissim. Amet cursus sit amet dictum sit. Nibh mauris cursus mattis
                molestie a iaculis at erat. Euismod in pellentesque massa placerat duis ultricies.
                Commodo quis imperdiet massa tincidunt nunc pulvinar. Vitae proin sagittis nisl
                rhoncus mattis rhoncus urna neque. Neque laoreet suspendisse interdum consectetur
                libero id faucibus nisl. Ut tellus elementum sagittis vitae et leo. Dignissim
                sodales ut eu sem integer vitae. Sed cras ornare arcu dui. Elementum nisi quis
                eleifend quam adipiscing vitae proin sagittis. Amet nulla facilisi morbi tempus
                iaculis urna id volutpat. Ipsum consequat nisl vel pretium lectus quam id leo in. At
                ultrices mi tempus imperdiet nulla malesuada pellentesque. Habitant morbi tristique
                senectus et netus et. Vestibulum lorem sed risus ultricies tristique nulla aliquet
                enim. Elit pellentesque habitant morbi tristique senectus. Tristique risus nec
                feugiat in fermentum posuere urna. Facilisis gravida neque convallis a cras semper
                auctor neque. Consequat ac felis donec et odio pellentesque diam volutpat. Blandit
                volutpat maecenas volutpat blandit aliquam etiam. Iaculis urna id volutpat lacus
                laoreet. Volutpat consequat mauris nunc congue nisi vitae suscipit. Integer eget
                aliquet nibh praesent tristique magna sit amet purus. Iaculis eu non diam phasellus
                vestibulum lorem sed risus. Turpis egestas maecenas pharetra convallis. Sed viverra
                ipsum nunc aliquet bibendum enim facilisis gravida. Sed blandit libero volutpat sed
                cras ornare arcu dui. Donec massa sapien faucibus et molestie ac. Ut morbi tincidunt
                augue interdum velit euismod in pellentesque.
            </span>
            <div className={styles.accept}>Можно подтвердить {isTimerRun && `через ${timer}`}</div>
            <div className={styles.accept}>
                <input
                    className={styles.checkbox}
                    type="checkbox"
                    name="PrivacyPolicy"
                    id="PrivacyPolicy"
                    disabled={isTimerRun || scrollPosition < height}
                />
                <label htmlFor="PrivacyPolicy" className={styles.label}>
                    Ознакомлен с политикой конфиденциальности
                </label>
            </div>
        </div>
    );
}
