import React from 'react';
import './Interval.css';
import DateObject from 'react-date-object';
import { useRef } from 'react';

function Interval({ interval, ticketIndex, requestUID }) {
    const gapValue = useRef();
    const selectedValue = useRef();

    let time = new DateObject();
    time._format = 'HH:mm';
    time.parse(interval);

    if (time.hour < 1) {
        time = time.getValue('mm');
    } else {
        time = time.format('HH:mm');
    }

    const requestIntervalValue = (interval) => {
        let time = new DateObject();

        time._format = 'HH:mm';
        time.parse(interval);

        if (time.hour < 1) {
            time = time.getValue('mm');
        } else {
            time = time.format('HH:mm');
        }

        if (time <= 5) {
            selectedValue.current = 1;
            gapValue.current = '34px';
        } else if (time <= 10) {
            selectedValue.current = 2;
            gapValue.current = '48px';
        } else if (time <= 15) {
            selectedValue.current = 3;
            gapValue.current = '79px';
        } else if (time <= 20) {
            selectedValue.current = 4;
            gapValue.current = '90px';
        } else if (time <= 25) {
            selectedValue.current = 5;
            gapValue.current = '105px';
        } else if (time <= 30) {
            selectedValue.current = 6;
            gapValue.current = '113px';
        } else if (time <= 35) {
            selectedValue.current = 7;
            gapValue.current = '134px';
        } else if (time <= 40) {
            selectedValue.current = 8;
            gapValue.current = '146px';
        } else if (time <= 45) {
            selectedValue.current = 9;
            gapValue.current = '164px';
        } else if (time <= 50) {
            selectedValue.current = 10;
            gapValue.current = '188px';
        } else if (time <= 55) {
            selectedValue.current = 11;
            gapValue.current = '192px';
        } else {
            selectedValue.current = 11;
            gapValue.current = '199px';
        }
    };

    requestIntervalValue(interval);

    return (
        <>
            <div
                id={requestUID}
                className="interval"
                ref={(element) => {
                    if (element) {
                        element.style.setProperty('--value', gapValue.current);
                    }
                }}
            >
                <span className="intervalTitle">Интервал</span>
                <div className="intervalLine">
                    <hr className="intervalBigLine" />
                    <div className={selectedValue.current === 1 && 'intervalPositionSelected'}>
                        <hr
                            id={`${ticketIndex} ${requestUID} 8`}
                            className="intervalSmallLine"
                            ref={(element) => {
                                if (element) {
                                    if (selectedValue.current === 1) {
                                        {
                                            element.classList.add('selectedInterval');
                                        }
                                    }
                                }
                            }}
                        />
                        {selectedValue.current === 1 && (
                            <span id={ticketIndex} className={'intervalValue'}>
                                {interval.split(':')[0] === '00'
                                    ? `${interval.split(':')[1][0]} мин` === '0'
                                        ? `${interval.split(':')[1][1]} мин`
                                        : `${interval.split(':')[1]} мин`
                                    : interval.split(':')[1] !== '00'
                                      ? `${interval.split(':')[0][1]} ч ${interval.split(':')[1]} м`
                                      : `${interval.split(':')[0][1]} 

                ${
                    interval.split(':')[0][1] === '1'
                        ? 'час'
                        : interval.split(':')[0][1] <= '4'
                          ? 'часа'
                          : 'часов'
                }`}
                            </span>
                        )}
                    </div>
                    <div className={selectedValue.current === 2 && 'intervalPositionSelected'}>
                        <hr
                            id={`${ticketIndex} ${requestUID} 9`}
                            className="intervalBigLine"
                            ref={(element) => {
                                if (element) {
                                    if (selectedValue.current === 2) {
                                        {
                                            element.classList.add('selectedInterval');
                                        }
                                    }
                                }
                            }}
                        />
                        {selectedValue.current === 2 && (
                            <span id={ticketIndex} className={'intervalValue'}>
                                {interval.split(':')[0] === '00'
                                    ? `${interval.split(':')[1][0]} мин` === '0'
                                        ? `${interval.split(':')[1][1]} мин`
                                        : `${interval.split(':')[1]} мин`
                                    : interval.split(':')[1] !== '00'
                                      ? `${interval.split(':')[0][1]} ч ${interval.split(':')[1]} м`
                                      : `${interval.split(':')[0][1]} 

                ${
                    interval.split(':')[0][1] === '1'
                        ? 'час'
                        : interval.split(':')[0][1] <= '4'
                          ? 'часа'
                          : 'часов'
                }`}
                            </span>
                        )}
                    </div>
                    <div className={selectedValue.current === 3 && 'intervalPositionSelected'}>
                        <hr
                            id={`${ticketIndex} ${requestUID} 10`}
                            className="intervalSmallLine"
                            ref={(element) => {
                                if (element) {
                                    if (selectedValue.current === 3) {
                                        {
                                            element.classList.add('selectedInterval');
                                        }
                                    }
                                }
                            }}
                        />
                        {selectedValue.current === 3 && (
                            <span id={ticketIndex} className={'intervalValue'}>
                                {interval.split(':')[0] === '00'
                                    ? `${interval.split(':')[1][0]} мин` === '0'
                                        ? `${interval.split(':')[1][1]} мин`
                                        : `${interval.split(':')[1]} мин`
                                    : interval.split(':')[1] !== '00'
                                      ? `${interval.split(':')[0][1]} ч ${interval.split(':')[1]} м`
                                      : `${interval.split(':')[0][1]} 

                ${
                    interval.split(':')[0][1] === '1'
                        ? 'час'
                        : interval.split(':')[0][1] <= '4'
                          ? 'часа'
                          : 'часов'
                }`}
                            </span>
                        )}
                    </div>
                    <div className={selectedValue.current === 4 && 'intervalPositionSelected'}>
                        <hr
                            id={`${ticketIndex} ${requestUID} 11`}
                            className="intervalBigLine"
                            ref={(element) => {
                                if (element) {
                                    if (selectedValue.current === 4) {
                                        {
                                            element.classList.add('selectedInterval');
                                        }
                                    }
                                }
                            }}
                        />
                        {selectedValue.current === 4 && (
                            <span id={ticketIndex} className={'intervalValue'}>
                                {interval.split(':')[0] === '00'
                                    ? `${interval.split(':')[1][0]} мин` === '0'
                                        ? `${interval.split(':')[1][1]} мин`
                                        : `${interval.split(':')[1]} мин`
                                    : interval.split(':')[1] !== '00'
                                      ? `${interval.split(':')[0][1]} ч ${interval.split(':')[1]} м`
                                      : `${interval.split(':')[0][1]} 

                ${
                    interval.split(':')[0][1] === '1'
                        ? 'час'
                        : interval.split(':')[0][1] <= '4'
                          ? 'часа'
                          : 'часов'
                }`}
                            </span>
                        )}
                    </div>
                    <div className={selectedValue.current === 5 && 'intervalPositionSelected'}>
                        <hr
                            id={`${ticketIndex} ${requestUID} 12`}
                            className="intervalSmallLine"
                            ref={(element) => {
                                if (element) {
                                    if (selectedValue.current === 5) {
                                        {
                                            element.classList.add('selectedInterval');
                                        }
                                    }
                                }
                            }}
                        />
                        {selectedValue.current === 5 && (
                            <span id={ticketIndex} className={'intervalValue'}>
                                {interval.split(':')[0] === '00'
                                    ? `${interval.split(':')[1][0]} мин` === '0'
                                        ? `${interval.split(':')[1][1]} мин`
                                        : `${interval.split(':')[1]} мин`
                                    : interval.split(':')[1] !== '00'
                                      ? `${interval.split(':')[0][1]} ч ${interval.split(':')[1]} м`
                                      : `${interval.split(':')[0][1]} 

                ${
                    interval.split(':')[0][1] === '1'
                        ? 'час'
                        : interval.split(':')[0][1] <= '4'
                          ? 'часа'
                          : 'часов'
                }`}
                            </span>
                        )}
                    </div>
                    <div className={selectedValue.current === 6 && 'intervalPositionSelected'}>
                        <hr
                            id={`${ticketIndex} ${requestUID} 13`}
                            className="intervalBigLine"
                            ref={(element) => {
                                if (element) {
                                    if (selectedValue.current === 6) {
                                        {
                                            element.classList.add('selectedInterval');
                                        }
                                    }
                                }
                            }}
                        />
                        {selectedValue.current === 6 && (
                            <span id={ticketIndex} className={'intervalValue'}>
                                {interval.split(':')[0] === '00'
                                    ? `${interval.split(':')[1][0]} мин` === '0'
                                        ? `${interval.split(':')[1][1]} мин`
                                        : `${interval.split(':')[1]} мин`
                                    : interval.split(':')[1] !== '00'
                                      ? `${interval.split(':')[0][1]} ч ${interval.split(':')[1]} м`
                                      : `${interval.split(':')[0][1]} 

                ${
                    interval.split(':')[0][1] === '1'
                        ? 'час'
                        : interval.split(':')[0][1] <= '4'
                          ? 'часа'
                          : 'часов'
                }`}
                            </span>
                        )}
                    </div>
                    <div className={selectedValue.current === 7 && 'intervalPositionSelected'}>
                        <hr
                            id={`${ticketIndex} ${requestUID} 14`}
                            className="intervalSmallLine"
                            ref={(element) => {
                                if (element) {
                                    if (selectedValue.current === 7) {
                                        {
                                            element.classList.add('selectedInterval');
                                        }
                                    }
                                }
                            }}
                        />
                        {selectedValue.current === 7 && (
                            <span id={ticketIndex} className={'intervalValue'}>
                                {interval.split(':')[0] === '00'
                                    ? `${interval.split(':')[1][0]} мин` === '0'
                                        ? `${interval.split(':')[1][1]} мин`
                                        : `${interval.split(':')[1]} мин`
                                    : interval.split(':')[1] !== '00'
                                      ? `${interval.split(':')[0][1]} ч ${interval.split(':')[1]} м`
                                      : `${interval.split(':')[0][1]} 

                ${
                    interval.split(':')[0][1] === '1'
                        ? 'час'
                        : interval.split(':')[0][1] <= '4'
                          ? 'часа'
                          : 'часов'
                }`}
                            </span>
                        )}
                    </div>
                    <div className={selectedValue.current === 8 && 'intervalPositionSelected'}>
                        <hr
                            id={`${ticketIndex} ${requestUID} 15`}
                            className="intervalBigLine"
                            ref={(element) => {
                                if (element) {
                                    if (selectedValue.current === 8) {
                                        {
                                            element.classList.add('selectedInterval');
                                        }
                                    }
                                }
                            }}
                        />
                        {selectedValue.current === 8 && (
                            <span id={ticketIndex} className={'intervalValue'}>
                                {interval.split(':')[0] === '00'
                                    ? `${interval.split(':')[1][0]} мин` === '0'
                                        ? `${interval.split(':')[1][1]} мин`
                                        : `${interval.split(':')[1]} мин`
                                    : interval.split(':')[1] !== '00'
                                      ? `${interval.split(':')[0][1]} ч ${interval.split(':')[1]} м`
                                      : `${interval.split(':')[0][1]} 

                ${
                    interval.split(':')[0][1] === '1'
                        ? 'час'
                        : interval.split(':')[0][1] <= '4'
                          ? 'часа'
                          : 'часов'
                }`}
                            </span>
                        )}
                    </div>
                    <div className={selectedValue.current === 9 && 'intervalPositionSelected'}>
                        <hr
                            id={`${ticketIndex} ${requestUID} 16`}
                            className="intervalSmallLine"
                            ref={(element) => {
                                if (element) {
                                    if (selectedValue.current === 9) {
                                        {
                                            element.classList.add('selectedInterval');
                                        }
                                    }
                                }
                            }}
                        />
                        {selectedValue.current === 9 && (
                            <span id={ticketIndex} className={'intervalValue'}>
                                {interval.split(':')[0] === '00'
                                    ? `${interval.split(':')[1][0]} мин` === '0'
                                        ? `${interval.split(':')[1][1]} мин`
                                        : `${interval.split(':')[1]} мин`
                                    : interval.split(':')[1] !== '00'
                                      ? `${interval.split(':')[0][1]} ч ${interval.split(':')[1]} м`
                                      : `${interval.split(':')[0][1]} 

                ${
                    interval.split(':')[0][1] === '1'
                        ? 'час'
                        : interval.split(':')[0][1] <= '4'
                          ? 'часа'
                          : 'часов'
                }`}
                            </span>
                        )}
                    </div>
                    <div className={selectedValue.current === 10 && 'intervalPositionSelected'}>
                        <hr
                            id={`${ticketIndex} ${requestUID} 17`}
                            className="intervalBigLine"
                            ref={(element) => {
                                if (element) {
                                    if (selectedValue.current === 10) {
                                        {
                                            element.classList.add('selectedInterval');
                                        }
                                    }
                                }
                            }}
                        />
                        {selectedValue.current === 10 && (
                            <span id={ticketIndex} className={'intervalValue'}>
                                {interval.split(':')[0] === '00'
                                    ? `${interval.split(':')[1][0]} мин` === '0'
                                        ? `${interval.split(':')[1][1]} мин`
                                        : `${interval.split(':')[1]} мин`
                                    : interval.split(':')[1] !== '00'
                                      ? `${interval.split(':')[0][1]} ч ${interval.split(':')[1]} м`
                                      : `${interval.split(':')[0][1]} 

                ${
                    interval.split(':')[0][1] === '1'
                        ? 'час'
                        : interval.split(':')[0][1] <= '4'
                          ? 'часа'
                          : 'часов'
                }`}
                            </span>
                        )}
                    </div>
                    <div className={selectedValue.current === 11 && 'intervalPositionSelected'}>
                        <hr
                            id={`${ticketIndex} ${requestUID} 18`}
                            className="intervalSmallLine"
                            ref={(element) => {
                                if (element) {
                                    if (selectedValue.current === 11) {
                                        {
                                            element.classList.add('selectedInterval');
                                        }
                                    }
                                }
                            }}
                        />
                        {selectedValue.current === 11 && (
                            <span id={ticketIndex} className={'intervalValue'}>
                                {interval.split(':')[0] === '00'
                                    ? `${interval.split(':')[1][0]} мин` === '0'
                                        ? `${interval.split(':')[1][1]} мин`
                                        : `${interval.split(':')[1]} мин`
                                    : interval.split(':')[1] !== '00'
                                      ? `${interval.split(':')[0][1]} ч ${interval.split(':')[1]} м`
                                      : `${interval.split(':')[0][1]} 

                ${
                    interval.split(':')[0][1] === '1'
                        ? 'час'
                        : interval.split(':')[0][1] <= '4'
                          ? 'часа'
                          : 'часов'
                }`}
                            </span>
                        )}
                    </div>
                    <hr className="intervalBigLine" />
                    <hr className="intervalSmallLine" />
                    <hr className="intervalBigLine" />
                    <hr className="intervalSmallLine" />
                </div>
            </div>
        </>
    );
}

export default React.memo(Interval);
