import React from 'react';

import { format, parse } from 'date-fns';

import styles from './RequestDate.module.css';

function RequestDate({ date, isPushes }: { date: string; isPushes: boolean }) {
    const todaysDate = new Date();
    const parsedDate = parse(date, isPushes ? 'dd.MM.yyyy' : 'dd.MM.yy', new Date());

    return (
        <div className={styles.date}>
            <div className={styles.datePlaceholder}>
                <div className={styles.dateTextPlaceholder}>
                    <div className={styles.dateText}>
                        <span className={styles.text}>
                            {format(todaysDate, 'dd') === format(parsedDate, 'dd')
                                ? 'Сегодня'
                                : date}
                        </span>
                    </div>
                </div>
            </div>
            <hr className={styles.dateLine} />
        </div>
    );
}

export default RequestDate;
