import React from 'react';
import Header from '../../../Utils/Requests/CreateRequest/Header';
import styles from './UpdList.module.css';
import { useLocation } from 'react-router-dom';
import { format } from 'date-fns';

export const UpdList = () => {
    const location = useLocation();
    const data = location?.state?.data;
    const statisticUpd = location?.state?.statisticUpd;

    return (
        <>
            <Header headerTitle={`Статистика по УПД (${statisticUpd})`} backRedirect="bonuses" />

            <div className={styles.page}>
                {data?.map((item: any) => (
                    <div key={item.date + item.name + item.summ} className={styles.blockContainer}>
                        <div className={styles.firstBlockContainer}>
                            <span style={{ flex: '1 0 0' }} className={styles.headerTxt}>
                                {`${item.name} (${format(item.date, 'dd.MM/HH:mm')})`}
                            </span>
                            <span className={styles.headerTxt}>
                                {item.summ.toLocaleString('ru-RU', {
                                    style: 'currency',
                                    currency: 'RUB',
                                })}
                            </span>
                        </div>
                        <div className={styles.firstBlockContainer}>
                            <span style={{ flex: '1 0 0' }} className={styles.lowerTxt}>
                                Объем
                            </span>
                            <span className={styles.lowerTxt}>{item.amount} м3</span>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};
