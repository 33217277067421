import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        debug: false,
        // backend: {
        //     projectId: 'fbc48071-00ba-4c8b-9c66-275c28dc7a8e',
        //     apiKey: 'f984fbf7-7418-4717-860e-457da2cb078a',
        // },
        saveMissing: true,
        resources: {
            ru: {
                translation: {
                    Язык: 'Имя',
                    Россия: 'Россия',
                    Турецкий: 'Турецкий',
                    Настройки: 'Настройки',
                    Бонусы: 'Бонусы',
                    Документы: 'Документы',
                    Заявки: 'Заявки',
                    Сбросить: 'Сбросить',
                    Поиск: 'Поиск',
                    адреса: 'адреса',
                },
            },
            tr: {
                translation: {
                    Язык: 'Dil',
                    Россия: 'Rus',
                    Турецкий: 'Türkçe',
                    Настройки: 'Ayarlar',
                    Бонусы: 'Bonuslar',
                    Документы: 'Evrak',
                    Заявки: 'Dilekçeler',
                    Сбросить: 'Sıfırla',
                    адреса: 'adresler',
                },
            },
        },

        fallbackLng: 'ru-RU',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18next;
