import React from 'react';

function DocumentsIcon({ theme, isCurrent }) {
    const fillColor = !isCurrent ? '#B3B3B3' : theme === 'dark' ? '#FFFFFF' : 'black';
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M20 6.69231L14.3819 6.69232L11.2308 3.54116C11.06 3.36899 10.8566 3.23248 10.6326 3.13958C10.4086 3.04668 10.1683 2.99923 9.92577 3.00001H3.84615C3.35652 3.00001 2.88695 3.19451 2.54073 3.54074C2.1945 3.88696 2 4.35653 2 4.84616V19.5326C2.00061 20.0031 2.18777 20.4541 2.52045 20.7868C2.85312 21.1195 3.30415 21.3066 3.77462 21.3072L20.1027 21.3072C20.5649 21.3066 21.008 21.1227 21.3348 20.7959C21.6617 20.4691 21.8455 20.026 21.8462 19.5638V8.53846C21.8462 8.04883 21.6516 7.57925 21.3054 7.23303C20.9592 6.88681 20.4896 6.69231 20 6.69231ZM3.84615 4.84616H9.92577L11.7719 6.69232H3.84615V4.84616ZM20 19.4611L3.84615 19.4611V8.53847L20 8.53846V19.4611Z"
                fill={fillColor}
            />
        </svg>
    );
}

export default DocumentsIcon;
