import React, { useState, useEffect } from 'react';

import './CurrentInfoBlock.css';

import uuid from 'react-uuid';
import BlockCurrent from '../../../../content/svg/RequestUtils/BlockCurrent';
import BlockPlan from '../../../../content/svg/RequestUtils/BlockPlan';
import BlockEnd from '../../../../content/svg/RequestUtils/BlockEnd';

import { RequestDataType } from '../../../Pages/Requests/Requests';

interface CurrentInfoBlockProps {
    filteredRequests: RequestDataType[];
    status: string;
    statusToShow: string;
    substatuses: string[];
}

function CurrentInfoBlock({
    filteredRequests,
    status,
    statusToShow,
    substatuses,
}: CurrentInfoBlockProps) {
    const [totalRequests, setTotalRequests] = useState(0);
    const [totalCapacity, setTotalCapacity] = useState(0);
    const [planCapacity, setPlanCapacity] = useState(0);
    const [factCapacity, setFactCapacity] = useState(0);

    const [totalRequestsReg, setTotalRequestsReg] = useState('');

    const theme = localStorage.getItem('theme');

    const image = (status: string) => {
        if (status === 'Текущая') {
            return <BlockCurrent theme={theme} />;
        } else if (status === 'Плановая') {
            return <BlockPlan theme={theme} />;
        } else if (status === 'Завершена') {
            return <BlockEnd theme={theme} />;
        }
    };

    useEffect(() => {
        if (filteredRequests) {
            const newFiltered = [...filteredRequests].filter(
                (request) => request.status === status && substatuses.includes(request.substatus),
            );

            setTotalRequests(Object.keys(newFiltered).length);

            let totalCapacity = 0;
            let planCapacity = 0;
            let factCapacity = 0;

            newFiltered.map((request) => {
                totalCapacity += request.capacity;
                planCapacity += request.shipped;
                factCapacity += request.distributed;
                return request;
            });

            setTotalCapacity(totalCapacity);
            setPlanCapacity(planCapacity);
            setFactCapacity(factCapacity);
        }
    }, [filteredRequests]);

    useEffect(() => {
        const units = totalRequests % 10;
        if (units === 1) {
            setTotalRequestsReg(`${totalRequests} Заявка`);
        } else if (units === 0) {
            setTotalRequestsReg(`${totalRequests} Заявок`);
        } else if (units < 5) {
            setTotalRequestsReg(`${totalRequests} Заявки`);
        } else {
            setTotalRequestsReg(`${totalRequests} Заявок`);
        }
    }, [totalRequests]);

    return (
        <div key={uuid()} className="infoBlock">
            <div className="requestStatusHeader">
                <div className="statusText">
                    {image(status)}
                    <span className="statusTitle">{statusToShow}</span>
                </div>
                <span>{totalRequestsReg}</span>
            </div>
            <hr className="requestStatusHeader line" />
            {substatuses.map((status: string) => status === 'В работе') && (
                <div className="capacityInfo">
                    <div className="capacityBlock">
                        <span className="capacityTitle">Общий</span>
                        <span className="capacityValue">
                            {`${totalCapacity}м`}
                            <sup className="supMidTxt">3</sup>
                        </span>
                    </div>

                    <hr className="infoBlockLine" />

                    <div className="capacityBlock">
                        <span className="capacityTitle">Размещено</span>
                        <span className="capacityValue">
                            {`${factCapacity}м`}
                            <sup className="supMidTxt">3</sup>
                        </span>
                    </div>
                    <hr className="infoBlockLine" />

                    <div className="capacityBlock">
                        <span className="capacityTitle">Выгружено</span>
                        <span className="capacityValue">
                            {`${planCapacity}м`}
                            <sup className="supMidTxt">3</sup>
                        </span>
                    </div>
                </div>
            )}
        </div>
    );
}

export default CurrentInfoBlock;
