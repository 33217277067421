import * as React from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { useState, useEffect } from 'react';

import uuid from 'react-uuid';

import Header from '../../Utils/Requests/CreateRequest/Header';
import { setIsSearchAvaileble, setSearchInput } from '../../../store/slices/sliceRequests';
import { useGetAddressesQuery, useGetConsigneeQuery } from '../../../servises/api';

import ElementOfTheList from './ElementOfTheList/ElementOfTheList';

function SelectList() {
    const location = useLocation();
    const listType = location?.state?.listType;

    const dispatch = useDispatch();
    dispatch(setIsSearchAvaileble(true));
    dispatch(setSearchInput(''));

    const navigate = useNavigate();

    const searchInput = useSelector((state) => state.requests.searchInput);
    const selectedValues = useSelector((state) => state.bonuses.selectedValues);

    const { data, isSuccess } =
        listType === 'Адреса' ? useGetAddressesQuery('') : useGetConsigneeQuery('');
    const [list, setList] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);

    useEffect(() => {
        setList(data);
    }, [isSuccess]);

    if (isSuccess) {
        // useEffect(() => { пох
        //     list?.filter((listItem) => {
        //         selectedValues.forEach((value) => {
        //             if (!filteredItems.includes(listItem)) {
        //                 if (
        //                     listItem.address_uid ===
        //                     filteredItems.forEach((filteredItem) => {
        //                         if (filteredItem.address_uid === listItem.address_uid) {
        //                             return true;
        //                         } else {
        //                             return false;
        //                         }
        //                     })
        //                 ) {
        //                     // setFilteredItems(filteredItems.slice(searchedIndex, 1));
        //                 }
        //             }

        //             if (listItem.address_uid === value && !filteredItems.includes(listItem)) {
        //                 setFilteredItems((prev) => [listItem, ...prev]);
        //             }
        //         });
        //     });

        // }, [selectedValues]);

        return (
            <>
                <Header
                    headerTitle={listType === 'Адреса' ? 'Адрес' : 'Грузопол-ли'}
                    backRedirect="bonuses/filter"
                />

                <div className="contentContainer">
                    <div className="contentFields">
                        {list
                            ?.filter((ticket) =>
                                ticket?.title
                                    ?.toLowerCase()
                                    .includes(searchInput.toLocaleLowerCase()),
                            )
                            ?.map((ticket, index) => {
                                return (
                                    <ElementOfTheList
                                        index={index}
                                        listType={listType}
                                        key={uuid()}
                                        content={list}
                                        setContent={setList}
                                        ticket={ticket}
                                    />
                                );
                            })}
                    </div>
                </div>

                <div style={{}} className="footerSendButtonAddress">
                    <div className="sendButtonPlaceholder">
                        <div style={{ marginRight: 'auto', marginLeft: 'auto' }}>
                            <span className="footerTxt">Выбрано </span>
                            <span className="footerTxtBold">{selectedValues.length}</span>
                            <span className="footerTxt"> адресов</span>
                        </div>
                        <button onClick={() => navigate(-1)} className="sendButtonActive">
                            Готово
                        </button>
                    </div>
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </>
        );
    }
}

export default SelectList;
