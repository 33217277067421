import React from 'react';

import uuid from 'react-uuid';

import styles from './RequestFieldStatistic.module.css';

import RequestDate from '../RequestDate/RequestDate';
import RequestStatisticUPD from '../RequestsStatistic/RequestStatisticUPD';
import RequestsStatisticOrder from '../RequestsStatistic/RequestsStatisticOrder';
import RequestDateStatistic from '../RequestDate/RequestDateStatistic';

function RequestFieldStatistic({ twoList, isSearchView, startDate, endDate }: any) {
    return (
        <div className={styles.content}>
            <div className={styles.date}>
                <RequestDateStatistic date={twoList.date} />
                {twoList?.updList.length > 0 && (
                    <RequestStatisticUPD
                        startDate={startDate}
                        endDate={endDate}
                        list={twoList}
                        isSearchView={isSearchView}
                        key={uuid()}
                    />
                )}
                {twoList.orderList.map((order: any) => {
                    return (
                        <RequestsStatisticOrder
                            isSearchView={isSearchView}
                            key={uuid()}
                            order={order}
                        />
                    );
                })}
            </div>
        </div>
    );
}

export default React.memo(RequestFieldStatistic);
