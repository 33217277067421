import React, { useEffect } from 'react';
import { useState } from 'react';

import './Documents.css';

import uuid from 'react-uuid';

import { format, subDays, subMonths } from 'date-fns';

import DatePickerAndSearch from '../../Utils/Documents/DatePickerAndSearch/DatePickerAndSearch';
import Navigation from '../../Utils/Navigation/Navigation';
import LoadingDocuments from '../../Shared/Loadings/LoadingDocuments/LoadingDocuments';
import RequestsField from '../../Utils/Documents/RequestField/RequestField';
import { RequestFieldDataType } from '../../Entities/Interfaces/Interfaces';
import Popup from '../../Widgets/Popup/Popup';

import { useGetDeliveryListQuery, useGetUPDListQuery } from '../../../servises/api';

import { getCookie } from '../../Entities/Lib/CookieWork';
import { mobileProrabIntstance } from '../../Entities/Lib/axios';
function Documents() {
    const { data, isSuccess, isFetching } = useGetDeliveryListQuery(getCookie('token'), {
        skip: !getCookie('token'),
        refetchOnFocus: false,
    }) as {
        data: { date: string; data: RequestFieldDataType[] }[];
        isSuccess: boolean;
        isFetching: boolean;
    };

    const documentDates = data?.map((item) => item.date);

    const [pickerDate, setPickerDate] = useState(new Date());

    const [searchInput, setSearchInput] = useState('');
    const [searchedData, setSearchedData] = useState([]);

    useEffect(() => {
        if (isSuccess && Object.keys(searchInput).length >= 3) {
            getSearchList();
        }

        async function getSearchList() {
            const response = await mobileProrabIntstance.post('searchDocs', {
                searchInput: searchInput,
            });

            if (response.status === 200) {
                const data = await response.data;

                if (data) {
                    setSearchedData(data);
                }
            }
        }
    }, [searchInput]);

    if (isSuccess) {
        return (
            <>
                <div className="wrapper">
                    <DatePickerAndSearch
                        searchInput={searchInput}
                        setSearchInput={setSearchInput}
                        setPickerDate={setPickerDate}
                        pickerDate={pickerDate}
                        dateWithDocuments={documentDates}
                    />
                    <div style={{ paddingTop: '80px' }}></div>
                    <div className="list">
                        {Object.keys(searchInput.trim()).length >= 1 && searchedData !== null ? (
                            Object.keys(searchedData).length !== 0 ? (
                                searchedData.map((data) => {
                                    return (
                                        <RequestsField
                                            isSearchView={true}
                                            key={uuid()}
                                            dates={data}
                                        />
                                    );
                                })
                            ) : (
                                <div className="iconPlaceholderXddd">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="115"
                                        height="114"
                                        viewBox="0 0 115 114"
                                        fill="none"
                                    >
                                        <path
                                            d="M93.0469 49.2797V92.5469C93.0469 95.3752 91.9233 98.0876 89.9234 100.088C87.9235 102.087 85.2111 103.211 82.3828 103.211H32.6172C29.7889 103.211 27.0765 102.087 25.0766 100.088C23.0767 98.0876 21.9531 95.3752 21.9531 92.5469V21.4531C21.9531 18.6248 23.0767 15.9124 25.0766 13.9125C27.0765 11.9126 29.7889 10.7891 32.6172 10.7891H54.5563C56.4411 10.7893 58.2488 11.5381 59.5817 12.8708L90.9652 44.2542C92.2978 45.5872 93.0466 47.3948 93.0469 49.2797Z"
                                            stroke={
                                                localStorage.getItem('theme') === 'dark'
                                                    ? '#636367'
                                                    : '#DDDDE6'
                                            }
                                            strokeWidth="7.10938"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M57.5 12.5664V39.2266C57.5 41.1121 58.249 42.9204 59.5823 44.2536C60.9156 45.5869 62.7239 46.3359 64.6094 46.3359H91.2695"
                                            stroke={
                                                localStorage.getItem('theme') === 'dark'
                                                    ? '#636367'
                                                    : '#DDDDE6'
                                            }
                                            strokeWidth="7.10938"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>

                                    <span className="iconTxtxDDD">Документы не найдены</span>
                                </div>
                            )
                        ) : Object.keys(
                              data.filter((data) => data.date === format(pickerDate, 'dd.MM.yy')),
                          ).length !== 0 ? (
                            data
                                ?.filter((data) => data.date === format(pickerDate, 'dd.MM.yy'))
                                .map((date) => {
                                    return <RequestsField key={uuid()} dates={date} />;
                                })
                        ) : (
                            <div className="iconPlaceholderXddd">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="115"
                                    height="114"
                                    viewBox="0 0 115 114"
                                    fill="none"
                                >
                                    <path
                                        d="M93.0469 49.2797V92.5469C93.0469 95.3752 91.9233 98.0876 89.9234 100.088C87.9235 102.087 85.2111 103.211 82.3828 103.211H32.6172C29.7889 103.211 27.0765 102.087 25.0766 100.088C23.0767 98.0876 21.9531 95.3752 21.9531 92.5469V21.4531C21.9531 18.6248 23.0767 15.9124 25.0766 13.9125C27.0765 11.9126 29.7889 10.7891 32.6172 10.7891H54.5563C56.4411 10.7893 58.2488 11.5381 59.5817 12.8708L90.9652 44.2542C92.2978 45.5872 93.0466 47.3948 93.0469 49.2797Z"
                                        stroke={
                                            localStorage.getItem('theme') === 'dark'
                                                ? '#636367'
                                                : '#DDDDE6'
                                        }
                                        strokeWidth="7.10938"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M57.5 12.5664V39.2266C57.5 41.1121 58.249 42.9204 59.5823 44.2536C60.9156 45.5869 62.7239 46.3359 64.6094 46.3359H91.2695"
                                        stroke={
                                            localStorage.getItem('theme') === 'dark'
                                                ? '#636367'
                                                : '#DDDDE6'
                                        }
                                        strokeWidth="7.10938"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>

                                <span className="iconTxtxDDD">
                                    Нет документов от {format(pickerDate, 'dd.MM')}
                                </span>
                            </div>
                        )}
                        <div style={{ paddingTop: '76px' }}></div>
                    </div>
                    <br />
                </div>
                <Popup />
                <Navigation currentSection={'Документы'} />
            </>
        );
    } else if (isFetching) {
        return <LoadingDocuments />;
    }
}

export default Documents;
