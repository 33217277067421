import React from 'react';

import styles from '../../../components/Utils/Settings/MainButtons/MainButtons.module.css';

function Exit() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="20"
            viewBox="0 0 17 20"
            fill="none"
        >
            <g clipPath="url(#clip0_442_1151)">
                <path
                    d="M5.5 1.00012H13.5C14.0304 1.00012 14.5391 1.21084 14.9142 1.58591C15.2893 1.96098 15.5 2.46969 15.5 3.00012V17.0001C15.5 17.5306 15.2893 18.0393 14.9142 18.4143C14.5391 18.7894 14.0304 19.0001 13.5 19.0001H5.5M1.5 10.0001L5.5 6.00012M1.5 10.0001L5.5 14.0001M1.5 10.0001H11.5"
                    className={styles.exitBtnIcon}
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_442_1151">
                    <rect
                        width="16"
                        height="20"
                        fill="white"
                        transform="matrix(-1 0 0 1 16.5 0.00012207)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}

export default Exit;
