import React from 'react';

function BlueRightArrow() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="17"
            viewBox="0 0 11 17"
            fill="none"
        >
            <path
                d="M10.2626 8.85725C10.2626 8.62937 10.1714 8.40155 9.99823 8.24654L2.77039 1.19187C2.61544 1.03693 2.41491 0.945778 2.18706 0.945778C1.71309 0.945778 1.34851 1.3013 1.34851 1.7752C1.34851 2.00309 1.43966 2.2127 1.58549 2.36771L8.72221 9.34945L8.72221 8.37418L1.58549 15.356C1.43966 15.5018 1.34851 15.7114 1.34851 15.9484C1.34851 16.4224 1.71309 16.7778 2.18706 16.7778C2.41491 16.7778 2.61544 16.6867 2.77951 16.5317L9.99823 9.47705C10.1714 9.30387 10.2626 9.09424 10.2626 8.85725Z"
                fill="#78B9FF"
            />
        </svg>
    );
}

export default BlueRightArrow;
