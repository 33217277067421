import React from 'react';

function X({ theme, style, onClick }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            className={style}
            onClick={onClick}
        >
            <g clipPath="url(#clip0_3143_12741)">
                <path
                    d="M14.4001 1.6001L1.6001 14.4001M1.6001 1.6001L14.4001 14.4001"
                    stroke={theme === 'dark' ? 'white' : 'black'}
                    strokeWidth={2.66996}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_3143_12741">
                    <rect width={16} height={16} fill="none" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default X;
