import React from 'react';

function Language({ theme }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
        >
            <path
                d="M1 9.00006C1 13.4185 4.5816 17.0001 9 17.0001C13.4184 17.0001 17 13.4185 17 9.00006C17 4.58166 13.4184 1.00006 9 1.00006C4.5816 1.00006 1 4.58166 1 9.00006Z"
                fill={theme === 'dark' ? '#323232' : 'white'}
                stroke={theme === 'dark' ? 'white' : '#202020'}
                strokeWidth="1.1"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.79991 1.04004C9.79991 1.04004 12.1999 4.20004 12.1999 9.00004C12.1999 13.8 9.79991 16.96 9.79991 16.96M8.19991 16.96C8.19991 16.96 5.79991 13.8 5.79991 9.00004C5.79991 4.20004 8.19991 1.04004 8.19991 1.04004M1.50391 11.8H16.4959H1.50391ZM1.50391 6.20004H16.4959H1.50391Z"
                fill={theme === 'dark' ? '#323232' : 'white'}
            />
            <path
                d="M9.79991 1.04004C9.79991 1.04004 12.1999 4.20004 12.1999 9.00004C12.1999 13.8 9.79991 16.96 9.79991 16.96M8.19991 16.96C8.19991 16.96 5.79991 13.8 5.79991 9.00004C5.79991 4.20004 8.19991 1.04004 8.19991 1.04004M1.50391 11.8H16.4959M1.50391 6.20004H16.4959"
                stroke={theme === 'dark' ? 'white' : '#202020'}
                strokeWidth="1.1"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default Language;
