import React from 'react';

import styles from './LoadingDocuments.module.css';

import Navigation from '../../../Utils/Navigation/Navigation';
import DatePickerAndSearch from '../../../Utils/Documents/DatePickerAndSearch/DatePickerAndSearch';

import { useState } from 'react';

function LoadingDocuments() {
    const [pickerDate, setPickerDate] = useState(new Date());

    return ( 
        <>
        <div className='wrapper'>
            <DatePickerAndSearch
            pickerDate={pickerDate}
            setPickerDate={setPickerDate}
            />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className='list'>
                <div className={styles.content}>
                    <div className={styles.today}>
                        <div className={styles.date}>
                            <hr className={styles.rec37}/>
                        </div>

                        <div className={styles.accordeonPlaceholder}>
                            <div className={styles.accordeonTitle}>
                                <div className={styles.docs_card_not_loaded}>
                                    <div className={styles.name}>
                                        <div className={styles.code}>
                                            <hr className={styles.rec38} />
                                        </div>
                                        <div className={styles.poloskaFrame}>
                                            <hr className={styles.rec47} />
                                        </div>

                                        <hr className={styles.rec50} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.accordeonPlaceholder}>
                            <div className={styles.accordeonTitle}>
                                <div className={styles.docs_card_not_loaded}>
                                    <div className={styles.name}>
                                        <div className={styles.code}>
                                            <hr className={styles.rec38} />
                                        </div>
                                        <div className={styles.poloskaFrame}>
                                            <hr className={styles.rec47} />
                                        </div>

                                        <hr className={styles.rec50} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.accordeonPlaceholder}>
                            <div className={styles.accordeonTitle}>
                                <div className={styles.docs_card_not_loaded}>
                                    <div className={styles.name}>
                                        <div className={styles.code}>
                                            <hr className={styles.rec38} />
                                        </div>
                                        <div className={styles.poloskaFrame}>
                                            <hr className={styles.rec47} />
                                        </div>

                                        <hr className={styles.rec50} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.accordeonPlaceholder}>
                            <div className={styles.accordeonTitle}>
                                <div className={styles.docs_card_not_loaded}>
                                    <div className={styles.name}>
                                        <div className={styles.code}>
                                            <hr className={styles.rec38} />
                                        </div>
                                        <div className={styles.poloskaFrame}>
                                            <hr className={styles.rec47} />
                                        </div>

                                        <hr className={styles.rec50} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.accordeonPlaceholder}>
                            <div className={styles.accordeonTitle}>
                                <div className={styles.docs_card_not_loaded}>
                                    <div className={styles.name}>
                                        <div className={styles.code}>
                                            <hr className={styles.rec38} />
                                        </div>
                                        <div className={styles.poloskaFrame}>
                                            <hr className={styles.rec47} />
                                        </div>

                                        <hr className={styles.rec50} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                <br />
                <br />
        <br />
        </div>
        <Navigation
        currentSection={'Документы'}
        />
        </>
     );
}

export default LoadingDocuments;