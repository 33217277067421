import React, { useRef } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';

import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import { EffectCreative, Pagination, Navigation as SwiperNavigation } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';

import bonusesPageScreenshot from '../../../../content/png/bonuses_page_screenshot.png';
import handWithPhone from '../../../../content/png/handWithPhone.png';
import handWithGiftBox from '../../../../content/png/handWithGiftBox.png';

import UserCard from '../../../../content/svg/BonusesPage/UserCard';
import Clock from '../../../../content/svg/BonusesPage/Clock';
import ApplePayCard from '../../../../content/svg/BonusesPage/ApplePayCard';

import styles from './Manual.module.css';

export default function Manual() {
    const theme = localStorage.getItem('theme');
    const navigate = useNavigate();
    const swiperRef = useRef<SwiperClass | null>(null);
    //todo: дивы с классом slide перемещаем в SwiperSlide
    //todo: не надо прописывать высоту swiperSlide
    return (
        <div className={styles.page}>
            <div className={styles.header}>
                {/* <span onClick={() => navigate('/bonuses')}>Пропустить</span> */}
            </div>

            <div className={[styles.slide, styles.slide1].join(' ')}>
                <img
                    style={{ width: '17.27338rem', height: '23.9375rem' }}
                    src={`https://api-erkon.tech/bonuses_page_screenshot.png`}
                    alt="img"
                />
                <div className={styles.info}>
                    <span className={styles.head}>Долгий срок действия</span>
                    <span className={styles.text}>
                        Год, чтобы активировать карту, и три года, чтобы воспользоваться
                    </span>
                </div>
            </div>
            <div className={[styles.slide, styles.slide2].join(' ')}>
                <img
                    alt="img"
                    src={`https://api-erkon.tech/handWithPhone.png`}
                    style={{ width: '18.4375rem', height: '18.37756rem' }}
                />
                <div className={styles.info}>
                    <span className={styles.head}>Остаток не сгорает</span>
                    <span className={styles.text}>
                        Сертификат можно потратить на одну покупку или оплатить несколько заказов
                    </span>
                </div>
            </div>
            <div className={[styles.slide, styles.slide3].join(' ')}>
                <img
                    alt="img"
                    src={`https://api-erkon.tech/handWithGiftBox.png`}
                    style={{ width: '19.87356rem', height: ' 21.625rem' }}
                />
                <div className={styles.info}>
                    <span className={styles.head}>Остаток не сгорает</span>
                    <span className={styles.text}>
                        Сертификат можно потратить на одну покупку или оплатить несколько заказов
                    </span>
                </div>
            </div>
            <div className={[styles.slide, styles.slide4].join(' ')}>
                <span className={styles.head}>Как использовать сертификат</span>
                <div className={styles.usageManual}>
                    <div className={styles.element}>
                        <div className={styles.icon}>
                            <UserCard />
                        </div>
                        <span>
                            Перейдите в личный кабинет и введите код активации в разделе “Кодовые
                            слова и подарочные сертификаты”.
                        </span>
                    </div>
                    <div className={styles.element}>
                        <div className={styles.icon}>
                            <Clock />
                        </div>
                        <span>
                            Через 10-15 минут после применения кода наминал сертификата начислится
                            на Баланс средств.
                        </span>
                    </div>
                    <div className={styles.element}>
                        <div className={styles.icon}>
                            <ApplePayCard />
                        </div>
                        <span>
                            Чтобы воспользоваться этими средствами, при оформлении заказа выберите
                            способ оплаты ”С моего Баланса средств”
                        </span>
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <div className={styles.pagination}>{/* тут должны быть точки */}</div>
                <div className={styles.buttons}>
                    {/* todo надо заменить true на проверку первого слайда swiperRef.current?.activeIndex*/}
                    {/* {true && (
                        <button
                            className={styles.prevBtn}
                            onClick={() => swiperRef.current?.slidePrev()}
                        >
                            <span>Назад</span>
                        </button>
                    )} */}
                    <button className={styles.nextBtn} onClick={() => navigate(-1)}>
                        <span>Готово</span>
                    </button>
                </div>
            </div>
        </div>
    );
}
