import React from 'react';
import ReactDOM from 'react-dom/client';

import './global.css';
import './colors.css';
import { Provider } from 'react-redux';

import { Toaster } from 'react-hot-toast';

import App from './App';
import { store } from './store/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <Toaster containerStyle={{ zIndex: '9999999', fontFamily: 'Inter' }} />
        <App />
    </Provider>,
);
