import React from 'react';

function SelfDrain() {
    return (
        <svg
            width="135"
            height="66"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 135 66"
            fill="none"
        >
            <path
                d="M85.1893 49.4625C89.7863 51.3391 95.0342 49.1337 96.9107 44.5367L97.376 43.3967L80.7287 36.6012L80.2634 37.7412C78.3869 42.3382 80.5923 47.586 85.1893 49.4625Z"
                fill="url(#paint0_linear_375_7416)"
            />
            <path
                d="M96.9107 44.5367C95.0342 49.1337 89.7863 51.3391 85.1893 49.4625C80.5923 47.586 78.3869 42.3382 80.2634 37.7412L80.7287 36.6012L82.0413 33.3857L78.7161 32.0283L76.9381 36.3838C74.5982 42.1162 76.7459 50.2654 84.145 53.2857C91.544 56.306 98.4445 50.5204 100.784 44.788L97.376 43.3967L96.9107 44.5367Z"
                fill="#ECECEC"
            />
            <path
                d="M76.5677 36.2327C74.6447 40.9437 75.6579 47.2392 80.0163 51.1763C68.4854 46.49 49.8667 38.9201 42.9554 36.0989C40.4656 35.0825 38.5725 32.9004 37.5186 30.422C36.4626 27.9388 36.2758 25.2228 37.1137 23.1702L39.8051 16.5769L78.1945 32.2476L76.5677 36.2327Z"
                fill="url(#paint1_linear_375_7416)"
                stroke="#474747"
                strokeWidth="0.8"
            />
            <path
                d="M101.163 44.9615C99.936 47.9676 96.5616 52.5043 91.8531 53.9284C87.1446 55.3526 81.3549 52.8835 78.6873 49.7743"
                stroke="#474747"
                strokeWidth="0.8"
            />
            <path d="M97.2198 43.7681L80.5725 36.9727" stroke="#474747" strokeWidth="0.8" />
            <path d="M81.8892 33.77L78.189 32.2449" stroke="#474747" strokeWidth="0.8" />
            <path d="M100.834 45.2576L97.134 43.7324" stroke="#474747" strokeWidth="0.8" />
            <path d="M82.3824 33.5362L80.8081 37.5122" stroke="#474747" strokeWidth="0.8" />
            <defs>
                <linearGradient
                    id="paint0_linear_375_7416"
                    x1="90.4066"
                    y1="36.8004"
                    x2="83.744"
                    y2="53.1221"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#989898" />
                    <stop offset="0.229167" stopColor="#C5C5C5" />
                    <stop offset="0.984375" stopColor="#989898" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_375_7416"
                    x1="70.8415"
                    y1="28.814"
                    x2="64.1583"
                    y2="45.186"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.494792" stopColor="#ECECEC" />
                    <stop offset="1" stopColor="#C1C1C1" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default SelfDrain;
