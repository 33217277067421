import { addDays } from 'date-fns';

import toast from 'react-hot-toast';

import { createCookie } from './CookieWork.js';
import { mobileProrabIntstance } from './axios.js';

import { GettingSettingsType } from '../Interfaces/Interfaces.js';

export async function tryGetSettings(token: string) {
    try {
        const responseSettings = await mobileProrabIntstance.post(`getSettings`, '', {
            headers: {
                token: token,
            },
        });

        if (responseSettings.status === 200) {
            const dataSettings = (await responseSettings.data) as GettingSettingsType;

            if (dataSettings) {
                localStorage.setItem('err', 'false');
                localStorage.setItem('advancedMode', JSON.stringify(dataSettings.advancedMode));
                localStorage.setItem('name', dataSettings.name);
                localStorage.setItem('theme', dataSettings.theme);
                localStorage.setItem(
                    'isOnlyOneCar',
                    JSON.stringify(dataSettings.NotificationOfVehicleDeparture),
                );
                localStorage.setItem('isCarArrive', JSON.stringify(dataSettings.carArrival));
                localStorage.setItem('onBoarding', JSON.stringify(dataSettings.onboarding));
                localStorage.setItem('isPushEnabled', JSON.stringify(dataSettings.push));
                localStorage.setItem('isPushEnabled', JSON.stringify(dataSettings?.emailValue));

                document.documentElement.dataset.theme = dataSettings.theme;
            }
        }
    } catch (error) {
        console.log(error);

        toast.error('Ошибка авторизации, попробуйте снова');
    }
}

export async function tryToLogin(code: string, phoneValue: string) {
    try {
        const responseToken = await mobileProrabIntstance.post(`code_verification`, {
            phoneValue: phoneValue.replace(/ /g, ''),
            code: code,
        });
        const data = await responseToken.data;

        if (data.token) {
            createCookie('token', data.token, addDays(new Date(), 7));
            await tryGetSettings(data.token);

            if (localStorage?.getItem('onBoarding') === 'true') {
                window.location.href = '/onBording';
            } else {
                window.location.href = '/logo';
            }
            // await trySendPushToken();
        }
    } catch (error) {
        console.log(error);
        toast.error('Ошибка авторизации, попробуйте снова');
    }
}

export async function tryVerifyPhone(event: any, phoneValue: string) {
    event.preventDefault();
    try {
        const response = await mobileProrabIntstance.post(`auth`, {
            phoneValue: phoneValue,
        });

        if (response.data.result === true) {
            localStorage.setItem('phone', phoneValue);
            // navigate('/code', { state: { phone: phoneValue }, replace: true });
        }
    } catch (error) {
        console.log(error);
        toast.error('Ошибка авторизации, попробуйте снова');
    }
}
