import React from 'react';

function BlockEnd({ theme }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
        >
            <g clipPath="url(#clip0_6_14336)">
                <path
                    d="M5.06573 1.16666H1.1665V12.9062H12.3957V9.82491"
                    stroke={theme === 'dark' ? '#ffffff' : '#1c1c1c'}
                    strokeWidth="2.04167"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.74414 5.76198L7.07338 8.02899L12.9101 2.18753"
                    stroke={theme === 'dark' ? '#ffffff' : '#1c1c1c'}
                    strokeWidth="2.04167"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_6_14336">
                    <rect width="14" height="14" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default BlockEnd;
