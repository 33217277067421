import React, { useRef } from 'react';

import ReactDatePicker from 'react-datepicker';

import { format, subMonths } from 'date-fns';

import styles from './DatePickerAndSearchWithRange.module.css';
import Calendar from '../../../content/svg/RequestsPage/Calendar';
import Loupe from '../../../content/svg/DocumentsUtils/Loupe';
import Clear from '../../../content/svg/DocumentsUtils/Clear';

interface DatePickerAndSearchProps {
    startDate: Date;
    setStartDate: React.Dispatch<React.SetStateAction<Date>>;
    setEndDate: React.Dispatch<React.SetStateAction<Date>>;
    endDate: Date | null;
    searchInput: string;
    setSearchInput: React.Dispatch<React.SetStateAction<string>>;
    dateWithDocuments?: string[];
}

function DatePickerAndSearch({
    startDate,
    setStartDate,
    setEndDate,
    endDate,
    searchInput,
    setSearchInput,
    dateWithDocuments,
}: DatePickerAndSearchProps) {
    const theme = localStorage.getItem('theme');

    const handleClearField = () => {
        setSearchInput('');

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    };

    return (
        <div className="header">
            <div className={styles.datePicker}>
                <div className={styles.buttonCalendar}>
                    <Calendar theme={theme} style={styles.icon} />

                    <ReactDatePicker
                        onFocus={(event: Event) => (event.target as HTMLInputElement).blur()}
                        closeOnScroll={true}
                        popperPlacement="bottom-end"
                        selected={startDate}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        maxDate={new Date()}
                        onChange={(date: Array<Date>) => {
                            const [start, end] = date;
                            setStartDate(start);
                            setEndDate(end);
                        }}
                        className={styles.hiddenDate}
                        dayClassName={(date: Date) => {
                            if (dateWithDocuments?.includes(format(date, 'dd.MM.yy'))) {
                                return styles.dateWithDocuments;
                            }
                        }}
                    />
                </div>

                <div className={styles.searchFieldPlaceholderHeader}>
                    <div className="searchFieldPlaceholderHeader">
                        <Loupe theme={theme} />
                        <input
                            type="text"
                            value={searchInput}
                            onChange={(event) => setSearchInput(event.target.value)}
                            placeholder="Поиск по номеру заявки/рейса"
                            className="searchInputHeader"
                        />
                        {searchInput && (
                            <Clear
                                theme={theme}
                                style={{ position: 'absolute', right: '20' }}
                                onClick={handleClearField}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(DatePickerAndSearch);
