import React from 'react';

import { Link } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import FileChecked from '../../../../../content/svg/DocumentsUtils/FileChecked';
import Letter from '../../../../../content/svg/RequestsPage/Letter';
import { baseType, mobileProrabIntstance } from '../../../../Entities/Lib/axios';
import { setDeliveryTicket, togglePopup } from '../../../../../store/slices/sliceDocuments';
import { getCookie } from '../../../../Entities/Lib/CookieWork';

import styles from './Accordion.module.css';
import toast from 'react-hot-toast';

function Accordion({ deliveryData }: { deliveryData: any }) {
    const theme = localStorage.getItem('theme');
    const dispatch = useDispatch();

    const result = deliveryData.map((deliveryTicket: any, index: number) => {
        return (
            <>
                {index === 0 ? (
                    <hr className={styles.separator} />
                ) : (
                    <hr className={styles.separatorDashhed} />
                )}
                <div key={deliveryTicket.deliveryUID} className={styles.raceBlock}>
                    <div className={styles.raceTicket}>
                        <div className={styles.raceName}>
                            <span className={styles.raceNameTxt}>
                                {deliveryTicket.deliveryNumber}
                            </span>
                            <div className={styles.raceDetails}>
                                <div className={styles.raceAuto}>
                                    <span className={styles.raceAutoTxt}>
                                        {deliveryTicket.ABS.replace(/\s/g, '')}
                                    </span>
                                </div>
                                <div className={styles.raceQty}>
                                    <span className={styles.raceQtyTxt}>
                                        {deliveryTicket.weightTS}м<sup className="supMidTxt">3</sup>
                                    </span>
                                </div>
                                <div className={styles.raceAuto}>
                                    <span className={styles.raceQtyTxt}>
                                        {deliveryTicket.deliveryDate}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={styles.docs}>
                            <Link
                                target="_blank"
                                to={`https://api-erkon.tech/${baseType}/hs/mobileProrab/deliveryDoc/${
                                    deliveryTicket.deliveryUID
                                }/${getCookie('token')}/ТН`}
                                className={styles.base}
                            >
                                <div className={styles.bincihinin}>
                                    <span className={styles.baseTxt}>Транспортная накладная</span>
                                    {deliveryTicket.TNfromArchive && <FileChecked theme={theme} />}
                                </div>

                                <div className={styles.downloadBtn}>
                                    <div className={styles.arrowImg}>
                                        <Letter theme={theme} />
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className={styles.docs}>
                            <div
                                onClick={() => {
                                    dispatch(togglePopup(true));
                                    dispatch(setDeliveryTicket(deliveryTicket.deliveryUID));
                                }}
                                className={styles.base}
                            >
                                <span className={styles.baseTxt}>Паспорт качества</span>
                                <FileChecked theme={theme} />

                                <div className={styles.downloadBtn}>
                                    <div className={styles.arrowImg}>
                                        <Letter theme={theme} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    });

    return <>{result}</>;
}

export default React.memo(Accordion);
