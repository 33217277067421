import React from 'react';
import ReactDOM from 'react-dom';

import styles from './ModalError.module.css';

export default function ModalError({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
    if (!isOpen) return null;
    const root = document.getElementById('root');
    if (!root) return null;

    React.useEffect(() => {
        const timer = setTimeout(() => onClose(), 4000);
        return () => clearTimeout(timer);
    }, []);

    return ReactDOM.createPortal(
        <div className={styles.container} onClick={() => onClose()}>
            <div className={styles.redCircle}>
                <div className={styles.whiteRing}></div>
                <div className={styles.line}></div>
                <span className={styles.text}>
                    Что-то пошло не так,
                    <br />
                    попробуйте снова
                </span>
            </div>
        </div>,
        root,
    );
}
