import React from 'react';

import styles from './AdditionalCapacity.module.css';

import ModalSelect from '../../Utils/Requests/CreateRequest/ModalSelect';
import InputWithButtons from '../../Utils/Requests/CreateRequest/Input/InputWithButtons/InputWithButtons';
import Header from '../../Utils/Requests/CreateRequest/Header';

import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { baseType } from '../../../App';
import { mobileProrabIntstance } from '../../Entities/Lib/axios';
import toast from 'react-hot-toast';

function AdditionalCapacity() {
    const [capacity, setCapacity] = useState(0);

    const location = useLocation();
    const nomenclatureName = location?.state?.nomenclatureName;
    const maximumCapacity = location?.state?.maximumCapacity;
    const request = location?.state?.request;

    const navigate = useNavigate();

    useEffect(() => {
        if (capacity > maximumCapacity) {
            setCapacity(maximumCapacity);
        }
    }, [capacity]);

    async function requestAdditionalCapacity() {
        try {
            const response = await mobileProrabIntstance.post(`additional_capacity`, {
                orderUID: request.order_uid,
                capacity: capacity,
            });

            if (response.status === 200) {
                const data = await response.data;

                if (data === true) {
                    navigate('/successAdditionalCapacity');
                }
            }
        } catch (e) {
            toast.error('Ошибка оформления довоза');
        }
    }

    return (
        <>
            <Header headerTitle={'Оформление довоза'} backRedirect={-1} />
            <div className={styles.content}>
                <span className="titleRequestMenu">Что и сколько</span>
                <ModalSelect
                    selectTitle={'Номенклатура'}
                    selectedInformation={nomenclatureName}
                    isEditable={false}
                />
                <InputWithButtons
                    inputTitle={'Объём, м3'}
                    inputValue={capacity}
                    setInputValue={setCapacity}
                    valueToChange={0.5}
                />
            </div>
            <div style={{ paddingTop: '300px' }} className="footer">
                {capacity > 0 ? (
                    <button
                        style={{ opacity: localStorage.getItem('isOnlyView') === 'true' ? 0.5 : 1 }}
                        disabled={localStorage.getItem('isOnlyView') === 'true'}
                        onClick={() => requestAdditionalCapacity()}
                        className="changeButton"
                    >
                        Оформить довоз
                    </button>
                ) : (
                    <button disabled className="changeButtonDisabled">
                        Оформить довоз
                    </button>
                )}
            </div>
        </>
    );
}

export default AdditionalCapacity;
