import React from 'react';

function Letter({ theme }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                d="M1.59961 11.2V12.8C1.59961 13.2243 1.76818 13.6313 2.06824 13.9313C2.3683 14.2314 2.77526 14.4 3.19961 14.4H12.7996C13.224 14.4 13.6309 14.2314 13.931 13.9313C14.231 13.6313 14.3996 13.2243 14.3996 12.8V11.2"
                stroke={theme === 'dark' ? '#ffffff' : '#202020'}
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 6.40015L8 10.4001M8 10.4001L4 6.40015M8 10.4001L8 0.800146"
                stroke={theme === 'dark' ? '#ffffff' : '#202020'}
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>

        // <svg
        //     xmlns="http://www.w3.org/2000/svg"
        //     width="24"
        //     height="24"
        //     viewBox="0 0 24 24"
        //     fill="none"
        // >
        //     <path
        //         d="M12.4855 15.6812L15.4316 18.6355L12.4773 21.5897L13.6458 22.75L17.7604 18.6355L13.6458 14.5209L12.4855 15.6812ZM18.2459 15.6812L21.192 18.6355L18.2377 21.5897L19.4063 22.75L23.5208 18.6355L19.4063 14.5209L18.2459 15.6812Z"
        //         fill={theme === 'dark' ? '#ffffff' : '#202020'}
        //     />
        //     <path
        //         d="M10.3542 17.8125H2.12508V5.39469L11.531 11.904C11.6688 11.9995 11.8324 12.0507 12.0001 12.0507C12.1677 12.0507 12.3314 11.9995 12.4691 11.904L21.8751 5.39469V12.875H23.5209V4.64583C23.5209 4.20933 23.3475 3.79071 23.0389 3.48205C22.7302 3.1734 22.3116 3 21.8751 3H2.12508C1.68858 3 1.26995 3.1734 0.961301 3.48205C0.652648 3.79071 0.479248 4.20933 0.479248 4.64583V17.8125C0.479248 18.249 0.652648 18.6676 0.961301 18.9763C1.26995 19.2849 1.68858 19.4583 2.12508 19.4583H10.3542V17.8125ZM20.0647 4.64583L12.0001 10.2252L3.9355 4.64583H20.0647Z"
        //         fill={theme === 'dark' ? '#ffffff' : '#202020'}
        //     />
        // </svg>
    );
}

export default Letter;
