import React from 'react';

import styles from './Cars.module.css';

import { useState } from 'react';
import { parseISO, format } from 'date-fns';
import CurrentCar from '../../../../../content/svg/RequestsPage/CurrentCars';
import CurrentCars from '../../../../../content/svg/RequestsPage/CurrentCars';
import DoneCars from '../../../../../content/svg/RequestsPage/DoneCars';

function Cars({ cars, carStatusToShow, setMapState }) {
    const theme = localStorage.getItem('theme');
    const [isOpened, setIsOpened] = useState(Object.keys(cars).length !== 0);

    const tryToCall = (phone_number) => {
        window.location.href = `tel:${phone_number}`;
    };

    function setMap(latitude, longitude) {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        setMapState({ center: [latitude, longitude], zoom: 16 });
    }

    function renderTime(time) {
        if (time === '00:00') {
            return '--:--';
        }

        if (time.split(':')[0] === '00') {
            //проверка на часы
            if (time.split(':')[1][0] === '0' && time.split(':')[1][1] !== '0') {
                //проверка на минуты в единичном виде
                return `${time.split(':')[1][1]} м.`;
            } else {
                return `${time.split(':')[1]} м.`;
            }
        } else {
            if (time.split(':')[1][0] === '0' && time.split(':')[1][1] !== '0') {
                //проверка на минуты в единичном виде
                return `${time.split(':')[0][1]} ч ${time.split(':')[1][1]} м.`;
            } else {
                return `${time.split(':')[0][1]} ч ${time.split(':')[1]} м.`;
            }
        }
    }

    return (
        <div className={styles.activeCards}>
            <div onClick={() => setIsOpened(!isOpened)} className={styles.truckStatusGroup}>
                <div className={styles.info}>
                    <div className={styles.name}>
                        {carStatusToShow === 'текущие рейсы' ? (
                            <CurrentCars theme={theme} />
                        ) : (
                            <DoneCars theme={theme} />
                        )}

                        <span className={styles.nameTxt}>{carStatusToShow}</span>
                    </div>

                    <div className={styles.summmary}>
                        <span className={styles.totalCapacity}>
                            {`${cars.reduce(
                                (totalCapacity, currentValue) =>
                                    totalCapacity + currentValue.capacity,
                                0,
                            )}м`}
                            <sup className="supMidTxt">3</sup>
                        </span>
                        <span className={styles.totalAbs}>{`${Object.keys(cars).length} АБС`}</span>
                    </div>
                    {isOpened ? (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="19"
                            height="11"
                            viewBox="0 0 19 11"
                            fill="none"
                        >
                            <path
                                d="M9.16065 0.0795612C9.41703 0.0795612 9.67331 0.182112 9.8477 0.376974L17.7842 8.5083C17.9585 8.68261 18.0611 8.90821 18.0611 9.16455C18.0611 9.69775 17.6611 10.1079 17.128 10.1079C16.8716 10.1079 16.6358 10.0054 16.4614 9.84131L8.60693 1.8125H9.70411L1.8496 9.84131C1.68554 10.0054 1.4497 10.1079 1.18311 10.1079C0.649902 10.1079 0.25 9.69775 0.25 9.16455C0.25 8.90821 0.35254 8.68261 0.526855 8.49804L8.46338 0.376974C8.65821 0.182112 8.89405 0.0795612 9.16065 0.0795612Z"
                                fill={theme === 'dark' ? '#fff' : '#202020'}
                            />
                        </svg>
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="19"
                            height="11"
                            viewBox="0 0 19 11"
                            fill="none"
                        >
                            <path
                                d="M9.15038 11C8.89401 11 8.63772 10.8975 8.46333 10.7026L0.526821 2.57129C0.352522 2.39697 0.249973 2.17138 0.249973 1.91504C0.249973 1.38183 0.649935 0.971678 1.18307 0.971678C1.43945 0.971678 1.67526 1.07422 1.84965 1.23828L9.7041 9.26709L8.60692 9.26709L16.4614 1.23828C16.6255 1.07422 16.8613 0.97168 17.1279 0.97168C17.6611 0.97168 18.061 1.38184 18.061 1.91504C18.061 2.17138 17.9585 2.39698 17.7842 2.58155L9.84765 10.7026C9.65283 10.8975 9.41699 11 9.15038 11Z"
                                fill={theme === 'dark' ? '#fff' : '#202020'}
                            />
                        </svg>
                    )}
                </div>
            </div>
            {isOpened ? (
                <div className={styles.progress_cards}>
                    {cars
                        .filter((car) => car.status === 'На разгрузке')
                        .map((car) => {
                            return (
                                <div key={car.abs} className={styles.progress_card}>
                                    <div className={styles.progressInfo}>
                                        <div className={styles.progressInfoPlaceholder}>
                                            <div className={styles.progress_graphics}>
                                                <div
                                                    className={styles.progress_graphicsPlaceholder}
                                                >
                                                    <div className={styles.progress_lines}>
                                                        <hr className={styles.grey_line} />
                                                        <hr className={styles.green_line} />
                                                    </div>
                                                    <div className={styles.process_element}>
                                                        <div
                                                            className={
                                                                styles.process_elementPlaceholder
                                                            }
                                                        >
                                                            <span className={styles.timeStatus}>
                                                                {format(
                                                                    parseISO(car.onWay_date),
                                                                    'HH:mm',
                                                                )}
                                                            </span>

                                                            <div className={styles.circle_start}>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="13"
                                                                    height="16"
                                                                    viewBox="0 0 13 16"
                                                                    fill="none"
                                                                >
                                                                    <path
                                                                        d="M0.499871 0.800962L0.499871 15.2012C0.500328 15.347 0.540611 15.4899 0.61638 15.6146C0.692149 15.7392 0.800536 15.8409 0.929876 15.9086C1.05921 15.9763 1.20461 16.0076 1.35041 15.9989C1.4962 15.9903 1.63688 15.9421 1.7573 15.8596L12.1758 8.65951C12.6077 8.36111 12.6077 7.64269 12.1758 7.34349L1.7573 0.143349C1.63713 0.0600078 1.49638 0.0111343 1.35034 0.00203896C1.2043 -0.00705639 1.05856 0.0239743 0.928946 0.0917597C0.799334 0.159545 0.690807 0.261492 0.615161 0.386525C0.539515 0.511558 0.499641 0.654895 0.499871 0.800962ZM10.3117 8.0011L2.10271 13.6748L2.10271 2.32739L10.3117 8.0011Z"
                                                                        fill="white"
                                                                    />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={styles.process_elementCenter}>
                                                        <div
                                                            className={
                                                                styles.process_elementCenterPlaceholder
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    styles.process_elementCenterPlace
                                                                }
                                                            >
                                                                <span className={styles.timeStatus}>
                                                                    {format(
                                                                        parseISO(
                                                                            car.unloading_date,
                                                                        ),
                                                                        'HH:mm',
                                                                    )}
                                                                </span>

                                                                <div
                                                                    className={styles.circle_start}
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="17"
                                                                        height="14"
                                                                        viewBox="0 0 17 14"
                                                                        fill="none"
                                                                    >
                                                                        <path
                                                                            d="M5.30004 10.9053L1.80004 7.40533C1.70852 7.31269 1.59952 7.23914 1.47936 7.18894C1.3592 7.13874 1.23027 7.11289 1.10004 7.11289C0.96982 7.11289 0.840889 7.13874 0.720729 7.18894C0.600568 7.23914 0.491567 7.31269 0.400044 7.40533C0.307405 7.49685 0.233853 7.60585 0.183654 7.72602C0.133454 7.84618 0.107605 7.97511 0.107605 8.10533C0.107605 8.23556 0.133454 8.36449 0.183654 8.48465C0.233853 8.60481 0.307405 8.71381 0.400044 8.80533L4.59004 12.9953C4.98004 13.3853 5.61004 13.3853 6.00004 12.9953L16.6 2.40533C16.6927 2.31381 16.7662 2.20481 16.8164 2.08465C16.8666 1.96448 16.8925 1.83556 16.8925 1.70533C16.8925 1.5751 16.8666 1.44618 16.8164 1.32601C16.7662 1.20585 16.6927 1.09685 16.6 1.00533C16.5085 0.91269 16.3995 0.839139 16.2794 0.788939C16.1592 0.738739 16.0303 0.712891 15.9 0.712891C15.7698 0.712891 15.6409 0.738739 15.5207 0.788939C15.4006 0.839139 15.2916 0.91269 15.2 1.00533L5.30004 10.9053Z"
                                                                            fill="white"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={styles.truckRight}>
                                                        {car.downtime === '00:00' ? (
                                                            <div className={styles.time_current}>
                                                                <span className={styles.timeTruck}>
                                                                    {renderTime(car.unloading_time)}
                                                                </span>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className={
                                                                    styles.time_currentProstoy
                                                                }
                                                            >
                                                                <span
                                                                    className={
                                                                        styles.timeTruckProsoy
                                                                    }
                                                                >
                                                                    {renderTime(car.unloading_time)}
                                                                </span>
                                                            </div>
                                                        )}

                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="40"
                                                            height="27"
                                                            viewBox="0 0 40 27"
                                                            fill="none"
                                                        >
                                                            <rect
                                                                x="1.62762"
                                                                y="7.25635"
                                                                width="3"
                                                                height="11"
                                                                fill="#E9573F"
                                                            />
                                                            <path
                                                                d="M28 18L21.5 18.0059L21.4999 16.9475V10L24.0308 13.1089L28 18Z"
                                                                fill="#434A54"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M12.1274 1.76985C12.6227 1.85291 13.1237 1.94055 13.6274 2.03206L12.1274 1.76985Z"
                                                                fill="#FC6E51"
                                                            />
                                                            <path
                                                                d="M0.127463 7.75112C-0.372672 9.75117 0.627486 11.2515 2.62748 12.2515L7.28202 14.745L8.83967 15.5795L15.323 19.0527L16.6275 19.7515C18.7798 19.1645 20.4153 18.2713 21.6275 17.1991C22.9038 16.0702 23.7109 14.7432 24.1583 13.3664C25.3052 9.83681 24.4037 5.82241 22.4578 4.24502C22.1988 4.03504 21.9212 3.86824 21.6275 3.75152C21.4089 3.69687 21.1873 3.64222 20.9631 3.58766C18.6789 3.03184 16.1187 2.48461 13.6274 2.03206L12.1274 1.76985C8.83967 1.1503 8 1.00008 8 1.00008C8 1.00008 3.5 2.20537e-05 2.12744 2.2567L0.127463 7.75112Z"
                                                                fill="#FC6E51"
                                                            />
                                                            <path
                                                                d="M7.28204 14.7452L12.1275 1.77002C12.6227 1.85308 13.1237 1.94072 13.6275 2.03223L8.83969 15.5796L7.28204 14.7452Z"
                                                                fill="#E9573F"
                                                            />
                                                            <path
                                                                d="M21.6275 3.75176C21.4089 3.69711 21.1874 3.64246 20.9631 3.58789L15.3231 19.0529L16.6275 19.7518L22.4578 4.24525C22.1988 4.03527 21.9212 3.86847 21.6275 3.75176Z"
                                                                fill="#E9573F"
                                                            />
                                                            <rect
                                                                x="1.62762"
                                                                y="17.2563"
                                                                width="25"
                                                                height="5"
                                                                rx="1"
                                                                fill="#CCD1D9"
                                                            />
                                                            <path
                                                                d="M24.6276 22.2563V5.75635H34.6276L39.6276 15.7563V22.2563H24.6276Z"
                                                                fill="#E6E9ED"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M26.1276 7.25635V12.7563H28.1276V7.25635H26.1276ZM29.6276 12.7563H36.1276L33.6276 7.25635H29.6276V12.7563Z"
                                                                fill="#4FC2E9"
                                                            />
                                                            <path
                                                                d="M28.1276 7.25635V12.7563H29.6276V7.25635H28.1276Z"
                                                                fill="#3BAFDA"
                                                            />
                                                            <circle
                                                                cx="7.12762"
                                                                cy="21.7563"
                                                                r="4.5"
                                                                fill="#434A54"
                                                            />
                                                            <circle
                                                                cx="14.1276"
                                                                cy="21.7563"
                                                                r="4.5"
                                                                fill="#434A54"
                                                            />
                                                            <circle
                                                                cx="30.1276"
                                                                cy="21.7563"
                                                                r="4.5"
                                                                fill="#434A54"
                                                            />
                                                            <circle
                                                                cx="7.12762"
                                                                cy="21.7563"
                                                                r="1.5"
                                                                fill="#D9D9D9"
                                                            />
                                                            <circle
                                                                cx="14.1276"
                                                                cy="21.7563"
                                                                r="1.5"
                                                                fill="#D9D9D9"
                                                            />
                                                            <circle
                                                                cx="30.1276"
                                                                cy="21.7563"
                                                                r="1.5"
                                                                fill="#D9D9D9"
                                                            />
                                                        </svg>
                                                    </div>

                                                    <div className={styles.time_on_road}>
                                                        <div
                                                            className={
                                                                styles.time_on_roadPlaceholder
                                                            }
                                                        >
                                                            <span
                                                                className={styles.time_on_roadTxt}
                                                            >
                                                                {renderTime(car.travel_time)}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className={styles.process_elementRight}>
                                                        <div
                                                            className={
                                                                styles.process_elementRightPlaceholder
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    styles.process_elementCenterPlace
                                                                }
                                                            >
                                                                <span className={styles.timeStatus}>
                                                                    {car.end_date === 0
                                                                        ? '--:--'
                                                                        : format(
                                                                              parseISO(
                                                                                  car.end_date,
                                                                              ),
                                                                              'HH:mm',
                                                                          )}
                                                                </span>
                                                                <div
                                                                    className={styles.circeEmpty}
                                                                ></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={styles.statuses}>
                                                    <span className={styles.statusTxt}>В пути</span>
                                                    <span className={styles.statusTxt}>
                                                        На объекте
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {car.downtime !== '00:00' && (
                                        <div className={styles.prostoyPlaceholder}>
                                            <span className={styles.prostoyTxt}>
                                                Начался простой машины!
                                            </span>
                                        </div>
                                    )}

                                    <div className={styles.bottom_info}>
                                        <div className={styles.truck_info}>
                                            <div className={styles.truck_num}>
                                                <span className={styles.amount}>{car.abs}</span>
                                                <span className={styles.naming}>Госномер</span>
                                            </div>
                                            <div className={styles.volume}>
                                                <span className={styles.amount}>
                                                    {`${car.capacity}м`}
                                                    <sup className="supMidTxt">3</sup>
                                                </span>
                                                <span className={styles.naming}>Объем</span>
                                            </div>
                                        </div>
                                        <div className={styles.carButtons}>
                                            <div
                                                onClick={() => setMap(car.latitude, car.longitude)}
                                                className={styles.showOnTheMap}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M12.0933 18.7226L16.2 13.213C16.8816 12.2986 17.25 11.1865 17.25 10.0435C17.25 7.12597 14.8995 4.76087 12 4.76087C9.10051 4.76087 6.75 7.12597 6.75 10.0435C6.75 11.1865 7.11843 12.2986 7.8 13.213L11.9067 18.7226C11.9395 18.7667 11.9705 18.8083 12 18.8477C12.0295 18.8083 12.0605 18.7667 12.0933 18.7226ZM12 3C8.13401 3 5 6.15347 5 10.0435C5 11.5675 5.49125 13.0504 6.4 14.2696L10.5067 19.7791C11.0133 20.4588 11.2666 20.7986 11.5771 20.9202C11.8491 21.0266 12.1509 21.0266 12.4229 20.9202C12.7334 20.7986 12.9867 20.4588 13.4933 19.7791L17.6 14.2696C18.5088 13.0504 19 11.5675 19 10.0435C19 6.15347 15.866 3 12 3Z"
                                                        fill={
                                                            theme === 'dark' ? 'white' : '#F54941'
                                                        }
                                                    />
                                                </svg>
                                            </div>
                                            <div
                                                onClick={() => tryToCall(car.phone_number)}
                                                className={styles.callButton}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M12.2426 16.8492L16.5503 19.5415C17.3401 20.0351 18.366 19.9183 19.0245 19.2598L20.18 18.1043C21.1453 17.139 20.8812 15.5117 19.6602 14.9012L17.6552 13.8987C16.9445 13.5433 16.0908 13.6424 15.4804 14.1511L12.2426 16.8492ZM12.2426 16.8492L11.3571 16.1851C10.0684 15.2185 8.92359 14.0738 7.95707 12.7851L7.29289 11.8995M7.29289 11.8995L4.6006 7.59183C4.10701 6.80208 4.22385 5.77615 4.88238 5.11762L6.03788 3.96212C7.00317 2.99683 8.63045 3.26091 9.24095 4.4819L10.2435 6.48692C10.5988 7.19762 10.4997 8.05129 9.99105 8.66171L7.29289 11.8995Z"
                                                        stroke={
                                                            theme === 'dark' ? 'white' : '#F54941'
                                                        }
                                                        strokeWidth="2"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                    {cars
                        .filter((car) => car.status === 'В пути')
                        .map((car) => {
                            return (
                                <div key={car.abs} className={styles.progress_card}>
                                    <div className={styles.progressInfo}>
                                        <div className={styles.progressInfoPlaceholder}>
                                            <div className={styles.progress_graphics}>
                                                <div
                                                    className={styles.progress_graphicsPlaceholder}
                                                >
                                                    <div className={styles.progress_lines}>
                                                        <hr className={styles.grey_line} />
                                                        <hr className={styles.green_line} />
                                                    </div>

                                                    <div className={styles.process_element}>
                                                        <div
                                                            className={
                                                                styles.process_elementPlaceholder
                                                            }
                                                        >
                                                            <span className={styles.timeStatus}>
                                                                {format(
                                                                    parseISO(car.onWay_date),
                                                                    'HH:mm',
                                                                )}
                                                            </span>

                                                            <div className={styles.circle_start}>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="13"
                                                                    height="16"
                                                                    viewBox="0 0 13 16"
                                                                    fill="none"
                                                                >
                                                                    <path
                                                                        d="M0.499871 0.800962L0.499871 15.2012C0.500328 15.347 0.540611 15.4899 0.61638 15.6146C0.692149 15.7392 0.800536 15.8409 0.929876 15.9086C1.05921 15.9763 1.20461 16.0076 1.35041 15.9989C1.4962 15.9903 1.63688 15.9421 1.7573 15.8596L12.1758 8.65951C12.6077 8.36111 12.6077 7.64269 12.1758 7.34349L1.7573 0.143349C1.63713 0.0600078 1.49638 0.0111343 1.35034 0.00203896C1.2043 -0.00705639 1.05856 0.0239743 0.928946 0.0917597C0.799334 0.159545 0.690807 0.261492 0.615161 0.386525C0.539515 0.511558 0.499641 0.654895 0.499871 0.800962ZM10.3117 8.0011L2.10271 13.6748L2.10271 2.32739L10.3117 8.0011Z"
                                                                        fill="white"
                                                                    />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={styles.truck}>
                                                        <div className={styles.time_current}>
                                                            <span className={styles.timeTruck}>
                                                                {renderTime(car.travel_time)}
                                                            </span>
                                                        </div>

                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="40"
                                                            height="27"
                                                            viewBox="0 0 40 27"
                                                            fill="none"
                                                        >
                                                            <rect
                                                                x="1.62762"
                                                                y="7.25635"
                                                                width="3"
                                                                height="11"
                                                                fill="#E9573F"
                                                            />
                                                            <path
                                                                d="M28 18L21.5 18.0059L21.4999 16.9475V10L24.0308 13.1089L28 18Z"
                                                                fill="#434A54"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M12.1274 1.76985C12.6227 1.85291 13.1237 1.94055 13.6274 2.03206L12.1274 1.76985Z"
                                                                fill="#FC6E51"
                                                            />
                                                            <path
                                                                d="M0.127463 7.75112C-0.372672 9.75117 0.627486 11.2515 2.62748 12.2515L7.28202 14.745L8.83967 15.5795L15.323 19.0527L16.6275 19.7515C18.7798 19.1645 20.4153 18.2713 21.6275 17.1991C22.9038 16.0702 23.7109 14.7432 24.1583 13.3664C25.3052 9.83681 24.4037 5.82241 22.4578 4.24502C22.1988 4.03504 21.9212 3.86824 21.6275 3.75152C21.4089 3.69687 21.1873 3.64222 20.9631 3.58766C18.6789 3.03184 16.1187 2.48461 13.6274 2.03206L12.1274 1.76985C8.83967 1.1503 8 1.00008 8 1.00008C8 1.00008 3.5 2.20537e-05 2.12744 2.2567L0.127463 7.75112Z"
                                                                fill="#FC6E51"
                                                            />
                                                            <path
                                                                d="M7.28204 14.7452L12.1275 1.77002C12.6227 1.85308 13.1237 1.94072 13.6275 2.03223L8.83969 15.5796L7.28204 14.7452Z"
                                                                fill="#E9573F"
                                                            />
                                                            <path
                                                                d="M21.6275 3.75176C21.4089 3.69711 21.1874 3.64246 20.9631 3.58789L15.3231 19.0529L16.6275 19.7518L22.4578 4.24525C22.1988 4.03527 21.9212 3.86847 21.6275 3.75176Z"
                                                                fill="#E9573F"
                                                            />
                                                            <rect
                                                                x="1.62762"
                                                                y="17.2563"
                                                                width="25"
                                                                height="5"
                                                                rx="1"
                                                                fill="#CCD1D9"
                                                            />
                                                            <path
                                                                d="M24.6276 22.2563V5.75635H34.6276L39.6276 15.7563V22.2563H24.6276Z"
                                                                fill="#E6E9ED"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M26.1276 7.25635V12.7563H28.1276V7.25635H26.1276ZM29.6276 12.7563H36.1276L33.6276 7.25635H29.6276V12.7563Z"
                                                                fill="#4FC2E9"
                                                            />
                                                            <path
                                                                d="M28.1276 7.25635V12.7563H29.6276V7.25635H28.1276Z"
                                                                fill="#3BAFDA"
                                                            />
                                                            <circle
                                                                cx="7.12762"
                                                                cy="21.7563"
                                                                r="4.5"
                                                                fill="#434A54"
                                                            />
                                                            <circle
                                                                cx="14.1276"
                                                                cy="21.7563"
                                                                r="4.5"
                                                                fill="#434A54"
                                                            />
                                                            <circle
                                                                cx="30.1276"
                                                                cy="21.7563"
                                                                r="4.5"
                                                                fill="#434A54"
                                                            />
                                                            <circle
                                                                cx="7.12762"
                                                                cy="21.7563"
                                                                r="1.5"
                                                                fill="#D9D9D9"
                                                            />
                                                            <circle
                                                                cx="14.1276"
                                                                cy="21.7563"
                                                                r="1.5"
                                                                fill="#D9D9D9"
                                                            />
                                                            <circle
                                                                cx="30.1276"
                                                                cy="21.7563"
                                                                r="1.5"
                                                                fill="#D9D9D9"
                                                            />
                                                        </svg>
                                                    </div>

                                                    <div className={styles.process_elementCenter}>
                                                        <div
                                                            className={
                                                                styles.process_elementCenterPlaceholder
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    styles.process_elementCenterPlace
                                                                }
                                                            >
                                                                <span className={styles.timeStatus}>
                                                                    {car.unloading_date === 0
                                                                        ? '--:--'
                                                                        : format(
                                                                              parseISO(
                                                                                  car.unloading_date,
                                                                              ),
                                                                              'HH:mm',
                                                                          )}
                                                                </span>
                                                                <div
                                                                    className={styles.circeEmpty}
                                                                ></div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={styles.process_elementRight}>
                                                        <div
                                                            className={
                                                                styles.process_elementRightPlaceholder
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    styles.process_elementCenterPlace
                                                                }
                                                            >
                                                                <span className={styles.timeStatus}>
                                                                    {car.end_date === 0
                                                                        ? '--:--'
                                                                        : format(
                                                                              parseISO(
                                                                                  car.end_date,
                                                                              ),
                                                                              'HH:mm',
                                                                          )}
                                                                </span>
                                                                <div
                                                                    className={styles.circeEmpty}
                                                                ></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={styles.statuses}>
                                                    <span className={styles.statusTxt}>В пути</span>
                                                    <span
                                                        style={{ position: 'relative' }}
                                                        className={styles.statusTxt}
                                                    >
                                                        <div
                                                            className={
                                                                styles.destinationPlaceholder
                                                            }
                                                        >
                                                            <span className={styles.timeStatus}>
                                                                Приедет через
                                                            </span>
                                                            <span className={styles.statusTxt}>
                                                                ~ {car.arrival_time}
                                                            </span>
                                                        </div>
                                                        На объекте
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.bottom_info}>
                                        <div className={styles.truck_info}>
                                            <div className={styles.truck_num}>
                                                <span className={styles.amount}>{car.abs}</span>
                                                <span className={styles.naming}>Госномер</span>
                                            </div>
                                            <div className={styles.volume}>
                                                <span className={styles.amount}>
                                                    {`${car.capacity}м`}
                                                    <sup className="supMidTxt">3</sup>
                                                </span>
                                                <span className={styles.naming}>Объем</span>
                                            </div>
                                        </div>
                                        <div className={styles.carButtons}>
                                            <div
                                                onClick={() => setMap(car.latitude, car.longitude)}
                                                className={styles.showOnTheMap}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M12.0933 18.7226L16.2 13.213C16.8816 12.2986 17.25 11.1865 17.25 10.0435C17.25 7.12597 14.8995 4.76087 12 4.76087C9.10051 4.76087 6.75 7.12597 6.75 10.0435C6.75 11.1865 7.11843 12.2986 7.8 13.213L11.9067 18.7226C11.9395 18.7667 11.9705 18.8083 12 18.8477C12.0295 18.8083 12.0605 18.7667 12.0933 18.7226ZM12 3C8.13401 3 5 6.15347 5 10.0435C5 11.5675 5.49125 13.0504 6.4 14.2696L10.5067 19.7791C11.0133 20.4588 11.2666 20.7986 11.5771 20.9202C11.8491 21.0266 12.1509 21.0266 12.4229 20.9202C12.7334 20.7986 12.9867 20.4588 13.4933 19.7791L17.6 14.2696C18.5088 13.0504 19 11.5675 19 10.0435C19 6.15347 15.866 3 12 3Z"
                                                        fill={
                                                            theme === 'dark' ? 'white' : '#F54941'
                                                        }
                                                    />
                                                </svg>
                                            </div>
                                            <div
                                                onClick={() => tryToCall(car.phone_number)}
                                                className={styles.callButton}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M12.2426 16.8492L16.5503 19.5415C17.3401 20.0351 18.366 19.9183 19.0245 19.2598L20.18 18.1043C21.1453 17.139 20.8812 15.5117 19.6602 14.9012L17.6552 13.8987C16.9445 13.5433 16.0908 13.6424 15.4804 14.1511L12.2426 16.8492ZM12.2426 16.8492L11.3571 16.1851C10.0684 15.2185 8.92359 14.0738 7.95707 12.7851L7.29289 11.8995M7.29289 11.8995L4.6006 7.59183C4.10701 6.80208 4.22385 5.77615 4.88238 5.11762L6.03788 3.96212C7.00317 2.99683 8.63045 3.26091 9.24095 4.4819L10.2435 6.48692C10.5988 7.19762 10.4997 8.05129 9.99105 8.66171L7.29289 11.8995Z"
                                                        stroke={
                                                            theme === 'dark' ? 'white' : '#F54941'
                                                        }
                                                        strokeWidth="2"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                    {cars
                        .filter((car) => car.status === 'Завершен')
                        .map((car) => {
                            return (
                                <div key={car.abs} className={styles.progress_card}>
                                    <div className={styles.progressInfo}>
                                        <div className={styles.progressInfoPlaceholder}>
                                            <div className={styles.progress_graphics}>
                                                <div
                                                    className={styles.progress_graphicsPlaceholder}
                                                >
                                                    <div className={styles.progress_lines}>
                                                        <hr className={styles.grey_line} />
                                                        <hr
                                                            className={styles.green_lineFullWidth}
                                                        />
                                                    </div>

                                                    <div className={styles.process_element}>
                                                        <div
                                                            className={
                                                                styles.process_elementPlaceholder
                                                            }
                                                        >
                                                            <span className={styles.timeStatus}>
                                                                {format(
                                                                    parseISO(car.onWay_date),
                                                                    'HH:mm',
                                                                )}
                                                            </span>

                                                            <div className={styles.circle_start}>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="13"
                                                                    height="16"
                                                                    viewBox="0 0 13 16"
                                                                    fill="none"
                                                                >
                                                                    <path
                                                                        d="M0.499871 0.800962L0.499871 15.2012C0.500328 15.347 0.540611 15.4899 0.61638 15.6146C0.692149 15.7392 0.800536 15.8409 0.929876 15.9086C1.05921 15.9763 1.20461 16.0076 1.35041 15.9989C1.4962 15.9903 1.63688 15.9421 1.7573 15.8596L12.1758 8.65951C12.6077 8.36111 12.6077 7.64269 12.1758 7.34349L1.7573 0.143349C1.63713 0.0600078 1.49638 0.0111343 1.35034 0.00203896C1.2043 -0.00705639 1.05856 0.0239743 0.928946 0.0917597C0.799334 0.159545 0.690807 0.261492 0.615161 0.386525C0.539515 0.511558 0.499641 0.654895 0.499871 0.800962ZM10.3117 8.0011L2.10271 13.6748L2.10271 2.32739L10.3117 8.0011Z"
                                                                        fill="white"
                                                                    />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={styles.process_elementCenter}>
                                                        <div
                                                            className={
                                                                styles.process_elementCenterPlaceholder
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    styles.process_elementCenterPlace
                                                                }
                                                            >
                                                                <span className={styles.timeStatus}>
                                                                    {format(
                                                                        parseISO(
                                                                            car.unloading_date,
                                                                        ),
                                                                        'HH:mm',
                                                                    )}
                                                                </span>

                                                                <div
                                                                    className={styles.circle_start}
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="17"
                                                                        height="14"
                                                                        viewBox="0 0 17 14"
                                                                        fill="none"
                                                                    >
                                                                        <path
                                                                            d="M5.30004 10.9053L1.80004 7.40533C1.70852 7.31269 1.59952 7.23914 1.47936 7.18894C1.3592 7.13874 1.23027 7.11289 1.10004 7.11289C0.96982 7.11289 0.840889 7.13874 0.720729 7.18894C0.600568 7.23914 0.491567 7.31269 0.400044 7.40533C0.307405 7.49685 0.233853 7.60585 0.183654 7.72602C0.133454 7.84618 0.107605 7.97511 0.107605 8.10533C0.107605 8.23556 0.133454 8.36449 0.183654 8.48465C0.233853 8.60481 0.307405 8.71381 0.400044 8.80533L4.59004 12.9953C4.98004 13.3853 5.61004 13.3853 6.00004 12.9953L16.6 2.40533C16.6927 2.31381 16.7662 2.20481 16.8164 2.08465C16.8666 1.96448 16.8925 1.83556 16.8925 1.70533C16.8925 1.5751 16.8666 1.44618 16.8164 1.32601C16.7662 1.20585 16.6927 1.09685 16.6 1.00533C16.5085 0.91269 16.3995 0.839139 16.2794 0.788939C16.1592 0.738739 16.0303 0.712891 15.9 0.712891C15.7698 0.712891 15.6409 0.738739 15.5207 0.788939C15.4006 0.839139 15.2916 0.91269 15.2 1.00533L5.30004 10.9053Z"
                                                                            fill="white"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={styles.time_on_road}>
                                                        <div
                                                            className={
                                                                styles.time_on_roadPlaceholder
                                                            }
                                                        >
                                                            <span
                                                                className={styles.time_on_roadTxt}
                                                            >
                                                                {renderTime(car.travel_time)}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className={styles.time_on_roadRight}>
                                                        <div
                                                            className={
                                                                styles.time_on_roadPlaceholder
                                                            }
                                                        >
                                                            <span
                                                                className={styles.time_on_roadTxt}
                                                            >
                                                                {renderTime(car.unloading_time)}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className={styles.process_elementRight}>
                                                        <div
                                                            className={
                                                                styles.process_elementRightPlaceholder
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    styles.process_elementCenterPlace
                                                                }
                                                            >
                                                                <span className={styles.timeStatus}>
                                                                    {format(
                                                                        parseISO(car.end_date),
                                                                        'HH:mm',
                                                                    )}
                                                                </span>

                                                                <div
                                                                    className={styles.circle_start}
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="22"
                                                                        height="12"
                                                                        viewBox="0 0 22 12"
                                                                        fill="none"
                                                                    >
                                                                        <path
                                                                            d="M15.8697 0.454502C15.7842 0.368758 15.6825 0.300731 15.5706 0.254317C15.4587 0.207902 15.3388 0.184011 15.2176 0.184011C15.0965 0.184011 14.9766 0.207902 14.8647 0.254317C14.7528 0.300731 14.6511 0.368758 14.5656 0.454502L9.34895 5.67111L10.6531 6.97527L15.8697 1.74941C16.2212 1.39793 16.2212 0.805976 15.8697 0.454502ZM19.7914 0.445252L10.6531 9.58357L7.43435 6.37406C7.26141 6.20112 7.02685 6.10396 6.78227 6.10396C6.53769 6.10396 6.30313 6.20112 6.13019 6.37406C5.95725 6.547 5.86009 6.78156 5.86009 7.02614C5.86009 7.27071 5.95725 7.50527 6.13019 7.67821L9.99641 11.5444C10.3571 11.9052 10.9398 11.9052 11.3006 11.5444L21.0956 1.75865C21.1813 1.67309 21.2494 1.57145 21.2958 1.45955C21.3422 1.34766 21.3661 1.22772 21.3661 1.10658C21.3661 0.985442 21.3422 0.865494 21.2958 0.753602C21.2494 0.64171 21.1813 0.540071 21.0956 0.454502H21.0863C21.0029 0.36719 20.9027 0.29753 20.7919 0.249655C20.681 0.201781 20.5616 0.176667 20.4408 0.175804C20.3201 0.174942 20.2003 0.198347 20.0888 0.244633C19.9772 0.290919 19.8761 0.359141 19.7914 0.445252ZM0.904331 7.68746L4.77054 11.5537C5.13127 11.9144 5.71397 11.9144 6.0747 11.5537L6.72215 10.9062L2.20848 6.37406C2.12292 6.28832 2.02128 6.22029 1.90938 6.17388C1.79749 6.12746 1.67754 6.10357 1.55641 6.10357C1.43527 6.10357 1.31532 6.12746 1.20343 6.17388C1.09154 6.22029 0.9899 6.28832 0.904331 6.37406C0.543608 6.73478 0.543608 7.32674 0.904331 7.68746Z"
                                                                            fill="white"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={styles.statuses}>
                                                    <span className={styles.statusTxt}>В пути</span>
                                                    <span className={styles.statusTxt}>
                                                        На объекте
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.bottom_info}>
                                        <div className={styles.truck_info}>
                                            <div className={styles.truck_num}>
                                                <span className={styles.amount}>{car.abs}</span>
                                                <span className={styles.naming}>Госномер</span>
                                            </div>
                                            <div className={styles.volume}>
                                                <span className={styles.amount}>
                                                    {`${car.capacity}м`}
                                                    <sup className="supMidTxt">3</sup>
                                                </span>
                                                <span className={styles.naming}>Объем</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            ) : null}
        </div>
    );
}

export default Cars;
