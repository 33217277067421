import React from 'react';

function RequestsIcon({ theme, isCurrent }) {
    const fillColor = !isCurrent ? '#B3B3B3' : theme === 'dark' ? '#FFFFFF' : 'black';
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 4.18757C4 2.98007 4.98 2.00007 6.1875 2.00007L12.6886 2.00002C13.7644 2.00001 14.6339 2.87683 14.625 3.95257V3.95257V7.31256C14.625 7.48506 14.765 7.62506 14.9375 7.62506H18.2975H18.9914C19.6813 7.62506 20.2408 8.18411 20.2414 8.87408L20.25 19.8126C20.25 20.3927 20.0195 20.9491 19.6093 21.3594C19.1991 21.7696 18.6427 22.0001 18.0625 22.0001H6.1875C5.60734 22.0001 5.05094 21.7696 4.6407 21.3594C4.23047 20.9491 4 20.3927 4 19.8126V4.18757ZM5.96653 3.9666C6.02513 3.90799 6.10462 3.87507 6.1875 3.87507H12.75V7.31256C12.75 7.89273 12.9805 8.44912 13.3907 8.85936C13.8009 9.26959 14.3573 9.50006 14.9375 9.50006H18.375V19.8126C18.375 19.8954 18.3421 19.9749 18.2835 20.0335C18.2249 20.0921 18.1454 20.1251 18.0625 20.1251H6.1875C6.015 20.1251 5.875 19.9851 5.875 19.8126V4.18757C5.875 4.10469 5.90792 4.0252 5.96653 3.9666Z"
                fill={fillColor}
            />
        </svg>
    );
}

export default RequestsIcon;
