import React, { useEffect } from 'react';

import './Navigation.css';

import { useNavigate } from 'react-router-dom';
import RequestsIcon from '../../../content/svg/NavigationIcon/RequestsIcon';
import DocumentsIcon from '../../../content/svg/NavigationIcon/DocumentsIcon';
import BonusesIcon from '../../../content/svg/NavigationIcon/BonusesIcon';
import SettingsIcon from '../../../content/svg/NavigationIcon/SettingsIcon';
import { handleRegisterPush } from '../../../notifications/Notification';
import { useAddRequestMutation, useResetPingMutation } from '../../../servises/api';
import { useTranslation } from 'react-i18next';
import { getCookie } from '../../Entities/Lib/CookieWork';

interface NavigationProps {
    currentSection: 'Заявки' | 'Документы' | 'Бонусы' | 'Настройки';
}

function Navigation({ currentSection }: NavigationProps) {
    const navigate = useNavigate();
    const theme = localStorage.getItem('theme');

    let userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
    const isApple = /iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream;
    const isAndroid = /android/i.test(userAgent);

    const [addRequest] = useAddRequestMutation();
    const [resetPing] = useResetPingMutation();

    const { t } = useTranslation();

    useEffect(() => {
        const tapBar = document.getElementById('tapBar');
        if (isApple) {
            if (tapBar) {
                tapBar.style.setProperty('--height', '4.8rem');
            }
        }

        if (isAndroid) {
            if (tapBar) {
                tapBar.style.setProperty('--height', '3.8rem');
            }
        }
    }, []);

    function setSection(redirectLink: string) {
        handleRegisterPush();
        if (getCookie('token') && currentSection !== 'Заявки') {
            addRequest('');
        }
        resetPing('');

        navigate(`${redirectLink}`);
    }

    return (
        <footer className="tapBar" id="tapBar">
            <div className="navigationBar">
                <div onClick={() => setSection('/requests')} className="navElement">
                    <RequestsIcon theme={theme} isCurrent={currentSection === 'Заявки'} />
                    <span className={currentSection === 'Заявки' ? 'navTitleActive' : 'navTitle'}>
                        {t('Заявки')}
                    </span>
                </div>

                <div onClick={() => setSection('/documents')} className="navElement">
                    <DocumentsIcon theme={theme} isCurrent={currentSection === 'Документы'} />
                    <span
                        className={currentSection === 'Документы' ? 'navTitleActive' : 'navTitle'}
                    >
                        {t('Документы')}
                    </span>
                </div>
                {localStorage.getItem('isOnlyView') === 'false' && (
                    <div
                        className="createRequestButton"
                        onClick={() =>
                            navigate(
                                localStorage?.getItem('IsMarkCreation') === 'false'
                                    ? '/createRequest'
                                    : '/createRequestMarks',
                                { replace: true },
                            )
                        }
                    >
                        <div className="buttonCreateRequest">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="24"
                                viewBox="0 0 25 24"
                                fill="none"
                            >
                                <path
                                    d="M5.58831 13.0883H11.4117V18.9117C11.4117 19.5036 11.8986 20 12.5 20C13.1014 20 13.5883 19.5036 13.5883 18.9117V13.0883H19.4117C20.0036 13.0883 20.5 12.6014 20.5 12C20.5 11.3986 20.0036 10.9117 19.4117 10.9117H13.5883V5.08831C13.5883 4.49642 13.1014 4 12.5 4C11.8986 4 11.4117 4.49642 11.4117 5.08831V10.9117H5.58831C4.99642 10.9117 4.5 11.3986 4.5 12C4.5 12.6014 4.99642 13.0883 5.58831 13.0883Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                    </div>
                )}
                <div onClick={() => setSection('/bonuses')} className="navElement">
                    <BonusesIcon theme={theme} isCurrent={currentSection === 'Бонусы'} />
                    <span className={currentSection === 'Бонусы' ? 'navTitleActive' : 'navTitle'}>
                        {t(
                            localStorage.getItem('isOnlyView') === 'false'
                                ? 'Бонусы'
                                : 'Статистика',
                        )}
                    </span>
                </div>

                <div onClick={() => setSection('/settings')} className="navElement">
                    <SettingsIcon theme={theme} isCurrent={currentSection === 'Настройки'} />
                    <span
                        className={currentSection === 'Настройки' ? 'navTitleActive' : 'navTitle'}
                    >
                        {t('Настройки')}
                    </span>
                </div>
            </div>
        </footer>
    );
}

export default React.memo(Navigation);

//предыдущая версия определения иконок

// {currentSection === 'Заявки' ? (
//     <>
//         <svg
//             xmlns="http://www.w3.org/2000/svg"
//             width="24"
//             height="24"
//             viewBox="0 0 24 24"
//             fill="none"
//         >
//             <path
//                 fillRule="evenodd"
//                 clipRule="evenodd"
//                 d="M4 4.18757C4 2.98007 4.98 2.00007 6.1875 2.00007L12.6886 2.00002C13.7644 2.00001 14.6339 2.87683 14.625 3.95257V3.95257V7.31256C14.625 7.48506 14.765 7.62506 14.9375 7.62506H18.2975H18.9914C19.6813 7.62506 20.2408 8.18411 20.2414 8.87408L20.25 19.8126C20.25 20.3927 20.0195 20.9491 19.6093 21.3594C19.1991 21.7696 18.6427 22.0001 18.0625 22.0001H6.1875C5.60734 22.0001 5.05094 21.7696 4.6407 21.3594C4.23047 20.9491 4 20.3927 4 19.8126V4.18757ZM5.96653 3.9666C6.02513 3.90799 6.10462 3.87507 6.1875 3.87507H12.75V7.31256C12.75 7.89273 12.9805 8.44912 13.3907 8.85936C13.8009 9.26959 14.3573 9.50006 14.9375 9.50006H18.375V19.8126C18.375 19.8954 18.3421 19.9749 18.2835 20.0335C18.2249 20.0921 18.1454 20.1251 18.0625 20.1251H6.1875C6.015 20.1251 5.875 19.9851 5.875 19.8126V4.18757C5.875 4.10469 5.90792 4.0252 5.96653 3.9666Z"
//                 fill={theme === 'dark' ? '#FFFFFF' : 'black'}
//             />
//         </svg>
//         <span className="navTitleActive">{'Заявки'}</span>
//     </>
// ) : (
//     <>
//         <svg
//             xmlns="http://www.w3.org/2000/svg"
//             width="24"
//             height="24"
//             viewBox="0 0 24 24"
//             fill="none"
//         >
//             <path
//                 fillRule="evenodd"
//                 clipRule="evenodd"
//                 d="M4 4.18757C4 2.98007 4.98 2.00007 6.1875 2.00007L12.6886 2.00002C13.7644 2.00001 14.6339 2.87683 14.625 3.95257V3.95257V7.31256C14.625 7.48506 14.765 7.62506 14.9375 7.62506H18.2975H18.9914C19.6813 7.62506 20.2408 8.18411 20.2414 8.87408L20.25 19.8126C20.25 20.3927 20.0195 20.9491 19.6093 21.3594C19.1991 21.7696 18.6427 22.0001 18.0625 22.0001H6.1875C5.60734 22.0001 5.05094 21.7696 4.6407 21.3594C4.23047 20.9491 4 20.3927 4 19.8126V4.18757ZM5.96653 3.9666C6.02513 3.90799 6.10462 3.87507 6.1875 3.87507H12.75V7.31256C12.75 7.89273 12.9805 8.44912 13.3907 8.85936C13.8009 9.26959 14.3573 9.50006 14.9375 9.50006H18.375V19.8126C18.375 19.8954 18.3421 19.9749 18.2835 20.0335C18.2249 20.0921 18.1454 20.1251 18.0625 20.1251H6.1875C6.015 20.1251 5.875 19.9851 5.875 19.8126V4.18757C5.875 4.10469 5.90792 4.0252 5.96653 3.9666Z"
//                 fill="#B3B3B3"
//             />
//         </svg>
//         <span className="navTitle">{'Заявки'}</span>
//     </>
// )}
