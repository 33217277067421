import React from 'react';

function RightArrow() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="10"
            viewBox="0 0 15 10"
            fill="none"
        >
            <path
                d="M14.6234 5.45667C14.8756 5.20446 14.8756 4.79554 14.6234 4.54333L10.5133 0.43327C10.2611 0.181056 9.85217 0.181056 9.59996 0.43327C9.34774 0.685483 9.34774 1.0944 9.59996 1.34662L13.2533 5L9.59996 8.65339C9.34774 8.9056 9.34774 9.31452 9.59996 9.56673C9.85217 9.81895 10.2611 9.81895 10.5133 9.56673L14.6234 5.45667ZM0.166687 5.64583L14.1667 5.64583L14.1667 4.35417L0.166687 4.35417L0.166687 5.64583Z"
                fill="#8A8A8E"
            />
        </svg>
    );
}

export default RightArrow;
