import React from 'react';

import axios from 'axios';

import './ChangeRequestMenu.css';

import { useLocation, useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import Header from '../../../Utils/Requests/ChangeRequest/Header';
import YMap from '../../../Utils/Requests/YMap/YMap';

import { selectContactInfo } from '../../../../store/slices/sliceSelectedInfo';

import { useAddRequestMutation } from '../../../../servises/api';

import { mobileProrabIntstance } from '../../../Entities/Lib/axios.js';

import { RequestDataType } from '../Requests';

import RequestMainInformation from './RequestMainInformation/RequestMainInformation';
import RequestButtonActive from './RequestButtons/RequestButtonActive/RequestButtonActive';
import RequestButtonCompleted from './RequestButtons/RequestButtonCompleted/RequestButtonCompleted';
import Department from './Department/Department';
import RequestButtonWaiting from './RequestButtons/RequestButtonWaiting/RequestButtonWaiting';

function ChangeRequestMenu() {
    const location = useLocation();
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [refreshReq] = useAddRequestMutation();

    const request = location?.state?.request as RequestDataType;

    dispatch(selectContactInfo(request.phone_number));

    async function cancelOrder() {
        if (window.confirm('Вы действительно хотите удалить заявку?')) {
            const response = await mobileProrabIntstance.post(`orderDelete`, {
                orderUID: request.order_uid,
            });

            if (response.status === 200) {
                refreshReq(null);
                navigate('/successCancel');
            }
        }
    }

    if (request.substatus === 'Завершена') {
        return (
            <>
                <Header
                    headerTitle={request.nomenclature}
                    backRedirect={'requests'}
                    request={request}
                />
                <br />
                <br />
                <div className="menuAndInformationContainer">
                    <YMap isWithMap={false} isWithActiveCars={false} request={request} />

                    <RequestMainInformation request={request} />
                </div>

                <br />
                <br />
                <br />
                <RequestButtonCompleted request={request} />
                <br />
                <br />
            </>
        );
    } else if (request.substatus === 'На проверке') {
        return (
            <>
                <Header
                    headerTitle={request.nomenclature}
                    backRedirect={'requests'}
                    request={request}
                />
                <br />
                <br />
                <div className="menuAndInformationContainer">
                    <RequestMainInformation request={request} />
                </div>

                <br />
                <br />
                <br />
                <div className="footer">
                    <button onClick={() => cancelOrder()} className="changeButtonWhite">
                        Удалить
                    </button>
                </div>

                <br />
                <br />
            </>
        );
    }
}

export default ChangeRequestMenu;
