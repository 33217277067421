import React, { useEffect, useRef } from 'react';

export function ResizableTextarea({
    value,
    ...props
}: React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
>) {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = '0px';
            const { scrollHeight } = textAreaRef.current;

            textAreaRef.current.style.height = `${scrollHeight}px`;
        }
    }, [textAreaRef, value]);

    return <textarea maxLength={600} rows={1} value={value} {...props} ref={textAreaRef} />;
}
