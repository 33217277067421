import axios from 'axios';

import { getCookie } from './CookieWork';

export const baseType = 'mobile';

export const mobileProrabIntstance = axios.create({
    baseURL: `https://api-erkon.tech/${baseType}/hs/mobileProrab/`,
    headers: {
        token: getCookie('token'),
    },
});

export const obmenHSInstance = axios.create({
    baseURL: `https://api-erkon.tech/${baseType}/hs/ObmenMP/`,
    headers: {
        token: getCookie('token'),
    },
});
