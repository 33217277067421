import React from 'react';

import Header from '../../../Utils/Requests/CreateRequest/Header';

import styles from './LoadingTiles.module.css';

function LoadingTiles({ tilesHeader, backRedirect }) {
    return (
        <>
            <Header headerTitle={tilesHeader} backRedirect={backRedirect} />
            <div className={styles.contentContainer}>
                <div className={styles.contentFields}>
                    {tilesHeader !== 'Адрес' ? (
                        <>
                            <div className={styles.contentTicket}></div>
                            <div className={styles.contentTicket}></div>
                            <div className={styles.contentTicket}></div>
                            <div className={styles.contentTicket}></div>
                            <div className={styles.contentTicket}></div>
                            <div className={styles.contentTicket}></div>
                            <div className={styles.contentTicket}></div>
                            <div className={styles.contentTicket}></div>
                        </>
                    ) : (
                        <>
                            <div className={styles.contentTicketBig}></div>
                            <div className={styles.contentTicketBig}></div>
                            <div className={styles.contentTicketBig}></div>
                            <div className={styles.contentTicketBig}></div>
                            <div className={styles.contentTicketBig}></div>
                            <div className={styles.contentTicketBig}></div>
                            <div className={styles.contentTicketBig}></div>
                            <div className={styles.contentTicketBig}></div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default LoadingTiles;
