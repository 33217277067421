import React from 'react';
import { useState } from 'react';

import uuid from 'react-uuid';

import { Link } from 'react-router-dom';

import Accordion from '../Accordion/Accordion';
import { baseType, mobileProrabIntstance } from '../../../../Entities/Lib/axios';
import { RequestFieldDataType } from '../../../../Entities/Interfaces/Interfaces';
import Download from '../../../../../content/svg/RequestsPage/Download';
import BigUpArrow from '../../../../../content/svg/RequestsPage/BigUpArrow';
import BigDownArrow from '../../../../../content/svg/RequestsPage/BigDownArrow';

import { getCookie } from '../../../../Entities/Lib/CookieWork';

import FileChecked from '../../../../../content/svg/DocumentsUtils/FileChecked';

import styles from './RequestsStatisticOrder.module.css';
import Letter from '../../../../../content/svg/RequestsPage/Letter';
import toast from 'react-hot-toast';
import AccordionStatisticOrder from '../AccordionStatistic/AccordionStatisticOrder';

interface RequestProps {
    order: RequestFieldDataType;
    isSearchView?: boolean;
}

function RequestsStatisticOrder({ order, isSearchView }: any) {
    const [isAccordeonOpened, setIsAccordeonOpened] = useState(isSearchView ? true : false);
    const theme = localStorage.getItem('theme');

    return (
        <div key={uuid()} className={styles.accordeonPlaceholder}>
            <div className={styles.accordeonPosition}>
                <div className={styles.accordeon}>
                    <div className={styles.order}>
                        <div className={styles.namePlaceholder}>
                            <div className={styles.name}>
                                <span className={styles.nomenclature}>
                                    {order.NomenclatureName}
                                </span>
                                <span className={styles.address}>{order.address}</span>
                            </div>
                            {!isSearchView && <hr className={styles.separator} />}
                        </div>
                        <div className={styles.totalPlaceholder}>
                            {order.deliveryList[0].decAvailable ? (
                                <>
                                    <Link
                                        target="_blank"
                                        to={`https://api-erkon.tech/${baseType}/hs/mobileProrab/deliveryDoc/${
                                            order.deliveryList[0].UID
                                        }/${getCookie('token')}/Декларация`}
                                        className={styles.base}
                                    >
                                        <span className={styles.baseTxt}>Декларация</span>
                                        <FileChecked theme={theme} />

                                        <div className={styles.downloadBtn}>
                                            <div className={styles.arrowImg}>
                                                <Letter theme={theme} />
                                            </div>
                                        </div>
                                    </Link>
                                    <hr className={styles.separator} />
                                </>
                            ) : null}
                            {localStorage.getItem('advancedMode') === 'true' && !isSearchView && (
                                <div className={styles.twoPlashkas}>
                                    {localStorage.getItem('isOpenDocumentInBrowser') === 'true' ? (
                                        <>
                                            <Link
                                                target="_blank"
                                                to={`https://api-erkon.tech/${baseType}/hs/mobileProrab/deliveryDocAll/${
                                                    order.UID
                                                }/${getCookie('token')}/ВсеТН`}
                                                className={styles.baseTwo}
                                            >
                                                <span className={styles.baseTxt}>Все ТН</span>

                                                {order.deliveryList.every(
                                                    (deliveryTicket: any) =>
                                                        deliveryTicket.TNfromArchive === true,
                                                ) && <FileChecked theme={theme} />}
                                                <div className={styles.downloadBtn}>
                                                    <div className={styles.arrowImg}>
                                                        <Letter theme={theme} />
                                                    </div>
                                                </div>
                                            </Link>

                                            <Link
                                                target="_blank"
                                                to={`https://api-erkon.tech/${baseType}/hs/mobileProrab/deliveryDocAll/${
                                                    order.UID
                                                }/${getCookie('token')}/ВсеПК`}
                                                className={styles.baseTwo}
                                            >
                                                <span className={styles.baseTxt}>Все ПК</span>
                                                <FileChecked theme={theme} />
                                                <div className={styles.downloadBtn}>
                                                    <div className={styles.arrowImg}>
                                                        <Letter theme={theme} />
                                                    </div>
                                                </div>
                                            </Link>
                                        </>
                                    ) : null}
                                </div>
                            )}
                        </div>
                        {!isSearchView && (
                            <div
                                onClick={() => setIsAccordeonOpened(!isAccordeonOpened)}
                                className={styles.qty}
                            >
                                <div className={styles.txt}>
                                    <div className={styles.race}>
                                        <span className={styles.racesText}>Рейсы:</span>
                                        <span className={styles.racesValue}>{order.count}</span>
                                    </div>

                                    <div className={styles.amount}>
                                        <span className={styles.amountText}>Объем:</span>
                                        <span className={styles.amountValue}>
                                            {order.weight}м<sup className="supMidTxt">3</sup>
                                        </span>
                                    </div>

                                    <div className={styles.arrow}>
                                        {isAccordeonOpened ? (
                                            <BigUpArrow theme={theme} />
                                        ) : (
                                            <BigDownArrow theme={theme} />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {isAccordeonOpened && (
                        <AccordionStatisticOrder
                            deliveryData={order.deliveryList}
                            key={order.number}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default React.memo(RequestsStatisticOrder);
