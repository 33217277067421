import toast from 'react-hot-toast';

import { getCookie } from './CookieWork';
import { mobileProrabIntstance } from './axios';

export default async function sendSettings(callback) {
    if (callback) {
        callback();
    }

    mobileProrabIntstance
        .post('setSettings', {
            nameuser: localStorage?.getItem('name'),
            theme: localStorage?.getItem('theme'),
            NotificationOfVehicleDeparture: JSON.parse(localStorage?.getItem('isCarArrive')),
            carArrival: JSON.parse(localStorage?.getItem('isOnlyOneCar')),
            token: getCookie('token'),
            onboarding: false,
            isPushEnabled: JSON.parse(localStorage?.getItem('isPushEnabled')),
        })
        .then((response) => {
            if (response.status === 200 && callback) {
                callback();
            }
        })
        .catch(() => {
            toast.error('Ошибка сохранения настроек');
        });
}
