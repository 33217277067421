import React from 'react';

import styles from './LoadingRequests.module.css';

import DatePicker from '../../../Utils/Requests/DatePicker/DatePicker';
import Navigation from '../../../Utils/Navigation/Navigation';

function LoadingRequests() {
    return ( 
        <>
        <header style={{height: "119px"}} className={styles.header}>
        <div className='headerRequests'>
            <DatePicker />
        </div>
        <br />
        <br />
        <br />
        <br />        
        <br />     
            <div className={styles.plashkas}>
                <hr className={styles.plashkasLine} />
                <hr className={styles.plashkasLine} />
            </div>
        </header>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className={styles.firstGroup}>
            <div className={styles.cardCategory}>
                <div className={styles.Cards_group_head_not_loaded}>
                    <div className={styles.cardHeader}>
                        <hr className={styles.bigLine} />
                        <hr className={styles.smallLine} />
                    </div>
                    <div className={styles.cardContent}>
                        <hr className={styles.contentLine} />
                        <hr className={styles.contentLine} />
                        <hr className={styles.contentLine} />
                    </div>
                </div>
            </div>

            <div className={styles.cards}>
                <div className={styles.card}>
                    <div className={styles.Request_card_not_loaded}>
                        <div className={styles.name}>
                            <div className={styles.code}>
                                <hr className={styles.cardHeaderLine} />
                                <hr className={styles.cardHeaderLineSmall} />
                            </div>
                            <div className={styles.underCode}>
                                <hr className={styles.underCodeLine} />
                            </div>
                            <hr className={styles.mainContnetLine} />
                            <hr className={styles.footerLine} />
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.cards}>
                <div className={styles.card}>
                    <div className={styles.Request_card_not_loaded}>
                        <div className={styles.name}>
                            <div className={styles.code}>
                                <hr className={styles.cardHeaderLine} />
                                <hr className={styles.cardHeaderLineSmall} />
                            </div>
                            <div className={styles.underCode}>
                                <hr className={styles.underCodeLine} />
                            </div>
                            <hr className={styles.mainContnetLine} />
                            <hr className={styles.footerLine} />
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.cards}>
                <div className={styles.card}>
                    <div className={styles.Request_card_not_loaded}>
                        <div className={styles.name}>
                            <div className={styles.code}>
                                <hr className={styles.cardHeaderLine} />
                                <hr className={styles.cardHeaderLineSmall} />
                            </div>
                            <div className={styles.underCode}>
                                <hr className={styles.underCodeLine} />
                            </div>
                            <hr className={styles.mainContnetLine} />
                            <hr className={styles.footerLine} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Navigation currentSection={'Заявки'} />
        </>
     );
}

export default LoadingRequests;