import React from 'react';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';

import ModalSelect from '../../../../../../Utils/Requests/CreateRequest/ModalSelect';
import 'react-datepicker/dist/react-datepicker.css';
import DateAndTimeInput from '../../../../../../Utils/Requests/CreateRequest/Input/DateAndTimeInput/DateAndTimeInput';

import { format, parseISO } from 'date-fns';

import DeliveryTypeSelect from '../../../../../../Utils/Requests/CreateRequest/Selectors/DeliveryTypeSelect';
import FactoryTypeSelect from '../../../../../../Utils/Requests/CreateRequest/Selectors/FactoryTypeSelect';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store/store';

interface AddressProps {
    address: string;
    lower_date: Date;
    lower_time: Date;
    upper_date: Date;
    upper_time: Date;
    isPlanned?: boolean;
    parsedDate: Date;
    setParsedDate: React.Dispatch<Date>;
    request?: any;
    refAddressSelector: any;
    deliveryType: any;
    receiver: any;
    factoryType: any;
    setFactoryType: any;
}

function Address({
    address,
    lower_date,
    lower_time,
    upper_date,
    upper_time,
    isPlanned,
    parsedDate,
    setParsedDate,
    request,
    refAddressSelector,
    deliveryType,
    receiver,
    factoryType,
    setFactoryType,
}: AddressProps) {
    const client = useSelector((state: RootState) => state.selectedInfo.selectedClient);

    return (
        <div className="addressAndTime">
            <span className="titleRequestMenu">Куда</span>
            <div className="addressAndTimeContent">
                {localStorage?.getItem('severalCounterparts') === 'true' && (
                    <ModalSelect
                        isEditable={false}
                        selectTitle={'Клиент'}
                        selectedInformation={client}
                        methodToGetContent={`clients`}
                    />
                )}
                {deliveryType === 'Самовывоз' ? (
                    <>
                        <ModalSelect
                            isEditable={false}
                            refAddressSelector={refAddressSelector}
                            selectTitle={'Грузополучатель'}
                            route={'addReceiver'}
                            selectedInformation={receiver}
                        />

                        <FactoryTypeSelect
                            factoryType={factoryType}
                            setFactoryType={setFactoryType}
                        />
                    </>
                ) : (
                    <ModalSelect
                        selectTitle={'Адрес'}
                        refAddressSelector={refAddressSelector}
                        selectedInformation={address}
                    />
                )}
            </div>
            <span className="titleRequestMenu">Когда</span>
            <div className="addressAndTimeContent">
                <DateAndTimeInput
                    request={request}
                    isNewRequest={false}
                    date={typeof parsedDate === 'string' ? parseISO(parsedDate) : parsedDate}
                    setDate={setParsedDate}
                    lower_date={lower_date}
                    lower_time={lower_time}
                    upper_date={upper_date}
                    upper_time={upper_time}
                    isPlanned={isPlanned}
                />
                {request ? (
                    request.dateChanged === true ? (
                        <div className="alarmDiv">
                            <span className="alarmTxt">{`Время было изменено с ${format(
                                new Date(request.lastDate),
                                'HH:mm',
                            )} на ${request.time}`}</span>
                        </div>
                    ) : null
                ) : null}
            </div>
        </div>
    );
}

export default Address;
