import React from 'react';

function DoneCars({ theme }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
        >
            <g clipPath="url(#clip0_2520_6160)">
                <path
                    d="M5.06597 1.1665H1.16675V12.9061H12.3959V9.82475"
                    stroke={theme === 'dark' ? '#fff' : '#202020'}
                    strokeWidth="2.04167"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.74414 5.76195L7.07338 8.02896L12.9101 2.1875"
                    stroke={theme === 'dark' ? '#fff' : '#202020'}
                    strokeWidth="2.04167"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_2520_6160">
                    <rect width="14" height="14" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default DoneCars;
