import React, { useRef } from 'react';

import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import { type Swiper as SwiperRef } from 'swiper';
import { Pagination, Navigation } from 'swiper/modules';
import { EffectCreative } from 'swiper/modules';
import { useNavigate } from 'react-router';

import 'swiper/css';
import 'swiper/css/pagination';

import firstSvgWhite from '../../../content/svg/onBordingWhite.svg';
import firstSvgBlack from '../../../content/svg/onBordingBlack.svg';
import secondImgMenu from '../../../content/jpgs/secondImg.jpg';
import secondImgMenuBlack from '../../../content/png/secondImgBlack.png';
import thirdImage from '../../../content/png/thirdOnBording.png';
import thirdImgMenuBlack from '../../../content/png/thirdImgBlack.png';
import map from '../../../content/png/imageMap.png';
import mapBlack from '../../../content/png/mapBlack.png';
import fivethImgBlackx3 from '../../../content/png/test3x.png';
import fivethImage from '../../../content/png/imageFiveth.png';
import sixthImage from '../../../content/png/SixthImage.png';
import sixthImgBlack from '../../../content/png/sixthImgBlack.png';

import styles from './OnBording.module.css';
import sendSettings from '../../Entities/Lib/sendSettings';

function OnBording() {
    const theme = localStorage.getItem('theme');

    const navigate = useNavigate();
    const swiperRef = useRef<SwiperRef | null>(null);

    return (
        <>
            <div className={styles.header}>
                <span
                    onClick={() => {
                        sendSettings();
                        navigate(
                            localStorage?.getItem('onBoarding') === 'true' ? '/logo' : '/settings',
                        );
                    }}
                    className={styles.skipTxt}
                >
                    Пропустить
                </span>
            </div>

            <Swiper
                navigation={true}
                pagination={{
                    clickable: true,
                }}
                effect="creative"
                onSwiper={(swiper: SwiperClass) => {
                    swiperRef.current = swiper;
                }}
                creativeEffect={{
                    prev: {
                        translate: ['-200%', 0, -1],
                    },
                    next: {
                        translate: ['100%', 0, 0],
                    },
                }}
                style={{
                    height: '93vh',
                    display: 'flex',
                    alignItems: 'center',
                    background: theme === 'dark' ? '#202020' : '#fff',
                }}
                modules={[Pagination, EffectCreative, Navigation]}
            >
                <SwiperSlide
                    style={{ alignItems: 'center', marginLeft: 'auto', marginRight: 'auto' }}
                >
                    <img
                        style={{
                            display: 'flex',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginTop: '15%',
                        }}
                        src={theme === 'dark' ? firstSvgBlack : firstSvgWhite}
                        alt="firstBord"
                    />
                    <div className={styles.info}>
                        <div className={styles.txt}>
                            <div className={styles.txtPlaceholder}>
                                <span className={styles.txtUpper}>Добро пожаловать</span>
                                <span className={styles.txtUpper}>в приложение Эркон!</span>
                                <span className={styles.txtLower}>
                                    Здесь вы можете заказывать бетон
                                </span>
                                <span className={styles.txtLower}>
                                    и отслеживать процесс доставки.
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className={styles.footer}>
                        <div
                            onClick={() => swiperRef?.current?.slideNext()}
                            className={styles.checkBtnPlaceholder}
                        >
                            <button className={styles.checkBtn}>Далее</button>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                            style={{
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                zIndex: '0',
                                marginTop: '10%',
                                width: '273px',
                                height: '599px',
                            }}
                            src={theme === 'dark' ? secondImgMenuBlack : secondImgMenu}
                            alt="imgSecond"
                        />
                    </div>
                    <div className={styles.infoLargeImgBox}>
                        <div className={styles.txt}>
                            <div className={styles.txtPlaceholder}>
                                <span className={styles.txtUpper}>Все заявки перед глазами</span>
                                <span className={styles.txtLower}>
                                    Просматривайте всю информацию
                                </span>
                                <span className={styles.txtLower}>
                                    по вашим заказам в одном месте.
                                </span>
                                <span className={styles.txtLower}>
                                    Проверяйте и подтверждайте заявки.
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className={styles.footer}>
                        <div
                            onClick={() => swiperRef.current?.slidePrev()}
                            className={styles.backButtonPlaceholder}
                        >
                            <button className={styles.backButton}>Назад</button>
                        </div>
                        <div
                            onClick={() => swiperRef.current?.slideNext()}
                            className={styles.checkBtnPlaceholder}
                        >
                            <button className={styles.checkBtn}>Далее</button>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                            style={{
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                zIndex: '0',
                                marginTop: '10%',
                                width: '273px',
                                height: '599px',
                            }}
                            src={theme === 'dark' ? thirdImgMenuBlack : thirdImage}
                            alt="imgSecond"
                        />
                    </div>
                    <div className={styles.infoLargeImgBox}>
                        <div className={styles.txt}>
                            <div className={styles.txtPlaceholder}>
                                <span className={styles.txtUpper}>Самостоятельно</span>
                                <span className={styles.txtUpper}>создавайте заявки на бетон</span>
                                <span className={styles.txtLower}>
                                    Выбирайте нужный адрес, марку бетона
                                </span>
                                <span className={styles.txtLower}>
                                    и указывайте все нужные параметры.
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.footer}>
                        <div
                            onClick={() => swiperRef.current?.slidePrev()}
                            className={styles.backButtonPlaceholder}
                        >
                            <button className={styles.backButton}>Назад</button>
                        </div>
                        <div
                            onClick={() => swiperRef.current?.slideNext()}
                            className={styles.checkBtnPlaceholder}
                        >
                            <button className={styles.checkBtn}>Далее</button>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                            style={{
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                zIndex: '0',
                                marginTop: '15%',
                                width: '314px',
                                height: '351px',
                            }}
                            src={theme === 'dark' ? mapBlack : map}
                            alt="imgSecond"
                        />
                    </div>
                    <div className={styles.info}>
                        <div className={styles.txt}>
                            <div className={styles.txtPlaceholder}>
                                <span className={styles.txtUpper}>Отслеживайте бетоновозы </span>
                                <span className={styles.txtLower}>
                                    Следите за геолокацией машин
                                </span>
                                <span className={styles.txtLower}>
                                    с помощью карты и наглядного
                                </span>
                                <span className={styles.txtLower}>отображения статусов.</span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.footer}>
                        <div
                            onClick={() => swiperRef.current?.slidePrev()}
                            className={styles.backButtonPlaceholder}
                        >
                            <button className={styles.backButton}>Назад</button>
                        </div>
                        <div
                            onClick={() => swiperRef.current?.slideNext()}
                            className={styles.checkBtnPlaceholder}
                        >
                            <button className={styles.checkBtn}>Далее</button>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                            style={{
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                zIndex: '0',
                                marginTop: '15%',
                                width: '295px',
                                height: '363px',
                            }}
                            src={theme === 'dark' ? fivethImgBlackx3 : fivethImage}
                            alt="imgSecond"
                        />
                    </div>
                    <div className={styles.info}>
                        <div className={styles.txt}>
                            <div className={styles.txtPlaceholder}>
                                <span className={styles.txtUpper}>Быстрый доступ к документам</span>
                                <span className={styles.txtLower}>
                                    Скачивайте копии всех необходимых
                                </span>
                                <span className={styles.txtLower}>документов по рейсам.</span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.footer}>
                        <div
                            onClick={() => swiperRef.current?.slidePrev()}
                            className={styles.backButtonPlaceholder}
                        >
                            <button className={styles.backButton}>Назад</button>
                        </div>
                        <div
                            onClick={() => swiperRef.current?.slideNext()}
                            className={styles.checkBtnPlaceholder}
                        >
                            <button className={styles.checkBtn}>Далее</button>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                            style={{
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                zIndex: '0',
                                marginTop: '15%',
                                width: '318px',
                                height: '346px',
                            }}
                            src={theme === 'dark' ? sixthImgBlack : sixthImage}
                            alt="imgSecond"
                        />
                    </div>

                    <div className={styles.info}>
                        <div className={styles.txt}>
                            <div className={styles.txtPlaceholder}>
                                <span className={styles.txtUpper}>Подарки за заказы</span>
                                <span className={styles.txtLower}>
                                    Участвуйте в бонусной программе,
                                </span>
                                <span className={styles.txtLower}>заказывая больше бетона,</span>
                                <span className={styles.txtLower}>и получайте вознаграждения.</span>
                            </div>
                        </div>
                    </div>
                    <div
                        onClick={() => {
                            sendSettings();
                            navigate(
                                localStorage?.getItem('onBoarding') === 'true'
                                    ? '/logo'
                                    : '/settings',
                            );
                        }}
                        className={styles.footer}
                    >
                        <div className={styles.checkBtnPlaceholder}>
                            <button className={styles.checkBtn}>Готово</button>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </>
    );
}

export default OnBording;
