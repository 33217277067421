import React, { useEffect, useState } from 'react';

import './RequestButtonActive.css';

import { useNavigate } from 'react-router-dom';

import { mobileProrabIntstance } from '../../../../../Entities/Lib/axios';

import { RequestDataType } from '../../../Requests';

interface RequestButtonActiveProps {
    request: RequestDataType;
    buttonTxt: string;
}

function RequestButtonActive({ request, buttonTxt }: RequestButtonActiveProps) {
    const navigate = useNavigate();

    const theme = localStorage.getItem('theme');

    const [maximumCapacity, setMaximumCapacity] = useState(NaN);
    const [isAdditionalCapacityAvailible, setIsAdditionalCapacityAvailible] = useState(false);

    useEffect(() => {
        if (maximumCapacity === 0 || !maximumCapacity) {
            setIsAdditionalCapacityAvailible(false);
        } else {
            setIsAdditionalCapacityAvailible(true);
        }
    });

    useEffect(() => {
        async function tryGetMaximumCapacity() {
            const response = await mobileProrabIntstance.post(`available_capacity`, {
                orderUID: request.order_uid,
            });

            if (response.status === 200) {
                const data = await response.data;

                setMaximumCapacity(data);
            }
        }

        tryGetMaximumCapacity();
    }, []);

    async function cancelOrder() {
        if (window.confirm('Вы действительно хотите отменить подтверждение?')) {
            const response = await mobileProrabIntstance.post(`orderConfirmCanсel`, {
                orderUID: request.order_uid,
            });

            if (response.status === 200) {
                navigate('/successCancel');
            }
        }
    }

    return (
        <div className="footer">
            {isAdditionalCapacityAvailible ? (
                <button
                    disabled={localStorage.getItem('isOnlyView') === 'true'}
                    style={{ opacity: localStorage.getItem('isOnlyView') === 'true' ? 0.5 : 1 }}
                    onClick={() =>
                        navigate('requestAdditionalCapacity', {
                            state: {
                                backRedirect: '/requests',
                                nomenclatureName: request.nomenclature,
                                maximumCapacity: Math.ceil(maximumCapacity),
                                request: request,
                            },
                        })
                    }
                    className="changeButton"
                >
                    Оформить довоз
                </button>
            ) : null}

            {request.cancelAvailable ? (
                <button onClick={() => cancelOrder()} className="changeButton">
                    {buttonTxt}
                </button>
            ) : (
                <a
                    style={{ textDecoration: 'none', color: theme === 'dark' ? '#fff' : '#F54941' }}
                    className="operatorButtonWhite"
                    href="tel:+74996538186"
                >
                    Связаться с диспетчером
                </a>
            )}
        </div>
    );
}

export default RequestButtonActive;
