import React from 'react';
import { useRef } from 'react';

import styles from './RequestFilter.module.css';

import DatePicker from '../DatePicker/DatePicker';
import TabsPlashkas from '../Tabs/TabsPlashkas/TabsPlashkas';

import { RequestDataType } from '../../../Pages/Requests/Requests';
import DateObject from 'react-date-object';

interface RequestFilterProps {
    index: number;
    setIndex: React.Dispatch<React.SetStateAction<number>>;
    requests: RequestDataType[];
    filteredRequests: RequestDataType[];
    setFilterdRequests: React.Dispatch<React.SetStateAction<RequestDataType[]>>;
    setSelectedDate: React.Dispatch<React.SetStateAction<Date>>; // todo change to date
}

function RequestFilter({
    requests,
    setFilterdRequests,
    setSelectedDate,
    filteredRequests,
    index,
    setIndex,
}: RequestFilterProps) {
    const headerRef = useRef<HTMLDivElement>(null);

    return (
        <header ref={headerRef} className={styles.header}>
            <div className="headerRequests">
                <DatePicker
                    headerRef={headerRef}
                    requests={requests}
                    setFilterdRequests={setFilterdRequests}
                    setSelectedDate={setSelectedDate}
                />
            </div>

            <TabsPlashkas index={index} setIndex={setIndex} filteredRequests={filteredRequests} />

            {/* <Tabs filteredRequests={filteredRequests} selectedDate={selectedDate} requests={requests} setFillteredRequests={setFilterdRequests} currentStatus={'Активные'} /> */}

            {/* <CoolTabs index={index} setIndex={setIndex} filteredRequests={filteredRequests} selectedDate={selectedDate} requests={requests} setFillteredRequests={setFilterdRequests} /> */}
        </header>
    );
}

export default RequestFilter;
