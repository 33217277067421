import React from 'react';

import InputWithButtons from '../Input/InputWithButtons/InputWithButtons';
import ModalSelect from '../ModalSelect';
import { RootState } from '../../../../../store/store';
import { useSelector } from 'react-redux';

interface ReceivingMethodSelectProps {
    pumpCount: number;
    setPumpCount: (value: number) => void;
    laitance: number;
    setLaitance: (value: number) => void;
    acceptanceType: string;
    requestType: string;
    refPumpSelector?: React.RefObject<HTMLDivElement>;
}

function ReceivingMethodSelect({
    pumpCount,
    setPumpCount,
    laitance,
    setLaitance,
    acceptanceType,
    requestType,
    refPumpSelector,
}: ReceivingMethodSelectProps) {
    const pumpInfo = useSelector((state: RootState) => state.selectedInfo.pumps);
    const storeLaitance = useSelector((state: RootState) => state.selectedInfo.laitance);
    const storePumpCount = useSelector((state: RootState) => state.selectedInfo.pumpCount);
    const ownPumps = useSelector((state: RootState) => state.selectedInfo.ownPumps);

    const title = storePumpCount
        ? `cвоиx - ${ownPumps}; под заказ - ${pumpInfo.length}; ЦМ - ${storeLaitance} м³`
        : 'Параметры насоса';

    if (requestType === 'Создание') {
        return (
            <ModalSelect
                route="nasosSettings"
                isEditable
                selectTitle={title}
                refPumpSelector={refPumpSelector}
            />
        );
    }
    return (
        <>
            {acceptanceType === 'Насос' && (
                <InputWithButtons
                    inputTitle={'Кол-во насосов, шт'}
                    inputValue={pumpCount}
                    setInputValue={setPumpCount}
                    valueToChange={1}
                    maxValue={3}
                    minValue={1}
                />
            )}
        </>
    );
}

export default ReceivingMethodSelect;
