import React from 'react';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setFiller } from '../../../../../store/slices/sliceSelectedInfo';
import { RootState } from '../../../../../store/store';

import { NomenclatureOptionsType } from '../../../../Entities/Interfaces/Interfaces';

function NomenclatureTypeSelect() {
    const dispatch = useDispatch();

    const selectedFiller = useSelector((state: RootState) => state.selectedInfo.selectedFiller);
    const isGraniteAvailable = useSelector(
        (state: RootState) => state.selectedInfo.isGraniteAvailable,
    ) as NomenclatureOptionsType[];

    const selectNomenclatureType = (id: number) => {
        if (id === 1) {
            dispatch(setFiller(isGraniteAvailable[0].filler)); // ? что тут происходит
        } else {
            dispatch(setFiller(isGraniteAvailable[1].filler));
        }
    };

    useEffect(() => {
        if (Object.keys(isGraniteAvailable).length === 1) {
            dispatch(setFiller(isGraniteAvailable[0].filler));
        } else if (Object.keys(isGraniteAvailable).length === 2 && selectedFiller === null) {
            dispatch(setFiller(isGraniteAvailable[0].filler));
        }
    }, [isGraniteAvailable]);

    return (
        <>
            {Object.keys(isGraniteAvailable).length === 2 && (
                <div className="coneDraftButtons">
                    <button
                        onClick={() => selectNomenclatureType(1)}
                        className={
                            selectedFiller === 'Гравий' ? 'coneDraftBtn clicked3' : 'coneDraftBtn'
                        }
                    >
                        Гравий
                    </button>
                    <button
                        onClick={() => selectNomenclatureType(2)}
                        className={
                            selectedFiller === 'Гранит' ? 'coneDraftBtn clicked3' : 'coneDraftBtn'
                        }
                    >
                        Гранит
                    </button>
                </div>
            )}
        </>
    );
}

export default NomenclatureTypeSelect;
