import React from 'react';
import { useState } from 'react';

import uuid from 'react-uuid';

import { Link } from 'react-router-dom';

import Accordion from '../Accordion/Accordion';
import { baseType, mobileProrabIntstance } from '../../../../Entities/Lib/axios';
import { RequestFieldDataType } from '../../../../Entities/Interfaces/Interfaces';
import Download from '../../../../../content/svg/RequestsPage/Download';
import BigUpArrow from '../../../../../content/svg/RequestsPage/BigUpArrow';
import BigDownArrow from '../../../../../content/svg/RequestsPage/BigDownArrow';

import { getCookie } from '../../../../Entities/Lib/CookieWork';

import FileChecked from '../../../../../content/svg/DocumentsUtils/FileChecked';

import styles from './RequestsStatisticOrder.module.css';
import Letter from '../../../../../content/svg/RequestsPage/Letter';
import toast from 'react-hot-toast';
import AccordionStatisticUPD from '../AccordionStatistic/AccordionStatisticUPD';
import { format } from 'date-fns';

function RequestStatisticUPD({ list, isSearchView, startDate, endDate }: any) {
    const [isAccordeonOpened, setIsAccordeonOpened] = useState(isSearchView ? true : false);
    const theme = localStorage.getItem('theme');

    return (
        <div key={uuid()} className={styles.accordeonPlaceholder}>
            <div className={styles.accordeonPosition}>
                <div className={styles.accordeon}>
                    <div className={styles.order}>
                        <div className={styles.namePlaceholder}>
                            <div
                                onClick={() => setIsAccordeonOpened(!isAccordeonOpened)}
                                className={styles.nameForUPD}
                            >
                                <span style={{ width: '100%' }} className={styles.nomenclature}>
                                    {`Поступления за ${format(list.updList[0].date, 'dd.MM.yy')}`}
                                </span>
                                {/* <span className={styles.address}>{order.orderAddress}</span> */}
                            </div>
                            {!isSearchView && <hr className={styles.separator} />}
                        </div>
                        <div className={styles.totalPlaceholder}>
                            <Link
                                target="_blank"
                                to={`https://api-erkon.tech/${baseType}/hs/mobileProrab//updFileAll/${getCookie(
                                    'token',
                                )}/${startDate}/${endDate}
                            `}
                                className={styles.base}
                            >
                                <span className={styles.baseTxt}>Все УПД</span>
                                {list.updList.some((upd: any) => upd.signed) && (
                                    <FileChecked theme={theme} />
                                )}
                                <div className={styles.downloadBtn}>
                                    <div className={styles.arrowImg}>
                                        <Letter theme={theme} />
                                    </div>
                                </div>
                            </Link>
                        </div>
                        {!isSearchView && (
                            <div
                                onClick={() => setIsAccordeonOpened(!isAccordeonOpened)}
                                className={styles.qty}
                            >
                                <div className={styles.txt}>
                                    <div className={styles.raceNotFit}>
                                        <span className={styles.racesText}>УПД:</span>
                                        <span className={styles.racesValue}>
                                            {list.updList.length}
                                        </span>
                                    </div>

                                    <div className={styles.amount}>
                                        <span className={styles.amountText}>Сумма:</span>
                                        <span className={styles.amountValue}>
                                            {list.summ.toLocaleString('ru-RU', {
                                                style: 'currency',
                                                currency: 'RUB',
                                            })}
                                        </span>
                                    </div>

                                    <div
                                        style={{ width: '25px', height: '25px' }}
                                        className={styles.arrow}
                                    >
                                        {isAccordeonOpened ? (
                                            <BigUpArrow theme={theme} />
                                        ) : (
                                            <BigDownArrow theme={theme} />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {isAccordeonOpened && <AccordionStatisticUPD updData={list.updList} />}
                </div>
            </div>
        </div>
    );
}

export default React.memo(RequestStatisticUPD);
