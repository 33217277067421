import React from 'react';

import styles from './ChartWithNomenclatures.module.css';

import { Doughnut } from 'react-chartjs-2';
import uuid from 'react-uuid';
import { Chart } from 'chart.js';

interface ChartWithNomenclaturesProps {
    data: {
        date: { nomenclature: string; percent: string; capacity: number }[];
    };
    isSuccess: boolean;
}
function ChartWithNomenclatures({ data, isSuccess }: ChartWithNomenclaturesProps) {
    const theme = localStorage.getItem('theme');

    if (isSuccess) {
        const lables = data.date.map((data) => {
            return data.nomenclature;
        });

        const percentes = data.date.map((data) => {
            return +data.percent.replace('%', '');
        });

        const colors = ['#FF8B86', '#78B9FF', '#F59741', '#7FEA9A', '#FCD54A'];

        if (Object.keys(data.date).length !== 0) {
            const dataMass = {
                labels: lables,
                datasets: [
                    {
                        data: percentes,
                        backgroundColor: ['#FF8B86', '#78B9FF', '#F59741', '#7FEA9A', '#FCD54A'],
                        borderColor: ['#FF8B86', '#78B9FF', '#F59741', '#7FEA9A', '#FCD54A'],
                        borderSkipped: [
                            ['top', 'bottom'],
                            ['top', 'bottom'],
                            ['top', 'bottom'],
                            ['top', 'bottom'],
                            ['top', 'bottom'],
                        ], //I'm sorry, I'm so so sorry...
                        borderRadius: 8,
                    },
                ],
            };
            return (
                <div className={styles.pie_chart}>
                    <Doughnut
                        style={{ display: 'flex', marginRight: '51%', padding: '12px' }}
                        data={dataMass}
                        options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                                tooltip: {
                                    enabled: false,
                                },
                                legend: {
                                    display: false,
                                    position: 'right',

                                    labels: {
                                        font: {
                                            family: 'Inter, sans-serif',
                                            size: 12,
                                        },
                                        color: theme === 'dark' ? '#fff' : '#202020',
                                        boxWidth: 6,
                                        boxHeight: 6,
                                        // borderRadius: 8,
                                        usePointStyle: true,
                                        filter: (legend) => {
                                            if (legend.text === 'Класс') {
                                                legend.fontColor = '#8A8A8E';
                                                legend.pointStyle = false;
                                                legend.textAlign = 'left';
                                            }

                                            return true;
                                        },
                                    },
                                },
                                datalabels: {
                                    display: false,
                                },
                            },
                            datasets: {
                                doughnut: {
                                    spacing: 0,
                                    // borderRadius: 8,
                                },
                            },
                        }}
                    />
                    <div className={styles.chart_info}>
                        <div className={styles.chart_infoPlaceholder}>
                            <div className={styles.class}>
                                <div className={styles.subhead}>
                                    <span className={styles.subheadTxt}>Класс</span>
                                </div>
                                {data.date.map((data, index) => {
                                    return (
                                        <div key={index} className={styles.elWithPic}>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="10"
                                                height="11"
                                                viewBox="0 0 10 11"
                                                fill="none"
                                            >
                                                <circle
                                                    cx="4.99988"
                                                    cy="5.50012"
                                                    r="4.99988"
                                                    fill={colors[index]}
                                                />
                                            </svg>
                                            <span className={styles.elWithPicTxt}>
                                                {data.nomenclature.split(' ')[0]}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>

                            <div className={styles.m3}>
                                <div className={styles.subhead}>
                                    <span className={styles.subheadTxt}>
                                        м<sup className="supMidTxt">3</sup>
                                    </span>
                                </div>
                                {data.date.map((data) => {
                                    return (
                                        <span key={uuid()} className={styles.elWithPicTxt}>
                                            {data.capacity}
                                        </span>
                                    );
                                })}
                            </div>

                            <div className={styles.percent}>
                                <div className={styles.subhead}>
                                    <span className={styles.subheadTxt}>%</span>
                                </div>
                                {data.date.map((data) => {
                                    return (
                                        <span key={uuid()} className={styles.elWithPicTxt}>
                                            {data.percent}
                                        </span>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <></>;
        }
    } else {
        return <></>;
    }
}

export default ChartWithNomenclatures;
