import { createSlice } from '@reduxjs/toolkit';

export const documentsSlice = createSlice({
    name: 'documents',
    initialState: {
        searchField: '',
        isPopupOpen: false,
        deliveryTicket: null
    },
    reducers: {
        setSearchField: (state, action) => {
            state.searchField = action.payload;
        },
        togglePopup: (state, action) => {
            state.isPopupOpen = action.payload;
        },
        setDeliveryTicket: (state, action) => {
            state.deliveryTicket = action.payload;
        },
    },
});

export const { setSearchField, togglePopup, setDeliveryTicket } = documentsSlice.actions;

export default documentsSlice.reducer;