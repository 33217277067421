import React from 'react';

function Trash({ theme }) {
    const color = theme === 'dark' ? 'white' : '#F54941';
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.76914 10.2236C7.19796 10.1983 7.56603 10.5255 7.59125 10.9543L7.99493 17.8169C8.03523 18.502 8.60263 19.037 9.28899 19.037H14.711C15.3974 19.037 15.9648 18.502 16.0051 17.8169L16.4087 10.9543C16.434 10.5255 16.802 10.1983 17.2309 10.2236C17.6597 10.2488 17.9868 10.6169 17.9616 11.0457L17.5579 17.9082C17.4693 19.4156 16.221 20.5926 14.711 20.5926H9.28899C7.779 20.5926 6.53073 19.4156 6.44206 17.9082L6.03838 11.0457C6.01315 10.6169 6.34033 10.2488 6.76914 10.2236Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.2222 9.18519L5.77778 9.18519C5.34822 9.18519 5 8.83696 5 8.40741C5 7.97785 5.34822 7.62963 5.77778 7.62963L18.2222 7.62963C18.6518 7.62963 19 7.97785 19 8.40741C19 8.83696 18.6518 9.18519 18.2222 9.18519Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.963 5.55556C10.247 5.55556 9.66667 6.13593 9.66667 6.85185V8.40741C9.66667 8.83696 9.31844 9.18518 8.88889 9.18518C8.45933 9.18518 8.11111 8.83696 8.11111 8.40741V6.85185C8.11111 5.27682 9.38793 4 10.963 4H13.037C14.6121 4 15.8889 5.27682 15.8889 6.85185V8.40741C15.8889 8.83696 15.5407 9.18518 15.1111 9.18518C14.6816 9.18518 14.3333 8.83696 14.3333 8.40741V6.85185C14.3333 6.13593 13.753 5.55556 13.037 5.55556H10.963Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.4444 11.7778C10.874 11.7778 11.2222 12.126 11.2222 12.5556V15.6667C11.2222 16.0962 10.874 16.4444 10.4444 16.4444C10.0149 16.4444 9.66667 16.0962 9.66667 15.6667V12.5556C9.66667 12.126 10.0149 11.7778 10.4444 11.7778Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.5556 11.7778C13.9851 11.7778 14.3333 12.126 14.3333 12.5556V15.6667C14.3333 16.0962 13.9851 16.4444 13.5556 16.4444C13.126 16.4444 12.7778 16.0962 12.7778 15.6667V12.5556C12.7778 12.126 13.126 11.7778 13.5556 11.7778Z"
                fill={color}
            />
        </svg>
    );
}

export default Trash;
