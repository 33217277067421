import React from 'react';

import style from './MonthSelect.module.css';

interface MonthSelectProps {
    title: string;
    monthNumber: number;
    setPeriodMonth: (monthNumber: number) => void;
}

function MonthSelect({ title, monthNumber, setPeriodMonth }: MonthSelectProps) {
    return (
        <div className={style.title} onClick={() => setPeriodMonth(monthNumber)}>
            {title}
        </div>
    );
}

export default MonthSelect;
