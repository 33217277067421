import React from 'react';

import { useLocation } from 'react-router-dom';

import RequestMainInformation from '../../ShowRequestInfo/RequestMainInformation/RequestMainInformation';
import RequestButtonActive from '../../ShowRequestInfo/RequestButtons/RequestButtonActive/RequestButtonActive';
import Header from '../../../../Utils/Requests/ChangeRequest/Header';

import Department from '../../ShowRequestInfo/Department/Department';
import YMap from '../../../../Utils/Requests/YMap/YMap';

function ActiveRequest() {
    const location = useLocation();
    const request = location?.state?.request;

    return (
        <>
            <Header
                headerTitle={request.nomenclature}
                backRedirect={'requests'}
                request={request}
            />

            <div className="menuAndInformationContainer">
                {request.substatus === 'На распределении' ? (
                    <Department request={request} />
                ) : (
                    <YMap request={request} />
                )}
                <RequestMainInformation request={request} />
            </div>
            <br />
            <br />
            <br />
            <RequestButtonActive
                buttonTxt={
                    request.substatus === 'На распределении'
                        ? 'Отменить подтверждение'
                        : 'Отменить подвержднение'
                }
                request={request}
            />
            <br />
            <br />
        </>
    );
}

export default ActiveRequest;
