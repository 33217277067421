import React from 'react';

function VerticalCrane({ theme }) {
    return (
        <svg
            width="135"
            height="66"
            viewBox="0 0 135 66"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M29.0604 26.8085L45.5723 12.8472L63.3829 26.8085"
                stroke={theme === 'dark' ? '#B3B3B3' : '#474747'}
                strokeLinecap="round"
            />
            <rect
                x="28.6893"
                y="29.5913"
                width="35.2501"
                height="12.9869"
                fill="url(#paint0_linear_375_7396)"
            />
            <path
                d="M28.6893 42.5784H63.9395L54.2921 56.6784H39.0788L28.6893 42.5784Z"
                fill="url(#paint1_linear_375_7396)"
            />
            <path
                d="M29.0604 64.4706C28.753 64.4706 28.5038 64.7198 28.5038 65.0271C28.5038 65.3345 28.753 65.5837 29.0604 65.5837V64.4706ZM64.3105 65.5837C64.6179 65.5837 64.8671 65.3345 64.8671 65.0271C64.8671 64.7198 64.6179 64.4706 64.3105 64.4706V65.5837ZM34.6262 42.5784V65.0271H35.7394V42.5784H34.6262ZM57.446 42.5784V65.0271H58.5592V42.5784H57.446ZM29.0604 65.5837H64.3105V64.4706H29.0604V65.5837Z"
                fill={theme === 'dark' ? '#B3B3B3' : '#474747'}
            />
            <path
                d="M29.0604 42.5784L63.5684 42.5784"
                stroke={theme === 'dark' ? '#B3B3B3' : '#474747'}
                strokeWidth="1.11316"
                strokeLinecap="round"
            />
            <rect
                x="39.0788"
                y="56.6782"
                width="15.2132"
                height="4.45265"
                fill="url(#paint2_linear_375_7396)"
            />
            <path
                d="M29.0604 29.7768V26.6228M63.5684 29.7768V26.6228"
                stroke={theme === 'dark' ? '#B3B3B3' : '#474747'}
                strokeWidth="1.11316"
                strokeLinecap="round"
            />
            <path
                d="M29.0604 29.9624L63.5684 29.9624"
                stroke={theme === 'dark' ? '#B3B3B3' : '#474747'}
                strokeWidth="1.11316"
                strokeLinecap="round"
            />
            <path
                d="M47.143 2.93294C47.143 3.66235 46.625 4.27078 45.9369 4.41048C45.8395 4.43026 45.7386 4.44064 45.6354 4.44064C45.5321 4.44064 45.0669 4.43026 44.9695 4.41048C44.9695 4.41048 44.065 3.66235 44.065 2.93294C44.065 2.10026 44.8027 1.42524 45.6354 1.42524C46.468 1.42524 47.143 2.10026 47.143 2.93294Z"
                fill={theme === 'dark' ? '#B3B3B3' : '#474747'}
            />
            <path
                d="M45.6354 4.44064C45.5321 4.44064 45.0669 4.43026 44.9695 4.41048V6.79017C44.9695 7.32446 45.4026 7.75758 45.9369 7.75758L45.9369 4.41048C45.8395 4.43026 45.7386 4.44064 45.6354 4.44064Z"
                fill={theme === 'dark' ? '#B3B3B3' : '#474747'}
            />
            <path
                d="M47.2311 2.78231C47.2311 3.78152 46.4211 4.59147 45.4219 4.59147C44.4228 4.59147 43.6128 3.78152 43.6128 2.78231C43.6128 1.78309 44.4228 0.973145 45.4219 0.973145C46.4211 0.973145 47.2311 1.78309 47.2311 2.78231Z"
                fill={theme === 'dark' ? '#B3B3B3' : '#474747'}
            />
            <path
                d="M45.422 6.85299C45.9548 6.85299 46.8675 6.95777 47.328 7.22569C47.7884 7.4936 48.1697 7.87873 48.4329 8.34187C48.6962 8.80501 48.832 9.32963 48.8267 9.86233C48.8213 10.395 48.6749 10.9168 48.4023 11.3745C48.1298 11.8323 47.7408 12.2096 47.275 12.4682C46.8093 12.7268 46.2833 12.8573 45.7507 12.8466C45.2181 12.8358 44.6978 12.6841 44.2428 12.407C43.8687 12.1791 43.5498 11.873 43.3071 11.5111C43.22 11.3811 43.2721 11.2083 43.4097 11.1337C43.5473 11.0591 43.7179 11.1114 43.8079 11.2394C44.0009 11.5142 44.2491 11.7473 44.5376 11.923C44.9071 12.1481 45.3296 12.2713 45.7621 12.28C46.1946 12.2888 46.6218 12.1827 47 11.9728C47.3783 11.7628 47.6941 11.4564 47.9154 11.0846C48.1368 10.7129 48.2557 10.2892 48.26 9.85661C48.2644 9.42402 48.1541 8.99799 47.9403 8.62188C47.7265 8.24578 47.4169 7.93303 47.043 7.71546C46.8012 7.57478 46.3753 7.49235 45.9875 7.45165C45.6762 7.41899 45.422 7.16594 45.422 6.85299Z"
                fill={theme === 'dark' ? '#B3B3B3' : '#474747'}
                stroke={theme === 'dark' ? '#B3B3B3' : '#474747'}
                strokeWidth="0.633207"
            />
            <path
                d="M97.7928 15.3549C97.6949 15.3966 97.6055 15.4561 97.5294 15.5305L90.6109 22.4491C90.4655 22.6051 90.3863 22.8115 90.3901 23.0247C90.3939 23.238 90.4803 23.4414 90.6311 23.5923C90.7819 23.7431 90.9853 23.8295 91.1986 23.8332C91.4118 23.837 91.6182 23.7578 91.7743 23.6124L97.288 18.0987L97.2884 48.0699L91.6202 42.4017C91.4641 42.2563 91.2578 42.1771 91.0445 42.1809C90.8313 42.1847 90.6278 42.271 90.477 42.4219C90.3262 42.5727 90.2398 42.7761 90.236 42.9894C90.2323 43.2026 90.3114 43.409 90.4568 43.5651L97.5299 50.6381C97.606 50.7125 97.6953 50.7721 97.7933 50.8137C97.8937 50.8572 98.0021 50.8796 98.1116 50.8796C98.221 50.8796 98.3294 50.8572 98.4298 50.8137C98.5278 50.7721 98.6171 50.7125 98.6932 50.6381L105.766 43.5651C105.92 43.4107 106.007 43.2015 106.007 42.9834C106.007 42.7652 105.92 42.556 105.766 42.4017C105.612 42.2475 105.402 42.161 105.184 42.161C104.966 42.161 104.757 42.2475 104.603 42.4017L98.9347 48.0699L98.9342 18.0987L104.448 23.6124C104.602 23.7666 104.811 23.8532 105.029 23.8532C105.248 23.8532 105.457 23.7666 105.611 23.6124C105.765 23.4581 105.852 23.2489 105.852 23.0307C105.852 22.8126 105.765 22.6034 105.611 22.4491L98.6928 15.5305C98.6167 15.4561 98.5273 15.3966 98.4294 15.3549C98.3289 15.3115 98.2206 15.289 98.1111 15.289C98.0016 15.289 97.8933 15.3115 97.7928 15.3549Z"
                fill={theme === 'dark' ? '#B3B3B3' : '#474747'}
            />
            <defs>
                <linearGradient
                    id="paint0_linear_375_7396"
                    x1="28.6893"
                    y1="36.0848"
                    x2="63.9396"
                    y2="36.0848"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D5D5D5" />
                    <stop offset="0.258081" stopColor="#ECECEC" />
                    <stop offset="0.765625" stopColor="#ECECEC" />
                    <stop offset="1" stopColor="#D5D5D5" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_375_7396"
                    x1="28.6893"
                    y1="49.6284"
                    x2="63.9396"
                    y2="49.6284"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D5D5D5" />
                    <stop offset="0.258081" stopColor="#ECECEC" />
                    <stop offset="0.765625" stopColor="#ECECEC" />
                    <stop offset="1" stopColor="#D5D5D5" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_375_7396"
                    x1="46.6854"
                    y1="56.6782"
                    x2="46.6854"
                    y2="61.1309"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#929292" stopOpacity="0.49" />
                    <stop offset="1" stopColor="#929292" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default VerticalCrane;
