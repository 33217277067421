import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const weatherApi = createApi({
    reducerPath: 'weatherApi',
    refetchOnFocus: true,
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://meteostat.p.rapidapi.com',
        headers: {
            'x-rapidapi-key': 'f2c00081a4msha3b22631c25a361p1fcc33jsn05dad46653ab',
            'x-rapidapi-host': 'meteostat.p.rapidapi.com',
        },
    }),

    endpoints: (builder) => ({
        getWeather: builder.query<any, any>({
            query: ({ startDate, endDate }) => ({
                url: `/stations/daily`,
                params: {
                    station: '27612', // id Московской станции
                    start: startDate,
                    end: endDate,
                },
            }),
        }),
    }),
});

export const { useGetWeatherQuery } = weatherApi;
