import React from 'react';

import { useLocalStorage } from 'usehooks-ts';

import { useDispatch, useSelector } from 'react-redux';

import Header from '../../../Requests/CreateRequest/Header';

import styles from './PushesSettings.module.css';
import { useSendSettings } from '../../../../CustomHooks/useSendSettings';
import { handleRegisterPush } from '../../../../../notifications/Notification';

function PushesSettings() {
    const [isPushEnabled, setIsPushEnabled] = useLocalStorage(
        'isPushEnabled',
        localStorage?.getItem('isPushEnabled') || '',
    );

    const [isOnlyOneCar, setIsOnlyOneCar] = useLocalStorage(
        'isOnlyOneCar',
        localStorage?.getItem('isOnlyOneCar') || '',
    );
    const [isCarArrive, setIsCarArrive] = useLocalStorage(
        'isCarArrive',
        localStorage?.getItem('isCarArrive') || '',
    );

    const [sendSettings] = useSendSettings();

    function setCar(status: boolean) {
        setIsOnlyOneCar(status as unknown as string);
    }

    return (
        <>
            <Header headerTitle={'Push-уведомления'} backRedirect={'settings'} />
            <div className={styles.content}>
                <div className={styles.general_push}>
                    <div className={styles.general_push_placeholder}>
                        <span className={styles.txt}>Включить push-уведомления</span>
                        <input
                            className={styles.switcher}
                            onChange={() => {
                                if ((isPushEnabled as unknown as boolean) === true) {
                                    setIsPushEnabled(false as unknown as string);
                                    sendSettings();
                                } else {
                                    setIsPushEnabled(true as unknown as string);
                                    sendSettings();
                                }
                            }}
                            checked={isPushEnabled ? true : false}
                            type="checkbox"
                            id="switch2"
                        />
                        <label className={styles.labelForSwitcher} htmlFor="switch2">
                            Toggle
                        </label>
                    </div>
                </div>
                <div
                    style={{ opacity: isPushEnabled ? '1' : '0.5' }}
                    className={styles.generalPush}
                >
                    <span className={styles.generalPushtTxt}>Уведомления по рейсам</span>
                    <div className="coneDraftButtons">
                        <button
                            disabled={!isPushEnabled as unknown as boolean}
                            onClick={() => sendSettings(setCar(true))}
                            className={
                                JSON.parse(isOnlyOneCar) === true
                                    ? 'coneDraftBtn clicked3'
                                    : 'coneDraftBtn'
                            }
                        >
                            Все машины
                        </button>
                        <button
                            disabled={!isPushEnabled as unknown as boolean}
                            onClick={() => sendSettings(setCar(false))}
                            className={
                                JSON.parse(isOnlyOneCar) === false
                                    ? 'coneDraftBtn clicked3'
                                    : 'coneDraftBtn'
                            }
                        >
                            Только первая
                        </button>
                    </div>
                </div>

                <div
                    style={{ opacity: isPushEnabled ? '1' : '0.5' }}
                    className={styles.special_push}
                >
                    <span className={styles.special_pushTxt}>Оповещать, когда</span>
                    <div className={styles.car_arrived}>
                        <div className={styles.valuePlaceholder}>
                            <span className={styles.value}>Машина приехала</span>
                            <input
                                className={styles.switcher}
                                onChange={() => {
                                    if (isPushEnabled as unknown as boolean) {
                                        sendSettings(
                                            setIsCarArrive(!isCarArrive as unknown as string),
                                        );
                                    } else {
                                        return null;
                                    }
                                }}
                                checked={JSON.parse(isCarArrive)}
                                type="checkbox"
                                id="switch"
                            />
                            <label className={styles.labelForSwitcher} htmlFor="switch">
                                Toggle
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PushesSettings;
