import React from 'react';

interface DeliveryTypeSelectProps {
    deliveryType: string;
    setDeliveryType: React.Dispatch<React.SetStateAction<string>>;
}

function DeliveryTypeSelect({ deliveryType, setDeliveryType }: DeliveryTypeSelectProps) {
    const selectDeliveryType = (deliveryType: string) => {
        setDeliveryType(deliveryType);
    };

    return (
        <div className="coneDraftButtons">
            <button
                onClick={() => selectDeliveryType('Доставка')}
                className={deliveryType === 'Доставка' ? 'coneDraftBtn clicked' : 'coneDraftBtn'}
            >
                Доставка
            </button>
            <button
                onClick={() => selectDeliveryType('Самовывоз')}
                className={deliveryType === 'Самовывоз' ? 'coneDraftBtn clicked' : 'coneDraftBtn'}
            >
                Самовывоз
            </button>
        </div>
    );
}

export default DeliveryTypeSelect;
