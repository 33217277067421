import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import '../../../components/Pages/Requests/CreateRequest/ModalViews/ModalView.css';

import {
    selectAddress,
    selectAddressUID,
    selectContactInfo,
    selectCounterpart,
    selectNomenclature,
    selectNomenclatureUID,
    selectReceiver,
    selectReceiverUID,
    setIsGraniteavailable,
    setNomenclatureMobility,
    setSelectedClient,
    setSelectedClientUID,
} from '../../../store/slices/sliceSelectedInfo';

import { RootState } from '../../../store/store';
import ModalOk from '../../../content/svg/RequestsPage/ModalOk';
import { ClientDataType } from '../Interfaces/Interfaces';
import { fixPageState } from '../../../store/slices/sliceUser';
import { setAvailebleEntarances } from '../../../store/slices/sliceRequests';

interface ClientModalProps {
    content: ClientDataType[];
    backRedirect: string;
    request: any;
}

function ClientModal({ content, backRedirect, request }: ClientModalProps) {
    const theme = localStorage.getItem('theme');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const selectedRef = useRef<HTMLDivElement>(null);

    const selectedClientUID = useSelector(
        (state: RootState) => state.selectedInfo.selectedClientUID,
    );

    useEffect(() => {
        if (selectedRef.current !== null) {
            selectedRef.current.scrollIntoView({
                behavior: 'smooth',
                inline: 'start',
                block: 'center',
            });
        }
    }, [content]);

    const setNomenclature = (selectedClient: string, selectedClientUID: string) => {
        refreshData();
        setData();

        navigate(`/${backRedirect}`, { state: { request: request, paramToFixPage: true } });

        function setData() {
            dispatch(setSelectedClient(selectedClient));
            dispatch(setSelectedClientUID(selectedClientUID));
        }

        function refreshData() {
            dispatch(selectReceiver(null));
            dispatch(selectReceiverUID(null));
            dispatch(selectAddress(null));
            dispatch(selectAddressUID(null));
            dispatch(selectNomenclature(null));
            dispatch(selectNomenclatureUID(null));
            dispatch(selectContactInfo(null));
            dispatch(setIsGraniteavailable(false));
            dispatch(setNomenclatureMobility(null));
        }
    };

    return (
        <div className="contentContainer">
            <div className="contentFields">
                {content.map((ticket, index) => {
                    return (
                        <div
                            onClick={() => setNomenclature(ticket.title, ticket.uid)}
                            key={index}
                            ref={ticket.uid === selectedClientUID ? selectedRef : null}
                            className="contentTicket"
                        >
                            <span className="contentTxt">{ticket.title}</span>
                            {ticket.uid === selectedClientUID && <ModalOk theme={theme} />}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default ClientModal;
