import React from 'react';

export default function Clock() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="61"
            height="60"
            viewBox="0 0 61 60"
            fill="none"
        >
            <path
                d="M56.1211 24.1406H41.1211C40.8633 24.1406 40.6523 24.3516 40.6523 24.6094V27.4219C40.6523 27.6797 40.8633 27.8906 41.1211 27.8906H56.1211C56.3789 27.8906 56.5898 27.6797 56.5898 27.4219V24.6094C56.5898 24.3516 56.3789 24.1406 56.1211 24.1406ZM48.2695 32.1094H41.1211C40.8633 32.1094 40.6523 32.3203 40.6523 32.5781V35.3906C40.6523 35.6484 40.8633 35.8594 41.1211 35.8594H48.2695C48.5273 35.8594 48.7383 35.6484 48.7383 35.3906V32.5781C48.7383 32.3203 48.5273 32.1094 48.2695 32.1094ZM28.7168 18.8965H26.1797C25.8164 18.8965 25.5234 19.1895 25.5234 19.5527V34.084C25.5234 34.2949 25.623 34.4883 25.793 34.6113L34.5176 40.9746C34.8105 41.1855 35.2207 41.127 35.4316 40.834L36.9375 38.7773V38.7715C37.1484 38.4785 37.084 38.0684 36.791 37.8574L29.3672 32.4902V19.5527C29.373 19.1895 29.0742 18.8965 28.7168 18.8965Z"
                fill="var(--redA-rebB)"
            />
            <path
                d="M47.9063 39.4862H44.5195C44.1914 39.4862 43.8809 39.6561 43.7051 39.9373C42.9609 41.1151 42.0938 42.2049 41.0977 43.201C39.3809 44.9178 37.3828 46.2655 35.1621 47.203C32.8594 48.1756 30.416 48.6678 27.8965 48.6678C25.3711 48.6678 22.9277 48.1756 20.6309 47.203C18.4102 46.2655 16.4121 44.9178 14.6953 43.201C12.9785 41.4842 11.6309 39.4862 10.6934 37.2655C9.72071 34.9686 9.22852 32.5252 9.22852 29.9998C9.22852 27.4744 9.72071 25.0369 10.6934 22.7342C11.6309 20.5135 12.9785 18.5155 14.6953 16.7987C16.4121 15.0819 18.4102 13.7342 20.6309 12.7967C22.9277 11.8241 25.377 11.3319 27.8965 11.3319C30.4219 11.3319 32.8652 11.8241 35.1621 12.7967C37.3828 13.7342 39.3809 15.0819 41.0977 16.7987C42.0938 17.7948 42.9609 18.8846 43.7051 20.0623C43.8809 20.3436 44.1914 20.5135 44.5195 20.5135H47.9063C48.3106 20.5135 48.5684 20.0916 48.3867 19.7342C44.5664 12.1346 36.8203 7.19515 28.1719 7.09554C15.5098 6.93733 4.9922 17.3026 4.96876 29.953C4.94532 42.6268 15.2168 52.91 27.8906 52.91C36.6504 52.91 44.5254 47.953 48.3867 40.2655C48.5684 39.908 48.3047 39.4862 47.9063 39.4862Z"
                fill="var(--redA-rebB)"
            />
        </svg>
    );
}
