import React from 'react';

import styles from './NewMarkMenu.module.css';

import { useDispatch, useSelector } from 'react-redux';
import { useSessionStorage } from 'usehooks-ts';
import { useLocation } from 'react-router-dom/dist';
import { useEffect } from 'react';
import { useRef } from 'react';

import Header from '../../../Utils/Requests/CreateRequest/Header';
import { setLaitance, setPumpCount } from '../../../../store/slices/sliceSelectedInfo';
import { setIsSearchAvaileble } from '../../../../store/slices/sliceRequests';

import { RootState } from '../../../../store/store';

import { fixPageState } from '../../../../store/slices/sliceUser';

import Capacity from '../CreateRequest/CreatingComponents/Capacity';
import Nomenclature from '../CreateRequest/CreatingComponents/Nomenclature';
import DateAndTimeInput from '../../../Utils/Requests/CreateRequest/Input/DateAndTimeInput/DateAndTimeInput';
import EntaranceSelector from '../../../Utils/Requests/CreateRequest/Input/EntaranceSelector/EntaranceSelector';
import { addHours, addWeeks, setHours, setMinutes } from 'date-fns';
import SubmitButton from '../../../Features/SubmitButton/SubmitButton';

function NewMarkMenu() {
    const dispatch = useDispatch();
    const location = useLocation();
    const isFixedPage = useSelector((state: RootState) => state.user.isFixedPage);
    const callbackFixPage = location?.state?.paramToFixPage;

    const savedScrollProperty = sessionStorage?.getItem('savedScrollProperty');

    if (callbackFixPage && isFixedPage) {
        dispatch(fixPageState(false));
        fixPage();
    }

    function fixPage() {
        if (savedScrollProperty) {
            const scrollValue = parseInt(savedScrollProperty, 10);

            window.scrollTo({
                top: scrollValue,
                left: 0,
            });
        }
    }

    const refNomenclatureSelector = useRef<HTMLDivElement>(null);
    const refPumpSelector = useRef<HTMLDivElement>(null);

    let startValueAddressUID = useSelector(
        (state: RootState) => state.selectedInfo.selectedAddressUID,
    );
    let startValueNomenclature = useSelector(
        (state: RootState) => state.selectedInfo.selectedNomenclature,
    );
    const nomenclatureUID = useSelector(
        (state: RootState) => state.selectedInfo.selectedNomenclatureUID,
    );
    const contactInformation = useSelector(
        (state: RootState) => state.selectedInfo.selectedContactNumber,
    );
    const deliveryType = useSelector((state: RootState) => state.marks.deliveryType);
    const factoryType = useSelector((state: RootState) => state.marks.factoryType);

    const startValueDate = useRef(
        useSelector((state: RootState) => state.selectedInfo.selectedDate),
    );

    let startValueSelectedAcceptanceType = '';
    let startValueIntervalValue = '01:00';
    let startValueIntensity = 10;
    let startValueCapacity = 20;
    let startValueConeDraft = 18;

    fixDate();

    const addressUID = startValueAddressUID;
    const nomenclature = startValueNomenclature;

    const [selectedAcceptanceType, setSelectedAcceptanceType] = useSessionStorage(
        'acceptanceType',
        startValueSelectedAcceptanceType,
    );
    const [receivingMethod, setReceivingMethod] = useSessionStorage('receivingMethod', 'Интервал');
    const [intervalValue, setIntervalValue] = useSessionStorage(
        'interval',
        startValueIntervalValue,
    );

    const [intensity, setIntensity] = useSessionStorage('intensity', startValueIntensity);
    const [capacity, setCapacity] = useSessionStorage('capacity', startValueCapacity);
    const [coneDraft, setConeDraft] = useSessionStorage('coneDraft', startValueConeDraft);
    const [crane, setCrane] = useSessionStorage('crane', 1);

    const selectedNomenclature = useSelector(
        (state: RootState) => state.selectedInfo.selectedNomenclature,
    );
    const availebleEntarances = useSelector(
        (state: RootState) => state.requests.availebleEntarances,
    );
    const date = useSelector((state: RootState) => state.marks.date);
    const dataMarks = useSelector((state: RootState) => state.marks.dataMarks);

    const laitance = useSelector((state: RootState) => state.selectedInfo.laitance);
    const setStoreLaitance = (value: number) => {
        dispatch(setLaitance(value));
    };
    const pumpCount = useSelector((state: RootState) => state.selectedInfo.pumpCount);
    const setStorePumpCount = (value: number) => {
        dispatch(setPumpCount(value));
    };

    useEffect(() => {
        dispatch(setIsSearchAvaileble(false));
    }, []);

    useEffect(() => {
        if (
            selectedNomenclature === 'Цементное молоко' ||
            selectedNomenclature === 'Цементное молоко густое'
        ) {
            setCapacity(1);
        }
    }, [selectedNomenclature]);

    function fixDate() {
        if (startValueDate.current) {
            if (startValueDate.current.getTime() <= new Date().getTime()) {
                startValueDate.current = new Date();
            }
        }
    }

    return (
        <div className="requestMenu">
            <Header headerTitle={'Добавление марки'} backRedirect={'createRequestMarks'} />
            <div className={styles.page}>
                <div className="nomenclatureAndCapacity">
                    <span className="titleRequestMenu">Когда</span>
                    <div className="addressAndTimeContent">
                        <DateAndTimeInput
                            date={date}
                            setDate={() => {
                                return null;
                            }}
                            isNewRequest={true}
                            upper_date={addWeeks(new Date(), 2)}
                            lower_date={addHours(new Date(), 2)}
                            upper_time={setHours(setMinutes(new Date(), 30), 23)}
                            lower_time={setHours(setMinutes(new Date(), 0), 0)}
                        />

                        {Object.keys(availebleEntarances).length !== 0 && (
                            <EntaranceSelector entaranses={availebleEntarances} request={{}} />
                        )}
                    </div>
                    <div style={{ marginBottom: '32px' }}></div>
                </div>
                <Nomenclature
                    requestCopy={false}
                    capacity={capacity}
                    setCapacity={setCapacity}
                    coneDraft={coneDraft}
                    setConeDraft={setConeDraft}
                    addressUID={addressUID}
                    selectedNomenclature={nomenclature}
                    refNomenclatureSelector={refNomenclatureSelector}
                />
                {selectedNomenclature !== 'Цементное молоко' &&
                    selectedNomenclature !== 'Цементное молоко густое' && (
                        <Capacity
                            capacity={capacity}
                            requestType={'Создание'}
                            intervalValue={intervalValue}
                            receivingMethod={receivingMethod}
                            setRecivingMethod={setReceivingMethod}
                            intensity={intensity}
                            setIntervalValue={setIntervalValue}
                            setIntensity={setIntensity}
                            acceptanceType={selectedAcceptanceType}
                            setSelectedAccptanceType={setSelectedAcceptanceType}
                            pumpCount={pumpCount}
                            setPumpCount={setStorePumpCount}
                            crane={crane}
                            setCrane={setCrane}
                            laitance={laitance}
                            setLaitance={setStoreLaitance}
                            refPumpSelector={refPumpSelector}
                        />
                    )}
                <br />
                <br />
                <br />

                <SubmitButton
                    crane={crane}
                    methodToExecute={'addMark'}
                    action={'create'}
                    requestType={'marks'}
                    initialTextValue={'Добавить марку'}
                    successType={'/success'}
                    addressUID={addressUID}
                    refAddressSelector={{} as React.RefObject<HTMLDivElement>}
                    refPhoneSelector={{} as React.RefObject<HTMLDivElement>}
                    isRequestCopy={false}
                    intervalValue={intervalValue}
                    intensity={intensity}
                    capacity={capacity}
                    receivingMethod={receivingMethod}
                    coneDraft={coneDraft}
                    pumpCount={pumpCount}
                    laitance={laitance}
                    refNomenclatureSelector={refNomenclatureSelector}
                    refPumpSelector={refPumpSelector}
                    date={date}
                    nomenclatureUID={nomenclatureUID}
                    contactInformation={contactInformation}
                    selectedAcceptanceType={selectedAcceptanceType}
                    deliveryType={deliveryType}
                    factoryType={factoryType}
                />
            </div>
        </div>
    );
}

export default NewMarkMenu;
