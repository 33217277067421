import React from 'react';

function CurrentCars({ theme }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                d="M4 12.2338V3.76619L4 3.76619L11.0563 8L4 12.2338Z"
                stroke={theme === 'dark' ? '#fff' : '#202020'}
                strokeWidth="2"
            />
        </svg>
    );
}

export default CurrentCars;
