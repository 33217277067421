import React from 'react';

import uuid from 'react-uuid';

import { RequestFieldDataType } from '../../../Entities/Interfaces/Interfaces';

import styles from './RequestField.module.css';

import RequestDate from './RequestDate/RequestDate';
import Requests from './Requests/Requests';

interface RequestsFieldProps {
    dates: {
        date: string;
        data: RequestFieldDataType[];
    };
    isSearchView?: boolean;
}

function RequestsField({ dates, isSearchView }: RequestsFieldProps) {
    return (
        <div className={styles.content}>
            <div className={styles.date}>
                <RequestDate isPushes={false} date={dates.date} />
                {dates.data.map((order) => {
                    return <Requests isSearchView={isSearchView} key={uuid()} order={order} />;
                })}
            </div>
        </div>
    );
}

export default React.memo(RequestsField);
