import React, { Dispatch, SetStateAction, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import ReceivingMethodSelect from '../../../../Utils/Requests/CreateRequest/Selectors/ReceivingMethodSelect';
import AcceptanceTypeSelect from '../../../../Utils/Requests/CreateRequest/AcceptanceTypeSelect/AcceptanceTypeSelect';
import CarSelector from '../../../../Utils/Requests/CreateRequest/Input/CarSelector/CarSelector';
import { setCars } from '../../../../../store/slices/sliceSelectedInfo';
import Plus from '../../../../../content/svg/RequestsPage/Plus';
import Minus from '../../../../../content/svg/RequestsPage/Minus';
import { addMinutes, format, parse, subMinutes } from 'date-fns';
import InputWithButtons from '../../../../Utils/Requests/CreateRequest/Input/InputWithButtons/InputWithButtons';

interface CapacityProps {
    intensity: number;
    setIntensity: Dispatch<SetStateAction<number>>;
    intervalValue: string;
    setIntervalValue: Dispatch<SetStateAction<string>>;
    acceptanceType: string;
    setSelectedAccptanceType: Dispatch<SetStateAction<string>>;
    receivingMethod: string;
    setRecivingMethod: Dispatch<SetStateAction<string>>;
    pumpCount: number;
    setPumpCount: (value: number) => void;
    crane: number;
    setCrane: (value: number) => void;
    laitance: number;
    setLaitance: (value: number) => void;
    requestType: string;
    capacity: number;
    refPumpSelector?: React.RefObject<HTMLDivElement>;
}

function Capacity({
    intervalValue,
    intensity,
    setIntervalValue,
    setIntensity,
    acceptanceType,
    setSelectedAccptanceType,
    receivingMethod,
    setRecivingMethod,
    pumpCount,
    setPumpCount,
    crane,
    setCrane,
    laitance,
    setLaitance,
    requestType,
    capacity,
    refPumpSelector,
}: CapacityProps) {
    const theme = localStorage.getItem('theme');

    const dispatch = useDispatch();

    function controleInterval(value: number, currentInterval: string, action: string) {
        const date = parse(currentInterval, 'HH:mm', new Date());

        if (action === '+') {
            setIntervalValue(format(addMinutes(date, value), 'HH:mm'));
        } else {
            setIntervalValue(format(subMinutes(date, value), 'HH:mm'));
        }
    }

    function controleIntensity(value: number, currentIntensity: number, action: string) {
        // currentIntensity = parseInt(currentIntensity, 10);

        if (action === '+') {
            if (currentIntensity <= 0) {
                setIntensity(0 + value);
            } else {
                setIntensity(currentIntensity + value);
            }
        } else {
            if (currentIntensity <= 0) {
                setIntensity(0);
            } else {
                setIntensity(currentIntensity - value);
            }
        }
    }

    const selectIntervalOrIntensity = (receivingMethod: React.SetStateAction<string>) => {
        setRecivingMethod(receivingMethod);
    };

    useEffect(() => {
        if (capacity >= 21) {
            dispatch(setCars(0));
        }
    }, [capacity]);

    return (
        <>
            <div className="methodAndInterval">
                <span className="titleRequestMenu">Способ</span>
                <div className="methodAndIntervalSelector">
                    <AcceptanceTypeSelect
                        setIntensity={setIntensity}
                        setIntervalValue={setIntervalValue}
                        setSelectedAccptanceType={setSelectedAccptanceType}
                        acceptanceType={acceptanceType}
                    />
                    {acceptanceType === 'Насос' && (
                        <ReceivingMethodSelect
                            requestType={requestType}
                            laitance={laitance}
                            setLaitance={setLaitance}
                            pumpCount={pumpCount}
                            setPumpCount={setPumpCount}
                            acceptanceType={acceptanceType}
                            refPumpSelector={refPumpSelector}
                        />
                    )}

                    {acceptanceType === 'Кран вертикал' && (
                        <InputWithButtons
                            inputTitle={'Кол-во кранов, шт'}
                            inputValue={crane}
                            setInputValue={setCrane}
                            valueToChange={1}
                            maxValue={3}
                            minValue={1}
                        />
                    )}

                    {acceptanceType === 'Кран горизонт' && (
                        <InputWithButtons
                            inputTitle={'Кол-во кранов, шт'}
                            inputValue={crane}
                            setInputValue={setCrane}
                            valueToChange={1}
                            maxValue={3}
                            minValue={1}
                        />
                    )}
                </div>
            </div>

            <div className="nomenclatureAndCapacity">
                <span className="titleRequestMenu">интервал / интенсивность</span>
                {capacity <= 20 && <CarSelector />}
                <div className="methodAndIntervalSelector">
                    <div className="coneDraftButtons">
                        <button
                            id="22"
                            onClick={() => selectIntervalOrIntensity('Интервал')}
                            className={
                                receivingMethod === 'Интервал'
                                    ? 'coneDraftBtn clicked4'
                                    : 'coneDraftBtn'
                            }
                        >
                            Интервал
                        </button>
                        <button
                            id="23"
                            onClick={() => selectIntervalOrIntensity('Интенсивность')}
                            className={
                                receivingMethod === 'Интенсивность'
                                    ? 'coneDraftBtn clicked4'
                                    : 'coneDraftBtn'
                            }
                        >
                            Интенсивность
                        </button>
                    </div>
                    {receivingMethod === 'Интервал' ? (
                        <div className="inputRequestMenu">
                            <Minus
                                theme={theme}
                                onClick={() => controleInterval(5, intervalValue, '-')}
                            />

                            <div className="inputAndTitle">
                                <span className="labelForInput">Интервал</span>
                                <input
                                    value={intervalValue}
                                    onChange={(event) => setIntervalValue(event.target.value)}
                                    type="time"
                                    className="inputedInformation"
                                    min={'00:05'}
                                />
                            </div>

                            <Plus
                                theme={theme}
                                onClick={() => controleInterval(5, intervalValue, '+')}
                            />
                        </div>
                    ) : (
                        <div className="inputRequestMenu">
                            <Minus theme={theme} onClick={() => setIntensity(intensity - 1)} />

                            <div className="inputAndTitle">
                                <span className="labelForInput">
                                    Интенсивность, м<sup className="supLowTxt">3</sup>/ч
                                </span>
                                <input
                                    value={intensity}
                                    onChange={(event) => setIntensity(+event.target.value)}
                                    type="tel"
                                    className="inputedInformation"
                                />
                            </div>

                            <Plus theme={theme} onClick={() => setIntensity(intensity + 1)} />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default Capacity;
