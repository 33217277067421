import React from 'react';

export default function RightArrow() {
    const theme = localStorage.getItem('theme');
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.21967 17.7803C8.92678 17.4874 8.92678 17.0126 9.21967 16.7197L13.6893 12.25L9.21967 7.78033C8.92678 7.48744 8.92678 7.01256 9.21967 6.71967C9.51256 6.42678 9.98744 6.42678 10.2803 6.71967L15.2803 11.7197C15.5732 12.0126 15.5732 12.4874 15.2803 12.7803L10.2803 17.7803C9.98744 18.0732 9.51256 18.0732 9.21967 17.7803Z"
                fill={theme === 'dark' ? '#FFFFFF' : '#202020'}
            />
        </svg>
    );
}
